var DATE_REGEX, DBEP_PUBLICATION_STATUS_DOC_ID, DbHelper, Exceptions, MASTER_PROJECT_ID, ORGANIZATIONS_DATA_DOC_ID, PANEL_VOICE_REGEX, Project, ProjectDbService, TASKS_TO_RUN_DOC_ID, W, addProjectToCollection, createAdolopmentTask, mdaImportTopicReplicationFilterFn, mdaTablePrefix, mdaWorkflowReplicationFilterFn, mediator, prepareDeleteConflictDocs, projectsHelper, questionsAndSnapshotReplicationFilterFn, refDocPrefix, removeCoITask, removeConsensusTask, restoreSnapshotReplicationFilterFn, snapshotReplicationFilterFn, utils, _ref, _ref1,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

DbHelper = require('base/lib/db_helper');

Project = require('models/project');

Exceptions = require('lib/exceptions');

utils = require('base/lib/utils');

projectsHelper = require('actions/async/projects');

prepareDeleteConflictDocs = require('base/lib/doc_helper').prepareDeleteConflictDocs;

_ref = require('lib/doc_prefixes'), mdaTablePrefix = _ref.mdaTable, ORGANIZATIONS_DATA_DOC_ID = _ref.organizationsData, refDocPrefix = _ref.reference, TASKS_TO_RUN_DOC_ID = _ref.tasksToRun;

DBEP_PUBLICATION_STATUS_DOC_ID = require('lib/doc_ids').DBEP_PUBLICATION_STATUS;

MASTER_PROJECT_ID = require('lib/mda_helper').MASTER_PROJECT_ID;

_ref1 = require('lib/project_tasks/tasks'), removeConsensusTask = _ref1.remove_old_voting_consensus, removeCoITask = _ref1.remove_coi, createAdolopmentTask = _ref1.create_adolopment_etd;

W = require('when');

mediator = require('mediator');

DATE_REGEX = /^\([0-9]{4}\-[0-9]{2}\-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}\) /;

PANEL_VOICE_REGEX = /p_[a-zA-Z0-9_\-]+:(etd\-(snapshots|highlights)|panel\-voice\-.+|results).*/;

snapshotReplicationFilterFn = function(login, projectId) {
  return function(doc) {
    if (doc._id === ORGANIZATIONS_DATA_DOC_ID) {
      return false;
    }
    if (doc.docType === 'projectMember' && doc.gdtLogin !== login) {
      return false;
    }
    if (doc._id === TASKS_TO_RUN_DOC_ID) {
      return false;
    }
    if (doc._id === 'publication_status') {
      return false;
    }
    if (doc._id === DBEP_PUBLICATION_STATUS_DOC_ID) {
      return false;
    }
    if (doc._id === 'questions_generation') {
      return false;
    }
    if (doc._id === 'outcomes_generation') {
      return false;
    }
    if (doc._id === 'etd-voting-preferences') {
      return false;
    }
    if (doc._id === ("" + projectId + ":etd-voting")) {
      return false;
    }
    if (doc._id.match(PANEL_VOICE_REGEX)) {
      return false;
    }
    return true;
  };
};

questionsAndSnapshotReplicationFilterFn = function(login, projectId, selectedQuestions) {
  return function(doc) {
    if (doc.docType === 'question' && !selectedQuestions.contains(doc._id)) {
      return false;
    }
    return snapshotReplicationFilterFn(login, projectId)(doc);
  };
};

restoreSnapshotReplicationFilterFn = function(doc) {
  var _ref2;
  return (_ref2 = doc._id) !== ORGANIZATIONS_DATA_DOC_ID && _ref2 !== TASKS_TO_RUN_DOC_ID && _ref2 !== DBEP_PUBLICATION_STATUS_DOC_ID;
};

mdaWorkflowReplicationFilterFn = function(login, docIds, projectId) {
  if (projectId == null) {
    projectId = MASTER_PROJECT_ID;
  }
  return function(doc) {
    var _ref2;
    if (doc._id === projectId) {
      return true;
    }
    if (doc._id === 'latestChange') {
      return true;
    }
    if (doc.docType === 'projectMember' && doc.gdtLogin === login) {
      return true;
    }
    if (doc._id.indexOf(refDocPrefix) === 0) {
      return true;
    }
    if (_ref2 = doc._id, __indexOf.call(docIds, _ref2) >= 0) {
      return true;
    }
    return false;
  };
};

mdaImportTopicReplicationFilterFn = function(docIds, docIdsNotToReplicate) {
  return function(doc) {
    var _ref2, _ref3;
    if (_ref2 = doc._id, __indexOf.call(docIdsNotToReplicate, _ref2) >= 0) {
      return false;
    }
    if (doc._id.indexOf(refDocPrefix) === 0) {
      return true;
    }
    if (_ref3 = doc._id, __indexOf.call(docIds, _ref3) >= 0) {
      return true;
    }
    return false;
  };
};

addProjectToCollection = function(projectId) {
  var newDb;
  newDb = DbHelper.localReplica(projectId);
  return newDb.get(projectId).then(function(doc) {
    mediator.projects.add(new Project(doc));
    return projectId;
  });
};

module.exports = ProjectDbService = (function() {
  function ProjectDbService() {}

  ProjectDbService.prototype.generateId = function(login) {
    return "p_" + login + "_" + (utils.generateGUID());
  };

  ProjectDbService.prototype.projectsRedirectingTo = function(project) {
    var duplicateOf;
    duplicateOf = project.get('duplicateOf');
    if (duplicateOf) {
      return [duplicateOf];
    } else {
      return [];
    }
  };

  ProjectDbService.prototype.duplicate = function(login, projectId, newProjectName) {
    var p;
    if (newProjectName == null) {
      newProjectName = null;
    }
    p = DbHelper.localReplica(projectId);
    return W(p.get(projectId).then((function(_this) {
      return function() {
        var newDb, newId;
        newId = _this.generateId(login);
        newDb = DbHelper.localReplica(newId);
        return p.replicate.to(newDb).then(function() {
          return _this._doChangesToMetadoc(projectId, newId, newProjectName);
        }).then(function() {
          return _this._updateProjectMembers(newId, login);
        }).then(function() {
          return _this._removeVotingDocs(projectId, newId);
        }).then(function() {
          return _this._updateLatestChangeDoc(newId);
        }).then(function() {
          return newId;
        });
      };
    })(this)));
  };

  ProjectDbService.prototype.createAdolopment = function(login, projectId, selectedQuestions) {
    var p;
    p = DbHelper.localReplica(projectId);
    return W(p.get(projectId).then((function(_this) {
      return function(metadoc) {
        var newDb, newId, newProjectName;
        newId = _this.generateId(login);
        newDb = DbHelper.localReplica(newId);
        newProjectName = "(Adolopment) " + metadoc.name;
        return p.replicate.to(newDb, {
          filter: questionsAndSnapshotReplicationFilterFn(login, projectId, selectedQuestions)
        }).then(function() {
          return _this._doChangesToMetadoc(projectId, newId, newProjectName);
        }).then(function() {
          return _this._setLinkToAdolopment(projectId, newId);
        }).then(function() {
          return _this._updateProjectMembers(newId, login);
        }).then(function() {
          return _this._removeVotingDocs(projectId, newId);
        }).then(function() {
          return _this._updateLatestChangeDoc(newId);
        }).then(function() {
          return removeCoITask.run(newId);
        }).then(function() {
          return removeConsensusTask.run(newId);
        }).then(function() {
          return createAdolopmentTask.run(newId, {
            selectedQuestions: selectedQuestions
          });
        }).then(function() {
          return addProjectToCollection(newId);
        }).then(function() {
          return newId;
        });
      };
    })(this)));
  };

  ProjectDbService.prototype.createSnapshot = function(login, projectId) {
    var p;
    p = DbHelper.localReplica(projectId);
    return W(p.get(projectId).then((function(_this) {
      return function(metadoc) {
        var newDb, newId, newProjectName;
        newProjectName = "(" + (moment().format('YYYY-MM-DD HH:mm:ss')) + ") " + metadoc.name;
        newId = _this.generateId(login);
        newDb = DbHelper.localReplica(newId);
        return p.replicate.to(newDb, {
          filter: snapshotReplicationFilterFn(login, projectId)
        }).then(function() {
          return _this._doChangesToMetadoc(projectId, newId, newProjectName);
        }).then(function() {
          return _this._updateLatestChangeDoc(newId);
        }).then(function() {
          return _this._makeCurrentUserAdmin(newId);
        }).then(function() {
          return removeCoITask.run(newId);
        }).then(function() {
          return removeConsensusTask.run(newId);
        }).then(function() {
          return addProjectToCollection(newId);
        }).then(function() {
          return newId;
        });
      };
    })(this)));
  };

  ProjectDbService.prototype.restoreSnapshot = function(login, projectId) {
    var p;
    p = DbHelper.localReplica(projectId);
    return W(p.get(projectId).then((function(_this) {
      return function(metadoc) {
        var newDb, newId, newProjectName;
        newProjectName = metadoc.name.replace(DATE_REGEX, '');
        newId = _this.generateId(login);
        newDb = DbHelper.localReplica(newId);
        return p.replicate.to(newDb, {
          filter: restoreSnapshotReplicationFilterFn
        }).then(function() {
          return _this._doChangesToMetadoc(projectId, newId, newProjectName);
        }).then(function() {
          return _this._updateLatestChangeDoc(newId);
        }).then(function() {
          return newId;
        });
      };
    })(this)));
  };

  ProjectDbService.prototype.createMdaWorkflow = function(login, name, docIds) {
    var mp, newDb, newId;
    mp = DbHelper.localReplica(MASTER_PROJECT_ID);
    newId = this.generateId('mda_workflow');
    newDb = DbHelper.localReplica(newId);
    return mp.replicate.to(newDb, {
      filter: mdaWorkflowReplicationFilterFn(login, docIds),
      checkpoint: false
    }).then((function(_this) {
      return function() {
        return _this._doChangesToMetadoc(MASTER_PROJECT_ID, newId, name);
      };
    })(this)).then((function(_this) {
      return function() {
        return _this._makeCurrentUserAdmin(newId);
      };
    })(this)).then((function(_this) {
      return function() {
        return _this._updateLatestChangeDoc(newId);
      };
    })(this)).then(function() {
      return addProjectToCollection(newId);
    }).then(function() {
      return newId;
    });
  };

  ProjectDbService.prototype.createMdaWorkflowSnapshot = function(login, projectId, docIds) {
    var p;
    p = DbHelper.localReplica(projectId);
    return W(p.get(projectId).then((function(_this) {
      return function(metadoc) {
        var newDb, newId, newProjectName;
        newId = _this.generateId('mda_workflow');
        newDb = DbHelper.localReplica(newId);
        newProjectName = "(" + (moment().format('YYYY-MM-DD HH:mm:ss')) + ") " + metadoc.name;
        return p.replicate.to(newDb, {
          filter: mdaWorkflowReplicationFilterFn(login, docIds, projectId),
          checkpoint: false
        }).then(function() {
          return _this._doChangesToMetadoc(projectId, newId, newProjectName);
        }).then(function() {
          return _this._makeCurrentUserAdmin(newId);
        }).then(function() {
          return _this._updateLatestChangeDoc(newId);
        }).then(function() {
          return addProjectToCollection(newId);
        }).then(function() {
          return {
            newId: newId,
            newProjectName: newProjectName
          };
        });
      };
    })(this)));
  };

  ProjectDbService.prototype.importMdaTopic = function(projectId, docIds) {
    var destinationProject, docIdsNotToReplicatePromise, sourceProject;
    sourceProject = DbHelper.localReplica(projectId);
    destinationProject = DbHelper.localReplica(mediator.project.id);
    docIdsNotToReplicatePromise = projectId === MASTER_PROJECT_ID ? W.resolve([]) : W(destinationProject.allDocs()).then(function(_arg) {
      var rows;
      rows = _arg.rows;
      return _.chain(rows).pluck('id').compact().filter(function(id) {
        return _.some([mdaTablePrefix, refDocPrefix], function(prefix) {
          return id.indexOf(prefix) === 0;
        });
      }).value();
    });
    return docIdsNotToReplicatePromise.then(function(docIdsNotToReplicate) {
      return sourceProject.replicate.to(destinationProject, {
        filter: mdaImportTopicReplicationFilterFn(docIds, docIdsNotToReplicate),
        checkpoint: false
      });
    });
  };

  ProjectDbService.prototype._updateLatestChangeDoc = function(projectId) {
    var db;
    db = DbHelper.localReplica(projectId);
    return W(db.get('latestChange', {
      conflicts: true
    }).then(function(doc) {
      var delConflictDocs, timestamp;
      timestamp = Date.now();
      _.extend(doc, {
        timestamp: timestamp
      }, {
        '$timestamp': timestamp
      });
      delConflictDocs = prepareDeleteConflictDocs(projectId, 'latestChange', doc._conflicts);
      return db.bulkDocs(delConflictDocs.concat(doc));
    }));
  };

  ProjectDbService.prototype._doChangesToMetadoc = function(projectId, newId, newProjectName) {
    var db;
    db = DbHelper.localReplica(newId);
    return W(db.get(projectId).then(function(doc) {
      var name, newDoc;
      name = newProjectName != null ? newProjectName : doc != null ? doc.name : void 0;
      newDoc = _({}).extend(doc, {
        _id: newId,
        cloneOf: projectId,
        name: name
      });
      delete newDoc._rev;
      return db.put(newDoc).then(function() {
        return db.remove(doc);
      });
    }));
  };

  ProjectDbService.prototype._setLinkToAdolopment = function(projectId, newId) {
    var db;
    db = DbHelper.localReplica(projectId);
    return W(db.get(projectId).then(function(doc) {
      var linksToAdolopedProjects;
      linksToAdolopedProjects = (doc.linksToAdolopedProjects || []).concat(newId);
      doc = _({}).extend(doc, {
        linksToAdolopedProjects: linksToAdolopedProjects
      });
      return db.put(doc);
    }));
  };

  ProjectDbService.prototype._makeCurrentUserAdmin = function(projectId) {
    var projectDb;
    projectDb = DbHelper.localReplica(projectId);
    return projectsHelper.fetchProjectMembers(projectId).then(function(_arg) {
      var login, member, members;
      members = _arg.members;
      login = mediator.user.get('name');
      member = _.find(members, function(m) {
        return m.gdtLogin === login;
      });
      if (!member) {
        return;
      }
      member.accessRights = ['admin'];
      return projectDb.put(member);
    });
  };

  ProjectDbService.prototype._updateProjectMembers = function(projectId, userLogin) {
    var projectDb;
    projectDb = DbHelper.localReplica(projectId);
    return projectsHelper.fetchProjectMembers(projectId).then(function(_arg) {
      var members, nonAdmins;
      members = _arg.members;
      nonAdmins = _(members).reject(function(member) {
        return member.gdtLogin === userLogin;
      });
      nonAdmins = _(nonAdmins).map(function(m) {
        m._deleted = true;
        return m;
      });
      return projectDb.bulkDocs(nonAdmins);
    });
  };

  ProjectDbService.prototype._removeVotingDocs = function(projectId, newId) {
    var projectDb;
    projectDb = DbHelper.localReplica(newId);
    return projectDb.allDocs({
      include_docs: true,
      keys: ['etd-voting-preferences', "" + projectId + ":etd-voting"]
    }).then(function(_arg) {
      var doc, docs, rows, updatedDocs, _i, _len;
      rows = _arg.rows;
      docs = _.pluck(rows, 'doc');
      updatedDocs = [];
      for (_i = 0, _len = docs.length; _i < _len; _i++) {
        doc = docs[_i];
        if (doc != null) {
          updatedDocs.push(_.extend(doc, {
            _deleted: true
          }));
        }
      }
      if (!_.isEmpty(updatedDocs)) {
        return projectDb.bulkDocs(updatedDocs);
      }
    });
  };

  ProjectDbService.prototype.destroy = function(id) {
    return W(DbHelper.localReplica(id).destroy());
  };

  ProjectDbService.prototype.isProjectDb = function(dbName) {
    return _.str.startsWith(dbName, 'p_');
  };

  ProjectDbService.prototype.checkIfCentralMetadocExists = function(id) {
    return W(DbHelper.centralReplica(id).get(id)).then(function() {
      return true;
    }, function(err) {
      var _ref2;
      if (err.status === 404) {
        return false;
      } else if ((_ref2 = err.status) === 401 || _ref2 === 0 || _ref2 === 500) {
        throw new Exceptions.no_connection;
      } else if (err.status === 403) {
        throw new Exceptions.authentication.forbidden;
      } else {
        throw err;
      }
    });
  };

  ProjectDbService.prototype.fetchCentralProject = function(id) {
    mediator.publish('!replication:fetchCentralProjectStarted', id);
    return W(DbHelper.centralReplica(id).replicate.to(DbHelper.localReplica(id)))["catch"]((function(_this) {
      return function(err) {
        if (err.status === 404) {
          throw new Exceptions.project_missing_on_server;
        } else if (err.status === 401) {
          mediator.publish('sessionExpired');
          return W.promise(function(resolve) {
            return mediator.subscribe('login', function() {
              return resolve(_this.fetchCentralProject(id));
            });
          });
        } else if (err.status === 403) {
          throw new Exceptions.authentication.forbidden;
        } else if (err.status >= 400 && err.status < 500) {
          throw err;
        } else {
          throw new Exceptions.project_cannot_be_fetched;
        }
      };
    })(this))["finally"](function() {
      return mediator.publish('!replication:fetchCentralProjectEnded', id);
    });
  };

  return ProjectDbService;

})();
