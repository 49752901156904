var additionalOptions, desirableAndUndesirableModerateText, mediator, sectionDescription, sectionOptions, sectionRatingDescription, sections, veryLowText;

try {
  mediator = require('mediator');
} catch (_error) {
  mediator = {
    services: {
      switches: {
        isServerSwitchOn: function() {
          return false;
        }
      }
    }
  };
}

desirableAndUndesirableModerateText = function() {
  if (mediator.services.switches.isServerSwitchOn('acpFeatures')) {
    return 'Medium';
  } else {
    return 'Moderate';
  }
};

veryLowText = function() {
  if (mediator.services.switches.isServerSwitchOn('acpFeatures')) {
    return 'Insufficient';
  } else {
    return 'Very low';
  }
};

sectionDescription = function(sectionId) {
  var acpFeaturesOn;
  acpFeaturesOn = mediator.services.switches.isServerSwitchOn('acpFeatures');
  switch (sectionId) {
    case 'desirableEffects':
      if (acpFeaturesOn) {
        return "How substantial are the differences in desirable anticipated effects?";
      } else {
        return "How substantial are the desirable anticipated effects?";
      }
      break;
    case 'undesirableEffects':
      if (acpFeaturesOn) {
        return 'How substantial are the differences in undesirable anticipated effects';
      } else {
        return 'How substantial are the undesirable anticipated effects?';
      }
      break;
    case 'resourcesRequired':
      if (acpFeaturesOn) {
        return 'How large are the differences in resource requirements (costs)?';
      } else {
        return 'How large are the resource requirements (costs)?"';
      }
      break;
    case 'costEffectiveness':
      if (acpFeaturesOn) {
        return "For each comparison, what is the economic value of the intervention in relation to the comparator?";
      } else {
        return "Does the cost-effectiveness of the intervention favor the intervention or the comparison?";
      }
  }
};

sectionRatingDescription = function(sectionId) {
  var acpFeaturesOn;
  acpFeaturesOn = mediator.services.switches.isServerSwitchOn('acpFeatures');
  switch (sectionId) {
    case 'desirableEffects':
      if (acpFeaturesOn) {
        return "How substantial are the differences in desirable anticipated effects for each comparison?";
      } else {
        return "How substantial are the desirable anticipated effects for each intervention?";
      }
      break;
    case 'undesirableEffects':
      if (acpFeaturesOn) {
        return 'How substantial are the differences in undesirable anticipated effects for each comparison?';
      } else {
        return 'How substantial are the undesirable anticipated effects for each intervention?';
      }
      break;
    case 'certaintyOfEvidence':
      if (acpFeaturesOn) {
        return "What is the overall certainty of the evidence of effects for each comparison?";
      } else {
        return "What is the overall certainty of the evidence of effects for each intervention?";
      }
      break;
    case 'balanceOfEffects':
      if (acpFeaturesOn) {
        return "Does the balance between desirable and undesirable effects favor the interventions or the comparisons? ";
      } else {
        return "Does the balance between desirable and undesirable effects favor the intervention or the comparison for each intervention?";
      }
      break;
    case 'resourcesRequired':
      if (acpFeaturesOn) {
        return 'How large are the differences in resource requirements (costs) for each comparison?';
      } else {
        return "How large are the resource requirements (costs) for each intervention?";
      }
      break;
    case 'costEffectiveness':
      if (acpFeaturesOn) {
        return "For each comparison, what is the economic value of the intervention in relation to the comparator?";
      } else {
        return "Which intervention does the cost effectiveness favor?";
      }
  }
};

sectionOptions = function(sectionId) {
  var acpFeaturesOn;
  acpFeaturesOn = mediator.services.switches.isServerSwitchOn('acpFeatures');
  switch (sectionId) {
    case 'desirableEffects':
    case 'undesirableEffects':
      if (acpFeaturesOn) {
        return [
          {
            text: "No clinically meaningful differences ",
            value: "trivial"
          }, {
            text: "Small",
            value: "small"
          }, {
            text: "Medium",
            value: "moderate"
          }, {
            text: "Large",
            value: "large"
          }
        ];
      } else {
        return [
          {
            text: "Trivial",
            value: "trivial"
          }, {
            text: "Small",
            value: "small"
          }, {
            text: "Moderate",
            value: "moderate"
          }, {
            text: "Large",
            value: "large"
          }
        ];
      }
      break;
    case 'balanceOfEffects':
      if (acpFeaturesOn) {
        return [
          {
            text: "Favors the comparison",
            value: "favors_comparison"
          }, {
            text: "Probably favors the comparison",
            value: "probably_favors_comparison"
          }, {
            text: "May favor the comparison",
            value: "may_favors_comparison"
          }, {
            text: "Does not favor either the intervention or the comparison",
            value: "doesnt_favor_any"
          }, {
            text: "Probably does not favor either the intervention or the comparison",
            value: "probably_not_favor_any"
          }, {
            text: "May not favor either the intervention or the comparison",
            value: "may_not_favor_any"
          }, {
            text: "May favor the intervention",
            value: "may_favors_intervention"
          }, {
            text: "Probably favors the intervention",
            value: "probably_favors_intervention"
          }, {
            text: "Favors the intervention",
            value: "favors_intervention"
          }
        ];
      } else {
        return [
          {
            text: "Favors the comparison",
            value: "favors_comparison"
          }, {
            text: "Probably favors the comparison",
            value: "probably_favors_comparison"
          }, {
            text: "Does not favor either the intervention or the comparison",
            value: "doesnt_favor_any"
          }, {
            text: "Probably favors the intervention",
            value: "probably_favors_intervention"
          }, {
            text: "Favors the intervention",
            value: "favors_intervention"
          }
        ];
      }
      break;
    case 'costEffectiveness':
      if (acpFeaturesOn) {
        return [
          {
            text: "High-value intervention",
            value: "high_value_intervention"
          }, {
            text: "Intermediate-value intervention",
            value: "intermediate_value_intervention"
          }, {
            text: "Low-value intervention",
            value: "low_value_intervention"
          }
        ];
      } else {
        return [
          {
            text: "Favors the comparison",
            value: "favors_comparison"
          }, {
            text: "Probably favors the comparison",
            value: "probably_favors_comparison"
          }, {
            text: "Does not favor either the intervention or the comparison",
            value: "doesnt_favor_any"
          }, {
            text: "Probably favors the intervention",
            value: "probably_favors_intervention"
          }, {
            text: "Favors the intervention",
            value: "favors_intervention"
          }
        ];
      }
      break;
    case 'resourcesRequired':
      if (acpFeaturesOn) {
        return [
          {
            text: "Large differences in costs",
            value: "large_costs"
          }, {
            text: "Modest differences in costs",
            value: "moderate_costs"
          }, {
            text: "Negligible differences in costs and savings",
            value: "negligible_costs_savings"
          }, {
            text: "Modest differences in savings",
            value: "moderate_savings"
          }, {
            text: "Large differences savings",
            value: "large_savings"
          }
        ];
      } else {
        return [
          {
            text: "Large costs",
            value: "large_costs"
          }, {
            text: "Moderate costs",
            value: "moderate_costs"
          }, {
            text: "Negligible costs and savings",
            value: "negligible_costs_savings"
          }, {
            text: "Moderate savings",
            value: "moderate_savings"
          }, {
            text: "Large savings",
            value: "large_savings"
          }
        ];
      }
  }
};

additionalOptions = function(sectionId) {
  var acpFeaturesOn;
  acpFeaturesOn = mediator.services.switches.isServerSwitchOn('acpFeatures');
  switch (sectionId) {
    case 'costEffectiveness':
      if (acpFeaturesOn) {
        return [
          {
            text: "Varies",
            value: "varies"
          }, {
            text: "Uncertain",
            value: "uncertain"
          }, {
            text: "No studies",
            value: "no_included_studies"
          }
        ];
      } else {
        return [
          {
            text: "Varies",
            value: "varies"
          }, {
            text: "No included studies",
            value: "no_included_studies"
          }
        ];
      }
  }
};

sections = function() {
  return {
    question: {
      docId: "",
      type: "",
      sectionsOrder: [],
      sections: {
        intent: {
          name: "Intent",
          content: ""
        },
        age: {
          name: "Age",
          content: ""
        },
        population: {
          name: 'Population',
          content: ""
        },
        intervention: {
          name: 'Intervention',
          content: ""
        },
        comparison: {
          name: 'Comparison',
          content: ""
        },
        mainOutcomes: {
          name: 'Main outcomes',
          content: ""
        },
        setting: {
          name: 'Setting',
          content: ""
        },
        perspective: {
          name: 'Perspective',
          content: ""
        },
        background: {
          name: 'Background',
          content: ""
        },
        problem: {
          name: "Problem",
          content: ""
        },
        option: {
          name: "Option",
          content: ""
        },
        purpose: {
          name: "Purpose of the test",
          content: ""
        },
        role: {
          name: "Role of the test",
          content: ""
        },
        linkedTreatments: {
          name: "Linked treatments",
          content: ""
        },
        anticipatedOutcomes: {
          name: "Anticipated outcomes",
          content: ""
        },
        requirements: {
          name: "Requirements",
          content: ""
        },
        subgroups: {
          name: "Subgroups",
          content: ""
        },
        coi: {
          name: "Conflict of interests",
          content: ""
        }
      }
    },
    assessment: {
      sectionsOrder: [],
      sections: {
        problem: {
          name: 'Problem',
          criterionId: 'problem',
          researchEvidenceId: 'problem',
          additionalConsiderationId: 'problem'
        },
        desirableEffects: {
          name: "Desirable Effects",
          criterionId: "desirableEffects",
          researchEvidenceId: "desirableEffects",
          additionalConsiderationId: "desirableEffects"
        },
        undesirableEffects: {
          name: "Undesirable Effects",
          criterionId: "undesirableEffects",
          researchEvidenceId: "undesirableEffects",
          additionalConsiderationId: "undesirableEffects"
        },
        certaintyOfEvidence: {
          name: "Certainty of evidence",
          criterionId: 'certaintyOfEvidence',
          researchEvidenceId: "certaintyOfEvidence",
          additionalConsiderationId: "certaintyOfEvidence"
        },
        values: {
          name: "Values",
          criterionId: "values",
          researchEvidenceId: "values",
          additionalConsiderationId: "values"
        },
        balanceOfEffects: {
          name: "Balance of effects",
          criterionId: "balanceOfEffects",
          researchEvidenceId: "balanceOfEffects",
          additionalConsiderationId: "balanceOfEffects"
        },
        resourcesRequired: {
          name: "Resources required",
          criterionId: "resourcesRequired",
          researchEvidenceId: "resourcesRequired",
          additionalConsiderationId: "resourcesRequired"
        },
        certaintyOfEvidenceOfRequiredResources: {
          name: "Certainty of evidence of required resources",
          criterionId: "certaintyOfEvidenceOfRequiredResources",
          researchEvidenceId: "certaintyOfEvidenceOfRequiredResources",
          additionalConsiderationId: "certaintyOfEvidenceOfRequiredResources"
        },
        costEffectiveness: {
          name: "Cost effectiveness",
          criterionId: "costEffectiveness",
          researchEvidenceId: "costEffectiveness",
          additionalConsiderationId: "costEffectiveness"
        },
        equity: {
          name: "Equity",
          criterionId: "equity",
          researchEvidenceId: "equity",
          additionalConsiderationId: "equity"
        },
        acceptability: {
          name: "Acceptability",
          criterionId: "acceptability",
          researchEvidenceId: "acceptability",
          additionalConsiderationId: "acceptability"
        },
        feasibility: {
          name: "Feasibility",
          criterionId: "feasibility",
          researchEvidenceId: "feasibility",
          additionalConsiderationId: "feasibility"
        },
        testAccuracy: {
          name: "Test accuracy",
          criterionId: "testAccuracy",
          researchEvidenceId: "testAccuracy",
          additionalConsiderationId: "testAccuracy"
        },
        certaintyOfEvidenceOfTestAccuracy: {
          name: "Certainty of the evidence of test accuracy",
          criterionId: "certaintyOfEvidenceOfTestAccuracy",
          researchEvidenceId: "certaintyOfEvidenceOfTestAccuracy",
          additionalConsiderationId: "certaintyOfEvidenceOfTestAccuracy"
        },
        certaintyOfEvidenceOfTestEffects: {
          name: "Certainty of the evidence of test's effects",
          criterionId: "certaintyOfEvidenceOfTestEffects",
          researchEvidenceId: "certaintyOfEvidenceOfTestEffects",
          additionalConsiderationId: "certaintyOfEvidenceOfTestEffects"
        },
        certaintyOfEvidenceOfManagementEffects: {
          name: "Certainty of the evidence of management's effects",
          criterionId: "certaintyOfEvidenceOfManagementEffects",
          researchEvidenceId: "certaintyOfEvidenceOfManagementEffects",
          additionalConsiderationId: "certaintyOfEvidenceOfManagementEffects"
        },
        certaintyOfEvidenceOfTestResult: {
          name: "Certainty of the evidence of test result/management",
          criterionId: "certaintyOfEvidenceOfTestResult",
          researchEvidenceId: "certaintyOfEvidenceOfTestResult",
          additionalConsiderationId: "certaintyOfEvidenceOfTestResult"
        },
        certaintyOfEffects: {
          name: "Certainty of effects",
          criterionId: "certaintyOfEffects",
          researchEvidenceId: "certaintyOfEffects",
          additionalConsiderationId: "certaintyOfEffects"
        },
        balanceOfHealthBenefitsAndHarms: {
          name: "Balance of health benefits and harms",
          criterionId: "balanceOfHealthBenefitsAndHarms",
          researchEvidenceId: "balanceOfHealthBenefitsAndHarms",
          additionalConsiderationId: "balanceOfHealthBenefitsAndHarms"
        },
        humanRights: {
          name: "Human rights",
          criterionId: "humanRights",
          researchEvidenceId: "humanRights",
          additionalConsiderationId: "humanRights"
        },
        socioCulturalAcceptability: {
          name: "Socio-cultural acceptability",
          criterionId: "socioCulturalAcceptability",
          researchEvidenceId: "socioCulturalAcceptability",
          additionalConsiderationId: "socioCulturalAcceptability"
        },
        healthEquityEqualityAndNonDiscrimination: {
          name: "Health equity, equality, and non-discrimination",
          criterionId: "healthEquityEqualityAndNonDiscrimination",
          researchEvidenceId: "healthEquityEqualityAndNonDiscrimination",
          additionalConsiderationId: "healthEquityEqualityAndNonDiscrimination"
        },
        societalImplications: {
          name: "Societal implications",
          criterionId: "societalImplications",
          researchEvidenceId: "societalImplications",
          additionalConsiderationId: "societalImplications"
        },
        financialAndEconomicConsiderations: {
          name: "Financial and economic considerations",
          criterionId: "financialAndEconomicConsiderations",
          researchEvidenceId: "financialAndEconomicConsiderations",
          additionalConsiderationId: "financialAndEconomicConsiderations"
        },
        feasibilityAndHealthSystemConsiderations: {
          name: "Feasibility and health system considerations",
          criterionId: "feasibilityAndHealthSystemConsiderations",
          researchEvidenceId: "feasibilityAndHealthSystemConsiderations",
          additionalConsiderationId: "feasibilityAndHealthSystemConsiderations"
        },
        qualityOfEvidence: {
          name: "Quality of Evidence",
          criterionId: "qualityOfEvidence",
          researchEvidenceId: "qualityOfEvidence",
          additionalConsiderationId: "qualityOfEvidence"
        },
        additionalSection: {
          name: "Custom criterion",
          criterionId: "additionalSection",
          researchEvidenceId: "additionalSection",
          additionalConsiderationId: "additionalSection"
        },
        scientificallySound: {
          name: 'SCIENTIFICALLY SOUND (CERTAINTY OF EVIDENCE)',
          criterionId: 'scientificallySound',
          researchEvidenceId: 'scientificallySound',
          additionalConsiderationId: 'scientificallySound'
        },
        relevantDirect: {
          name: 'RELEVANT (DIRECT)',
          criterionId: 'relevantDirect',
          researchEvidenceId: 'relevantDirect',
          additionalConsiderationId: 'relevantDirect'
        },
        responsive: {
          name: 'RESPONSIVE',
          criterionId: 'responsive',
          researchEvidenceId: 'responsive',
          additionalConsiderationId: 'responsive'
        },
        feasible: {
          name: 'FEASIBLE',
          criterionId: 'feasible',
          researchEvidenceId: 'feasible',
          additionalConsiderationId: 'feasible'
        },
        problemEOHR: {
          name: "Problem",
          criterionId: "problemEOHR",
          researchEvidenceId: "problem",
          additionalConsiderationId: "problem"
        },
        desirableEffectsEOHR: {
          name: "Desirable Effects",
          criterionId: "desirableEffectsEOHR",
          researchEvidenceId: "desirableEffects",
          additionalConsiderationId: "desirableEffects"
        },
        undesirableEffectsEOHR: {
          name: "Undesirable Effects",
          criterionId: "undesirableEffectsEOHR",
          researchEvidenceId: "undesirableEffects",
          additionalConsiderationId: "undesirableEffects"
        },
        certaintyOfEvidenceEOHR: {
          name: "Certainty of Evidence",
          criterionId: "certaintyOfEvidenceEOHR",
          researchEvidenceId: "certaintyOfEvidence",
          additionalConsiderationId: "certaintyOfEvidence"
        },
        valuesEOHR: {
          name: "Values",
          criterionId: "valuesEOHR",
          researchEvidenceId: "values",
          additionalConsiderationId: "values"
        },
        balanceOfEffectsEOHR: {
          name: "Balance of Effects",
          criterionId: "balanceOfEffectsEOHR",
          researchEvidenceId: "balanceOfEffects",
          additionalConsiderationId: "balanceOfEffects"
        },
        resourcesRequiredEOHR: {
          name: "Resources Required",
          criterionId: "resourcesRequiredEOHR",
          researchEvidenceId: "resourcesRequired",
          additionalConsiderationId: "resourcesRequired"
        },
        certaintyOfEvidenceOfRequiredResourcesEOHR: {
          name: "Certainty of Evidence of Required Resources",
          criterionId: "certaintyOfEvidenceOfRequiredResourcesEOHR",
          researchEvidenceId: "certaintyOfEvidenceOfRequiredResources",
          additionalConsiderationId: "certaintyOfEvidenceOfRequiredResources"
        },
        costEffectivenessEOHR: {
          name: "Cost Effectiveness",
          criterionId: "costEffectivenessEOHR",
          researchEvidenceId: "costEffectiveness",
          additionalConsiderationId: "costEffectiveness"
        },
        equityEOHR: {
          name: "Equity",
          criterionId: "equityEOHR",
          researchEvidenceId: "equity",
          additionalConsiderationId: "equity"
        },
        acceptabilityEOHR: {
          name: "Acceptability",
          criterionId: "acceptabilityEOHR",
          researchEvidenceId: "acceptability",
          additionalConsiderationId: "acceptability"
        },
        feasibilityEOHR: {
          name: "Feasibility",
          criterionId: "feasibilityEOHR",
          researchEvidenceId: "feasibility",
          additionalConsiderationId: "feasibility"
        },
        netBalance: {
          name: "Net balance",
          criterionId: "netBalance",
          researchEvidenceId: "netBalance",
          additionalConsiderationId: "netBalance"
        }
      },
      criteria: {
        problem: {
          description: "Is the problem a priority?",
          info: "The more serious or urgent a problem is, the more likely it is that an option that addresses the problem will be a priority",
          options: [
            {
              text: "No",
              value: "no"
            }, {
              text: "Probably no",
              value: "probably_no"
            }, {
              text: "Probably yes",
              value: "probably_yes"
            }, {
              text: "Yes",
              value: "yes"
            }
          ],
          additionalOptions: [
            {
              text: "Varies",
              value: "varies"
            }, {
              text: "Don't know",
              value: "dont_know"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: "general",
            questions: [
              {
                question: "Are the consequences of the problem serious (i.e. severe or important in terms of the potential benefits or savings)?",
                options: [
                  {
                    text: "No",
                    value: "no"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Yes",
                    value: "yes"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Is the problem urgent?",
                options: [
                  {
                    text: "No",
                    value: "no"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Yes",
                    value: "yes"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Is it a recognized priority (e.g. based on a political or policy decision)?",
                options: [
                  {
                    text: "No",
                    value: "no"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Yes",
                    value: "yes"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }
            ]
          }
        },
        netBalance: {
          description: "Net Balance",
          info: "What is the overall net balance of effects?",
          options: [
            {
              text: "Large net desirable",
              value: "large_net_desirable"
            }, {
              text: "Moderate net desirable",
              value: "moderate_net_desirable"
            }, {
              text: "Small net desirable",
              value: "small_net_desirable"
            }, {
              text: "Null net balance",
              value: "null_net_balance"
            }, {
              text: "Small net undesirable",
              value: "small_net_undesirable"
            }, {
              text: "Moderate net undesirable",
              value: "moderate_net_undesirable"
            }, {
              text: "Large net undesirable",
              value: "large_net_undesirable"
            }
          ],
          additionalOptions: [],
          selectedOptions: {}
        },
        desirableEffects: {
          description: sectionDescription('desirableEffects'),
          ratingDescription: sectionRatingDescription('desirableEffects'),
          info: "How large are the desirable effects of the intervention taking into account the importance of the outcomes (how much they are valued), and the size of the effect (the likelihood of experiencing a benefit or how much of an improvement individuals would be likely to experience)?",
          options: sectionOptions('desirableEffects'),
          additionalOptions: [
            {
              text: "Varies",
              value: "varies"
            }, {
              text: "Don't know",
              value: "dont_know"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: 'outcomes',
            questions: [
              {
                question: "How substantial is the anticipated effect (difference) for each main outcome for which there is a desirable effect?",
                options: sectionOptions('desirableEffects'),
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOptions: {}
              }
            ]
          },
          ratingOptions: ['most_effective', 'intermediate_effectiveness', 'least_effective']
        },
        undesirableEffects: {
          description: sectionDescription('undesirableEffects'),
          ratingDescription: sectionRatingDescription('undesirableEffects'),
          info: "How large are the undesirable effects of the intervention taking into account the importance of the outcomes (how much they are valued), and the size of the effect (the likelihood of experiencing a benefit or how much of an improvement individuals would be likely to experience)?",
          options: sectionOptions('undesirableEffects'),
          additionalOptions: [
            {
              text: "Varies",
              value: "varies"
            }, {
              text: "Don't know",
              value: "dont_know"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: "outcomes",
            questions: [
              {
                question: "How substantial is the anticipated effect (difference) for each main outcome for which there is an undesirable effect?",
                options: sectionOptions('undesirableEffects'),
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOptions: {}
              }
            ]
          },
          ratingOptions: ['least_harmful', 'intermediate', 'more_harmful']
        },
        certaintyOfEvidence: {
          description: "What is the overall certainty of the evidence of effects?",
          ratingDescription: sectionRatingDescription('certaintyOfEvidence'),
          info: "How good an indication does the research provide of the likely effects across all of the critical outcomes; i.e. the likelihood that the effects will be different enough from what the research found that it might affect a decision about the intervention?",
          options: [
            {
              text: veryLowText(),
              value: "very_low"
            }, {
              text: "Low",
              value: "low"
            }, {
              text: "Moderate",
              value: "moderate"
            }, {
              text: "High",
              value: "high"
            }
          ],
          additionalOptions: [
            {
              text: "No included studies",
              value: "no_included_studies"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: ""
          }
        },
        values: {
          description: "Is there important uncertainty about or variability in how much people value the main outcomes?",
          info: "How much do individuals value each of the main outcomes? Is uncertainty about how much they value each of the outcomes or variability in how much different individuals value the outcomes large enough that it could lead to different decisions?",
          options: [
            {
              text: "Important uncertainty or variability",
              value: "important_uncertainty"
            }, {
              text: "Possibly important uncertainty or variability",
              value: "possible_important"
            }, {
              text: "Probably no important uncertainty or variability",
              value: "probably_no_important"
            }, {
              text: "No important uncertainty or variability",
              value: "no_important"
            }
          ],
          additionalOptions: [],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: "outcomes",
            questions: [
              {
                question: "Is there important uncertainty about how much people value the main outcomes?",
                options: [
                  {
                    text: "Important uncertainty",
                    value: "important"
                  }, {
                    text: "Possibly important uncertainty",
                    value: "probably_important"
                  }, {
                    text: "Probably no important uncertainty",
                    value: "probably_no_important"
                  }, {
                    text: "No important uncertainty",
                    value: "no_important"
                  }
                ],
                selectedOptions: {}
              }
            ]
          }
        },
        balanceOfEffects: {
          description: "Does the balance between desirable and undesirable effects favor the intervention or the comparison?",
          ratingDescription: sectionRatingDescription('balanceOfEffects'),
          info: "What is the balance between the desirable and undesirable effects, taking into account how much individuals value the main outcomes, how substantial the desirable and undesirable effects are, the certainty of those estimates, discount rates, risk aversion and risk seeking?",
          options: sectionOptions('balanceOfEffects'),
          additionalOptions: [
            {
              text: "Varies",
              value: "varies"
            }, {
              text: "Don't know",
              value: "dont_know"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            additionalInfos: [
              {
                description: "Detailed judgements for this criterion include judgements regarding each of the four preceding criteria:",
                criteriaAnswers: [
                  {
                    criterionId: "values"
                  }, {
                    criterionId: "certaintyOfEvidence"
                  }, {
                    criterionId: "desirableEffects"
                  }, {
                    criterionId: "undesirableEffects"
                  }
                ]
              }, {
                description: "In addition, panels might want to consider (and, if relevant document) the extent to which the following considerations might influence the balance between the desirable and undesirable effects:",
                additions: ["How much less people value outcomes that are in the future compared to outcomes that occur now (their discount rates)?", "People’s attitudes towards undesirable effects (how risk averse they are).", "People’s attitudes towards desirable effects (how risk seeking they are)."]
              }
            ]
          },
          ratingOptions: ['best_balance', 'intermediate', 'worst_balance']
        },
        resourcesRequired: {
          description: sectionDescription("resourcesRequired"),
          ratingDescription: sectionRatingDescription('resourcesRequired'),
          info: "How large is the cost of the difference in resource use between the intervention and comparison?",
          options: sectionOptions('resourcesRequired'),
          additionalOptions: [
            {
              text: "Varies",
              value: "varies"
            }, {
              text: "Don't know",
              value: "dont_know"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: "resources",
            questions: [
              {
                question: "How large is the difference in each item of resource for which fewer resources are required?",
                options: [
                  {
                    text: "Trivial",
                    value: "trivial"
                  }, {
                    text: "Small",
                    value: "small"
                  }, {
                    text: "Moderate",
                    value: "moderate"
                  }, {
                    text: "Large",
                    value: "large"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                resources: []
              }, {
                question: "How large is the difference in each item of resource use for which more resources are required?",
                options: [
                  {
                    text: "Large",
                    value: "large"
                  }, {
                    text: "Moderate",
                    value: "moderate"
                  }, {
                    text: "Small",
                    value: "small"
                  }, {
                    text: "Trivial",
                    value: "trivial"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                resources: []
              }
            ]
          },
          ratingOptions: ['less_costs', 'intermediate_costs', 'most_costs']
        },
        certaintyOfEvidenceOfRequiredResources: {
          description: "What is the certainty of the evidence of resource requirements (costs)?",
          info: "How certain is the evidence of a difference for each type of resource use (e.g. drugs, hospitalisations) and the cost of resources?",
          options: [
            {
              text: veryLowText(),
              value: "very_low"
            }, {
              text: "Low",
              value: "low"
            }, {
              text: "Moderate",
              value: "moderate"
            }, {
              text: "High",
              value: "high"
            }
          ],
          additionalOptions: [
            {
              text: "No included studies",
              value: "no_included_studies"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: "resources",
            questions: [
              {
                question: "Have all important items of resource use that may differ between the options being considered been identified?",
                options: [
                  {
                    text: "No",
                    value: "no"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Yes",
                    value: "yes"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                resources: []
              }, {
                question: "How certain is the cost of the items of resource use that differ between the options being considered?",
                options: [
                  {
                    text: "Important uncertainty",
                    value: "important"
                  }, {
                    text: "Possibly important uncertainty",
                    value: "probably_important"
                  }, {
                    text: "Probably no important uncertainty",
                    value: "probably_no_important"
                  }, {
                    text: "No important uncertainty",
                    value: "no_important"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                resources: []
              }, {
                question: "Is there important variability in the cost of the items of resource use that differ between the options being considered?",
                options: [
                  {
                    text: "Important variability",
                    value: "important"
                  }, {
                    text: "Possibly important variability",
                    value: "probably_important"
                  }, {
                    text: "Probably no important variability",
                    value: "probably_no_important"
                  }, {
                    text: "No important variability",
                    value: "no_important"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                resources: []
              }
            ]
          }
        },
        costEffectiveness: {
          description: sectionDescription('costEffectiveness'),
          ratingDescription: sectionRatingDescription('costEffectiveness'),
          info: "Is the intervention cost-effective, taking into account uncertainty about or variability in the costs, uncertainty about or variability in the net benefit, sensitivity analyses, and the reliability and applicability of the economic evaluation?",
          options: sectionOptions('costEffectiveness'),
          additionalOptions: additionalOptions('costEffectiveness'),
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: "general",
            questions: [
              {
                question: "Is the cost-effectiveness ratio sensitive to one-way sensitivity analyses?",
                options: [
                  {
                    text: "No",
                    value: "no"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Yes",
                    value: "yes"
                  }
                ],
                additionalOptions: [
                  {
                    text: "No included studies",
                    value: "no_included_studies"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Is the cost-effectiveness ratio sensitive to multi-variable sensitivity analysis?",
                options: [
                  {
                    text: "No",
                    value: "no"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Yes",
                    value: "yes"
                  }
                ],
                additionalOptions: [
                  {
                    text: "No included studies",
                    value: "no_included_studies"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Is the economic evaluation on which the cost-effectiveness estimate is based reliable?",
                options: [
                  {
                    text: "No",
                    value: "no"
                  }, {
                    text: "Very serious limitations",
                    value: "very_serious"
                  }, {
                    text: "Serious limitations",
                    value: "serious"
                  }, {
                    text: "No serious limitations",
                    value: "no_serious"
                  }
                ],
                additionalOptions: [
                  {
                    text: "No included studies",
                    value: "no_included_studies"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Is the economic evaluation on which the cost-effectiveness estimate is based applicable to the setting(s) of interest?",
                options: [
                  {
                    text: "No",
                    value: "no"
                  }, {
                    text: "Very serious limitations",
                    value: "very_serious"
                  }, {
                    text: "Serious limitations",
                    value: "serious"
                  }, {
                    text: "No serious limitations",
                    value: "no_serious"
                  }
                ],
                additionalOptions: [
                  {
                    text: "No included studies",
                    value: "no_included_studies"
                  }
                ],
                selectedOption: ""
              }
            ]
          },
          ratingOptions: ["best_cost_effectiveness", "intermediate_cost_effectiveness", "worst_cost_effectiveness"]
        },
        equity: {
          description: "What would be the impact on health equity?",
          ratingDescription: "If recommended, which intervention would reduce health inequities the most?",
          info: "Are there plausible reasons for anticipating differences in the relative effectiveness of the intervention for disadvantaged subgroups or different baseline conditions across disadvantaged subgroups that affect the absolute effectiveness of the intervention or the importance of the problem?",
          options: [
            {
              text: "Reduced",
              value: "reduced"
            }, {
              text: "Probably reduced",
              value: "probably_reduced"
            }, {
              text: "Probably no impact",
              value: "probably_no_impact"
            }, {
              text: "Probably increased",
              value: "probably_increased"
            }, {
              text: "Increased",
              value: "increased"
            }
          ],
          additionalOptions: [
            {
              text: "Varies",
              value: "varies"
            }, {
              text: "Don't know",
              value: "dont_know"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: "general",
            questions: [
              {
                question: "Are there groups or settings that might be disadvantaged in relation to the problem or options that are considered?",
                options: [
                  {
                    text: "Yes",
                    value: "yes"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "No",
                    value: "no"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Are there plausible reasons for anticipating differences in the relative effectiveness of the option for disadvantaged groups or settings?",
                options: [
                  {
                    text: "Yes",
                    value: "yes"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "No",
                    value: "no"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Are there different baseline conditions across groups or settings that affect the absolute effectiveness of the option or the importance of the problem for disadvantaged groups or settings?",
                options: [
                  {
                    text: "Yes",
                    value: "yes"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "No",
                    value: "no"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Are there important considerations that should be made when implementing the intervention (option) in order to ensure that inequities are reduced, if possible, and that they are not increased?",
                options: [
                  {
                    text: "Yes",
                    value: "yes"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "No",
                    value: "no"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }
            ]
          },
          ratingOptions: ['most_reduction', 'intermediate_reduction', 'less_reduction']
        },
        acceptability: {
          description: "Is the intervention acceptable to key stakeholders?",
          ratingDescription: "Which intervention is more acceptable to key stakeholders?",
          info: "Are key stakeholders likely not to accept the distribution of the benefits, harms and costs; or the costs or undesirable effects in the short term for desirable effects (benefits) in the future? Are they likely to disagree with the values attached to the desirable or undesirable effects, or not to accept the diagnostic intervention because of ethical concerns?",
          options: [
            {
              text: "No",
              value: "no"
            }, {
              text: "Probably no",
              value: "probably_no"
            }, {
              text: "Probably yes",
              value: "probably_yes"
            }, {
              text: "Yes",
              value: "yes"
            }
          ],
          additionalOptions: [
            {
              text: "Varies",
              value: "varies"
            }, {
              text: "Don't know",
              value: "dont_know"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: "general",
            questions: [
              {
                question: "Are there key stakeholders that would not accept the distribution of the benefits, harms and costs?",
                options: [
                  {
                    text: "Yes",
                    value: "yes"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "No",
                    value: "no"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Are there key stakeholders that would not accept the costs or undesirable effects in the short term for desirable effects (benefits) in the future?",
                options: [
                  {
                    text: "Yes",
                    value: "yes"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "No",
                    value: "no"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Are there key stakeholders that would not agree with the values attached to the desirable or undesirable effects (because of how they might be affected personally or because of their perceptions of the relative importance of the effects for others)?",
                options: [
                  {
                    text: "Yes",
                    value: "yes"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "No",
                    value: "no"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Would the intervention (option) adversely affect people’s autonomy?",
                options: [
                  {
                    text: "Yes",
                    value: "yes"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "No",
                    value: "no"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Are there key stakeholders that would disapprove of the intervention (option) morally, for reasons other than its effects on people’s autonomy (i.e. in relationship to ethical principles such as non-maleficence, beneficence or justice)?",
                options: [
                  {
                    text: "Yes",
                    value: "yes"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "No",
                    value: "no"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }
            ]
          },
          ratingOptions: ['best_acceptability', 'intermediate_acceptability', 'worst_acceptability']
        },
        feasibility: {
          description: "Is the intervention feasible to implement?",
          ratingDescription: "Which intervention is more feasible to implement?",
          info: "Is it feasible to sustain use of the diagnostic intervention and to address potential barriers to using it?",
          options: [
            {
              text: "No",
              value: "no"
            }, {
              text: "Probably no",
              value: "probably_no"
            }, {
              text: "Probably yes",
              value: "probably_yes"
            }, {
              text: "Yes",
              value: "yes"
            }
          ],
          additionalOptions: [
            {
              text: "Varies",
              value: "varies"
            }, {
              text: "Don't know",
              value: "dont_know"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: "general",
            questions: [
              {
                question: "Is the intervention (option) sustainable?",
                options: [
                  {
                    text: "No",
                    value: "no"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Yes",
                    value: "yes"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Are there important barriers that are likely to limit the feasibility of implementing the intervention (option) or require consideration when implementing it?",
                options: [
                  {
                    text: "No",
                    value: "no"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Yes",
                    value: "yes"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }
            ]
          },
          ratingOptions: ['most_feasible', 'intermediate_feasibility', 'least_feasible']
        },
        testAccuracy: {
          description: "How accurate is the test?",
          options: [
            {
              text: "Very inaccurate",
              value: "very_inaccurate"
            }, {
              text: "Inaccurate",
              value: "inaccurate"
            }, {
              text: "Accurate",
              value: "accurate"
            }, {
              text: "Very accurate",
              value: "very_accurate"
            }
          ],
          additionalOptions: [
            {
              text: "Varies",
              value: "varies"
            }, {
              text: "Don't know",
              value: "dont_know"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: ""
          }
        },
        certaintyOfEvidenceOfTestAccuracy: {
          description: "What is the overall certainty of the evidence of test accuracy?",
          options: [
            {
              text: veryLowText(),
              value: "very_low"
            }, {
              text: "Low",
              value: "low"
            }, {
              text: "Moderate",
              value: "moderate"
            }, {
              text: "High",
              value: "high"
            }
          ],
          additionalOptions: [
            {
              text: "No included studies",
              value: "no_included_studies"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: ""
          }
        },
        certaintyOfEvidenceOfTestEffects: {
          description: "What is the overall certainty of the evidence for any critical or important direct benefits, adverse effects or burden of the test?",
          options: [
            {
              text: veryLowText(),
              value: "very_low"
            }, {
              text: "Low",
              value: "low"
            }, {
              text: "Moderate",
              value: "moderate"
            }, {
              text: "High",
              value: "high"
            }
          ],
          additionalOptions: [
            {
              text: "No included studies",
              value: "no_included_studies"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: ""
          }
        },
        certaintyOfEvidenceOfManagementEffects: {
          description: "What is the overall certainty of the evidence of effects of the management that is guided by the test results?",
          options: [
            {
              text: veryLowText(),
              value: "very_low"
            }, {
              text: "Low",
              value: "low"
            }, {
              text: "Moderate",
              value: "moderate"
            }, {
              text: "High",
              value: "high"
            }
          ],
          additionalOptions: [
            {
              text: "No included studies",
              value: "no_included_studies"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: ""
          }
        },
        certaintyOfEvidenceOfTestResult: {
          description: "How certain is the link between test results and management decisions?",
          options: [
            {
              text: veryLowText(),
              value: "very_low"
            }, {
              text: "Low",
              value: "low"
            }, {
              text: "Moderate",
              value: "moderate"
            }, {
              text: "High",
              value: "high"
            }
          ],
          additionalOptions: [
            {
              text: "No included studies",
              value: "no_included_studies"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: ""
          }
        },
        certaintyOfEffects: {
          description: "What is the overall certainty of the evidence of effects of the test?",
          info: "How good an indication does the research provide of the likely effects across all of the critical outcomes; i.e. the likelihood that the effects will be different enough from what the research found that it might affect a decision about the diagnostic intervention?",
          options: [
            {
              text: veryLowText(),
              value: "very_low"
            }, {
              text: "Low",
              value: "low"
            }, {
              text: "Moderate",
              value: "moderate"
            }, {
              text: "High",
              value: "high"
            }
          ],
          additionalOptions: [
            {
              text: "No included studies",
              value: "no_included_studies"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: ""
          }
        },
        balanceOfHealthBenefitsAndHarms: {
          description: "Does the balance between desirable and undesirable health effects favor the intervention or the comparison?",
          info: "The greater the net health benefit associated with an intervention, the greater the likelihood of a general recommendation in favour of this intervention.",
          options: [
            {
              text: "Favors the comparison",
              value: "favors_the_comparison"
            }, {
              text: "Probably favors the comparison",
              value: "probably_favors_the_comparison"
            }, {
              text: "Does not favor either the intervention or the comparison",
              value: "does_not_favor_either_the_intervention_or_the_comparison"
            }, {
              text: "Probably favors the intervention",
              value: "probably_favors_the_intervention"
            }, {
              text: "Favors the intervention",
              value: "favors_the_intervention"
            }
          ],
          additionalOptions: [
            {
              text: "Varies",
              value: "varies"
            }, {
              text: "Don't know",
              value: "dont_know"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: "general",
            questions: [
              {
                question: "Does the short- and longer-term <strong>efficacy</strong> (under controlled, often ideal circumstances) or <strong>effectiveness</strong> (in a real-life setting) of the intervention on the <strong>health of individuals</strong>, including patient-reported outcomes, favor the intervention or the comparison?",
                options: [
                  {
                    text: "Favors the comparison",
                    value: "favors_the_comparison"
                  }, {
                    text: "Probably favors the comparison",
                    value: "probably_favors_the_comparison"
                  }, {
                    text: "Does not favor either the intervention or the comparison",
                    value: "does_not_favor_either_the_intervention_or_the_comparison"
                  }, {
                    text: "Probably favors the intervention",
                    value: "probably_favors_the_intervention"
                  }, {
                    text: "Favors the intervention",
                    value: "favors_the_intervention"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Does the short- and longer-term <strong>effectiveness</strong> or <strong>impact</strong> of the intervention on the <strong>health of the population</strong>, including on beneficiary-reported outcomes, favor the intervention or the comparison? (This should include considerations regarding whether population-level outcomes represent aggregated individual-level outcomes or emerge through system dynamics.)",
                options: [
                  {
                    text: "Favors the comparison",
                    value: "favors_the_comparison"
                  }, {
                    text: "Probably favors the comparison",
                    value: "probably_favors_the_comparison"
                  }, {
                    text: "Does not favor either the intervention or the comparison",
                    value: "does_not_favor_either_the_intervention_or_the_comparison"
                  }, {
                    text: "Probably favors the intervention",
                    value: "probably_favors_the_intervention"
                  }, {
                    text: "Favors the intervention",
                    value: "favors_the_intervention"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Does the extent to which <strong>patients/beneficiaries’ value</strong> different health outcomes favor the intervention or the comparison?",
                options: [
                  {
                    text: "Favors the comparison",
                    value: "favors_the_comparison"
                  }, {
                    text: "Probably favors the comparison",
                    value: "probably_favors_the_comparison"
                  }, {
                    text: "Does not favor either the intervention or the comparison",
                    value: "does_not_favor_either_the_intervention_or_the_comparison"
                  }, {
                    text: "Probably favors the intervention",
                    value: "probably_favors_the_intervention"
                  }, {
                    text: "Favors the intervention",
                    value: "favors_the_intervention"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Does the <strong>probability and severity of adverse effects</strong> associated with the intervention (including the risk of the intervention being misused) favor the intervention or the comparison?",
                options: [
                  {
                    text: "Favors the comparison",
                    value: "favors_the_comparison"
                  }, {
                    text: "Probably favors the comparison",
                    value: "probably_favors_the_comparison"
                  }, {
                    text: "Does not favor either the intervention or the comparison",
                    value: "does_not_favor_either_the_intervention_or_the_comparison"
                  }, {
                    text: "Probably favors the intervention",
                    value: "probably_favors_the_intervention"
                  }, {
                    text: "Favors the intervention",
                    value: "favors_the_intervention"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Do the <strong>broader positive or negative health-related impacts</strong> (e.g. reduction of stigma, positive impact on other diseases, spillover effects beyond patients/beneficiaries) associated with the intervention favor the intervention or the comparison?",
                options: [
                  {
                    text: "Favors the comparison",
                    value: "favors_the_comparison"
                  }, {
                    text: "Probably favors the comparison",
                    value: "probably_favors_the_comparison"
                  }, {
                    text: "Does not favor either the intervention or the comparison",
                    value: "does_not_favor_either_the_intervention_or_the_comparison"
                  }, {
                    text: "Probably favors the intervention",
                    value: "probably_favors_the_intervention"
                  }, {
                    text: "Favors the intervention",
                    value: "favors_the_intervention"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }
            ]
          }
        },
        humanRights: {
          description: "Is the intervention in accordance with universal human rights standards and principles?",
          info: "All recommendations should be in accordance with universal human rights standards and principles.",
          options: [
            {
              text: "No",
              value: "no"
            }, {
              text: "Probably no",
              value: "probably_no"
            }, {
              text: "Uncertain",
              value: "uncertain"
            }, {
              text: "Probably yes",
              value: "probably_yes"
            }, {
              text: "Yes",
              value: "yes"
            }
          ],
          additionalOptions: [
            {
              text: "Varies",
              value: "varies"
            }, {
              text: "Don't know",
              value: "dont_know"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: "general",
            questions: []
          }
        },
        socioCulturalAcceptability: {
          description: "Is the intervention acceptable to key stakeholders?",
          info: "The greater the socio-cultural acceptability of an intervention to all or most relevant stakeholders, the greater the likelihood of a general recommendation in favour of this intervention.",
          options: [
            {
              text: "No",
              value: "no"
            }, {
              text: "Probably no",
              value: "probably_no"
            }, {
              text: "Uncertain",
              value: "uncertain"
            }, {
              text: "Probably yes",
              value: "probably_yes"
            }, {
              text: "Yes",
              value: "yes"
            }
          ],
          additionalOptions: [
            {
              text: "Varies",
              value: "varies"
            }, {
              text: "Don't know",
              value: "dont_know"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: "general",
            questions: [
              {
                question: "How substantial is the intrusiveness of the intervention in terms of infringing on individual liberties (including privacy and dignity)? (Intrusiveness ranges from trivial – for example through enabling choice (e.g. building cycle paths) to high – for example by restricting or eliminating choice (e.g. banning cigarettes)).",
                options: [
                  {
                    text: "Large",
                    value: "large"
                  }, {
                    text: "Moderate",
                    value: "moderate"
                  }, {
                    text: "Small",
                    value: "small"
                  }, {
                    text: "Trivial",
                    value: "trivial"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "How substantial is the impact of the intervention on the autonomy of individuals, population groups, and/or organizations (with regards to their ability to make a competent, informed, and voluntary decision)?",
                options: [
                  {
                    text: "Large",
                    value: "large"
                  }, {
                    text: "Moderate",
                    value: "moderate"
                  }, {
                    text: "Small",
                    value: "small"
                  }, {
                    text: "Trivial",
                    value: "trivial"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Does the socio-cultural acceptability of the intervention among intended beneficiaries favor the intervention or the comparison?",
                options: [
                  {
                    text: "Favors the comparison",
                    value: "favors_the_comparison"
                  }, {
                    text: "Probably favors the comparison",
                    value: "probably_favors_the_comparison"
                  }, {
                    text: "Does not favor either the intervention or the comparison",
                    value: "does_not_favor_either_the_intervention_or_the_comparison"
                  }, {
                    text: "Probably favors the intervention",
                    value: "probably_favors_the_intervention"
                  }, {
                    text: "Favors the intervention",
                    value: "favors_the_intervention"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Does the socio-cultural acceptability of the intervention among those intended to implement the intervention favor the intervention or the comparison?",
                options: [
                  {
                    text: "Favors the comparison",
                    value: "favors_the_comparison"
                  }, {
                    text: "Probably favors the comparison",
                    value: "probably_favors_the_comparison"
                  }, {
                    text: "Does not favor either the intervention or the comparison",
                    value: "does_not_favor_either_the_intervention_or_the_comparison"
                  }, {
                    text: "Probably favors the intervention",
                    value: "probably_favors_the_intervention"
                  }, {
                    text: "Favors the intervention",
                    value: "favors_the_intervention"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Does the socio-cultural acceptability of the intervention among other relevant stakeholder groups favor the intervention or the comparison?",
                options: [
                  {
                    text: "Favors the comparison",
                    value: "favors_the_comparison"
                  }, {
                    text: "Probably favors the comparison",
                    value: "probably_favors_the_comparison"
                  }, {
                    text: "Does not favor either the intervention or the comparison",
                    value: "does_not_favor_either_the_intervention_or_the_comparison"
                  }, {
                    text: "Probably favors the intervention",
                    value: "probably_favors_the_intervention"
                  }, {
                    text: "Favors the intervention",
                    value: "favors_the_intervention"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Does the socio-cultural acceptability of the intervention among the general public favor the intervention or the comparison?",
                options: [
                  {
                    text: "Favors the comparison",
                    value: "favors_the_comparison"
                  }, {
                    text: "Probably favors the comparison",
                    value: "probably_favors_the_comparison"
                  }, {
                    text: "Does not favor either the intervention or the comparison",
                    value: "does_not_favor_either_the_intervention_or_the_comparison"
                  }, {
                    text: "Probably favors the intervention",
                    value: "probably_favors_the_intervention"
                  }, {
                    text: "Favors the intervention",
                    value: "favors_the_intervention"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }
            ]
          }
        },
        healthEquityEqualityAndNonDiscrimination: {
          description: "What would be the impact of the intervention on health equity, equality, and non-discrimination?",
          info: "The greater the likelihood that the intervention increases health equity and/or equality and that it reduces discrimination against any particular group, the greater the likelihood of a general recommendation in favour of this intervention.",
          options: [
            {
              text: "Negative",
              value: "negative"
            }, {
              text: "Probably negative",
              value: "probably_negative"
            }, {
              text: "Neither negative nor positive",
              value: "neither_negative_nor_positive"
            }, {
              text: "Probably positive",
              value: "probably_positive"
            }, {
              text: "Positive",
              value: "positive"
            }
          ],
          additionalOptions: [
            {
              text: "Varies",
              value: "varies"
            }, {
              text: "Don't know",
              value: "dont_know"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: "general",
            questions: [
              {
                question: "Is the intervention likely to increase existing inequalities and/or inequities in the health condition or its determinants? (This should include considerations of likely changes in inequalities over time, e.g. whether initial increases are likely to balance out over time, as the intervention is scaled up?)",
                options: [
                  {
                    text: "No",
                    value: "no"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Yes",
                    value: "yes"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Are the intervention’s benefits and harms likely to be distributed in an equitable manner? (This should include a special focus on implications for vulnerable, marginalized or otherwise socially disadvantaged population groups.)",
                options: [
                  {
                    text: "No",
                    value: "no"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Yes",
                    value: "yes"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Is the intervention affordable among affected population groups, and therefore financially accessible? (This should include the impact on household health expenditures, including the risk of catastrophic health expenditures and health-related financial risks.)",
                options: [
                  {
                    text: "No",
                    value: "no"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Yes",
                    value: "yes"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Is the intervention accessible among affected population groups? (This should include considerations regarding physical as well as informational access.)",
                options: [
                  {
                    text: "No",
                    value: "no"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Yes",
                    value: "yes"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Does the intervention address a particularly severe (e.g. life-threatening, end-of-life, affecting individuals with a low pre-existing health status) or rare condition?",
                options: [
                  {
                    text: "No",
                    value: "no"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Yes",
                    value: "yes"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Does the intervention represent the only available option? (This should include considerations whether the intervention is proportionate to the need, and whether it will be subject to periodic review.)",
                options: [
                  {
                    text: "No",
                    value: "no"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Yes",
                    value: "yes"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }
            ]
          }
        },
        societalImplications: {
          description: "Does the balance between desirable and undesirable societal implications favor the intervention or the comparison?",
          info: "The greater the net societal benefit associated with an intervention, the greater the likelihood of a general recommendation in favour of this intervention.",
          options: [
            {
              text: "Favors the comparison",
              value: "favors_the_comparison"
            }, {
              text: "Probably favors the comparison",
              value: "probably_favors_the_comparison"
            }, {
              text: "Does not favor either the intervention or the comparison",
              value: "does_not_favor_either_the_intervention_or_the_comparison"
            }, {
              text: "Probably favors the intervention",
              value: "probably_favors_the_intervention"
            }, {
              text: "Favors the intervention",
              value: "favors_the_intervention"
            }
          ],
          additionalOptions: [
            {
              text: "Varies",
              value: "varies"
            }, {
              text: "Don't know",
              value: "dont_know"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: "general",
            questions: [
              {
                question: "Do the social impact and social consequences of the intervention (such as increase or reduction of stigma, educational outcomes, social cohesion, or the attainment of various human rights beyond health) favor the intervention or the comparison?",
                options: [
                  {
                    text: "Favors the comparison",
                    value: "favors_the_comparison"
                  }, {
                    text: "Probably favors the comparison",
                    value: "probably_favors_the_comparison"
                  }, {
                    text: "Does not favor either the intervention or the comparison",
                    value: "does_not_favor_either_the_intervention_or_the_comparison"
                  }, {
                    text: "Probably favors the intervention",
                    value: "probably_favors_the_intervention"
                  }, {
                    text: "Favors the intervention",
                    value: "favors_the_intervention"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Do the environmental impact and the implications for ecological sustainability (e.g. protection of natural resources, mitigation or adaption to climate change) favor the intervention or the comparison?",
                options: [
                  {
                    text: "Favors the comparison",
                    value: "favors_the_comparison"
                  }, {
                    text: "Probably favors the comparison",
                    value: "probably_favors_the_comparison"
                  }, {
                    text: "Does not favor either the intervention or the comparison",
                    value: "does_not_favor_either_the_intervention_or_the_comparison"
                  }, {
                    text: "Probably favors the intervention",
                    value: "probably_favors_the_intervention"
                  }, {
                    text: "Favors the intervention",
                    value: "favors_the_intervention"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }
            ]
          }
        },
        financialAndEconomicConsiderations: {
          description: "Do financial and economic considerations favor the intervention or the comparison?",
          info: "The more advantageous the financial and economic implications of an intervention, the greater the likelihood of a general recommendation in favour of this intervention.",
          options: [
            {
              text: "Favors the comparison",
              value: "favors_the_comparison"
            }, {
              text: "Probably favors the comparison",
              value: "probably_favors_the_comparison"
            }, {
              text: "Does not favor either the intervention or the comparison",
              value: "does_not_favor_either_the_intervention_or_the_comparison"
            }, {
              text: "Probably favors the intervention",
              value: "probably_favor_the_intervention"
            }, {
              text: "Favors the intervention",
              value: "favors_the_intervention"
            }
          ],
          additionalOptions: [
            {
              text: "Varies",
              value: "varies"
            }, {
              text: "Don't know",
              value: "dont_know"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: "general",
            questions: [
              {
                question: "Do the <strong>cost and budget</strong> impacts of implementing and maintaining the intervention favor the intervention or the comparison? (This should include considerations of how cost and budget impacts vary in the short- versus longer-term.)",
                options: [
                  {
                    text: "Favors the comparison",
                    value: "favors_the_comparison"
                  }, {
                    text: "Probably favors the comparison",
                    value: "probably_favors_the_comparison"
                  }, {
                    text: "Does not favor either the intervention or the comparison",
                    value: "does_not_favor_either_the_intervention_or_the_comparison"
                  }, {
                    text: "Probably favors the intervention",
                    value: "probably_favors_the_intervention"
                  }, {
                    text: "Favors the intervention",
                    value: "favors_the_intervention"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Does the <strong>overall impact of the intervention on the economy</strong> favor the intervention or the comparison? (This should include considerations of how the different types of economic impact are distributed across different sectors or organizational levels whether the intervention contributes to or limits the achievement of broader development and poverty reduction goals, and how it impacts the available workforce.)",
                options: [
                  {
                    text: "Favors the comparison",
                    value: "favors_the_comparison"
                  }, {
                    text: "Probably favors the comparison",
                    value: "probably_favors_the_comparison"
                  }, {
                    text: "Does not favor either the intervention or the comparison",
                    value: "does_not_favor_either_the_intervention_or_the_comparison"
                  }, {
                    text: "Probably favors the intervention",
                    value: "probably_favors_the_intervention"
                  }, {
                    text: "Favors the intervention",
                    value: "favors_the_intervention"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Does the <strong>ratio of costs and benefits</strong> (e.g. based on estimates of cost-effectiveness, cost-benefit or cost-utility) favor the intervention or the comparison?",
                options: [
                  {
                    text: "Favors the comparison",
                    value: "favors_the_comparison"
                  }, {
                    text: "Probably favors the comparison",
                    value: "probably_favors_the_comparison"
                  }, {
                    text: "Does not favor either the intervention or the comparison",
                    value: "does_not_favor_either_the_intervention_or_the_comparison"
                  }, {
                    text: "Probably favors the intervention",
                    value: "probably_favors_the_intervention"
                  }, {
                    text: "Favors the intervention",
                    value: "favors_the_intervention"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }
            ]
          }
        },
        feasibilityAndHealthSystemConsiderations: {
          description: "Is the intervention feasible to implement?",
          info: "The greater the feasibility of an option from the perspective of all or most stakeholders, the greater the likelihood of a general recommendation in favour of the intervention. The more advantageous the implications for the health system as a whole, the greater the likelihood of a general recommendation in favour of the intervention.",
          options: [
            {
              text: "No",
              value: "no"
            }, {
              text: "Probably not",
              value: "probably_not"
            }, {
              text: "Uncertain",
              value: "uncertain"
            }, {
              text: "Probably yes",
              value: "probably_yes"
            }, {
              text: "Yes",
              value: "yes"
            }
          ],
          additionalOptions: [
            {
              text: "Varies",
              value: "varies"
            }, {
              text: "Don't know",
              value: "dont_know"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: "general",
            questions: [
              {
                question: "Are there <strong>legal barriers</strong> which may limit the feasibility of implementing the intervention?",
                options: [
                  {
                    text: "Yes",
                    value: "yes"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "No",
                    value: "no"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Are there <strong>governance aspects</strong> (e.g. strategic considerations, past decisions) which may limit the feasibility of implementing the intervention? (This should include considerations regarding the presence or absence of formal or information institutions which can provide effective leadership, oversight, and accountability in implementing the intervention influence feasibility of implementation)",
                options: [
                  {
                    text: "Yes",
                    value: "yes"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "No",
                    value: "no"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "What are the <strong>implications</strong> of the intervention <strong>interaction and fit with the existing health system?</strong> (This includes considerations regarding the intervention’s interaction with or impact on the existing health system and its components?)",
                options: [
                  {
                    text: "Large beneficial implications",
                    value: "large_beneficial_implications"
                  }, {
                    text: "Moderate beneficial implications",
                    value: "moderate_beneficial_implications"
                  }, {
                    text: "Negligible beneficial and adverse implications",
                    value: "negligible_beneficial_and_adverse_implications"
                  }, {
                    text: "Moderate adverse implications",
                    value: "moderate_adverse_implications"
                  }, {
                    text: "Large adverse implications",
                    value: "large_adverse_implications"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "What are the <strong>implications</strong> of the intervention <strong>for the health workforce and broader human resources</strong> (in the health sector or other sectors? (This should include considerations regarding the need for, usage of, and impact on health workforce and other human resources as well as their distribution)",
                options: [
                  {
                    text: "Large beneficial implications",
                    value: "large_beneficial_implications"
                  }, {
                    text: "Moderate beneficial implications",
                    value: "moderate_beneficial_implications"
                  }, {
                    text: "Negligible beneficial and adverse implications",
                    value: "negligible_beneficial_and_adverse_implications"
                  }, {
                    text: "Moderate adverse implications",
                    value: "moderate_adverse_implications"
                  }, {
                    text: "Large adverse implications",
                    value: "large_adverse_implications"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "What are the <strong>implications</strong> of the intervention <strong>for health system infrastructure and broader infrastructure</strong>? (This should include considerations regarding the need for, usage of, and impact on non-human resources and infrastructure as well as their distribution)",
                options: [
                  {
                    text: "Large beneficial implications",
                    value: "large_beneficial_implications"
                  }, {
                    text: "Moderate beneficial implications",
                    value: "moderate_beneficial_implications"
                  }, {
                    text: "Negligible beneficial and adverse implications",
                    value: "negligible_beneficial_and_adverse_implications"
                  }, {
                    text: "Moderate adverse implications",
                    value: "moderate_adverse_implications"
                  }, {
                    text: "Large adverse implications",
                    value: "large_adverse_implications"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }
            ]
          }
        },
        qualityOfEvidence: {
          description: "What is the overall quality of evidence?",
          info: "The greater the quality of the evidence across different criteria in the WHO-INTEGRATE framework, the greater the likelihood of a general recommendation.",
          options: [
            {
              text: "Very low",
              value: "very_low"
            }, {
              text: "Low",
              value: "low"
            }, {
              text: "Moderate",
              value: "moderate"
            }, {
              text: "High",
              value: "high"
            }
          ],
          additionalOptions: [
            {
              text: "Varies",
              value: "varies"
            }, {
              text: "Don't know",
              value: "dont_know"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: "general",
            questions: []
          }
        },
        additionalSection: {
          description: "Custom criterion description",
          info: "Provide custom tooltip",
          options: [
            {
              text: "Judgement 1",
              value: "option_1"
            }, {
              text: "Judgement 2",
              value: "option_2"
            }, {
              text: "Judgement 3",
              value: "option_3"
            }, {
              text: "Judgement 4",
              value: "option_4"
            }, {
              text: "Judgement 5",
              value: "option_5"
            }
          ],
          additionalOptions: [
            {
              text: "Additional judgement 1",
              value: "additionalOption_1"
            }, {
              text: "Additional judgement 2",
              value: "additionalOption_2"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: "general",
            questions: []
          }
        },
        scientificallySound: {
          description: "based on evidence (GRADE criteria)",
          options: [],
          selectedOptions: "",
          ratingOptions: ['very_scientifically_sound', 'scientifically_sound', 'least_scientifically_sound']
        },
        relevantDirect: {
          description: "to the selected problem and in the field of application",
          options: [],
          selectedOptions: "",
          ratingOptions: ['very_relevant', 'relevant', 'least_relevant']
        },
        responsive: {
          description: "sensitive to change that means they should be able to capture possible changes in the system",
          options: [],
          selectedOptions: "",
          ratingOptions: ['very_responsive', 'responsive', 'least_responsive']
        },
        feasible: {
          description: "to identify and measure, and not susceptible to manipulation",
          options: [],
          selectedOptions: "",
          ratingOptions: ['very_feasible', 'feasible', 'least_feasible']
        },
        problemEOHR: {
          description: "Is the problem a priority?",
          info: "The more serious or urgent a problem is, the more likely it is that an option that addresses the problem will be a priority",
          options: [
            {
              text: "No",
              value: "no"
            }, {
              text: "Probably no",
              value: "probably_no"
            }, {
              text: "Probably yes",
              value: "probably_yes"
            }, {
              text: "Yes",
              value: "yes"
            }
          ],
          additionalOptions: [
            {
              text: "Varies",
              value: "varies"
            }, {
              text: "Don't know",
              value: "dont_know"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: "general",
            questions: [
              {
                question: "Are the consequences of the problem serious (i.e., severe, irreversible, or important)?",
                options: [
                  {
                    text: "No",
                    value: "no"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Yes",
                    value: "yes"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Is the problem urgent, emergent, or unprecedented??",
                options: [
                  {
                    text: "No",
                    value: "no"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Yes",
                    value: "yes"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Is the problem a recognized priority of the political system (local or national)?",
                options: [
                  {
                    text: "No",
                    value: "no"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Yes",
                    value: "yes"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Is the problem a recognized occupational or public health concern?",
                options: [
                  {
                    text: "No",
                    value: "no"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Yes",
                    value: "yes"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Is the problem a recognized priority for a local community?",
                options: [
                  {
                    text: "No",
                    value: "no"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Yes",
                    value: "yes"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }
            ]
          }
        },
        desirableEffectsEOHR: {
          description: "How substantial are the desirable anticipated effects?",
          ratingDescription: "How substantial are the desirable anticipated effects for each intervention?",
          info: "How large are the desirable effects of the intervention taking into account the importance of the outcomes (how much they are valued), and the size of the effect (the likelihood of experiencing a benefit or how much of an improvement individuals would be likely to experience)?",
          options: [
            {
              text: "Trivial",
              value: "trivial"
            }, {
              text: "Small",
              value: "small"
            }, {
              text: desirableAndUndesirableModerateText(),
              value: "moderate"
            }, {
              text: "Large",
              value: "large"
            }
          ],
          additionalOptions: [
            {
              text: "Varies",
              value: "varies"
            }, {
              text: "Don't know",
              value: "dont_know"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: 'outcomes',
            questions: [
              {
                question: "How substantial is the anticipated desirable impact (effect) of the intervention or exposure?",
                options: [
                  {
                    text: "Trivial",
                    value: "trivial"
                  }, {
                    text: "Small",
                    value: "small"
                  }, {
                    text: "Moderate",
                    value: "moderate"
                  }, {
                    text: "Large",
                    value: "large"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOptions: {}
              }, {
                question: "How substantial is the time span for the intervention to reach full effectiveness?",
                options: [
                  {
                    text: "Trivial",
                    value: "trivial"
                  }, {
                    text: "Small",
                    value: "small"
                  }, {
                    text: "Moderate",
                    value: "moderate"
                  }, {
                    text: "Large",
                    value: "large"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOptions: {}
              }
            ]
          },
          ratingOptions: ['most_effective', 'intermediate_effectiveness', 'least_effective']
        },
        undesirableEffectsEOHR: {
          description: "How substantial are the undesirable anticipated effects?",
          ratingDescription: "How substantial are the undesirable anticipated effects for each intervention?",
          info: "How large are the undesirable effects of the intervention taking into account the importance of the outcomes (how much they are valued), and the size of the effect (the likelihood of experiencing a benefit or how much of an improvement individuals would be likely to experience)?",
          options: [
            {
              text: "Large",
              value: "large"
            }, {
              text: "Moderate",
              value: "moderate"
            }, {
              text: "Small",
              value: "small"
            }, {
              text: "Trivial",
              value: "trivial"
            }
          ],
          additionalOptions: [
            {
              text: "Varies",
              value: "varies"
            }, {
              text: "Don't know",
              value: "dont_know"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: "outcomes",
            questions: [
              {
                question: "How substantial is the anticipated undesirable impact (effect) of the intervention or exposure?",
                options: [
                  {
                    text: "Large",
                    value: "large"
                  }, {
                    text: "Moderate",
                    value: "moderate"
                  }, {
                    text: "Small",
                    value: "small"
                  }, {
                    text: "Trivial",
                    value: "trivial"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOptions: {}
              }, {
                question: "How substantial is the time span for the undesirable effects of the intervention?",
                options: [
                  {
                    text: "Large",
                    value: "large"
                  }, {
                    text: "Moderate",
                    value: "moderate"
                  }, {
                    text: "Small",
                    value: "small"
                  }, {
                    text: "Trivial",
                    value: "trivial"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOptions: {}
              }
            ]
          },
          ratingOptions: ['least_harmful', 'intermediate', 'more_harmful']
        },
        certaintyOfEvidenceEOHR: {
          description: "What is the overall certainty of the evidence of effects?",
          ratingDescription: "What is the overall certainty of the evidence of effects for each intervention?",
          info: "How good an indication does the research provide of the likely effects across all of the critical outcomes; i.e. the likelihood that the effects will be different enough from what the research found that it might affect a decision about the intervention?",
          options: [
            {
              text: "Very low",
              value: "very_low"
            }, {
              text: "Low",
              value: "low"
            }, {
              text: "Moderate",
              value: "moderate"
            }, {
              text: "High",
              value: "high"
            }
          ],
          additionalOptions: [
            {
              text: "No included studies",
              value: "no_included_studies"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: ""
          }
        },
        valuesEOHR: {
          description: "Is there important uncertainty about or variability in how much people value the main outcomes?",
          info: "How much do individuals value each of the main outcomes? Is uncertainty about how much they value each of the outcomes or variability in how much different individuals value the outcomes large enough that it could lead to different decisions?",
          options: [
            {
              text: "Important uncertainty or variability",
              value: "important_uncertainty"
            }, {
              text: "Possibly important uncertainty or variability",
              value: "possible_important"
            }, {
              text: "Probably no important uncertainty or variability",
              value: "probably_no_important"
            }, {
              text: "No important uncertainty or variability",
              value: "no_important"
            }
          ],
          additionalOptions: [],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: "outcomes",
            questions: [
              {
                question: "Is there important uncertainty about how much people value the main outcomes?",
                options: [
                  {
                    text: "Important uncertainty",
                    value: "important"
                  }, {
                    text: "Probably important uncertainty",
                    value: "probably_important"
                  }, {
                    text: "Probably no important uncertainty",
                    value: "probably_no_important"
                  }, {
                    text: "No important uncertainty",
                    value: "no_important"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOptions: {}
              }, {
                question: "Is there meaningful variability in how much people value the main outcomes?",
                options: [
                  {
                    text: "Important variability",
                    value: "important"
                  }, {
                    text: "Probably important variability",
                    value: "probably_important"
                  }, {
                    text: "Probably no important variability",
                    value: "probably_no_important"
                  }, {
                    text: "No important variability",
                    value: "no_important"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOptions: {}
              }
            ]
          }
        },
        balanceOfEffectsEOHR: {
          description: "Does the balance between desirable and undesirable effects favor the intervention or the comparison?",
          ratingDescription: "Does the balance between desirable and undesirable effects favor the intervention or the comparison for each intervention?",
          info: "What is the balance between the desirable and undesirable effects, taking into account how much individuals value the main outcomes, how substantial the desirable and undesirable effects are, the certainty of those estimates, discount rates, risk aversion and risk seeking?",
          options: [
            {
              text: "Favors the comparison",
              value: "favors_comparison"
            }, {
              text: "Probably favors the comparison",
              value: "probably_favors_comparison"
            }, {
              text: "Does not favor either the intervention or the comparison",
              value: "doesnt_favor_any"
            }, {
              text: "Probably favors the intervention",
              value: "probably_favors_intervention"
            }, {
              text: "Favors the intervention",
              value: "favors_intervention"
            }
          ],
          additionalOptions: [
            {
              text: "Varies",
              value: "varies"
            }, {
              text: "Don't know",
              value: "dont_know"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            additionalInfos: [
              {
                description: "Detailed judgements for this criterion include judgements regarding each of the four preceding criteria:",
                criteriaAnswers: [
                  {
                    criterionId: "values"
                  }, {
                    criterionId: "certaintyOfEvidence"
                  }, {
                    criterionId: "desirableEffects"
                  }, {
                    criterionId: "undesirableEffects"
                  }
                ]
              }, {
                description: "In addition, panels might want to consider (and, if relevant document) the extent to which the following considerations might influence the balance between the desirable and undesirable effects:",
                additions: ["How much less people value outcomes that are in the future compared to outcomes that occur now (their discount rates)?", "People’s attitudes towards undesirable effects (how risk averse they are).", "People’s attitudes towards desirable effects (how risk seeking they are)."]
              }
            ]
          },
          ratingOptions: ['best_balance', 'intermediate', 'worst_balance']
        },
        resourcesRequiredEOHR: {
          description: "How large are the resource requirements (costs)?",
          ratingDescription: "How large are the resource requirements (costs) for each intervention?",
          info: "How large is the cost of the difference in resource use between the intervention and comparison?",
          options: [
            {
              text: "Large costs",
              value: "large_costs"
            }, {
              text: "Moderate costs",
              value: "moderate_costs"
            }, {
              text: "Negligible costs and savings",
              value: "negligible_costs_savings"
            }, {
              text: "Moderate savings",
              value: "moderate_savings"
            }, {
              text: "Large savings",
              value: "large_savings"
            }
          ],
          additionalOptions: [
            {
              text: "Varies",
              value: "varies"
            }, {
              text: "Don't know",
              value: "dont_know"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: "resources",
            questions: [
              {
                question: "For each type of resource, would the intervention or exposure under consideration produce additional costs or savings?",
                options: [
                  {
                    text: "Trivial",
                    value: "trivial"
                  }, {
                    text: "Small",
                    value: "small"
                  }, {
                    text: "Moderate",
                    value: "moderate"
                  }, {
                    text: "Large",
                    value: "large"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                resources: []
              }
            ]
          },
          ratingOptions: ['less_costs', 'intermediate_costs', 'most_costs']
        },
        certaintyOfEvidenceOfRequiredResourcesEOHR: {
          description: "What is the certainty of the evidence of resource requirements?",
          info: "How certain is the evidence of a difference for each type of resource use (e.g. drugs, hospitalisations) and the cost of resources?",
          options: [
            {
              text: veryLowText(),
              value: "very_low"
            }, {
              text: "Low",
              value: "low"
            }, {
              text: "Moderate",
              value: "moderate"
            }, {
              text: "High",
              value: "high"
            }
          ],
          additionalOptions: [
            {
              text: "No included studies",
              value: "no_included_studies"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: "resources",
            questions: [
              {
                question: "Have all relevant resource types been identified?",
                options: [
                  {
                    text: "No",
                    value: "no"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Yes",
                    value: "yes"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                resources: []
              }, {
                question: "Is there important variability in the cost of the resource types under consideration?",
                options: [
                  {
                    text: "Important variability",
                    value: "important"
                  }, {
                    text: "Probably important variability",
                    value: "probably_important"
                  }, {
                    text: "Probably no important variability",
                    value: "probably_no_important"
                  }, {
                    text: "No important variability",
                    value: "no_important"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                resources: []
              }
            ]
          }
        },
        costEffectivenessEOHR: {
          description: "Does the cost-effectiveness of the intervention favor the intervention or the comparison?",
          ratingDescription: "Which intervention does the cost effectiveness favor?",
          info: "Is the intervention cost-effective, taking into account uncertainty about or variability in the costs, uncertainty about or variability in the net benefit, sensitivity analyses, and the reliability and applicability of the economic evaluation?",
          options: [
            {
              text: "Favors the comparison",
              value: "favors_comparison"
            }, {
              text: "Probably favors the comparison",
              value: "probably_favors_comparison"
            }, {
              text: "Does not favor either the intervention or the comparison",
              value: "doesnt_favor_any"
            }, {
              text: "Probably favors the intervention",
              value: "probably_favors_intervention"
            }, {
              text: "Favors the intervention",
              value: "favors_intervention"
            }
          ],
          additionalOptions: [
            {
              text: "Varies",
              value: "varies"
            }, {
              text: "No included studies",
              value: "no_included_studies"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: "general",
            questions: [
              {
                question: "What is the certainty in the cost effectiveness analysis?",
                options: [
                  {
                    text: "Favors the comparison",
                    value: "favors_comparison"
                  }, {
                    text: "Probably favors the comparison",
                    value: "probably_favors_comparison"
                  }, {
                    text: "Does not favor either the intervention or the comparison",
                    value: "doesnt_favor_any"
                  }, {
                    text: "Probably favors the intervention",
                    value: "probably_favors_intervention"
                  }, {
                    text: "Favors the intervention",
                    value: "favors_intervention"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "No included studies",
                    value: "no_included_studies"
                  }
                ],
                selectedOption: ""
              }
            ]
          },
          ratingOptions: ["best_cost_effectiveness", "intermediate_cost_effectiveness", "worst_cost_effectiveness"]
        },
        equityEOHR: {
          description: "What would be the impact on equity?",
          ratingDescription: "If recommended, which intervention would reduce health inequities the most?",
          info: "Are there plausible reasons for anticipating differences in the relative effectiveness of the intervention for disadvantaged subgroups or different baseline conditions across disadvantaged subgroups that affect the absolute effectiveness of the intervention or the importance of the problem?",
          options: [
            {
              text: "Reduced",
              value: "reduced"
            }, {
              text: "Probably reduced",
              value: "probably_reduced"
            }, {
              text: "Probably no impact",
              value: "probably_no_impact"
            }, {
              text: "Probably increased",
              value: "probably_increased"
            }, {
              text: "Increased",
              value: "increased"
            }
          ],
          additionalOptions: [
            {
              text: "Varies",
              value: "varies"
            }, {
              text: "Don't know",
              value: "dont_know"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: "general",
            questions: [
              {
                question: "Are there groups or settings (e.g., susceptible populations or life stages) that may experience a different impact (effect) of the intervention or exposure due to variability in baseline conditions across the affected population?",
                options: [
                  {
                    text: "Yes",
                    value: "yes"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "No",
                    value: "no"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }, {
                    text: "Varies",
                    value: "varies"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Are the impacts unreasonably disproportionate to particular groups (e.g., complex or unquantified stressors)?",
                options: [
                  {
                    text: "Yes",
                    value: "yes"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "No",
                    value: "no"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }, {
                    text: "Varies",
                    value: "varies"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Would implementing the intervention or exposure reduce or increase inequities experienced by marginalized members of the affected population?",
                options: [
                  {
                    text: "Yes",
                    value: "yes"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "No",
                    value: "no"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }, {
                    text: "Varies",
                    value: "varies"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Are issues of equality (e.g., under-represented or under-studied groups) addressed?",
                options: [
                  {
                    text: "Yes",
                    value: "yes"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "No",
                    value: "no"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }, {
                    text: "Varies",
                    value: "varies"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Are social justice issues addressed? (e.g., Is the spirit of the ‘polluter pays principle’ upheld with regard to distribution of impacts/benefits?)",
                options: [
                  {
                    text: "Yes",
                    value: "yes"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "No",
                    value: "no"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }, {
                    text: "Varies",
                    value: "varies"
                  }
                ],
                selectedOption: ""
              }
            ]
          },
          ratingOptions: ['most_reduction', 'intermediate_reduction', 'less_reduction']
        },
        acceptabilityEOHR: {
          description: "Is the intervention acceptable to key stakeholders?",
          ratingDescription: "Which intervention is more acceptable to key stakeholders?",
          info: "Are key stakeholders likely not to accept the distribution of the benefits, harms and costs; or the costs or undesirable effects in the short term for desirable effects (benefits) in the future? Are they likely to disagree with the values attached to the desirable or undesirable effects, or not to accept the diagnostic intervention because of ethical concerns?",
          options: [
            {
              text: "No",
              value: "no"
            }, {
              text: "Probably no",
              value: "probably_no"
            }, {
              text: "Probably yes",
              value: "probably_yes"
            }, {
              text: "Yes",
              value: "yes"
            }
          ],
          additionalOptions: [
            {
              text: "Varies",
              value: "varies"
            }, {
              text: "Don't know",
              value: "dont_know"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: "general",
            questions: [
              {
                question: "What is the certainty in the acceptability of the intervention among the affected population?",
                options: [
                  {
                    text: "Very low",
                    value: "very_low"
                  }, {
                    text: "Low",
                    value: "low"
                  }, {
                    text: "Moderate",
                    value: "moderate"
                  }, {
                    text: "High",
                    value: "high"
                  }
                ],
                additionalOptions: [
                  {
                    text: "No included studies",
                    value: "no_included_studies"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Is there variability in the acceptability of the intervention or exposure among beneficiaries of the recommendation and other stakeholders?",
                options: [
                  {
                    text: "Important variability",
                    value: "important"
                  }, {
                    text: "Probably important variability",
                    value: "probably_important"
                  }, {
                    text: "Probably no important variability",
                    value: "probably_no_important"
                  }, {
                    text: "No important variability",
                    value: "no_important"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }
            ]
          },
          ratingOptions: ['best_acceptability', 'intermediate_acceptability', 'worst_acceptability']
        },
        feasibilityEOHR: {
          description: "Is the intervention feasible to implement?",
          ratingDescription: "Which intervention is more feasible to implement?",
          info: "Is it feasible to sustain use of the diagnostic intervention and to address potential barriers to using it?",
          options: [
            {
              text: "No",
              value: "no"
            }, {
              text: "Probably no",
              value: "probably_no"
            }, {
              text: "Probably yes",
              value: "probably_yes"
            }, {
              text: "Yes",
              value: "yes"
            }
          ],
          additionalOptions: [
            {
              text: "Varies",
              value: "varies"
            }, {
              text: "Don't know",
              value: "dont_know"
            }
          ],
          selectedOption: "",
          decision: {
            selectedOption: "",
            comments: ""
          },
          details: {
            panelDiscussion: "",
            questionsType: "general",
            questions: [
              {
                question: "Is the intervention (option) sustainable for the relevant duration of time?",
                options: [
                  {
                    text: "No",
                    value: "no"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Yes",
                    value: "yes"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Are there important barriers (e.g., absence of laws/regulations) that are likely to limit the feasibility of implementing the intervention (option)?",
                options: [
                  {
                    text: "No",
                    value: "no"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Yes",
                    value: "yes"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Are remedies available to address any important barriers to implementing the intervention?",
                options: [
                  {
                    text: "No",
                    value: "no"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Yes",
                    value: "yes"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }, {
                question: "Are there important enablers (e.g., presence of laws/regulations) that are likely to improve the feasibility of implementing the intervention (option)?",
                options: [
                  {
                    text: "No",
                    value: "no"
                  }, {
                    text: "Probably no",
                    value: "probably_no"
                  }, {
                    text: "Probably yes",
                    value: "probably_yes"
                  }, {
                    text: "Yes",
                    value: "yes"
                  }
                ],
                additionalOptions: [
                  {
                    text: "Varies",
                    value: "varies"
                  }, {
                    text: "Don't know",
                    value: "dont_know"
                  }
                ],
                selectedOption: ""
              }
            ]
          },
          ratingOptions: ['most_feasible', 'intermediate_feasibility', 'least_feasible']
        }
      },
      researchEvidences: {
        problem: {
          content: ""
        },
        desirableEffects: {
          content: ""
        },
        undesirableEffects: {
          content: ""
        },
        certaintyOfEvidence: {
          content: ""
        },
        values: {
          content: ""
        },
        balanceOfEffects: {
          content: ""
        },
        resourcesRequired: {
          content: ""
        },
        certaintyOfEvidenceOfRequiredResources: {
          content: ""
        },
        costEffectiveness: {
          content: ""
        },
        equity: {
          content: ""
        },
        acceptability: {
          content: ""
        },
        feasibility: {
          content: ""
        },
        testAccuracy: {
          content: ""
        },
        certaintyOfEvidenceOfTestAccuracy: {
          content: ""
        },
        certaintyOfEvidenceOfTestEffects: {
          content: ""
        },
        certaintyOfEvidenceOfManagementEffects: {
          content: ""
        },
        certaintyOfEvidenceOfTestResult: {
          content: ""
        },
        certaintyOfEffects: {
          content: ""
        },
        balanceOfHealthBenefitsAndHarms: {
          content: ""
        },
        humanRights: {
          content: ""
        },
        socioCulturalAcceptability: {
          content: ""
        },
        healthEquityEqualityAndNonDiscrimination: {
          content: ""
        },
        societalImplications: {
          content: ""
        },
        financialAndEconomicConsiderations: {
          content: ""
        },
        feasibilityAndHealthSystemConsiderations: {
          content: ""
        },
        qualityOfEvidence: {
          content: ""
        },
        additionalSection: {
          content: ""
        },
        scientificallySound: {
          content: ""
        },
        relevantDirect: {
          content: ""
        },
        responsive: {
          content: ""
        },
        feasible: {
          content: ""
        },
        netBalance: {
          content: ""
        }
      },
      additionalConsiderations: {
        problem: {
          content: ""
        },
        desirableEffects: {
          content: ""
        },
        undesirableEffects: {
          content: ""
        },
        certaintyOfEvidence: {
          content: ""
        },
        values: {
          content: ""
        },
        balanceOfEffects: {
          content: ""
        },
        resourcesRequired: {
          content: ""
        },
        certaintyOfEvidenceOfRequiredResources: {
          content: ""
        },
        costEffectiveness: {
          content: ""
        },
        equity: {
          content: ""
        },
        acceptability: {
          content: ""
        },
        feasibility: {
          content: ""
        },
        testAccuracy: {
          content: ""
        },
        certaintyOfEvidenceOfTestAccuracy: {
          content: ""
        },
        certaintyOfEvidenceOfTestEffects: {
          content: ""
        },
        certaintyOfEvidenceOfManagementEffects: {
          content: ""
        },
        certaintyOfEvidenceOfTestResult: {
          content: ""
        },
        certaintyOfEffects: {
          content: ""
        },
        balanceOfHealthBenefitsAndHarms: {
          content: ""
        },
        humanRights: {
          content: ""
        },
        socioCulturalAcceptability: {
          content: ""
        },
        healthEquityEqualityAndNonDiscrimination: {
          content: ""
        },
        societalImplications: {
          content: ""
        },
        financialAndEconomicConsiderations: {
          content: ""
        },
        feasibilityAndHealthSystemConsiderations: {
          content: ""
        },
        qualityOfEvidence: {
          content: ""
        },
        additionalSection: {
          content: ""
        },
        scientificallySound: {
          content: ""
        },
        relevantDirect: {
          content: ""
        },
        responsive: {
          content: ""
        },
        feasible: {
          content: ""
        },
        netBalance: {
          content: ""
        }
      }
    },
    conclusions: {
      sections: {
        recommendationTypeInterventionMultipleChoice: {
          description: "Type of recommendation",
          options: [
            {
              text: "Strong recommendation against the intervention",
              value: "recommend_against",
              direction: "negative"
            }, {
              text: "Conditional recommendation against the intervention",
              value: "suggest_against",
              direction: "negative"
            }, {
              text: "Conditional recommendation for either the intervention or the comparison",
              value: "suggest_either",
              direction: "none"
            }, {
              text: "Conditional recommendation for the intervention",
              value: "suggest",
              direction: "positive"
            }, {
              text: "Strong recommendation for the intervention",
              value: "recommend",
              direction: "positive"
            }
          ],
          selectedOptions: {}
        },
        recommendationTypeIntervention: {
          description: "Type of recommendation",
          options: [
            {
              text: "Strong recommendation against the intervention",
              value: "recommend_against",
              direction: "negative"
            }, {
              text: "Conditional recommendation against the intervention",
              value: "suggest_against",
              direction: "negative"
            }, {
              text: "Conditional recommendation for either the intervention or the comparison",
              value: "suggest_either",
              direction: "none"
            }, {
              text: "Conditional recommendation for the intervention",
              value: "suggest",
              direction: "positive"
            }, {
              text: "Strong recommendation for the intervention",
              value: "recommend",
              direction: "positive"
            }
          ],
          selectedOption: "",
          content: ""
        },
        recommendationTypeOption: {
          description: "Type of recommendation",
          options: [
            {
              text: "Strong recommendation against the option",
              value: "recommend_against",
              direction: "negative"
            }, {
              text: "Conditional recommendation against the option",
              value: "suggest_against",
              direction: "negative"
            }, {
              text: "Conditional recommendation for either the option or the comparison",
              value: "suggest_either",
              direction: "none"
            }, {
              text: "Conditional recommendation for the option",
              value: "suggest",
              direction: "positive"
            }, {
              text: "Strong recommendation for the option",
              value: "recommend",
              direction: "positive"
            }
          ],
          selectedOption: "",
          content: ""
        },
        decisionTypeCoverage: {
          description: "Type of decision",
          options: [
            {
              text: "Do not cover",
              value: "do_not_cover",
              direction: "negative"
            }, {
              text: "Cover with evidence development",
              value: "cover_with_evidence",
              direction: "positive"
            }, {
              text: "Cover with price negotiation",
              value: "cover_with_price",
              direction: "positive"
            }, {
              text: "Restricted coverage",
              value: "restricted_coverage",
              direction: "positive"
            }, {
              text: "Cover",
              value: "cover",
              direction: "positive"
            }
          ],
          selectedOption: "",
          content: ""
        },
        decisionTypeImplementation: {
          description: "Type of decision",
          options: [
            {
              text: "Do not implement the option",
              value: "do_not_implement",
              direction: "negative"
            }, {
              text: "Postpone the decision",
              value: "postpone",
              direction: "none"
            }, {
              text: "Conduct a pilot study of the option",
              value: "conduct_pilot_study",
              direction: "none"
            }, {
              text: "Implement the option with an impact evaluation",
              value: "implement_with_evaluation",
              direction: "positive"
            }, {
              text: "Implement the option",
              value: "implement",
              direction: "positive"
            }
          ],
          selectedOption: "",
          content: ""
        },
        recommendation: {
          description: "Recommendation",
          content: ""
        },
        multipleRecommendations: {
          description: "Recommendation(s)",
          recommendations: [
            {
              selectedOption: "",
              content: ""
            }
          ],
          options: [
            {
              text: "Strong recommendation against the intervention",
              value: "recommend_against",
              direction: "negative"
            }, {
              text: "Conditional recommendation against the intervention",
              value: "suggest_against",
              direction: "negative"
            }, {
              text: "Conditional recommendation for either the intervention or the comparison",
              value: "suggest_either",
              direction: "none"
            }, {
              text: "Conditional recommendation for the intervention",
              value: "suggest",
              direction: "positive"
            }, {
              text: "Strong recommendation for the intervention",
              value: "recommend",
              direction: "positive"
            }
          ]
        },
        decision: {
          description: "Decision",
          content: "",
          options: [
            {
              text: "Full implementation",
              value: "full_implementation"
            }, {
              text: "Impact evaluation",
              value: "impact_evaluation"
            }, {
              text: "Pilot Study",
              value: "pilot_study"
            }, {
              text: "Postpone",
              value: "postpone"
            }, {
              text: "Do not implement",
              value: "do_not_implement"
            }
          ]
        },
        justification: {
          description: "Justification",
          overallDescription: "Overall justification",
          content: "",
          details: {
            description: "Detailed justification"
          }
        },
        subgroupConsiderations: {
          description: "Subgroup considerations",
          content: ""
        },
        restrictions: {
          description: "Restrictions",
          content: ""
        },
        implementationConsiderations: {
          description: "Implementation considerations",
          content: "",
          details: {
            description: "Detailed implementation",
            content: ""
          }
        },
        multipleChronicConditionsAndPolypharmacy: {
          description: "Multiple Chronic conditions and Polypharmacy",
          content: ""
        },
        monitoringAndEvaluation: {
          description: "Monitoring and evaluation",
          content: "",
          details: {
            description: "Detailed monitoring and evaluation",
            content: ""
          }
        },
        researchPriorities: {
          description: "Research priorities",
          content: ""
        },
        contextAndSystemConsiderations: {
          description: "Context and system considerations",
          content: ""
        },
        goodPracticeStatement: {
          description: "Good practice statement",
          content: ""
        },
        clearAndActionable: {
          description: "Clear and actionable",
          content: "",
          options: [
            {
              text: "Yes",
              value: "yes"
            }, {
              text: "Probably yes",
              value: "probably_yes"
            }, {
              text: "Probably no",
              value: "probably_no"
            }, {
              text: "No",
              value: "no"
            }
          ],
          selectedOption: ""
        },
        theMessageIsNecessary: {
          description: "The message is necessary in regard to actual health care practice",
          content: "",
          options: [
            {
              text: "Yes",
              value: "yes"
            }, {
              text: "Probably yes",
              value: "probably_yes"
            }, {
              text: "Probably no",
              value: "probably_no"
            }, {
              text: "No",
              value: "no"
            }
          ],
          selectedOption: ""
        },
        implementationConsiderationsOfAllRelevantOutcomes: {
          description: "After consideration of all relevant outcomes and potential downstream consequences, implementing the good practice statement results in a large net positive consequences",
          content: "",
          options: [
            {
              text: "Yes",
              value: "yes"
            }, {
              text: "Probably yes",
              value: "probably_yes"
            }, {
              text: "Probably no",
              value: "probably_no"
            }, {
              text: "No",
              value: "no"
            }
          ],
          selectedOption: ""
        },
        collectingAndSummarizing: {
          description: "Collecting and summarizing the evidence is a poor use of the guideline panel's limited time and energy (opportunity cost in large)",
          content: "",
          options: [
            {
              text: "Yes",
              value: "yes"
            }, {
              text: "Probably yes",
              value: "probably_yes"
            }, {
              text: "Probably no",
              value: "probably_no"
            }, {
              text: "No",
              value: "no"
            }
          ],
          selectedOption: ""
        },
        rationaleConnectingIndirectEvidence: {
          description: "There is a well-documented clear and explicit rationale connecting the indirect evidence",
          content: "",
          options: [
            {
              text: "Yes",
              value: "yes"
            }, {
              text: "Probably yes",
              value: "probably_yes"
            }, {
              text: "Probably no",
              value: "probably_no"
            }, {
              text: "No",
              value: "no"
            }
          ],
          selectedOption: ""
        },
        implementationConsiderationsOrRemarks: {
          description: "Implementation considerations",
          content: "",
          options: [
            {
              text: "Yes",
              value: "yes"
            }, {
              text: "Probably yes",
              value: "probably_yes"
            }, {
              text: "Probably no",
              value: "probably_no"
            }, {
              text: "No",
              value: "no"
            }
          ],
          selectedOption: ""
        },
        keyMessage: {
          description: "Key Message",
          content: ""
        },
        limitations: {
          description: "Limitations",
          content: ""
        },
        considerations: {
          description: "Considerations",
          content: ""
        },
        mdgRecommendation: {
          description: "Recommendation",
          selectedOption: "",
          options: [
            {
              text: "Recommended",
              value: "recommend",
              direction: "positive"
            }, {
              text: "Not Recommended",
              value: "recommend_against",
              direction: "negative"
            }, {
              text: "Sometimes Recommended",
              value: "suggest_against",
              direction: "negative"
            }, {
              text: "No Recommendation",
              value: "suggest_either",
              direction: "none"
            }
          ],
          content: ""
        },
        indications: {
          description: "Indications",
          content: ""
        },
        benefits: {
          description: "Benefits",
          content: ""
        },
        harms: {
          description: "Harms",
          content: ""
        },
        frequencyDoseDuration: {
          description: "Frequency/Dose/Duration",
          content: ""
        },
        indicationsForDiscontinuation: {
          description: "Indications for discontinuation",
          content: ""
        },
        rationale: {
          description: "Rationale",
          content: ""
        },
        evidence: {
          description: "Evidence",
          content: ""
        },
        monitoringAndEvaluationQI: {
          description: "Monitoring and evaluation",
          content: ""
        },
        clinicalConsiderations: {
          description: "Clinical considerations",
          content: ""
        }
      }
    },
    presentations: {
      sectionsOrder: [],
      sections: {
        clinicians: {
          name: 'Clinicians',
          sectionsOrder: [],
          sections: {
            background: {
              name: "Background"
            },
            detailedJustification: {
              name: "Detailed justification"
            },
            justification: {
              name: "Justification"
            },
            subgroupConsiderations: {
              name: "Subgroup considerations"
            },
            summaryOfFindings: {
              name: "Summary of findings"
            },
            relatedRecommendations: {
              name: "Related recommendations"
            },
            implementationConsiderations: {
              name: "Implementation considerations"
            }
          }
        },
        patients: {
          name: "Patients",
          sectionsOrder: [],
          sections: {
            whyThisRecommendation: {
              name: "Why this recommendation?",
              parts: {
                whyThisRecommendation: {
                  name: "Why this recommendation",
                  content: ""
                },
                benefitsAndHarms: {
                  name: "Benefits and Harms",
                  content: ""
                },
                additionalInformation: {
                  name: "Additional information",
                  content: ""
                }
              },
              sectionsOrder: ['whyThisRecommendation', 'benefitsAndHarms', 'additionalInformation']
            },
            whatItMeansForYou: {
              name: "What does this mean for you?",
              parts: {
                whatYouCanDo: {
                  name: "What you can do",
                  content: ""
                },
                speakWithHCProfessional: {
                  name: "Talk with your health care professional",
                  content: "",
                  additionalFields: [
                    {
                      content: ""
                    }, {
                      content: ""
                    }
                  ]
                }
              },
              sectionsOrder: ['whatYouCanDo', 'speakWithHCProfessional']
            },
            whoIsThisFor: {
              name: "Who is this for?",
              parts: {
                whoIsThisFor: {
                  name: "Who is this for",
                  content: "",
                  additionalFields: [
                    {
                      content: ""
                    }
                  ]
                }
              },
              sectionsOrder: ['whoIsThisFor']
            },
            summaryOfFindings: {
              name: "SoF"
            },
            relatedRecommendations: {
              name: "Related recommendations"
            }
          }
        },
        policymakers: {
          name: "Policymakers",
          sectionsOrder: [],
          sections: {
            assessment: {
              name: "Assessment"
            },
            background: {
              name: "Background",
              description: "Question details for recommendation",
              details: {
                name: "About this decision",
                sections: {
                  setting: {
                    description: "Setting",
                    content: ""
                  },
                  perspective: {
                    description: "Perspective",
                    content: ""
                  },
                  decisionMakers: {
                    description: "Decision makers",
                    content: ""
                  },
                  date: {
                    description: "Date",
                    content: ""
                  }
                },
                sectionsOrder: ['setting', 'perspective', 'decisionMakers', 'date']
              }
            },
            decision: {
              name: "Decision",
              content: "",
              selectedOption: "",
              options: [
                {
                  text: "Full implementation",
                  value: "full_implementation"
                }, {
                  text: "Impact evaluation",
                  value: "impact_evaluation"
                }, {
                  text: "Pilot Study",
                  value: "pilot_study"
                }, {
                  text: "Postpone",
                  value: "postpone"
                }, {
                  text: "Do not implement",
                  value: "do_not_implement"
                }
              ]
            },
            implementation: {
              name: "Implementation"
            },
            justification: {
              name: "Justification"
            },
            monitoringAndEvaluation: {
              name: "Monitoring & Evaluation"
            },
            summaryOfFindings: {
              name: "Summary of findings"
            },
            relatedRecommendations: {
              name: "Related recommendations"
            }
          }
        }
      }
    }
  };
};

module.exports = sections;
