ChapterItem = require 'components/document_sections/v2/chapter_item'
{ DndProvider } = ReactDnD

ChaptersList = createReactClass
  displayName: 'ChaptersList'

  propTypes:
    chaptersOrder: PropTypes.instanceOf(Immutable.List).isRequired
    chapters: PropTypes.instanceOf(Immutable.Map).isRequired
    questionGroups: PropTypes.instanceOf(Immutable.Map).isRequired
    questions: PropTypes.instanceOf(Immutable.OrderedMap).isRequired
    scrollToSectionContent: PropTypes.shape({
      sectionId: PropTypes.string.isRequired,
      contentPos: PropTypes.number
    })
    mdaTopicId: PropTypes.string

  renderItems: (chaptersIds) ->
    { chapters, mdaTopicId, questionGroups, questions, scrollToSectionContent } = @props

    <ol className="chapters-list-items">
      {
        chaptersIds.map (chapterId) =>
          chapter = chapters.get chapterId
          subchaptersIds = chapter.get 'subchapters'

          <li key={chapterId} className="chapters-list-item">
            <ChapterItem
              chapterId={chapterId}
              mdaTopicId={mdaTopicId}
              questionGroups={questionGroups}
              questions={questions}
              scrollToSectionContent={scrollToSectionContent}
            />
            {
              if !subchaptersIds.isEmpty()
                @renderItems subchaptersIds
            }
          </li>
      }
    </ol>

  getRef: (el) ->
    @el = el

  handleDragOver: (evt) ->
    elRect = @el.getBoundingClientRect()
    currentScrollTop = @el.scrollTop
    edgeDelta = 120
    scrollingDelta = 20
    if evt.clientY <= elRect.y + edgeDelta
      @el.scrollTo({top: currentScrollTop - scrollingDelta})
    else if evt.clientY >= elRect.height - edgeDelta
      @el.scrollTo({top: currentScrollTop + scrollingDelta})

  render: ->
    { chaptersOrder } = @props

    <DndProvider backend={ReactDnDHTML5Backend['default']}>
      <div
        ref={@getRef}
        className="chapters-list flex flex-col overflow-auto"
        onDragOver={@handleDragOver}
      >
        {@renderItems chaptersOrder}
      </div>
    </DndProvider>


module.exports = ChaptersList
