'use strict';
var parent = require('../../../actual/array/virtual');
// TODO: Remove from `core-js@4`
require('../../../modules/esnext.array.at');
// TODO: Remove from `core-js@4`
require('../../../modules/esnext.array.filter-out');
require('../../../modules/esnext.array.filter-reject');
require('../../../modules/esnext.array.unique-by');

module.exports = parent;
