var INITIAL_STATE, PanelVoiceEtdActions, PanelVoiceEtdStore, VOTING_RESULTS_VIEW_TYPES, alt, errorHandler, immutable, mediator;

alt = require('alt');

mediator = require('mediator');

immutable = require('stores/utils/immutable');

errorHandler = require('lib/app_utils').errorHandler;

PanelVoiceEtdActions = require('actions/panel_voice_etd_actions');

VOTING_RESULTS_VIEW_TYPES = require('lib/etd_helper').VOTING_RESULTS_VIEW_TYPES;

INITIAL_STATE = Immutable.fromJS({
  meta: {
    activeTab: 'workspace',
    fetchingAdminSummaries: false,
    editingSectionId: null,
    activeStage: null,
    activeRound: null,
    fetchingViewSettings: false,
    activeJudgmentOptionBySection: {},
    activeResultsViewTypeBySection: {}
  },
  adminVotingSummaries: {},
  viewSettings: {
    anonymousVotingResults: false,
    hideVotingResults: false
  }
});

PanelVoiceEtdStore = (function() {
  function PanelVoiceEtdStore() {
    this.state = INITIAL_STATE;
    this.bindActions(PanelVoiceEtdActions);
    this.exportPublicMethods({
      getActiveTab: this.getActiveTab,
      getAnonymousMode: this.getAnonymousMode,
      getEditingSectionId: this.getEditingSectionId,
      getActiveStage: this.getActiveStage,
      getActiveRound: this.getActiveRound,
      getViewSettings: this.getViewSettings,
      isFetchingViewSettings: this.isFetchingViewSettings,
      isFetchingAdminSummaries: this.isFetchingAdminSummaries,
      getAdminVotingSummary: this.getAdminVotingSummary.bind(this),
      getActiveJudgmentOptionBySection: this.getActiveJudgmentOptionBySection,
      getResultsViewTypeBySection: this.getResultsViewTypeBySection
    });
  }

  PanelVoiceEtdStore.prototype.onChangeTab = function(tabName) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['meta', 'activeTab'], tabName);
      state.setIn(['meta', 'activeJudgmentOptionBySection'], Immutable.Map());
      return state.setIn(['meta', 'activeResultsViewTypeBySection'], Immutable.Map());
    }));
  };

  PanelVoiceEtdStore.prototype.onToggleEtdSectionEdit = function(sectionId) {
    return this.setState(this.state.updateIn(['meta', 'editingSectionId'], function(editingId) {
      if (editingId === sectionId) {
        return null;
      } else {
        return sectionId;
      }
    }));
  };

  PanelVoiceEtdStore.prototype.onUpdateActiveStage = function(newStage) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['meta', 'activeStage'], newStage);
      return state.setIn(['meta', 'activeRound'], null);
    }));
  };

  PanelVoiceEtdStore.prototype.onFetchViewSettingsSuccess = function(_arg) {
    var anonymousVotingResults, hideVotingResults;
    anonymousVotingResults = _arg.anonymousVotingResults, hideVotingResults = _arg.hideVotingResults;
    return this.setState(this.state.withMutations(function(state) {
      state.set('viewSettings', Immutable.fromJS({
        anonymousVotingResults: anonymousVotingResults,
        hideVotingResults: hideVotingResults
      }));
      return state.setIn(['meta', 'fetchingViewSettings'], false);
    }));
  };

  PanelVoiceEtdStore.prototype.onFetchViewSettingsError = function(err) {
    this.setState(this.state.setIn(['meta', 'fetchingViewSettings'], false));
    if (err.status !== 404) {
      return errorHandler(err);
    }
  };

  PanelVoiceEtdStore.prototype.onUpdateViewSetting = function(_arg) {
    var isActive, settingName;
    settingName = _arg.settingName, isActive = _arg.isActive;
    return this.setState(this.state.setIn(['viewSettings', settingName], isActive));
  };

  PanelVoiceEtdStore.prototype.onUpdateActiveRound = function(roundTimestamp) {
    return this.setState(this.state.setIn(['meta', 'activeRound'], roundTimestamp));
  };

  PanelVoiceEtdStore.prototype.onFetchAdminSummaries = function() {
    return this.setState(this.state.setIn(['meta', 'fetchingAdminSummaries'], true));
  };

  PanelVoiceEtdStore.prototype.onFetchAdminSummariesSuccess = function(summariesDoc) {
    return this.setState(this.state.withMutations(function(state) {
      state.setIn(['meta', 'fetchingAdminSummaries'], false);
      return state.set('adminVotingSummaries', Immutable.fromJS(summariesDoc));
    }));
  };

  PanelVoiceEtdStore.prototype.onFetchAdminSummariesError = function() {
    return this.setState(this.state.setIn(['meta', 'fetchingAdminSummaries'], false));
  };

  PanelVoiceEtdStore.prototype.onUpdateAdminVotingSummary = function(data) {
    var content, roundTimestamp, sectionId, updatePath;
    sectionId = data.sectionId, roundTimestamp = data.roundTimestamp, content = data.content;
    updatePath = ['adminVotingSummaries', 'rounds', roundTimestamp, 'sections', sectionId];
    return this.setState(this.state.setIn(updatePath, content));
  };

  PanelVoiceEtdStore.prototype.onSetSectionActiveJudgmentOption = function(_arg) {
    var option, sectionId;
    sectionId = _arg.sectionId, option = _arg.option;
    return this.setState(this.state.setIn(['meta', 'activeJudgmentOptionBySection', sectionId], option));
  };

  PanelVoiceEtdStore.prototype.onSetSectionResultsViewType = function(_arg) {
    var sectionId, type;
    sectionId = _arg.sectionId, type = _arg.type;
    return this.setState(this.state.setIn(['meta', 'activeResultsViewTypeBySection', sectionId], type));
  };

  PanelVoiceEtdStore.prototype.getActiveTab = function() {
    return this.state.getIn(['meta', 'activeTab']);
  };

  PanelVoiceEtdStore.prototype.getAnonymousMode = function() {
    return this.state.getIn(['meta', 'anonymousMode']);
  };

  PanelVoiceEtdStore.prototype.getEditingSectionId = function() {
    return this.state.getIn(['meta', 'editingSectionId']);
  };

  PanelVoiceEtdStore.prototype.getActiveStage = function() {
    return this.state.getIn(['meta', 'activeStage']);
  };

  PanelVoiceEtdStore.prototype.getActiveRound = function() {
    return this.state.getIn(['meta', 'activeRound']);
  };

  PanelVoiceEtdStore.prototype.getViewSettings = function() {
    return this.state.get('viewSettings');
  };

  PanelVoiceEtdStore.prototype.isFetchingAdminSummaries = function() {
    return this.state.getIn(['meta', 'fetchingAdminSummaries'], true);
  };

  PanelVoiceEtdStore.prototype.isFetchingViewSettings = function() {
    return this.state.getIn(['meta', 'fetchingViewSettings']);
  };

  PanelVoiceEtdStore.prototype.getAdminVotingSummary = function(votingTimestamp, sectionId) {
    return this.state.getIn(['adminVotingSummaries', 'rounds', "" + votingTimestamp, 'sections', sectionId]);
  };

  PanelVoiceEtdStore.prototype.getActiveJudgmentOptionBySection = function() {
    return this.state.getIn(['meta', 'activeJudgmentOptionBySection']);
  };

  PanelVoiceEtdStore.prototype.getResultsViewTypeBySection = function() {
    return this.state.getIn(['meta', 'activeResultsViewTypeBySection']);
  };

  return PanelVoiceEtdStore;

})();

module.exports = alt.createStore(immutable(PanelVoiceEtdStore), 'PanelVoiceEtdStore');
