Button = require 'components/common/button'
ComparisonQuestionsListButtonsRow =
  require 'components/evidence_syntheses/comparison_questions_list_buttons_row'
ConnectStore = require 'components/enhancers/connect_store'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
DynamicHeightContainer = require 'components/common/dynamic_height_container'
EditableQuestion = require 'components/evidence_syntheses/editable_question'
EditableQuestionsList =
  require 'components/evidence_syntheses/questions_list/editable_questions_list'
IconButton = require 'components/common/icon_button'
{ isValidLimit } = require 'lib/limits_helper'
KeyMessagesActions = require 'actions/key_messages_actions'
LimitExceededWarningModal = require 'components/common/limit_exceeded_warning_modal'
mediator = require 'mediator'
DocumentSectionsCommonStore = require 'stores/document_sections_common_store'
OrganizationsStore = require 'stores/organizations_store'
OverarchingQuestionsStore = require 'stores/overarching_questions_store'
OverarchingQuestionsActions = require 'actions/overarching_questions_actions'
QuestionsListComponentActions = require 'actions/questions_list_component_actions'
QuestionsActions = require 'actions/questions_actions'
QuestionsStore = require 'stores/questions_store'
QuestionGroupsStore = require 'stores/question_groups_store'
Spinner = require 'components/common/spinner'
Tooltip = require 'components/common/tooltip'
Translation = require 'components/mixins/translation'
withLimits = require 'components/enhancers/with_limits'

storeConnector =
  DocumentSectionsCommonStore: (Store) ->
    alreadyUsedRecommendations: Store.getAlreadyUsedRecommendationsQuestionsIds()
  QuestionsStore: (Store) ->
    questions: Store.getQuestions('regular')
    isFetchingQuestions: Store.isFetching()

  QuestionGroupsStore: (Store) ->
    questionGroups: Store.getQuestionGroups 'regular'
    isFetchingQuestionGroups: Store.isFetching()

  OrganizationsStore: (Store) ->
    projectFromOrganization: Store.isCurrentProjectFromOrganization()

  OverarchingQuestionsStore: (Store) ->
    isFetchingOverarchingQuestions: Store.isFetching()

ComparisonQuestionsList = createReactClass
  displayName: 'ComparisonQuestionsList'

  propTypes:
    alreadyUsedRecommendations: PropTypes.instanceOf(Immutable.Set).isRequired
    limits: PropTypes.instanceOf(Immutable.Map).isRequired,
    onQuestionsImport: PropTypes.func.isRequired
    questionGroups: PropTypes.instanceOf(Immutable.Map).isRequired
    questions: PropTypes.oneOfType([
      PropTypes.instanceOf(Immutable.OrderedMap),
      PropTypes.instanceOf(Immutable.Map)]
    ).isRequired
    isFetchingQuestions: PropTypes.bool.isRequired
    isFetchingQuestionGroups: PropTypes.bool.isRequired
    isFetchingOverarchingQuestions: PropTypes.bool.isRequired
    projectFromOrganization: PropTypes.bool.isRequired

  mixins: [
    CustomRenderMixin
    Translation('es:question')
  ]

  getInitialState: ->
    limitWarningModalOpen: false

  openLimitWarningModal: ->
    @setState limitWarningModalOpen: true

  closeLimitWarningModal: ->
    @setState limitWarningModalOpen: false

  isFetchingData: ->
    { isFetchingQuestions, isFetchingQuestionGroups, isFetchingOverarchingQuestions } = @props
    isFetchingQuestions or isFetchingQuestionGroups or isFetchingOverarchingQuestions

  isValidQuestionsLimit: ->
    isValidLimit @props.questions.size, @props.limits.get('questions')

  handleButtonAction: (action) ->  =>
    if not @isValidQuestionsLimit()
      @openLimitWarningModal()
      return

    switch action
      when 'add-management-question'
        @addRegular()
      when 'add-overarching-question'
        @addOverarching()
      when 'add-diagnostic-question'
        @addDiagnostic()
      when 'add-good-practice-question'
        @addGoodPractice()
      when 'add-key-message'
        @addKeyMessage()
      when 'import-questions'
        @props.onQuestionsImport()

  addOverarching: ->
    OverarchingQuestionsActions.createQuestion()

  addRegular: ->
    QuestionsActions.createQuestion
      questionsCollectionType: 'regular'

  addGoodPractice:->
    QuestionsActions.createQuestion
      questionsCollectionType: 'regular'
      attributes: type: 'good-practice-statement'

  addKeyMessage:->
    KeyMessagesActions.create()

  addDiagnostic: ->
    QuestionsActions.createQuestion
      questionsCollectionType: 'regular'
      attributes: type: 'diagnostic'

  render: ->
    isMdgTablesFeatureOn = mediator.services.switches.isOn 'mdgTables'

    if @isFetchingData()
      <Spinner />
    else
      <div className='comparison-questions-list'>
        <div>
          <DynamicHeightContainer>
            <EditableQuestionsList
              alreadyUsedRecommendations={@props.alreadyUsedRecommendations}
              questions={@props.questions}
              projectFromOrganization={@props.projectFromOrganization}
              questionGroups={@props.questionGroups}
              withNewGroupDropArea={@props.showDropQuestionGroupArea}
            >
              <EditableQuestion
                questionsCount={@props.questions.size}
                openLimitWarningModal={@openLimitWarningModal}
              />
            </EditableQuestionsList>
          </DynamicHeightContainer>
        </div>
        {not isMdgTablesFeatureOn && <ComparisonQuestionsListButtonsRow
            handleButtonAction={@handleButtonAction}
          />
        }
        {@state.limitWarningModalOpen and <LimitExceededWarningModal
          title={@i18n '../limits.questions_limit_reached'}
          onCancel={@closeLimitWarningModal}
        />}
      </div>

module.exports = withLimits(
  ConnectStore(
    ComparisonQuestionsList,
    [
      QuestionsStore
      QuestionGroupsStore
      OrganizationsStore
      OverarchingQuestionsStore
      DocumentSectionsCommonStore
    ],
    storeConnector
  )
)
