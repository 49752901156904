var DBEPView, DecisionAidsView, DisseminationController, DisseminationView, HEALTH_ADVISOR_WORKSPACE_ID, MdaTopicsWorkflowView, MobileView, ModuleController, PublicationPlatformView, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

DecisionAidsView = require('views/dissemination/decision_aids_view');

DBEPView = require('views/dissemination/dbep_view');

DisseminationView = require('views/dissemination/dissemination_view');

mediator = require('mediator');

MobileView = require('views/dissemination/mobile_view');

ModuleController = require('controllers/base/module_controller');

MdaTopicsWorkflowView = require('views/dissemination/mda_topics_workflow_view');

PublicationPlatformView = require('views/dissemination/publication_platform_view');

HEALTH_ADVISOR_WORKSPACE_ID = require('lib/mda_helper').HEALTH_ADVISOR_WORKSPACE_ID;

module.exports = DisseminationController = (function(_super) {
  __extends(DisseminationController, _super);

  function DisseminationController() {
    return DisseminationController.__super__.constructor.apply(this, arguments);
  }

  DisseminationController.prototype.defaultSubmodule = function() {
    if (mediator.services.switches.isOn('mdgTables')) {
      return 'dbep';
    } else if (mediator.activeWorkspace === HEALTH_ADVISOR_WORKSPACE_ID) {
      return 'mda-topics-workflow';
    } else {
      return 'dissemination';
    }
  };

  DisseminationController.prototype.viewsForSubmodules = {
    'dissemination': DisseminationView,
    'dbep': DBEPView,
    'decision-aids': DecisionAidsView,
    'mobile': MobileView,
    'publication-platform': PublicationPlatformView,
    'mda-topics-workflow': MdaTopicsWorkflowView
  };

  DisseminationController.prototype._viewParams = function(params) {
    var _ref, _ref1;
    return {
      model: mediator.project,
      service: mediator.services.publication,
      dbepPublicationService: mediator.services.dbepPublicationService,
      submoduleScreen: (_ref = params.submoduleScreen) != null ? _ref : null,
      itemId: (_ref1 = params.itemId) != null ? _ref1 : null
    };
  };

  return DisseminationController;

})(ModuleController);
