Assessment = require 'components/etd/assessment'
AssessmentWithPanelVoiceResults =
  require 'components/etd/panel_voice_etd/assessment_with_panel_voice_results'
ConclusionsWithPanelVoiceResults =
  require 'components/etd/panel_voice_etd/conclusions_with_panel_voice_results'
Conclusions = require 'components/etd/conclusions'
Recommendation = require 'components/etd/recommendation'
TypeOfRecommendationWithPanelVoiceResults =
  require 'components/etd/panel_voice_etd/type_of_recommendation_with_panel_voice_results'
Translation = require 'components/mixins/translation'

CustomRenderMixin = require 'components/mixins/custom_render_mixin'
{
  getRecommendationTypeSections
  getCurrentTORSectionId
  prepareSectionsForPrintout
} = require 'lib/etd_helper'
{ instanceOf, bool, string, func, oneOf } = PropTypes

WorkspaceTab = createReactClass
  displayName: 'WorkspaceTab'

  propTypes:
    activeJudgmentOptionBySection: instanceOf(Immutable.Map).isRequired
    activeResultsViewTypeBySection: instanceOf(Immutable.Map).isRequired
    additionalConsiderations: instanceOf(Immutable.Map)
    adolopmentData: instanceOf(Immutable.Map).isRequired,
    adolopments: instanceOf(Immutable.Map).isRequired,
    assessmentSections: instanceOf(Immutable.OrderedMap)
    attachments: instanceOf(Immutable.Map)
    collapsedAssessmentSections: instanceOf(Immutable.Map)
    comparison: string
    conclusionsSections: instanceOf(Immutable.OrderedMap)
    criterions: instanceOf(Immutable.Map)
    criticalOutcomes: instanceOf(Immutable.List)
    editingSectionId: string
    etdHighlights: instanceOf(Immutable.Map).isRequired
    etdId: string.isRequired
    etdSnapshots: instanceOf(Immutable.Map)
    etdViewSettings: instanceOf(Immutable.Map).isRequired
    getAdminVotingSummary: func.isRequired
    hideVotingResults: bool.isRequired
    intervention: string
    keepSojStatus: func
    memberNamesMap: instanceOf(Immutable.Map).isRequired
    outcomes: instanceOf(Immutable.List)
    overarchingQuestionData: instanceOf(Immutable.Map)
    qualityIndicators: instanceOf(Immutable.List)
    questionName: string.isRequired
    questionType: string
    renderMode: string
    researchEvidences: instanceOf(Immutable.Map)
    showRecommendationsPart: bool
    sojStatus: string.isRequired
    templateId: string.isRequired
    type: oneOf(['overarching', 'keyMessages', 'regular', 'qualityIndicators'])
    votingResults: instanceOf(Immutable.Map).isRequired

  mixins: [
    CustomRenderMixin
    Translation('es:recommendations.table')
  ]

  getDefaultProps: ->
    showRecommendationsPart: true
    overarchingQuestionData: Immutable.Map()
    qualityIndicators: Immutable.List()

  getAssessmentProps: (withVoting = false) ->

    {
      adolopmentData
      collapsedAssessmentSections
      etdId
      etdViewSettings
      hiddenSojSections
      questionType
      overarchingQuestionData
    } = @props

    props = if withVoting
      assessmentVotingResults = @props.votingResults.get 'assessment'
      votedEtd = @props.etdSnapshots.get "#{assessmentVotingResults.get('timestamp')}"
      votedEtdAssessmentData = votedEtd.getIn ['templateData', 'assessment', 'criteria']

      _.chain(@props)
        .omit([
          'etdSnapshots',
          'adolopmentData'
        ])
        .extend
          etdTab: 'workspace'
          getAdminVotingSummary: @props.getAdminVotingSummary
          votedEtdData: votedEtdAssessmentData
          votingResults: assessmentVotingResults
          overarching: not overarchingQuestionData.isEmpty()
        .value()
    else
      _.omit @props, ['votingResults', 'etdSnapshots']

    # clear sections if user want's to export without judgments
    criterions = prepareSectionsForPrintout props.criterions, 'assessment', @props.renderOptions
    _.extend props, {
      adolopments: @props.adolopments
      adolopmentData: adolopmentData.get('assessment', Immutable.Map())
      criterions
      additionalConsiderationsVisible: etdViewSettings.get('showAdditionalConsiderations', true)
    }

  getConclusionsProps: (withVoting = false) ->
    props = if withVoting
      requireProps = ['adolopments', 'renderMode', 'assessmentSections', 'conclusionsSections',
        'questionName', 'editingSectionId', 'memberNamesMap', 'etdSnapshots',
        'etdHighlights', 'activeJudgmentOptionBySection', 'activeResultsViewTypeBySection']
      _.chain(@props)
        .pick(requireProps)
        .extend
          votingResults: @props.votingResults.get('conclusion')
          etdTab: 'workspace'
          getAdminVotingSummary: @props.getAdminVotingSummary
        .value()
    else
      _.pick @props, ['adolopments', 'renderMode', 'conclusionsSections', 'assessmentSections']

    firstConclusionSection = props.conclusionsSections.keySeq().first()

    conclusionsSections = if firstConclusionSection in getRecommendationTypeSections()
      props.conclusionsSections.rest()
    else
      props.conclusionsSections
    # clear sections if user want's to export without judgments
    conclusionsSections = prepareSectionsForPrintout conclusionsSections,
      'conclusion', @props.renderOptions

    _.extend(props, {
      adolopments: @props.adolopments
      adolopmentData: @props.adolopmentData.get('conclusions', Immutable.Map())
      attachments: @props.attachments
      conclusionsSections,
      etdId: @props.etdId,
      etdViewSettings: @props.etdViewSettings,
    })

  getRecommendationProps: (withVoting = false) ->
    sectionId = getCurrentTORSectionId @props.conclusionsSections

    votingTimestamp = @props.votingResults.getIn ['recommendation', 'timestamp']
    props = if withVoting
      requireProps = [
        'activeJudgmentOptionBySection',
        'activeResultsViewTypeBySection',
        'editingSectionId',
        'etdId',
        'etdViewSettings'
        'memberNamesMap',
        'overarchingQuestionData'
        'questionName',
        'renderMode',
        'templateId'
      ]
      _.chain(@props)
        .pick(requireProps)
        .extend
          etdTab: 'workspace'
          getAdminVotingSummary: @props.getAdminVotingSummary
          highlightToPanelMembers: @props.etdHighlights.get sectionId
          votedEtdData: @props.etdSnapshots.getIn ["#{votingTimestamp}", 'templateData',
            'conclusions', 'sections', sectionId], Immutable.Map()
          votingResults: @props.votingResults.get 'recommendation'
        .value()
    else
      _.pick @props, [
          'adolopments',
          'renderMode'
        ]

    # clear sections if user want's to export without judgments
    section = prepareSectionsForPrintout @props.conclusionsSections.get(sectionId),
      'recommendation', @props.renderOptions

    _.extend(props, {
      adolopments: @props.adolopments
      adolopmentData: @props.adolopmentData.get('conclusions', Immutable.Map())
      section, sectionId, etdId: @props.etdId, etdViewSettings: @props.etdViewSettings })

  sectionWithResults: (section) ->
    { votingResults, renderOptions, hideVotingResults } = @props
    return false if hideVotingResults
    return false if renderOptions?[section].results.checked is false
    votingResults.get(section)

  getAssessmentComponent: ->
    assessmentWithResults = @sectionWithResults('assessment')
    assessmentProps = @getAssessmentProps assessmentWithResults
    if assessmentWithResults
      <AssessmentWithPanelVoiceResults {...assessmentProps} />
    else
      <Assessment {...assessmentProps} />

  getRecommendationComponent: ->
    recommendationWithResults = @sectionWithResults('recommendation')
    recommendationProps = @getRecommendationProps recommendationWithResults

    if recommendationWithResults
      <TypeOfRecommendationWithPanelVoiceResults {...recommendationProps} />
    else
      <Recommendation {...recommendationProps} />

  getConclusionsComponent: ->
    conclusionsWithResults = @sectionWithResults('conclusion')
    conclusionsProps = @getConclusionsProps conclusionsWithResults
    if conclusionsWithResults
      <ConclusionsWithPanelVoiceResults {...conclusionsProps} />
    else
      <Conclusions {...conclusionsProps} />

  render: ->
    { renderOptions, showRecommendationsPart } = @props

    if _.isEmpty renderOptions
      <div className='panel-voice-etd__workspace-tab'>
        {@getAssessmentComponent()}
        <br />
        {showRecommendationsPart and @getRecommendationComponent()}
        <br />
        {@getConclusionsComponent()}
      </div>
    else
      <div className='panel-voice-etd__workspace-tab'>
        {if renderOptions.assessment.checked
          @getAssessmentComponent()
        }
        <br />
        {if showRecommendationsPart
          if renderOptions.recommendation.checked
            @getRecommendationComponent()
        }

        {if renderOptions.conclusion.checked
          @getConclusionsComponent()
        }
      </div>

module.exports = WorkspaceTab
