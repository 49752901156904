STYLE =
  display: 'inline-block'
  boxSizing: 'border-box'
  padding: '5px 0'
  lineHeight: '14px'

EllipsizedText = createReactClass
  displayName: 'EllipsizedText'

  propTypes:
    text: PropTypes.string.isRequired
    height: PropTypes.number.isRequired

  getInitialState: ->
    ellipsizedText: @props.text

  getStyleSpec: ->
    isEllipsized = @props.text isnt @state.ellipsizedText
    styleObject = _.extend {}, STYLE, if isEllipsized then height: @props.height

    if _.isObject @props.style
      _.extend styleObject, @props.style
    styleObject

  _ellipsize: ->
    return unless @mounted

    if @container.scrollHeight > @props.height
      @setState
        ellipsizedText: @state.ellipsizedText.replace /(\s(\S)+\s*){2}$/, '...'

  ellipsize: ->
    if window.requestAnimationFrame
      requestAnimationFrame @_ellipsize
    else
      @_ellipsize()

  onResize: ->
    @setState ellipsizedText: @props.text

  componentDidMount: ->
    @ellipsize()
    @mounted = true
    window.addEventListener 'resize', @onResize

  componentDidUpdate: (prevProps, prevState) ->
    if @props.text isnt prevProps.text
      return @setState ellipsizedText: @props.text, @ellipsize
    @ellipsize()

  componentWillUnmount: ->
    @mounted = false
    window.removeEventListener 'resize', @onResize

  render: ->
    <div ref={(el) => @container = el} style={@getStyleSpec()} onClick={@props.onClick}>
      {@state.ellipsizedText}
    </div>

module.exports = EllipsizedText
