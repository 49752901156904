module.exports = {
  $exports: {
    $ref: 'service'
  },
  service: {
    create: {
      module: 'lib/project_migrations/project_migration_service',
      args: [
        [
          {
            module: 'lib/project_migrations/team_members_as_separate_collection'
          }, {
            module: 'lib/project_migrations/team_members_separate_names'
          }, {
            module: 'lib/project_migrations/diagnostic_pooled_values_as_decimals'
          }, {
            module: 'lib/project_migrations/comparator_test_as_parameter'
          }, {
            module: 'lib/project_migrations/other_quality_modifiers_none_to_no'
          }, {
            module: 'lib/project_migrations/sensitivity_specificity_zero_to_null'
          }, {
            module: 'lib/project_migrations/diagnostic_question_to_pooled_source_of_data'
          }, {
            module: 'lib/project_migrations/sof_title_to_table_title'
          }, {
            module: 'lib/project_migrations/prognostic_module'
          }, {
            module: 'lib/project_migrations/administration_module'
          }, {
            module: 'lib/project_migrations/etd_renderer_data_model'
          }, {
            module: 'lib/project_migrations/presentation_submodule'
          }, {
            module: 'lib/project_migrations/prognostic_outcomes_quality_base_score'
          }, {
            module: 'lib/project_migrations/outcomes_submodule'
          }, {
            module: 'lib/project_migrations/voting_submodule'
          }, {
            module: 'lib/project_migrations/clone_shared_etd_docs'
          }, {
            module: 'lib/project_migrations/etd_header_role_and_subgroups_fields'
          }, {
            module: 'lib/project_migrations/questions_generation_migration'
          }, {
            module: 'lib/project_migrations/delete_option_from_values_section'
          }, {
            module: 'lib/project_migrations/etd_header_update_labels'
          }, {
            create: 'lib/project_migrations/typo_in_certaintyOfEvidenceOfManagementEffects',
            properties: {
              storePersistenceAdapter: {
                $ref: 'storePersistenceAdapter'
              },
              etdTemplateHelper: {
                module: 'stores/utils/etd_template_helper'
              },
              etdTemplatesStore: {
                module: 'stores/etd_templates_store'
              },
              etdTemplatesActions: {
                module: 'actions/etd_templates_actions'
              }
            }
          }, {
            module: 'lib/project_migrations/extract_voting_as_separate_module'
          }, {
            module: 'lib/project_migrations/remove_outcome_generation_fs'
          }, {
            module: 'lib/project_migrations/references_module'
          }, {
            module: 'lib/project_migrations/questions_generation_extended_approval_finished_tabs'
          }, {
            module: 'lib/project_migrations/etd_fields_content_to_draft_js_content'
          }, {
            module: 'lib/project_migrations/content_editable_to_textarea'
          }, {
            module: 'lib/project_migrations/team_members_to_project_members'
          }, {
            module: 'lib/project_migrations/references_ids_change'
          }, {
            module: 'lib/project_migrations/etd_fix_additional_questions_in_translations'
          }, {
            module: 'lib/project_migrations/references_ids_change'
          }, {
            module: 'lib/project_migrations/etd_template_definitions_change'
          }, {
            module: 'lib/project_migrations/etd_template_definitions_add_isof'
          }, {
            module: 'lib/project_migrations/recommendation_mode_saving_change'
          }, {
            module: 'lib/project_migrations/multi_comparisons_module'
          }, {
            module: 'lib/project_migrations/patients_type_presentation_change'
          }, {
            module: 'lib/project_migrations/duplicated_questions_etd_data_fix'
          }, {
            module: 'lib/project_migrations/etd_header_add_coi_field'
          }, {
            module: 'lib/project_migrations/etd_template_definitions_add_related_recommendations'
          }, {
            module: 'lib/project_migrations/comparison_questions_order_number_migration_to_groups_doc'
          }, {
            module: 'lib/project_migrations/ensure_outcomes_have_set_id_field'
          }, {
            module: 'lib/project_migrations/checklist_module'
          }, {
            module: 'lib/project_migrations/organization_projects_organization_id'
          }, {
            module: 'lib/project_migrations/decision_aid_submodule'
          }, {
            module: 'lib/project_migrations/dissemination_module_changes'
          }, {
            module: 'lib/project_migrations/all_modules'
          }, {
            module: 'lib/project_migrations/diagnostic_questions_screening_switcher'
          }, {
            module: 'lib/project_migrations/rename_category_to_progress_general_information'
          }, {
            module: 'lib/project_migrations/etd_separate_desirable_effect'
          }, {
            module: 'lib/project_migrations/covid_progress_plus'
          }, {
            module: 'lib/project_migrations/multi_comparisons_diagnostic_question'
          }, {
            module: 'lib/project_migrations/multi_comparisons_diagnostic_question_included_interventions'
          }, {
            module: 'lib/project_migrations/add_implementation_considerations_or_remarks_to_gps_templates_def'
          }, {
            module: 'lib/project_migrations/add_options_for_gps_templates'
          }, {
            module: 'lib/project_migrations/covid_remove_causation'
          }, {
            module: 'lib/project_migrations/add_description_to_multirecommendation_field'
          }, {
            module: 'lib/project_migrations/add_additional_section_to_etd_template'
          }, {
            module: 'lib/project_migrations/fix_equity_in_etds'
          }, {
            module: 'lib/project_migrations/premium_presentations_update_texts'
          }, {
            module: 'lib/project_migrations/update_gps_template_texts'
          }, {
            module: 'lib/project_migrations/add_implementation_considerations_to_clinicians_presentation'
          }, {
            module: 'lib/project_migrations/etd_separate_desirable_effect'
          }, {
            module: 'lib/project_migrations/change_recommendation_options_for_mdg_template'
          }, {
            module: 'lib/project_migrations/change_recommendation_options_for_mdg_template_2'
          }, {
            module: 'lib/project_migrations/change_quality_indicators_template'
          }, {
            module: 'lib/project_migrations/mda_topic_convert_initial_value_for_codes_from_list_to_map'
          }, {
            module: 'lib/project_migrations/mda_add_slug_to_topics'
          }, {
            module: 'lib/project_migrations/mdg_remove_lack_of_bias_from_study'
          }, {
            module: 'lib/project_migrations/wrap_table_rows_with_table_body_in_epiditor_content'
          }
        ]
      ]
    }
  }
};
