var DbDoc, acceptChange, createEditorView, processExtensions, rejectChange, sectionDocShape, sectionsView, serializeEditorState,
  __slice = [].slice;

DbDoc = require('lib/db_docs/db_doc');

sectionDocShape = require('lib/db_docs/doc_shapes/document_sections_v2_section_doc_shape');

createEditorView = Epiditor.createEditorView, serializeEditorState = Epiditor.serializeState, acceptChange = Epiditor.acceptChange, rejectChange = Epiditor.rejectChange, processExtensions = Epiditor.processExtensions;

sectionsView = {
  map: function(doc) {
    if (doc.type === 'document_sections_section') {
      return emit(doc.mdaTopicId, doc);
    }
  }
};

module.exports = DbDoc(sectionDocShape, {
  dbSetup: {
    dbView: {
      name: 'sectionsV2',
      view: sectionsView
    }
  },
  methods: {
    setProperty: function(propKey, propValue) {
      return this.setAt(propKey)(propValue);
    },
    addAnnotationReply: function(annotationId, data) {
      var parentAnnotationId;
      parentAnnotationId = data.parentId;
      return this.updateAt('content', 'annotations')(function(annotations) {
        var newParent, parentAnnotation;
        parentAnnotation = annotations[data.parentId];
        newParent = R.assoc('replies', (parentAnnotation.replies || []).concat(annotationId), parentAnnotation);
        return R.compose(R.assoc(annotationId, data), R.assoc(parentAnnotationId, newParent))(annotations);
      });
    },
    updateAnnotation: function(annotationId, data) {
      return this.updateAt('content', 'annotations', annotationId)(R.merge(R.__, data));
    },
    updateChange: function(changeId, data) {
      return this.updateAt('content', 'changeTracker', 'changes', changeId)(R.merge(R.__, data));
    },
    removeAnnotation: function(annotation) {
      return this.updateAt('content', 'annotations')(function(annotations) {
        var newReplies, repliesLens;
        repliesLens = R.lensPath([annotation.parentId, 'replies']);
        newReplies = R.without(annotation.id, R.view(repliesLens, annotations) || []);
        return R.compose(R.omit([annotation.id].concat(__slice.call(annotation.replies))), annotation.parentId ? R.set(repliesLens, newReplies) : R.identity)(annotations);
      });
    },
    applyChangeDecision: function(changeId, accepted) {
      var referencesInsertExtension, sectionLinksExtension;
      referencesInsertExtension = require('components/document_sections/v2/editor_extensions/references_insert/references_insert');
      sectionLinksExtension = require('components/document_sections/v2/editor_extensions/section_links/section_links');
      return this.updateAt('content')(function(editorContent) {
        var changeDecisionHandler, dispatchHandler, dummyDiv, editorViewRef, updatedContent;
        changeDecisionHandler = accepted ? acceptChange : rejectChange;
        dummyDiv = document.createElement('div');
        editorViewRef = null;
        dispatchHandler = function(tr) {
          if (editorViewRef) {
            return editorViewRef.updateState(editorViewRef.state.apply(tr));
          }
        };
        editorViewRef = createEditorView({
          state: editorContent,
          onDispatch: dispatchHandler,
          mode: 'readonly',
          extensions: processExtensions([sectionLinksExtension(), referencesInsertExtension()]),
          portalsAPI: {
            remove: function() {},
            show: function() {}
          }
        }, dummyDiv);
        changeDecisionHandler(changeId)(editorViewRef.state, editorViewRef.dispatch);
        updatedContent = serializeEditorState(editorViewRef.state);
        editorViewRef.destroy();
        dummyDiv = null;
        return updatedContent;
      });
    }
  }
});
