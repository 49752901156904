ChapterManagementMixin = require 'components/mixins/chapter_management_mixin'
ChapterSectionContent = require 'components/document_sections/v2/chapter_section_content'
ChapterSectionStatus = require 'components/document_sections/v2/chapter_section_status'
DiscardChangesActions = require 'actions/discard_changes_actions'
DocumentSectionsTitle = require 'components/document_sections/v2/document_sections_title'
DocumentSectionsV2Actions = require 'actions/document_sections_v2_actions'
DropdownMenu = require 'components/common/dropdown_menu'
EditingByInfo = require 'components/document_sections/v2/editing_by_info'
IconButton = require 'components/common/icon_button'
mediator = require 'mediator'
{ generateCommentId, getSettingsOptions } = require 'lib/document_sections_v2_helper'
{ getSettingsOrder } = require 'lib/mda_helper'

TextSection = createReactClass
  displayName: 'TextSection'

  propTypes:
    section: PropTypes.instanceOf(Immutable.Map).isRequired
    chapterId: PropTypes.string.isRequired
    onDelete: PropTypes.func.isRequired
    onInsertMdaTable: PropTypes.func.isRequired
    onInsertRecommendation: PropTypes.func.isRequired
    suggesting: PropTypes.bool.isRequired
    draftDiscussions: PropTypes.instanceOf(Immutable.Map)
    updatedDraftDiscussion: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
      discussionType: PropTypes.string.isRequired
      data: PropTypes.object
    })
    acceptedOrRejectedChange: PropTypes.shape({
      sectionId: PropTypes.string.isRequired,
      changeId: PropTypes.string.isRequired
      accepted: PropTypes.bool.isRequired
    })
    scrollToSectionContent: PropTypes.shape({
      sectionId: PropTypes.string.isRequired,
      contentPos: PropTypes.number
    })
    hasChanges: PropTypes.bool.isRequired
    readOnly: PropTypes.bool.isRequired
    mdaTopicId: PropTypes.string

  mixins: [ChapterManagementMixin]

  componentDidUpdate: (prevProps) ->
    if prevProps.section.get('title') isnt @props.section.get('title') # title has been saved
      DiscardChangesActions.setHasChanges(false)

  updateField: (key, value) ->
    projectId = mediator.project.id
    chapterId = @props.section.get('parent')
    sectionId = @props.section.get('_id')
    DocumentSectionsV2Actions.setSectionProperty(
      projectId, chapterId, sectionId, key, value
    )

  handleTitleChange: _.debounce((changedTitle) -> # in case if someone writes very fast
    hasChanges = changedTitle isnt @props.section.get('title')
    DiscardChangesActions.setHasChanges(hasChanges)
  , 500)

  handleTitleSave: (changedTitle) ->
    @updateField('title', changedTitle)

  handleContentSave: (changedContent) ->
    { section, chapterId } = @props
    sectionId = section.get('_id')
    projectId = mediator.project.id
    DiscardChangesActions.setHasChanges(false)
    DocumentSectionsV2Actions.saveChapterContent {
      projectId,
      chapterId,
      sectionId,
      content: changedContent
      textSection: true
    }

  handleStatusSave: (changedStatus) ->
    @updateField('status', changedStatus)

  handleSettingSelect: (selectedSetting) ->
    { section } = @props
    sectionId = section.get('_id')

    switch selectedSetting
      when 'add_new_text_field'
        DocumentSectionsV2Actions.addSection(mediator.project.id, @props.chapterId, sectionId)
      when 'insert_mda_table'
        @props.onInsertMdaTable({ sectionId })
      when 'insert_recommendation'
        @props.onInsertRecommendation({ sectionId })
      when 'duplicate'
        @duplicateSection()
      when 'delete'
        sectionId = @props.section.get('_id')
        @props.onDelete(sectionId)

  duplicateSection: () ->
    projectId = mediator.project.id
    chapterId = @props.section.get('parent')
    sectionId = @props.section.get('_id')
    DocumentSectionsV2Actions.duplicateSection(projectId, chapterId, sectionId)

  updateDraftDiscussions: (type, data) ->
    { chapterId, section } = @props
    sectionId = section.get('_id')
    DocumentSectionsV2Actions.updateDraftDiscussions { chapterId, sectionId, type, data }

  _updateSectionEditingInfo: (editing) ->
    { chapterId, section } = @props
    sectionId = section.get('_id')
    projectId = mediator.project.id
    user = {
      id: mediator.user.id
      name: mediator.user.getFullName()
    }

    DocumentSectionsV2Actions.updateSectionEditingInfo {
      projectId,
      chapterId,
      sectionId,
      user,
      editing,
    }

  startedEditing: ->
    sectionId = @props.section.get('_id')
    DocumentSectionsV2Actions.sectionEditStarted sectionId
    @_updateSectionEditingInfo(true)

  handleEditCancel: ->
    { section } = @props
    sectionId = section.get('_id')
    DocumentSectionsV2Actions.flushDraftDiscussions { sectionId }
    DiscardChangesActions.setHasChanges false
    @_updateSectionEditingInfo(false)

  render: ->
    {
      section,
      connectDragSource,
      suggesting,
      draftDiscussions,
      updatedDraftDiscussion,
      acceptedOrRejectedChange,
      scrollToSectionContent,
      hasChanges
      mdaTopicId
      readOnly
    } = @props

    editingBy = section.get 'editingBy'
    sectionId = section.get('_id')

    <React.Fragment>
      {if editingBy
        <EditingByInfo data={editingBy} />
      }
      <div className="chapter-section-header">
        {connectDragSource(
          <div className="drag-marker" />
        )}
        <DocumentSectionsTitle
          title={section.get('title')}
          onChange={@handleTitleChange}
          onSave={@handleTitleSave}
        />
        {@showChapterManagement() and <div className="chapter-section-management">
          <ChapterSectionStatus
            status={section.get('status')}
            onChange={@handleStatusSave}
          />
          <DropdownMenu
            opener={(
              <IconButton iconName="more" className="settings-toggle" />
            )}
            options={getSettingsOptions getSettingsOrder('text-section')}
            onSelect={@handleSettingSelect}
          />
        </div>}
      </div>
      <div className="chapter-section-content">
        <ChapterSectionContent
          content={section.get('content')}
          sectionId={sectionId}
          updateDraftDiscussions={@updateDraftDiscussions}
          onSave={@handleContentSave}
          onCancel={@handleEditCancel}
          onEditingStarted={@startedEditing}
          suggesting={suggesting}
          draftDiscussions={draftDiscussions}
          updatedDraftDiscussion={updatedDraftDiscussion}
          acceptedOrRejectedChange={acceptedOrRejectedChange}
          scrollToSectionContent={scrollToSectionContent}
          hasChanges={hasChanges}
          mdaTopicId={mdaTopicId}
          readOnly={readOnly}
        />
      </div>
    </React.Fragment>

module.exports = TextSection
