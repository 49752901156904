Input = require 'components/common/input'
IconButton = require 'components/common/icon_button'
SidebarChapterForm = require 'components/document_sections/v2/sidebar_chapter_form'

SidebarChapterContent = createReactClass
  displayName: 'SidebarChapterContent'

  propTypes:
    chapter: PropTypes.instanceOf(Immutable.Map).isRequired
    mdaTopicId: PropTypes.string
    onSave: PropTypes.func.isRequired
    onDelete: PropTypes.func.isRequired
    withEditButtons: PropTypes.bool

  getDefaultProps: ->
    withEditButtons: true

  getInitialState: ->
    editing: false

  toggleEdit: (evt) ->
    evt.stopPropagation()
    @setState ({ editing }) ->
      editing: !editing

  handleDelete: (evt) ->
    evt.stopPropagation()
    @props.onDelete()

  handleFormClick: (evt) ->
    evt.stopPropagation() # parent's click causes opening and scrolling but we don't want it here

  handleCancel: ->
    @setState
      editing: false

  handleSave: (data) ->
    @props.onSave(data)
    @setState
      editing: false

  render: ->
    { chapter, mdaTopicId, withEditButtons } = @props
    { editing } = @state

    <div className="sidebar-chapter-content">
      {
        if editing
          <div className="sidebar-chapter-content-form" onClick={@handleFormClick}>
            <SidebarChapterForm
              data={
                isFromAcoem: chapter.get 'isFromAcoem'
                slug: chapter.get 'slug'
                title: chapter.get 'title'
              }
              mdaTopicId={mdaTopicId}
              onCancel={@handleCancel}
              onSubmit={@handleSave}
            />
          </div>
        else
          <div className="sidebar-chapter-content-title flex items-center">
            <span className="title-text flex-grow">{chapter.get('title')}</span>
            {withEditButtons && <React.Fragment>
                <IconButton iconName="delete" className="ml-5" onClick={@handleDelete} />
                <IconButton iconName="edit" className="ml-5" onClick={@toggleEdit} />
              </React.Fragment>
            }
          </div>

      }
    </div>

module.exports = SidebarChapterContent
