var ChapterManagementMixin, mediator;

mediator = require('mediator');

ChapterManagementMixin = {
  showChapterManagement: function() {
    return !_.contains(mediator.user.getAccessRights(), 'mdg_panel_reviewer');
  }
};

module.exports = ChapterManagementMixin;
