var DOCUMENT_SECTIONS_V2_ROOT_ID, DOCUMENT_SECTIONS_V2_SECTION_TYPE, DocumentSectionsCommonActions, W, alreadyUsedRecsDbView, alt, mediator;

alt = require('alt');

mediator = require('mediator');

DOCUMENT_SECTIONS_V2_ROOT_ID = require('lib/doc_ids').DOCUMENT_SECTIONS_V2_ROOT_ID;

W = require('when/when');

DOCUMENT_SECTIONS_V2_SECTION_TYPE = require('lib/db_docs/doc_types').DOCUMENT_SECTIONS_V2_SECTION_TYPE;

alreadyUsedRecsDbView = {
  map: function(doc) {
    if (doc.type !== 'document_sections_section') {
      return;
    }
    if (!(doc.questionId && doc.recommendationId)) {
      return;
    }
    return emit(doc._id, {
      questionId: doc.questionId,
      recommendationId: doc.recommendationId,
      sectionId: doc._id
    });
  }
};

DocumentSectionsCommonActions = (function() {
  function DocumentSectionsCommonActions() {
    this.generateActions('fetchFailure', 'fetchSuccess', 'removeAlreadyUsedRecommendations', 'addAlreadyUsedRecommendation');
  }

  DocumentSectionsCommonActions.prototype.dbChange = function(change) {
    var alreadyUsedRecommendations, deleted, doc, id, questionId, recommendationId;
    doc = change.doc, deleted = change.deleted, id = change.id;
    questionId = doc.questionId, recommendationId = doc.recommendationId;
    alreadyUsedRecommendations = alt.stores.DocumentSectionsCommonStore.getAlreadyUsedRecommendations();
    if (deleted && alreadyUsedRecommendations.has(id)) {
      return this.actions.removeAlreadyUsedRecommendations([id]);
    } else if (doc.type === DOCUMENT_SECTIONS_V2_SECTION_TYPE && questionId && recommendationId) {
      return this.actions.addAlreadyUsedRecommendation({
        sectionId: id,
        data: Immutable.fromJS({
          questionId: questionId,
          recommendationId: recommendationId,
          sectionId: id
        })
      });
    }
  };

  DocumentSectionsCommonActions.prototype.fetch = function(_arg) {
    var adapter, projectId;
    projectId = _arg.dbId;
    adapter = mediator.services.storePersistenceAdapter;
    return adapter.exists(projectId, DOCUMENT_SECTIONS_V2_ROOT_ID).then(function(docSecRootExists) {
      if (docSecRootExists) {
        return adapter.fetchWithView(projectId, 'doc_sec_already_used_recommendations', alreadyUsedRecsDbView);
      } else {
        return W.resolve([]);
      }
    }).then((function(_this) {
      return function(alreadyUsedRecommendations) {
        return _this.actions.fetchSuccess({
          alreadyUsedRecommendations: alreadyUsedRecommendations
        });
      };
    })(this))["catch"](this.actions.fetchFailure);
  };

  return DocumentSectionsCommonActions;

})();

module.exports = alt.createActions(DocumentSectionsCommonActions);
