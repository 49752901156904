{ useState } = React
{ useI18n } = require 'lib/react_utils'
Button = require 'components/common/button'
ConfirmationModal = require 'components/common/confirmation_modal'
ConnectStore = require 'components/enhancers/connect_store'
DashboardActions = require 'actions/dashboard_actions'
DashboardHistoryModal = require 'components/dashboard/dashboard_history_modal'
DashboardStore = require 'stores/dashboard_store'
DatePicker = require 'components/common/date_picker'
ProjectActions = require 'actions/project_actions'
ProjectStore = require 'stores/project_store'
QuestionsStatusesActions = require 'actions/questions_statuses_actions'

DashboardToolbar = ({ project, confirmationDialogType }) ->
  i18n = useI18n('projects:dashboard')

  [newStartDate, setNewStartDate] = useState(null)

  onDateSave = (_e, newDate) ->
    setNewStartDate newDate
    DashboardActions.openConfirmationDialog 'restart_project'

  lockButtonClassNames = classNames 'mr-20 icon-right',
    if project.get('locked') then 'unlocked' else 'locked'

  startDate = project.get 'startDate'
  projectStarted = Boolean startDate
  startOrRestartClassName = classNames 'button icon-right mr-20',
    if projectStarted then 'refresh' else 'start'
  startOrRestartKey = if projectStarted then 'restart_project' else 'start_project'
  startDateFormatted = if startDate then moment(startDate).format('DD-MM-YYYY') else undefined
  selectedDates = if startDate then [new Date(startDate)] else []
  trigger = <span className={startOrRestartClassName}>
    {i18n startOrRestartKey}
  </span>

  onConfirm = ->
    promise = switch confirmationDialogType
      when 'restart_project'
        ProjectActions.startProject newStartDate.getTime()
      when 'reset_all_statuses'
        QuestionsStatusesActions.resetAllStatuses()

    promise.finally DashboardActions.closeConfirmationDialog

  <div className="dashboard-toolbar">
    <h2>{i18n 'dashboard'}</h2>
    <div className="flex flex-row items-center">
      <DatePicker
        inputHidden
        value={startDateFormatted}
        onSave={onDateSave}
        selectedDays={selectedDates}
        trigger={trigger}
      />
      <Button
        className="mr-20 refresh icon-right"
        label={i18n "reset_all_statuses"}
        onClick={-> DashboardActions.openConfirmationDialog 'reset_all_statuses'}
      />
      <Button
        className={lockButtonClassNames}
        label={i18n if project.get('locked') then 'unlock_project' else 'lock_project'}
        onClick={ProjectActions.lockOrUnlockProject}
      />
      <Button
        className="mr-20 clock icon-right"
        label={i18n 'project_history'}
        onClick={DashboardActions.openHistoryDialogAndFetchHistory}
      />
    </div>
    {not _.isEmpty(confirmationDialogType) and <ConfirmationModal
      isOpen
      question={i18n "confirmation_dialog_question_#{confirmationDialogType}"}
      confirmLabel={i18n "confirmation_dialog_confirm_label_#{confirmationDialogType}"}
      onConfirm={onConfirm}
      onCancel={DashboardActions.closeConfirmationDialog}
    />}
    <DashboardHistoryModal />
  </div>

DashboardToolbar.propTypes =
  project: PropTypes.instanceOf(Immutable.Map).isRequired
  confirmationDialogType: PropTypes.string

DashboardToolbar.defaultProps =
  confirmationDialogType: null

storeConnectors =
  DashboardStore: (Store) ->
    confirmationDialogType: Store.getConfirmationDialogType()
  ProjectStore: (Store) ->
    project: Store.getProject()

module.exports = ConnectStore DashboardToolbar, [ProjectStore, DashboardStore], storeConnectors
