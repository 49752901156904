var DocumentSectionsFromTemplateActions, QuestionsActions, ReferencesActions, alt, docSectionsFromTemplateId, mediator;

alt = require('alt');

docSectionsFromTemplateId = require('lib/doc_ids').DOCUMENT_SECTIONS_FROM_TEMPLATE;

mediator = require('mediator');

QuestionsActions = require('actions/questions_actions');

ReferencesActions = require('actions/references_actions');

DocumentSectionsFromTemplateActions = (function() {
  function DocumentSectionsFromTemplateActions() {
    this.generateActions('fetchSuccess', 'fetchFailure', 'saveSuccess', 'saveFailure', 'setAttachment', 'toggleSection', 'cancelEditChapterSubsection', 'hideAddChapterSubsection', 'cancelChapterSubsectionDeletion', 'expandAllChapters', 'collapseAllChapters', 'setProjectData');
  }

  DocumentSectionsFromTemplateActions.prototype.fetch = function() {
    var projectId;
    projectId = mediator.project.id;
    QuestionsActions.fetch();
    ReferencesActions.fetch({
      dbId: projectId,
      fetchUsedReferences: false
    });
    mediator.project.createDocSectionsTemplateIfNotExist().then((function(_this) {
      return function() {
        return mediator.services.storePersistenceAdapter.fetch(projectId, docSectionsFromTemplateId, {
          attachments: true
        }).then(_this.actions.fetchSuccess)["catch"](_this.actions.fetchFailure);
      };
    })(this));
    return this.dispatch();
  };

  DocumentSectionsFromTemplateActions.prototype.dbChange = function(_arg) {
    var doc;
    doc = _arg.doc;
    if (doc._id === docSectionsFromTemplateId) {
      return this.dispatch(doc);
    }
  };

  DocumentSectionsFromTemplateActions.prototype.saveAttachment = function(data) {
    this.actions.setAttachment(data);
    return this.actions.save();
  };

  DocumentSectionsFromTemplateActions.prototype.updateChapterSubsection = function(chapterId, subsectionIdx, content) {
    this.dispatch({
      chapterId: chapterId,
      subsectionIdx: subsectionIdx,
      content: content
    });
    return this.actions.save();
  };

  DocumentSectionsFromTemplateActions.prototype.save = function() {
    var doc, projectId;
    projectId = mediator.project.id;
    doc = alt.stores.DocSectionsFromTemplateStore.getDocument().toJS();
    return mediator.services.storePersistenceAdapter.save(projectId, doc);
  };

  DocumentSectionsFromTemplateActions.prototype.editChapterSubsection = function(chapterId, subsectionIdx) {
    return this.dispatch({
      chapterId: chapterId,
      subsectionIdx: subsectionIdx
    });
  };

  DocumentSectionsFromTemplateActions.prototype.saveEditingChapterSubsectionTitle = function(title, titleHidden) {
    this.dispatch({
      title: title,
      titleHidden: titleHidden
    });
    return this.actions.save();
  };

  DocumentSectionsFromTemplateActions.prototype.showAddChapterSubsection = function(chapterId) {
    return this.dispatch({
      chapterId: chapterId
    });
  };

  DocumentSectionsFromTemplateActions.prototype.saveAddingChapterSubsection = function(title, titleHidden) {
    this.dispatch({
      title: title,
      titleHidden: titleHidden
    });
    return this.actions.save();
  };

  DocumentSectionsFromTemplateActions.prototype.markChapterSubsectionToDelete = function(chapterId, subsectionIdx) {
    return this.dispatch({
      chapterId: chapterId,
      subsectionIdx: subsectionIdx
    });
  };

  DocumentSectionsFromTemplateActions.prototype.confirmChapterSubsectionDeletion = function() {
    this.dispatch();
    return this.actions.save();
  };

  DocumentSectionsFromTemplateActions.prototype.duplicateSubchapter = function(chapterId, subsectionIdx) {
    this.dispatch({
      chapterId: chapterId,
      subsectionIdx: subsectionIdx
    });
    return this.actions.save();
  };

  DocumentSectionsFromTemplateActions.prototype.toggleChapterExpand = function(chapterId) {
    return this.dispatch({
      chapterId: chapterId
    });
  };

  DocumentSectionsFromTemplateActions.prototype.saveProjectData = function(projectData) {
    this.actions.setProjectData(projectData);
    return this.actions.save();
  };

  return DocumentSectionsFromTemplateActions;

})();

module.exports = alt.createActions(DocumentSectionsFromTemplateActions);
