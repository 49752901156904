var appUtils, etdHighlightsPrefix, fetchEtdDoc, fetchEtdSnapshots, mediator, toggleEtdSectionHighlight;

mediator = require('mediator');

appUtils = require('lib/app_utils');

etdHighlightsPrefix = require('lib/doc_prefixes').etdHighlights;

fetchEtdDoc = function(projectId, docId) {
  return mediator.services.storePersistenceAdapter.fetch(projectId, docId, {
    attachments: true
  });
};

fetchEtdSnapshots = function(projectId, etdId) {
  var docId;
  docId = "" + projectId + ":etd-snapshots:" + etdId;
  return fetchEtdDoc(projectId, docId);
};

toggleEtdSectionHighlight = function(projectId, etdId, sectionId) {
  var docId;
  docId = "" + (etdHighlightsPrefix(projectId)) + etdId;
  return mediator.services.storePersistenceAdapter.updateOrCreate(projectId, docId, function(doc) {
    var currentVal;
    if (!('highlightedSections' in doc)) {
      doc = {
        etdId: etdId,
        highlightedSections: {}
      };
    }
    currentVal = doc.highlightedSections[sectionId];
    doc.highlightedSections[sectionId] = !currentVal;
    return doc;
  })["catch"](appUtils.errorHandler);
};

module.exports = {
  fetchEtdDoc: fetchEtdDoc,
  fetchEtdSnapshots: fetchEtdSnapshots,
  toggleEtdSectionHighlight: toggleEtdSectionHighlight
};
