'use strict';
var parent = require('../../actual/reflect');
require('../../modules/esnext.reflect.define-metadata');
require('../../modules/esnext.reflect.delete-metadata');
require('../../modules/esnext.reflect.get-metadata');
require('../../modules/esnext.reflect.get-metadata-keys');
require('../../modules/esnext.reflect.get-own-metadata');
require('../../modules/esnext.reflect.get-own-metadata-keys');
require('../../modules/esnext.reflect.has-metadata');
require('../../modules/esnext.reflect.has-own-metadata');
require('../../modules/esnext.reflect.metadata');

module.exports = parent;
