var PanelVoiceEtdActions, getPanelVoiceAdminSummaryUpdater, getSectionEditToggleHandler;

PanelVoiceEtdActions = require('actions/panel_voice_etd_actions');

getPanelVoiceAdminSummaryUpdater = function(sectionId, roundTimestamp, etdId) {
  return function(newContent) {
    return PanelVoiceEtdActions.updateAdminVotingSummary({
      etdId: etdId,
      sectionId: sectionId,
      roundTimestamp: roundTimestamp,
      content: newContent
    });
  };
};

getSectionEditToggleHandler = function(sectionId) {
  return function() {
    return PanelVoiceEtdActions.toggleEtdSectionEdit(sectionId);
  };
};

module.exports = {
  getPanelVoiceAdminSummaryUpdater: getPanelVoiceAdminSummaryUpdater,
  getSectionEditToggleHandler: getSectionEditToggleHandler
};
