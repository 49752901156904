EtdActions = require 'actions/etd_actions'
OptionsList = require 'components/common/options_list'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
DialogBoxMixin = require 'components/mixins/dialog_box_mixin'
{ bool, instanceOf, func, oneOfType, string } = PropTypes

{ Card, CardContent } = ReactComponents

OptionsTypeSection = createReactClass
  displayName: 'OptionsTypeSection'

  propTypes:
    noAutoSave: bool
    options: instanceOf(Immutable.List)
    renderMode: string.isRequired
    selectedOption: string.isRequired
    sectionId: string.isRequired
    cellId: string.isRequired
    isConsensus: bool
    editable: bool
    content: oneOfType [instanceOf(Immutable.Map), string]
    onChange: func

  mixins: [
    Translation('es:recommendations.table')
    CustomRenderMixin
    DialogBoxMixin
  ]

  getDefaultProps: ->
    noAutoSave: false
    isConsensus: false
    editable: true

  onChange: (e) ->
    return unless @props.editable
    value = e.currentTarget.getAttribute 'value'
    sectionId = @props.sectionId
    isConsensus = @props.isConsensus
    actionName = if @props.noAutoSave
      'updateConclusionsOption'
    else
      'updateAndSaveConclusionsOption'

    changePayload = { sectionId, value, isConsensus }
    EtdActions[actionName] changePayload
    @props.onChange?(changePayload)

  clearOptions: (e) ->
    return unless @props.editable
    props =
      options: [
        text: @i18n 'judgement_clear_factor'
        actionCbk: =>
          @closeDialogBox()
          EtdActions.clearConclusionsOption @props.sectionId, @props.isConsensus
      ]
    @showInDialogBox e, OptionsList, props, 'soj-options-list-dialog'
    e.preventDefault()

  _prepareTypeOptionsForPrintout: ->
    labels = []
    radios = []

    @props.options.forEach (option, idx) =>
      value = option.get('value')
      checked = value is @props.selectedOption
      radioElement =
        <td key={idx} className={'selected' if checked}>
          {if checked
            <p className="marker checked">&#x25cf; </p>
          else
            <p className="marker unchecked">&#x25cb; </p>
          }
        </td>

      radios.push radioElement
      labels.push(
        <td key={idx} className={'selected' if checked}>
          <label value={value}>
            <p>{option.get('text')}</p>
          </label>
        </td>
      )
    [labels, radios]

  _prepareTypeOptionsForApp: ->
    { editable, highlightAsDiff, options, selectedOption, readOnly } = @props

    <div className="conclusions-options">
      {options.map (option, idx) =>
        value = option.get('value')
        checked = value is selectedOption
        cardClasses = classNames 'checked': checked,
          'changed': checked and highlightAsDiff
          'readOnly': not editable or readOnly
        <Card key={idx} className={cardClasses}>
          <CardContent>
            <label onClick={@onChange} value={option.get('value')}>
              <span>{option.get('text')}</span>
            </label>
            <input
              type="radio"
              value={value}
              checked={checked}
              onChange={@onChange}
            />
          </CardContent>
        </Card>
      }
    </div>

  renderForPrintout: ->
    [labels, radios] = @_prepareTypeOptionsForPrintout()
    <table className='conclusions-options'>
      <tbody>
        <tr className='option-labels'>
          {labels}
        </tr>
        <tr className='option-marks'>
          {radios}
        </tr>
      </tbody>
    </table>

  renderForApp: ->
    @_prepareTypeOptionsForApp()

  render: ->
    <div className="recommendation-types" onContextMenu={@clearOptions}>
      <fieldset name={@props.sectionId} disabled={not @props.editable or @props.readOnly}>
        {if @props.renderMode is 'printout' then @renderForPrintout() else @renderForApp()}
      </fieldset>
    </div>

module.exports = OptionsTypeSection
