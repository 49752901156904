var __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

_.namespace(module, function(require) {
  var APPENDIX_EVIDENCE_TABLES_ID, APPENDIX_EVIDENCE_TABLES_TITLE, APPENDIX_REFERENCES_ID, APPENDIX_REFERENCES_SECTION_ID, APPENDIX_REFERENCES_TITLE, ChapterDoc, GeneralInformationDoc, MDGTopicDoc, MDG_COLLECTION_IDS_TO_LINK_PREFIXES, MdaTopicDoc, ReferencesActions, ReferencesChapterSection, RootDoc, SectionDoc, W, alt, exportSPPGuideline, mdaTableSectionExporter, mediator, pluck, processMdgCodes, processTitle, recommendationSectionExporter, removeHTMLNode, sequence, textSectionExporter, _ref;
  alt = require('alt');
  _ref = require('lib/services/document_sections_exporter/utils'), APPENDIX_EVIDENCE_TABLES_ID = _ref.APPENDIX_EVIDENCE_TABLES_ID, APPENDIX_EVIDENCE_TABLES_TITLE = _ref.APPENDIX_EVIDENCE_TABLES_TITLE, APPENDIX_REFERENCES_ID = _ref.APPENDIX_REFERENCES_ID, APPENDIX_REFERENCES_SECTION_ID = _ref.APPENDIX_REFERENCES_SECTION_ID, APPENDIX_REFERENCES_TITLE = _ref.APPENDIX_REFERENCES_TITLE, processMdgCodes = _ref.processMdgCodes, processTitle = _ref.processTitle;
  ChapterDoc = require('lib/db_docs/document_sections_v2_chapter_doc');
  exportSPPGuideline = require('lib/services/publication_platform_exporter/guideline');
  GeneralInformationDoc = require('lib/db_docs/general_information_doc');
  mdaTableSectionExporter = require('lib/services/document_sections_exporter/mda_table_section_exporter');
  MdaTopicDoc = require('lib/db_docs/mda_topic_doc');
  MDG_COLLECTION_IDS_TO_LINK_PREFIXES = require('lib/mdg_helper').MDG_COLLECTION_IDS_TO_LINK_PREFIXES;
  MDGTopicDoc = require('lib/db_docs/mdg_topic_doc');
  mediator = require('mediator');
  RootDoc = require('lib/db_docs/document_sections_v2_root_doc');
  ReferencesActions = require('actions/references_actions');
  SectionDoc = require('lib/db_docs/document_sections_v2_section_doc');
  sequence = require('when/sequence');
  recommendationSectionExporter = require('lib/services/document_sections_exporter/recommendation_section_exporter');
  ReferencesChapterSection = require('lib/services/document_sections_exporter/references_chapter_section');
  removeHTMLNode = require('base/lib/utils').removeHTMLNode;
  textSectionExporter = require('lib/services/document_sections_exporter/text_section_exporter');
  W = require('when/when');
  pluck = require('lib/immutable_utils').pluck;
  return {
    _sectionMapper: function(chapterSlugifiedPath, exportParams, sections, sectionParentPaths, chaptersPaths) {
      return function(sectionId) {
        return function() {
          var section;
          section = _.find(sections, function(_arg) {
            var _id;
            _id = _arg._id;
            return sectionId === _id;
          });
          if (!section) {
            return;
          }
          if ((section.questionId != null) && (section.recommendationId != null)) {
            return recommendationSectionExporter.exportSection(section, chapterSlugifiedPath, exportParams);
          } else if (section.mdaTableId != null) {
            return mdaTableSectionExporter.exportSection(section, chapterSlugifiedPath, sectionParentPaths, chaptersPaths, exportParams);
          } else {
            return textSectionExporter.exportSection(section, chapterSlugifiedPath, sectionParentPaths, chaptersPaths, exportParams);
          }
        };
      };
    },
    _processChaptersAndSections: function(chapters, sections, rootChapterIds, exportParams) {
      var chaptersPaths, chaptersQueue, chaptersToSlugMap, exportDate, guidelineId, mdaTopicId, processChapter, processedChapters, sectionParentPaths, sectionsTasks;
      chaptersToSlugMap = exportParams.chaptersToSlugMap, guidelineId = exportParams.guidelineId, exportDate = exportParams.exportDate, mdaTopicId = exportParams.mdaTopicId;
      chaptersQueue = [];
      sectionsTasks = [];
      sectionParentPaths = {};
      chaptersPaths = {};
      processChapter = function(chapterId, parentChapterId) {
        var chapter, chapterSlugifiedPath, slug;
        chapter = _.find(chapters, function(_arg) {
          var _id;
          _id = _arg._id;
          return _id === chapterId;
        });
        if (!chapter) {
          return;
        }
        slug = chapter.slug;
        chapterSlugifiedPath = parentChapterId ? "" + parentChapterId + "/" + slug : slug;
        chaptersQueue.push({
          chapter: chapter,
          chapterSlugifiedPath: chapterSlugifiedPath,
          parentChapterId: parentChapterId
        });
        return _.each(chapter.subchapters, function(id) {
          return processChapter(id, chapterSlugifiedPath);
        });
      };
      _.each(rootChapterIds, function(id) {
        return processChapter(id, null);
      });
      processedChapters = _.map(chaptersQueue, (function(_this) {
        return function(_arg) {
          var chapter, chapterPath, chapterSections, chapterSectionsWithRecommendationIds, chapterSlugifiedPath, chapterSubchapters, exportedChapter, guid, newSectionsTasks, parentChapterId, subchapters, title, _ref1;
          chapter = _arg.chapter, chapterSlugifiedPath = _arg.chapterSlugifiedPath, parentChapterId = _arg.parentChapterId;
          guid = chapter._id, chapterSections = chapter.sections, chapterSubchapters = chapter.subchapters, title = chapter.title;
          subchapters = _.chain(chapterSubchapters).map(function(subchapterId) {
            return chaptersToSlugMap[subchapterId];
          }).compact().value();
          chapterSectionsWithRecommendationIds = _.filter(chapterSections, function(sectionId) {
            var section;
            section = _.find(sections, function(_arg1) {
              var _id;
              _id = _arg1._id;
              return sectionId === _id;
            });
            return ((section != null ? section.questionId : void 0) != null) && ((section != null ? section.recommendationId : void 0) != null);
          });
          chapterPath = "" + guidelineId + "/" + chapterSlugifiedPath;
          chaptersPaths[guid] = chapterPath;
          _.each(chapterSections, function(sectionId) {
            return sectionParentPaths[sectionId] = chapterPath;
          });
          newSectionsTasks = _.map(chapterSections, _this._sectionMapper(chapterSlugifiedPath, exportParams, sections, sectionParentPaths, chaptersPaths));
          sectionsTasks.push.apply(sectionsTasks, newSectionsTasks);
          exportedChapter = {
            id: chapterPath,
            guid: guid,
            guidelineId: guidelineId,
            lastModified: exportDate,
            parentChapterId: parentChapterId,
            recommendations: chapterSectionsWithRecommendationIds,
            sections: chapterSections,
            subchapters: subchapters,
            tables: [],
            title: processTitle(title)
          };
          if (mdaTopicId) {
            exportedChapter['isFromAcoem'] = (_ref1 = chapter.isFromAcoem) != null ? _ref1 : false;
          }
          return exportedChapter;
        };
      })(this));
      return sequence(sectionsTasks).then(function(processedSectionsObjs) {
        return [processedChapters, processedSectionsObjs];
      });
    },
    exportGuideline: function(projectId, guidelineDoc, mdaTopic) {
      var exportedGuidelineForSPP;
      if (mediator.services.switches.isOn('publicationPlatform')) {
        exportedGuidelineForSPP = exportSPPGuideline(projectId, guidelineDoc);
        return _.omit(exportedGuidelineForSPP, '@id', 'originalId');
      } else if (mediator.services.switches.isOn('mda')) {
        return _.chain(mdaTopic).pick(['bodyParts', 'codes', 'template', 'lastUpdateProjectName']).pairs().map(function(_arg) {
          var key, value;
          key = _arg[0], value = _arg[1];
          switch (key) {
            case 'bodyParts':
              return [
                key, _.map(value, function(v) {
                  return Number(v);
                })
              ];
            case 'codes':
              if (_.isEmpty(value)) {
                return null;
              }
              return [key, processMdgCodes(value)];
            case 'lastUpdateProjectName':
              if (!value) {
                return null;
              }
              return ['lastWorkflowName', value];
            default:
              return [key, value];
          }
        }).compact().object().value();
      } else {
        return {};
      }
    },
    "export": function(payload) {
      var docsPromise, exportDate, mdaTopicId, projectId;
      if (payload == null) {
        payload = {};
      }
      mdaTopicId = payload.mdaTopicId, projectId = payload.projectId;
      if (projectId == null) {
        projectId = mediator.project.id;
      }
      exportDate = new Date().toISOString();
      docsPromise = mdaTopicId != null ? MdaTopicDoc.at(projectId, mdaTopicId).fetch().then(function(mdaTopic) {
        return RootDoc.at(projectId, mdaTopic.topicRootId).fetch().then(function(rootDoc) {
          return [{}, mdaTopic, rootDoc];
        });
      }) : W.all([GeneralInformationDoc.at(projectId).fetch(), RootDoc.at(projectId).fetch()]).then(function(_arg) {
        var generalInformationDoc, rootDoc;
        generalInformationDoc = _arg[0], rootDoc = _arg[1];
        return [generalInformationDoc, {}, rootDoc];
      });
      return W.all([ReferencesActions.fetch(), MDGTopicDoc.at(projectId).fetchMany(), ChapterDoc.at(projectId).fetchMany(mdaTopicId), SectionDoc.at(projectId).fetchMany(mdaTopicId), docsPromise]).then((function(_this) {
        return function(_arg) {
          var chapters, chaptersToSlugMap, collectionId, exportedGuideline, generalInformationDoc, guidelineId, guidelineTitle, linkPrefix, mdaTopic, rootDoc, rootGuid, rootId, sections, title, topicRows, topics, _ref1, _ref2, _ref3, _refPromiseResult;
          _refPromiseResult = _arg[0], topicRows = _arg[1], chapters = _arg[2], sections = _arg[3], (_ref1 = _arg[4], generalInformationDoc = _ref1[0], mdaTopic = _ref1[1], rootDoc = _ref1[2]);
          topics = _.chain(topicRows.rows).pluck('doc').compact().value();
          _ref2 = mdaTopicId != null ? {
            collectionId: 1,
            guidelineId: mdaTopic.slug,
            guidelineTitle: processTitle(mdaTopic.name)
          } : ((collectionId = generalInformationDoc.collectionId, guidelineId = generalInformationDoc.slug, title = generalInformationDoc.title, generalInformationDoc), {
            collectionId: collectionId,
            guidelineId: guidelineId,
            guidelineTitle: processTitle(title)
          }), collectionId = _ref2.collectionId, guidelineId = _ref2.guidelineId, guidelineTitle = _ref2.guidelineTitle;
          rootId = mdaTopicId != null ? mdaTopic.slug : guidelineId;
          rootGuid = mdaTopicId != null ? mdaTopicId : projectId;
          chaptersToSlugMap = _.chain(chapters).map(function(_arg1) {
            var slug, _id;
            _id = _arg1._id, slug = _arg1.slug;
            if (!slug) {
              return null;
            }
            return [_id, slug];
          }).compact().object().value();
          linkPrefix = mediator.services.switches.isOn('mdgTables') ? (_ref3 = MDG_COLLECTION_IDS_TO_LINK_PREFIXES[collectionId]) != null ? _ref3 : '/' : '/guidelines/';
          exportedGuideline = _this.exportGuideline(projectId, generalInformationDoc, mdaTopic);
          return _this._processChaptersAndSections(chapters, sections, rootDoc.chaptersOrder, {
            chaptersToSlugMap: chaptersToSlugMap,
            exportDate: exportDate,
            guidelineId: guidelineId,
            guidelineTitle: guidelineTitle,
            mdaTopicId: mdaTopicId,
            linkPrefix: linkPrefix,
            projectId: projectId,
            topics: topics
          }).then(function(_arg1) {
            var allReferencesIds, createReferencesChapter, diffRefIds, div, element, exportTables, fullReferencesData, missingReferences, processedChapters, processedGuideline, processedSections, processedSectionsObjs, recommendationSectionIds, references, referencesChapterId, referencesHTML, referencesIds, referencesIdsFromTables, rootChaptersSlugifiedIds, storeReferencesIds, tableSectionIds;
            processedChapters = _arg1[0], processedSectionsObjs = _arg1[1];
            processedSections = _.chain(processedSectionsObjs).pluck('sections').flatten().sortBy(function(_arg2) {
              var type;
              type = _arg2.type;
              if (type === 'table') {
                return 1;
              } else {
                return 0;
              }
            }).uniq(false, 'id').map(function(section) {
              if (section.title != null) {
                section.title = processTitle(section.title);
              }
              return section;
            }).value();
            referencesIds = _.chain(processedSectionsObjs).pluck('references').flatten().value();
            referencesIdsFromTables = _.chain(processedSectionsObjs).pluck('referencesFromTables').flatten().value();
            fullReferencesData = _.chain(processedSectionsObjs).pluck('fullReferencesData').flatten().reduce(function(acc, refs) {
              return _.extend(acc, refs);
            }, {}).value();
            allReferencesIds = _.uniq(_.union(referencesIds, referencesIdsFromTables));
            rootChaptersSlugifiedIds = _.chain(rootDoc.chaptersOrder).map(function(chapterId) {
              return chaptersToSlugMap[chapterId];
            }).compact().value();
            recommendationSectionIds = _.chain(processedSections).filter(function(_arg2) {
              var type;
              type = _arg2.type;
              return type === 'recommendation';
            }).pluck('id').map(function(id) {
              return _.last(id.split('/'));
            }).value();
            tableSectionIds = _.chain(processedSections).filter(function(_arg2) {
              var type;
              type = _arg2.type;
              return type === 'table' || type === 'mdaTable';
            }).pluck('id').map(function(id) {
              return _.last(id.split('/'));
            }).uniq().value();
            exportTables = mediator.services.switches.isOn('mdgTables') && !_.isEmpty(tableSectionIds);
            if (exportTables) {
              rootChaptersSlugifiedIds.push(APPENDIX_EVIDENCE_TABLES_ID);
              processedChapters.push({
                id: "" + rootId + "/" + APPENDIX_EVIDENCE_TABLES_ID,
                guid: "" + rootGuid + "_" + APPENDIX_EVIDENCE_TABLES_ID,
                guidelineId: guidelineId,
                lastModified: exportDate,
                recommendations: [],
                sections: tableSectionIds,
                subchapters: [],
                tables: tableSectionIds,
                title: processTitle(APPENDIX_EVIDENCE_TABLES_TITLE)
              });
            }
            createReferencesChapter = mediator.services.switches.isOn('mdgTables') && !_.isEmpty(referencesIds);
            if (createReferencesChapter) {
              rootChaptersSlugifiedIds.push(APPENDIX_REFERENCES_ID);
              referencesChapterId = "" + rootId + "/" + APPENDIX_REFERENCES_ID;
              processedChapters.push({
                id: referencesChapterId,
                guid: "" + rootGuid + "_" + APPENDIX_REFERENCES_SECTION_ID,
                guidelineId: guidelineId,
                lastModified: exportDate,
                recommendations: [],
                sections: [APPENDIX_REFERENCES_SECTION_ID],
                subchapters: [],
                tables: [],
                title: processTitle(APPENDIX_REFERENCES_TITLE)
              });
              div = document.createElement('div');
              element = React.createElement(ReferencesChapterSection, {
                referencesIds: allReferencesIds
              });
              ReactDOM.render(element, div);
              referencesHTML = div.innerHTML;
              ReactDOM.unmountComponentAtNode(div);
              removeHTMLNode(div);
              processedSections.push({
                id: "" + referencesChapterId + "/" + APPENDIX_REFERENCES_SECTION_ID,
                guid: "" + projectId + "_" + APPENDIX_REFERENCES_SECTION_ID,
                chapterId: referencesChapterId,
                guidelineId: guidelineId,
                htmlContent: referencesHTML,
                lastModified: exportDate,
                type: 'text'
              });
            }
            references = alt.stores.ReferencesStore.getReferenceByIds(allReferencesIds);
            references = references.length === allReferencesIds.length ? references : (storeReferencesIds = pluck(references, '_id').toJS(), diffRefIds = _.difference(allReferencesIds, storeReferencesIds), missingReferences = _.filter(fullReferencesData, function(ref) {
              var _ref4;
              return _ref4 = ref._id, __indexOf.call(diffRefIds, _ref4) >= 0;
            }), _.reduce(missingReferences, function(acc, ref) {
              var indexOfRef;
              indexOfRef = allReferencesIds.findIndex(function(refId) {
                return refId === ref._id;
              });
              return acc.insert(indexOfRef, Immutable.fromJS(ref));
            }, references));
            processedGuideline = _.extend(exportedGuideline, {
              id: rootId,
              guid: rootGuid,
              collectionId: collectionId,
              chapters: rootChaptersSlugifiedIds,
              lastModified: exportDate,
              recommendations: recommendationSectionIds,
              tables: tableSectionIds,
              title: guidelineTitle,
              references: (references || Immutable.List()).toJS()
            });
            return [processedGuideline, processedChapters, processedSections];
          });
        };
      })(this));
    }
  };
});
