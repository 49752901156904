'use strict';
// TODO: Remove from `core-js@4`
require('../../modules/es.object.to-string');
require('../../modules/esnext.iterator.constructor');
require('../../modules/esnext.iterator.indexed');

var entryUnbind = require('../../internals/entry-unbind');

module.exports = entryUnbind('Iterator', 'indexed');

