Input = require 'components/common/input'
IconButton = require 'components/common/icon_button'

DocumentSectionsTitle = createReactClass
  displayName: 'DocumentSectionsTitle'

  propTypes:
    title: PropTypes.string.isRequired
    onCancel: PropTypes.func
    onChange: PropTypes.func
    onSave: PropTypes.func.isRequired
    onDelete: PropTypes.func

  getInitialState: ->
    editing: false
    titleValue: @props.title

  toggleEdit: ->
    @setState ({ editing }) ->
      editing: !editing

  handleChange: (evt) ->
    nextTitleValue = evt.target.value
    @setState titleValue: nextTitleValue, () =>
      if @props.onChange
        @props.onChange(nextTitleValue)

  handleCancel: ->
    @setState { editing: false, titleValue: @props.title }, =>
      @props.onCancel?()

  handleSave: ->
    @props.onSave @state.titleValue
    @setState
      editing: false

  render: ->
    { title, onDelete } = @props
    { editing, titleValue } = @state

    <div className="document-sections-title">
      {
        if editing
          <React.Fragment>
            <Input
              autoFocus
              keyboardSupport
              type="text"
              value={titleValue}
              onChange={@handleChange}
              onRequestSave={@handleSave}
              className="title-input"
            />
            <IconButton iconName="clear" className="title-icon" onClick={@handleCancel} />
            <IconButton iconName="save" className="title-icon" onClick={@handleSave} />
          </React.Fragment>
        else
          <React.Fragment>
            <span className="title-text">{title}</span>
            {onDelete && (
              <IconButton iconName="delete" className="title-icon" onClick={onDelete} />
            )}
            <IconButton iconName="edit" className="title-icon" onClick={@toggleEdit} />
          </React.Fragment>
      }
    </div>

module.exports = DocumentSectionsTitle
