var DocumentSectionsActions, OutcomesActions, QuestionsGenActions, ReferencesActions, alt, documentSectionsDocId, mediator;

alt = require('alt');

mediator = require('mediator');

OutcomesActions = require('actions/outcomes_generation_actions');

QuestionsGenActions = require('actions/questions_generation_actions');

ReferencesActions = require('actions/references_actions');

documentSectionsDocId = require('lib/doc_ids').DOCUMENT_SECTIONS;

DocumentSectionsActions = (function() {
  function DocumentSectionsActions() {
    this.generateActions('fetchSuccess', 'fetchError', 'saveSuccess', 'saveError', 'toggleSection');
  }

  DocumentSectionsActions.prototype.fetch = function() {
    var projectId;
    projectId = mediator.project.id;
    OutcomesActions.fetch();
    QuestionsGenActions.fetch();
    ReferencesActions.fetch({
      dbId: projectId,
      fetchUsedReferences: false
    });
    mediator.services.storePersistenceAdapter.fetch(projectId, documentSectionsDocId).then(this.actions.fetchSuccess)["catch"](this.actions.fetchError);
    return this.dispatch();
  };

  DocumentSectionsActions.prototype.saveContent = function(content, activeSections, projectId) {
    var doc;
    doc = {
      _id: documentSectionsDocId,
      content: content,
      activeSections: activeSections
    };
    return mediator.services.storePersistenceAdapter.save(projectId, doc).then(this.actions.saveSuccess)["catch"](this.actions.saveError);
  };

  return DocumentSectionsActions;

})();

module.exports = alt.createActions(DocumentSectionsActions);
