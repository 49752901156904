ItemWithCheckbox = require 'components/common/item_with_checkbox'
QuestionWithCheckbox = require 'components/panel_voice/question_with_checkbox'
Translation = require 'components/mixins/translation'
{ instanceOf, func, string, oneOfType, bool } = PropTypes
{ groupQuestionsByGroup } = require 'lib/questions_helper'

List = (props) ->
  {
    blockedQuestions
    questionField
    questions
    onSelectQuestion
    selectedQuestions
    withNumbers
  } = props

  return <div /> if questions.isEmpty()

  <div className="questions-list">
    {questions.map (question) ->
      <QuestionWithCheckbox
        readonly={blockedQuestions.contains question.get('_id')}
        key={question.get("_id")}
        question={question}
        questionField={questionField}
        checked={selectedQuestions.contains question.get("_id")}
        onSelectQuestion={onSelectQuestion}
        withNumbers={withNumbers}
      />
    .toList()}
    {###
      This elements are for faking last item on the list and they will close border around group
    ###}
    <div className="item-with-checkbox fake">
      <div className="item-with-checkbox__checkbox" />
      <div className="bottom-border" />
    </div>
  </div>

QuestionsSelector = createReactClass

  displayName: "QuestionsSelector"

  propTypes:
    questions: oneOfType([
      instanceOf(Immutable.OrderedMap),
      instanceOf(Immutable.Map)
    ]).isRequired
    blockedQuestions: instanceOf(Immutable.List)
    className: string
    currentQuestionId: string
    questionField: string
    questionGroups: instanceOf(Immutable.Map).isRequired
    selectedQuestions: instanceOf(Immutable.List).isRequired
    updateSelected: func.isRequired
    withNumbers: bool
    withoutBorder: bool
    withSearch: bool
    withSelectAll: bool

  getDefaultProps: ->
    blockedQuestions: Immutable.List()
    className: null
    currentQuestionId: null
    questionField: 'question'
    withNumbers: false
    withoutBorder: false
    withSearch: false
    withSelectAll: true

  getInitialState: ->
    searchText: ''

  mixins: [Translation("voting:voting")]

  filterOutCurrentQuestion: (questions) ->
    { currentQuestionId } = @props
    return questions unless currentQuestionId
    questions.filterNot (question) -> question.get("_id") is currentQuestionId

  onSelectQuestion: (questionId) ->
    selectedQuestions = if @props.selectedQuestions.contains questionId
      @props.selectedQuestions.delete @props.selectedQuestions.indexOf questionId
    else
      @props.selectedQuestions.push questionId
    @props.updateSelected selectedQuestions

  toggleAllInGroup: (groupQuestions) -> (e) =>
    { blockedQuestions } = @props
    groupQuestions = groupQuestions.filterNot (question) ->
      blockedQuestions.contains question.get("_id")

    selectedQuestions = @props.selectedQuestions
    if e.currentTarget.checked is true
      selectedQuestions = groupQuestions.reduce (prev, question) ->
        if not prev.contains question.get("_id")
          prev.push question.get("_id")
        else prev
      , selectedQuestions
    else
      selectedQuestions = groupQuestions.reduce (prev, question) ->
        if prev.contains question.get("_id")
          prev.delete prev.indexOf question.get("_id")
        else prev
      , selectedQuestions
    @props.updateSelected selectedQuestions

  checkIfAllQuestionsSelected: ->
    { questions, blockedQuestions, selectedQuestions } = @props

    questions.filterNot (question) ->
      blockedQuestions.contains question.get("_id")
    .every (question) ->
      selectedQuestions.contains question.get("_id")

  toggleAllQuestions: ->
    { blockedQuestions, selectedQuestions: currentSelectedQuestions, questions } = @props

    currentSelectedQuestions = currentSelectedQuestions.filterNot (question) ->
      blockedQuestions.contains question

    filteredQuestions = questions.filterNot (question) ->
      blockedQuestions.contains question.get('_id')

    selectedQuestions = if currentSelectedQuestions.size is filteredQuestions.size
      if @props.currentQuestionId
        new Immutable.List([@props.currentQuestionId])
      else
        new Immutable.List()
    else
      filteredQuestions.reduce (acc, question) ->
        acc.push question.get("_id")
      , new Immutable.List()

    @props.updateSelected selectedQuestions

  onSearchChange: (e) ->
    @setState searchText: e.target.value

  filterQuestions: ->
    searchTextLower = @state.searchText.toLowerCase()
    { questions } = @props
    return questions if searchTextLower is ''
    questions.filter (question) ->
      questionTextLower = question.get('question')?.toLowerCase() ? ''
      questionTextLower.indexOf(searchTextLower) >= 0

  render: ->
    {
      blockedQuestions,
      className,
      questionField,
      questionGroups,
      questions,
      currentQuestionId,
      withSelectAll,
      withSearch,
      withNumbers,
      withoutBorder,
      selectedQuestions
    } = @props
    { searchText } = @state
    return <div className={className} /> if @filterOutCurrentQuestion(questions).isEmpty()

    questionsFilteredBySearch = @filterQuestions()
    questionsByGroup = groupQuestionsByGroup questionsFilteredBySearch, questionGroups

    <div className={classNames('questions-selector', className)}>
      {(withSearch or withSelectAll) and <div
        className={classNames('questions-selector__search', 'without-border': withoutBorder)}
      >
        {withSelectAll and <ItemWithCheckbox
          className="search__select-all"
          checked={@checkIfAllQuestionsSelected()}
          onChange={@toggleAllQuestions}
        >
          <button>{@i18n "/actions.select_all"}</button>
        </ItemWithCheckbox>}
        {withSearch and <input
          className="search__input"
          type="text"
          value={searchText}
          onChange={@onSearchChange}
          placeholder={"#{@i18n '/nav.search'}..."}
        />}
      </div>}
      {if withSearch and questionsFilteredBySearch.isEmpty()
        text = if searchText is ''
          @i18n '/messages.no_questions_in_project'
        else
          @i18n '/messages.no_questions_matching_criteria', { searchText }
        <div>{text}</div>
      }
      <div className="questions-selector__questions">
        {questionsByGroup.map (group, groupId) =>
          groupQuestions = group.get 'questions'
          if groupQuestions.has currentQuestionId
            groupQuestions = @filterOutCurrentQuestion groupQuestions
          return null if groupQuestions.isEmpty()

          allChecked = groupQuestions.filterNot (question) ->
            blockedQuestions.contains question.get("_id")
          .every (q) -> selectedQuestions.contains q.get("_id")

          if groupId is 'questionsWithoutGroup'
            <div className="questions-without-group" key={groupId}>
              <List
                blockedQuestions={blockedQuestions}
                selectedQuestions={selectedQuestions}
                questions={groupQuestions}
                questionField={questionField}
                onSelectQuestion={@onSelectQuestion}
                withNumbers={withNumbers}
              />
            </div>
          else
            <div className="question-groups" key={groupId}>
              <div className="group-container">
                <ItemWithCheckbox
                  className="border"
                  checked={allChecked}
                  onChange={@toggleAllInGroup groupQuestions}
                >
                  <div className="group-caption">
                    {group.get("caption")}
                  </div>
                </ItemWithCheckbox>
                <List
                  blockedQuestions={blockedQuestions}
                  selectedQuestions={selectedQuestions}
                  questions={groupQuestions}
                  questionField={questionField}
                  onSelectQuestion={@onSelectQuestion}
                  withNumbers={withNumbers}
                />
              </div>
            </div>
        .toList()}
      </div>
    </div>

module.exports = QuestionsSelector
