var __templateData = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "<div class=\"absolute-effect ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.effectMeasure : depth0), "rateRatio", {"name":"is","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "cont", {"name":"is","hash":{},"fn":this.program(4, data),"inverse":this.program(6, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </div>\n";
},"2":function(depth0,helpers,partials,data) {
  return "rate-ratio";
  },"4":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "    <div class=\"row mt-10\">\n      "
    + escapeExpression(((helpers.inputBlock || (depth0 && depth0.inputBlock) || helperMissing).call(depth0, "cont_effect_measure", "effectSize", {"name":"inputBlock","hash":{},"data":data})))
    + "\n    </div>\n    <div class=\"row mt-10\">\n      "
    + escapeExpression(((helpers.inputBlock || (depth0 && depth0.inputBlock) || helperMissing).call(depth0, "cont_effect_size_units", "effectSizeUnits", {"name":"inputBlock","hash":{},"data":data})))
    + "\n    </div>\n    <div class=\"row mt-10\">\n      "
    + escapeExpression(((helpers.inputBlock || (depth0 && depth0.inputBlock) || helperMissing).call(depth0, "cont_effect_interval", "confidenceIntervalTo", {"name":"inputBlock","hash":{},"data":data})))
    + "\n    </div>\n";
  stack1 = this.invokePartial(partials.interpretationOfResults, '    ', 'interpretationOfResults', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.i18nNamespace || (depth0 && depth0.i18nNamespace) || helperMissing).call(depth0, "es:outcome", {"name":"i18nNamespace","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"7":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "      <div class=\"edit-input-block row mt-10 abs-effect-auto-calc\">\n        <label for=\"absEffectAutoCalc\" class=\"long\">\n          <input type=\"checkbox\" id=\"absEffectAutoCalc\"\n            name=\"absEffectAutoCalc"
    + escapeExpression(((helper = (helper = helpers.riskLevelSuffix || (depth0 != null ? depth0.riskLevelSuffix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"riskLevelSuffix","hash":{},"data":data}) : helper)))
    + "\"\n          ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.absEffectAutoCalc : depth0), {"name":"if","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n          "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "absolute_auto_calculate", {"name":"i18n","hash":{},"data":data})))
    + "\n        </label>\n        <div class=\"sup\" />\n      </div>\n      <div class=\"edit-input-block row mt-10\n      ";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.effectMeasure : depth0), "rateRatio", {"name":"is","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"\n        data-property=\"absEffectSize"
    + escapeExpression(((helper = (helper = helpers.riskLevelSuffix || (depth0 != null ? depth0.riskLevelSuffix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"riskLevelSuffix","hash":{},"data":data}) : helper)))
    + "\">\n          <label for=\"absEffectSize\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "absolute", {"name":"i18n","hash":{},"data":data})))
    + ": </label>\n          <input type=\"text\" class=\"number\" id=\"absEffectSize\"\n            name=\"absEffectSize"
    + escapeExpression(((helper = (helper = helpers.riskLevelSuffix || (depth0 != null ? depth0.riskLevelSuffix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"riskLevelSuffix","hash":{},"data":data}) : helper)))
    + "\" ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.absEffectAutoCalc : depth0), {"name":"if","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n        <div class=\"inline\">\n        "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, (depth0 != null ? depth0.fewerMoreName : depth0), (depth0 != null ? depth0.fewerMore : depth0), "fewer: fewer, more: more", (depth0 != null ? depth0.absEffectAutoCalc : depth0), {"name":"selectableAttr","hash":{},"data":data})))
    + "\n        </div>\n        <label class=\"middle\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "per", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n";
  stack1 = ((helpers.is || (depth0 && depth0.is) || helperMissing).call(depth0, (depth0 != null ? depth0.effectMeasure : depth0), "rateRatio", {"name":"is","hash":{},"fn":this.program(12, data),"inverse":this.program(15, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "        <div class=\"sup\" />\n      </div>\n      <div class=\"edit-input-block row mt-10\" data-property=\"absEffectTo"
    + escapeExpression(((helper = (helper = helpers.riskLevelSuffix || (depth0 != null ? depth0.riskLevelSuffix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"riskLevelSuffix","hash":{},"data":data}) : helper)))
    + "\">\n        <label for=\"absEffectFrom\">95% CI "
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "from", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n        <input class=\"number\" id=\"absEffectFrom\" type=\"text\"\n          name=\"absEffectFrom"
    + escapeExpression(((helper = (helper = helpers.riskLevelSuffix || (depth0 != null ? depth0.riskLevelSuffix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"riskLevelSuffix","hash":{},"data":data}) : helper)))
    + "\" ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.absEffectAutoCalc : depth0), {"name":"if","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n        <div class=\"inline\">\n          "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, (depth0 != null ? depth0.fewerMoreFromName : depth0), (depth0 != null ? depth0.fewerMoreFrom : depth0), "fewer: fewer, more: more", (depth0 != null ? depth0.absEffectAutoCalc : depth0), {"name":"selectableAttr","hash":{},"data":data})))
    + "\n        </div>\n        <label class=\"middle\" for=\"absEffectTo\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "to", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n        <input class=\"number\" id=\"absEffectTo\" type=\"text\"\n          name=\"absEffectTo"
    + escapeExpression(((helper = (helper = helpers.riskLevelSuffix || (depth0 != null ? depth0.riskLevelSuffix : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"riskLevelSuffix","hash":{},"data":data}) : helper)))
    + "\" ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.absEffectAutoCalc : depth0), {"name":"if","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n        <div class=\"inline\">\n          "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, (depth0 != null ? depth0.fewerMoreToName : depth0), (depth0 != null ? depth0.fewerMoreTo : depth0), "fewer: fewer, more: more", (depth0 != null ? depth0.absEffectAutoCalc : depth0), {"name":"selectableAttr","hash":{},"data":data})))
    + "\n        </div>\n        <div class=\"sup\" />\n      </div>\n";
  stack1 = this.invokePartial(partials.interpretationOfResults, '      ', 'interpretationOfResults', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"8":function(depth0,helpers,partials,data) {
  return "checked";
  },"10":function(depth0,helpers,partials,data) {
  return "disabled";
  },"12":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function", buffer = "          <div class=\"inline\">\n            "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "perPatients", (depth0 != null ? depth0.perPatients : depth0), "100: 100, 1000: 1000, 10000: 10000, 1000000: 1000000, other: other", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n            <input class=\"other\" type=\"text\" name=\"perPatientsOther\"\n              value=\""
    + escapeExpression(((helper = (helper = helpers.perPatientsOther || (depth0 != null ? depth0.perPatientsOther : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"perPatientsOther","hash":{},"data":data}) : helper)))
    + "\"\n              ";
  stack1 = ((helpers.isnt || (depth0 && depth0.isnt) || helperMissing).call(depth0, (depth0 != null ? depth0.perPatients : depth0), "other", {"name":"isnt","hash":{},"fn":this.program(13, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += ">\n          </div>\n          <label class=\"middle\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "patient_s", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n          <label class=\"middle\">"
    + escapeExpression(((helpers.i18n || (depth0 && depth0.i18n) || helperMissing).call(depth0, "per", {"name":"i18n","hash":{},"data":data})))
    + "</label>\n          <div class=\"inline\">\n            "
    + escapeExpression(((helpers.selectableAttr || (depth0 && depth0.selectableAttr) || helperMissing).call(depth0, "perTimeFrame", (depth0 != null ? depth0.perTimeFrame : depth0), "weeks: weeks, months: months, years: years, other: other", {"name":"selectableAttr","hash":{},"data":data})))
    + "\n            <input class=\"other\" type=\"text\" name=\"perTimeFrameOther\"\n              value=\""
    + escapeExpression(((helper = (helper = helpers.perTimeFrameOther || (depth0 != null ? depth0.perTimeFrameOther : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"perTimeFrameOther","hash":{},"data":data}) : helper)))
    + "\"\n              ";
  stack1 = ((helpers.isnt || (depth0 && depth0.isnt) || helperMissing).call(depth0, (depth0 != null ? depth0.perTimeFrame : depth0), "other", {"name":"isnt","hash":{},"fn":this.program(13, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + ">\n          </div>\n";
},"13":function(depth0,helpers,partials,data) {
  return "style=\"display: none\"";
  },"15":function(depth0,helpers,partials,data) {
  var helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "          <div class=\"inline\">\n            "
    + escapeExpression(((helpers.absDenominatorSelectableAttr || (depth0 && depth0.absDenominatorSelectableAttr) || helperMissing).call(depth0, (depth0 != null ? depth0.absDenominator : depth0), {"name":"absDenominatorSelectableAttr","hash":{},"data":data})))
    + "\n          </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.compoundEdit || (depth0 && depth0.compoundEdit) || helperMissing).call(depth0, "absoluteEffect", "absolute_effect", {"name":"compoundEdit","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"usePartial":true,"useData":true});
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}