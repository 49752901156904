DocumentSectionsV2Actions = require 'actions/document_sections_v2_actions'
DropdownMenu = require 'components/common/dropdown_menu'
{
  default: Editor
  serializeState: serializeEditorState
} = Epiditor
{ getSettingsOptions } = require 'lib/document_sections_v2_helper'
IconButton = require 'components/common/icon_button'
{ isCurrentUserAdmin } = require 'lib/members_helper'
MdaTableActions = require 'actions/mda_table_actions'
mediator = require 'mediator'
{ resetSelectionOnState } = require 'lib/epiditor_utils'
{ useCoffeeCallback, useCoffeeMemo } = require 'lib/react_utils'
{ useRef } = React
useScrollToDocSection = require 'components/hooks/use_scroll_to_doc_section'
{ getSettingsOrder } = require 'lib/mda_helper'

MdaTableSection = ({
  connectDragSource
  mdaTopicId
  onDelete
  onInsertMdaTable
  onAddCode
  scrollToSectionContent
  section
  table
}) ->
  parent = section.get 'parent'
  projectId = mediator.project.id
  userAccessRights = mediator.user.getAccessRights()
  sectionId = section.get '_id'
  tableId = table.get '_id'
  title = table.get 'name'

  content = useCoffeeMemo [table], -> table.get('content')?.toJS()
  showChapterManagement = useCoffeeMemo [userAccessRights], ->
    not _.contains(userAccessRights, 'mdg_panel_reviewer')
  editorViewRef = useRef null
  useScrollToDocSection(sectionId, scrollToSectionContent, editorViewRef)

  editorLoaded = useCoffeeCallback [], (editorView) ->
    editorViewRef.current = editorView

  handleSettingSelect = useCoffeeCallback [projectId, parent, sectionId], (action) ->
    switch action
      when 'add_new_text_field'
        DocumentSectionsV2Actions.addSection projectId, parent, sectionId
      when 'insert_mda_table'
        onInsertMdaTable({ sectionId })
      when 'add_code'
        onAddCode({ sectionId })
      when 'delete'
        onDelete sectionId

  handleActiveAnnotationUpdated = useCoffeeCallback [sectionId], (current, prev) -> _.defer ->
    currentIds = current?.map ({ id }) -> id
    prevIds = prev?.map ({ id }) -> id

    DocumentSectionsV2Actions.setActiveDiscussionItem(
      if current then { sectionId, id: currentIds } else null,
      if prev then { sectionId, id: prevIds } else null
    )

  handleCommentsUpdated = useCoffeeCallback [projectId, tableId], (comments, maybePrevComments) ->
    if editorViewRef.current and not _.isEqual comments, maybePrevComments
      newContent = serializeEditorState resetSelectionOnState editorViewRef.current.state
      MdaTableActions.updateContent projectId, tableId, newContent

    if maybePrevComments
      _.defer ->
        newCommentId = R.find(
          (commentId) -> not R.has(commentId, maybePrevComments)
          R.keys(comments)
        )
        DocumentSectionsV2Actions.editDiscussion { id: newCommentId, isNew: true } if newCommentId

  editorPluginProps = useCoffeeMemo [
    handleActiveAnnotationUpdated
    handleCommentsUpdated
    mdaTopicId
  ], ->
    annotations:
      annotationDecorator: (annotation, selection) ->
        return undefined unless annotation.data?.context?.topicId is mdaTopicId
        if isCurrentUserAdmin() or annotation.author?.id is mediator.user.id
          annotation.getDecoration selection
        else
          undefined
      author:
        id: mediator.user.id
        name: mediator.user.getFullName()
      onActiveAnnotationUpdated: handleActiveAnnotationUpdated
      onBeforeAnnotationAdded: (annotation) ->
        annotation.update data: _.extend {},
          annotation.data ? {},
          context: topicId: mdaTopicId
      onChange: handleCommentsUpdated

  <React.Fragment>
    <div className="chapter-section-header">
      {connectDragSource(
        <div className="drag-marker"/>
      )}
      <div className="document-sections-title">
        <span className="title-text">{title}</span>
      </div>
      {showChapterManagement and <div className="chapter-section-management">
        <DropdownMenu
          opener={(
            <IconButton iconName="more" className="settings-toggle" />
          )}
          options={getSettingsOptions getSettingsOrder('mda-table-section')}
          onSelect={handleSettingSelect}
        />
      </div>}
    </div>
    <div className="mb-10">
      <Editor
        initialState={content}
        mode="readonly"
        onLoad={editorLoaded}
        pluginProps={editorPluginProps}
      />
    </div>
  </React.Fragment>

MdaTableSection.propTypes =
  connectDragSource: PropTypes.func.isRequired
  mdaTopicId: PropTypes.string.isRequired
  onDelete: PropTypes.func.isRequired
  onInsertMdaTable: PropTypes.func.isRequired
  onAddCode: PropTypes.func.isRequired
  scrollToSectionContent: PropTypes.shape({
    sectionId: PropTypes.string.isRequired,
    contentPos: PropTypes.number.isRequired
  })
  section: PropTypes.instanceOf(Immutable.Map).isRequired
  table: PropTypes.instanceOf(Immutable.Map).isRequired

module.exports = MdaTableSection
