var COCHRANE_ORGANIZATION_ID, HEALTH_ADVISOR_WORKSPACE_ID, MDG_WORKSPACE, MEMBERS_DB_VIEW, REQUIRED_FIELDS, SORTABLE_FIELDS, TeamMember, W, alt, appUtils, checkIfEmailUsed, clearEmail, createMember, filterAdminsAndResearchers, getAccessRightsDescription, getAccessRightsLabel, getAccessRightsList, getAttributesFromEmail, getInitials, getMemberAccessRights, getMemberFullName, getMemberPendingAccessRights, getMembersByLoginMapOfCurrentProject, getMembersLoginToNameMapOfCurrentProject, getMembersWithPendingInvitationsCount, isCurrentUserAdmin, isCurrentlyLogged, isMemberDuplicated, isReadOnly, mediator, mocHelpers, notMemberOfOrganization, parseTeamMembers, reduceMembersToValueList, sortDirectionValue, utils, validateMemberAgainstMembersList, validateMemberModel;

alt = require('alt');

appUtils = require('lib/app_utils');

COCHRANE_ORGANIZATION_ID = require('lib/archie_helper').COCHRANE_ORGANIZATION_ID;

MDG_WORKSPACE = require('lib/mdg_helper').MDG_WORKSPACE;

HEALTH_ADVISOR_WORKSPACE_ID = require('lib/mda_helper').HEALTH_ADVISOR_WORKSPACE_ID;

utils = require('base/lib/utils');

mediator = require('mediator');

mocHelpers = require('actions/utils/moc_helpers');

TeamMember = require('models/team_member');

W = require('when/when');

isReadOnly = function(member) {
  return member.has('gdtLogin') && isCurrentlyLogged(member) && !isMemberDuplicated(member.get('email'));
};

createMember = function(id) {
  if (id == null) {
    id = null;
  }
  return {
    _id: id != null ? id : utils.generateGUID(),
    accessRights: [],
    email: "",
    lastName: "",
    givenNames: "",
    notes: "",
    panelMember: false,
    title: "",
    docType: "projectMember"
  };
};

getMemberPendingAccessRights = function(member) {
  return member.get('pendingInvitationAccessRights', Immutable.List()).first();
};

getMemberAccessRights = function(member) {
  return member.get('accessRights', Immutable.List()).first();
};

getAccessRightsLabel = function(member, withPending) {
  var accessRights, pendingAccessRights;
  if (withPending == null) {
    withPending = false;
  }
  accessRights = getMemberAccessRights(member);
  pendingAccessRights = getMemberPendingAccessRights(member);
  if (withPending && member.get('pendingInvitation')) {
    return $.t("team:member_access_rights." + pendingAccessRights + ".label") + " (" + $.t("team:member.pending_invitation") + ")";
  } else if (accessRights) {
    return $.t("team:member_access_rights." + accessRights + ".label");
  } else {
    return '';
  }
};

getMembersWithPendingInvitationsCount = function(members) {
  var _ref;
  return ((_ref = members.filter(function(m) {
    return m.get('pendingInvitation', false);
  })) != null ? _ref.size : void 0) || 0;
};

filterAdminsAndResearchers = function(members) {
  return members.filter(function(m) {
    var _ref, _ref1;
    return (_ref = (_ref1 = m.get('accessRights')) != null ? _ref1.first() : void 0) === 'admin' || _ref === 'researcher';
  });
};

getInitials = function(member) {
  var first, initials, second;
  first = (member.get('givenNames') || '').slice(0, 1).toUpperCase();
  second = (member.get('lastName') || '').slice(0, 1).toUpperCase();
  initials = "";
  initials += first !== "" ? "" + first + "." : "";
  return initials += second !== "" ? "" + second + "." : "";
};

getAccessRightsDescription = function(accessRight) {
  if (accessRight) {
    return $.t("team:member_access_rights." + accessRight + ".description");
  } else {
    return '';
  }
};

clearEmail = function(email) {
  return email.replace(/^<|>$/g, "");
};

getAttributesFromEmail = function(email) {
  var givenNames, lastName, partial;
  email = clearEmail(utils.removeNonPrintableChars(email));
  partial = email.substring(0, email.indexOf('@'));
  givenNames = _.string.capitalize(partial.substring(0, partial.indexOf('.')));
  lastName = _.string.capitalize(partial.substring(partial.indexOf('.') + 1));
  return {
    givenNames: givenNames,
    lastName: lastName
  };
};

validateMemberModel = function(member) {
  var _ref;
  return Immutable.fromJS((_ref = mocHelpers.validate(member, TeamMember)) != null ? _ref : Immutable.Map());
};

validateMemberAgainstMembersList = function(memberToValidate, membersList, checkForDuplicates) {
  var accessRights, duplicatedEmail, members, validationErrors;
  if (checkForDuplicates == null) {
    checkForDuplicates = true;
  }
  members = membersList.filter(function(member) {
    return member.get('_id') && member.get('_id') !== memberToValidate.get('_id');
  });
  validationErrors = validateMemberModel(memberToValidate.toJS());
  if (checkForDuplicates) {
    duplicatedEmail = members.find(function(member) {
      return member.get('email') === memberToValidate.get('email') && !member.get('deleted');
    });
    if (duplicatedEmail) {
      validationErrors = validationErrors.set('email', $.t('errors:model.user_exists'));
    }
  }
  accessRights = memberToValidate.get('accessRights', Immutable.List());
  if (accessRights.isEmpty() || accessRights.includes('no_access')) {
    return W.resolve(validationErrors);
  } else {
    return checkIfEmailUsed(memberToValidate.get('email')).then(function(emailIsUsed) {
      if (emailIsUsed) {
        return validationErrors;
      } else {
        return validationErrors = validationErrors.set('email', $.t('projects:share_project_dialog.no_user_with_email'));
      }
    });
  }
};

checkIfEmailUsed = function(email) {
  return mediator.services.email.checkIfEmailUsed(email).then(function(used) {
    if (used) {
      return true;
    } else {
      return false;
    }
  }).otherwise(function() {
    return true;
  });
};

parseTeamMembers = function(membersList) {
  var members, separateMembersReg, _createMember;
  if (_.isEmpty(membersList)) {
    return Immutable.List();
  }
  separateMembersReg = /\s*[\;\,]\s*/;
  members = Immutable.fromJS([].concat(membersList.split(separateMembersReg)));
  members = members.filter(function(member) {
    return !_.isEmpty(member);
  });
  _createMember = function(_arg) {
    var email, givenNames, lastName, member;
    givenNames = _arg.givenNames, lastName = _arg.lastName, email = _arg.email;
    member = Immutable.fromJS(createMember());
    return member = member.withMutations(function(member) {
      return member.set('email', clearEmail(email)).set('givenNames', givenNames != null ? givenNames : getAttributesFromEmail(email).givenNames).set('lastName', lastName != null ? lastName : getAttributesFromEmail(email).lastName);
    });
  };
  return members.map(function(member) {
    var attributes, email, givenNames, lastName;
    attributes = member.trim().split(/\s+/);
    if (attributes.length === 1) {
      return _createMember({
        email: _.first(attributes)
      });
    } else if (attributes.length === 2) {
      return _createMember({
        lastName: _.string.capitalize(_.first(attributes)),
        email: _.last(attributes)
      });
    } else {
      givenNames = _.string.capitalize(attributes.shift());
      email = attributes.pop();
      lastName = _.string.capitalize(attributes.toString());
      return _createMember({
        givenNames: givenNames,
        lastName: lastName,
        email: email
      });
    }
  });
};

getAccessRightsList = function() {
  var accessRights;
  accessRights = mediator.services.switches.isServerSwitchOn('acpFeatures') ? ['admin', 'acp_review_team_member', 'acp_panel_member', 'no_access'] : mediator.activeWorkspace === COCHRANE_ORGANIZATION_ID ? ['admin', 'researcher', 'cochrane_author', 'no_access'] : mediator.activeWorkspace === HEALTH_ADVISOR_WORKSPACE_ID ? ['admin', 'mda_editor', 'mda_mar', 'mda_mab'] : appUtils.isActiveWorkspaceOrFeatureSwitchOn(MDG_WORKSPACE, 'mdgDocSecFeatures') ? ['admin', 'researcher', 'mdg_editor_in_chief', 'mdg_panel_reviewer', 'no_access'] : ['admin', 'researcher', 'no_access'];
  return _.map(accessRights, function(value) {
    return {
      value: value,
      text: $.t("team:member_access_rights." + value + ".label"),
      annotation: getAccessRightsDescription(value)
    };
  });
};

isCurrentlyLogged = function(member) {
  return mediator.user.get('email') === member.get('email');
};

isCurrentUserAdmin = function() {
  return _.contains(mediator.user.getAccessRights(), 'admin');
};

notMemberOfOrganization = function(member) {
  if (mediator.activeWorkspace === 'personal') {
    return false;
  }
  return !alt.stores.OrganizationsStore.getOrganizationMembers().some(function(m) {
    return m.get('email') === member.get('email');
  });
};

isMemberDuplicated = function(memberEmail) {
  var hasDuplicates, members;
  members = alt.stores.MembersStore.getState().get('members');
  hasDuplicates = members.filter(function(m) {
    return m.get('email') === memberEmail;
  });
  return hasDuplicates.size > 1;
};

SORTABLE_FIELDS = ['name', 'access-rights', 'panel-member'];

REQUIRED_FIELDS = ['email', 'accessRights', 'lastName'];

MEMBERS_DB_VIEW = {
  map: function(doc) {
    if (doc.docType === 'projectMember') {
      return emit(doc.lastName, doc);
    }
  }
};

sortDirectionValue = function(cellName, sortBy) {
  var direction, key;
  key = sortBy.get('key', "");
  direction = sortBy.get('direction', "");
  if (cellName !== key) {
    return "";
  }
  switch (direction) {
    case 1:
      return "asc";
    case -1:
      return "desc";
    default:
      return "";
  }
};

reduceMembersToValueList = function(members, key) {
  return members.reduce(function(acc, member) {
    return acc.push(member.get(key));
  }, Immutable.List());
};

getMembersByLoginMapOfCurrentProject = function() {
  return mediator.colls.teamMembers.reduce(function(acc, member) {
    var gdtLogin;
    gdtLogin = member.get('gdtLogin');
    if (!gdtLogin) {
      return acc;
    }
    return acc.set(gdtLogin, Immutable.fromJS(member.toJSON()));
  }, Immutable.Map());
};

getMembersLoginToNameMapOfCurrentProject = function() {
  return getMembersByLoginMapOfCurrentProject().map(function(member) {
    return getMemberFullName(member);
  });
};

getMemberFullName = function(member) {
  return [member.get('givenNames'), member.get('lastName')].join(' ').trim();
};

module.exports = {
  MEMBERS_DB_VIEW: MEMBERS_DB_VIEW,
  SORTABLE_FIELDS: SORTABLE_FIELDS,
  REQUIRED_FIELDS: REQUIRED_FIELDS,
  checkIfEmailUsed: checkIfEmailUsed,
  createMember: createMember,
  filterAdminsAndResearchers: filterAdminsAndResearchers,
  getAccessRightsLabel: getAccessRightsLabel,
  getAccessRightsList: getAccessRightsList,
  getAttributesFromEmail: getAttributesFromEmail,
  getInitials: getInitials,
  getMemberAccessRights: getMemberAccessRights,
  getMemberPendingAccessRights: getMemberPendingAccessRights,
  getMemberFullName: getMemberFullName,
  getMembersLoginToNameMapOfCurrentProject: getMembersLoginToNameMapOfCurrentProject,
  getMembersByLoginMapOfCurrentProject: getMembersByLoginMapOfCurrentProject,
  getMembersWithPendingInvitationsCount: getMembersWithPendingInvitationsCount,
  isCurrentlyLogged: isCurrentlyLogged,
  isCurrentUserAdmin: isCurrentUserAdmin,
  isMemberDuplicated: isMemberDuplicated,
  isReadOnly: isReadOnly,
  notMemberOfOrganization: notMemberOfOrganization,
  parseTeamMembers: parseTeamMembers,
  reduceMembersToValueList: reduceMembersToValueList,
  sortDirectionValue: sortDirectionValue,
  validateMemberAgainstMembersList: validateMemberAgainstMembersList,
  validateMemberModel: validateMemberModel
};
