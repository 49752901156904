'use strict';
// https://github.com/tc39/proposal-upsert
require('../modules/esnext.map.emplace');
// TODO: remove from `core-js@4`
require('../modules/esnext.map.update-or-insert');
// TODO: remove from `core-js@4`
require('../modules/esnext.map.upsert');
require('../modules/esnext.weak-map.emplace');
// TODO: remove from `core-js@4`
require('../modules/esnext.weak-map.upsert');
