module.exports = {
  QUESTION: 'question',
  MDG_QUESTION: 'mdgQuestion',
  PROGNOSTIC_QUESTION: 'prognosticQuestion',
  OVERARCHING_QUESTION: 'overarchingQuestion',
  MDG_SEARCH_STRATEGY: 'mdgSearchStrategy',
  MDG_TOPIC: 'mdgTopic',
  DOCUMENT_SECTIONS_V2_CHAPTER_TYPE: 'document_sections_chapter',
  DOCUMENT_SECTIONS_V2_SECTION_TYPE: 'document_sections_section',
  QUALITY_INDICATOR_TYPE: 'qualityIndicator',
  MDA_TABLE: 'mdaTable',
  MDA_TOPIC: 'mdaTopic',
  QUESTION_STATUSES_DOC_TYPE: 'questionStatus',
  TASK: 'event'
};
