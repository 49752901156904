PanelVoiceEtdVotingAction = require 'actions/panel_voice_etd_actions'
IconButton = require 'components/common/icon_button'
VoteOption =  require 'components/etd/panel_voice_etd/vote_option'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
{
  VOTING_RESULTS_VIEW_TYPES
  getVotingOptionLabel
  OVERARCHING_V2_TEMPLATES
} = require 'lib/etd_helper'
{ string, instanceOf, number, bool } = PropTypes

PROPOSED_VOTING_OPTIONS = Immutable.fromJS [
  text: $.t 'es:recommendations.table.agree'
  value: 'agree'
,
  text: $.t 'es:recommendations.table.disagree'
  value: 'disagree'
,
  text: $.t 'es:recommendations.table.radio_values.dont_know'
  value: 'dont_know'
]

getVotingOptionsRenderer = ({
  votesCountByValue,
  activeOption,
  onClick,
  resultsViewType,
  membersVotedCount
}) ->

  (option, idx) ->
    text = option.get 'text'
    value = option.get 'value'
    votesCount = votesCountByValue.get(value, 0)
    optionClickHandler = if votesCount > 0
      -> onClick(value)

    <VoteOption
      isActive={value is activeOption}
      key={idx}
      membersVotedCount={membersVotedCount}
      onClick={optionClickHandler}
      text={text}
      votesCount={votesCount}
      resultsViewType={resultsViewType}
    />

JudgementPanelVoiceDetails = createReactClass
  displayName: 'JudgementPanelVoiceDetails'

  propTypes:
    activeOption: string
    etdPart: string.isRequired
    isProposedVoting: bool.isRequired
    membersVotedCount: number.isRequired
    membersVotingCount: number.isRequired
    overarching: bool.isRequired
    renderMode: string.isRequired
    resultsViewType: string.isRequired
    sectionId: string.isRequired
    votedEtdData: instanceOf(Immutable.Map)
    votingResultsByOption: instanceOf(Immutable.Map)
    votingStatus: string.isRequired

  mixins: [
    CustomRenderMixin
    Translation('es:recommendations.table')
  ]

  isProposed: ->
    { isProposedVoting, votedEtdData, etdPart, overarching, templateId } = @props

    if etdPart is 'conclusions'
      isProposedVoting
    else
      ###
        When you send proposed voting but you haven't check judgment it will be
        treated like not proposed, additionally it will be always treated as proposed when it has
        rating options and it is overarching
      ###
      (isProposedVoting and votedEtdData.get 'selectedOption') or (overarching and
        (votedEtdData.has('ratingOptions') or votedEtdData.has('rating'))) or
        templateId in OVERARCHING_V2_TEMPLATES



  _getVotingOptionRenderer: ->
    { votingResultsByOption, membersVotedCount, activeOption, resultsViewType } = @props
    votesCountByValue = votingResultsByOption.map (results) -> results.size

    getVotingOptionsRenderer {
      membersVotedCount,
      votesCountByValue,
      activeOption,
      resultsViewType,
      onClick: @updateActiveOption
    }

  updateActiveOption: (newVal) ->
    # click on the same option again deselects the option
    newActiveOption = if @props.activeOption is newVal then null else newVal

    PanelVoiceEtdVotingAction.setSectionActiveJudgmentOption
      option: newActiveOption
      sectionId: @props.sectionId

  onVoteDataClick: ->
    @updateActiveOption null

  toggleResultsViewType: (type) ->
    PanelVoiceEtdVotingAction.setSectionResultsViewType { type, sectionId: @props.sectionId }

  renderResultsViewTypeControls: ->
    { etdPart, isProposedVoting, votingStatus, resultsViewType } = @props
    isConclusionsSectionWithOpenVoting = etdPart is 'conclusions' and not isProposedVoting

    <div className='results-view-type-controls'>
      {VOTING_RESULTS_VIEW_TYPES.map (type) =>
        <IconButton
          className={classNames inactive: resultsViewType isnt type }
          disabled={isConclusionsSectionWithOpenVoting or votingStatus is 'test'}
          iconName={type}
          key={type}
          onClick={=> @toggleResultsViewType type}
        />
      }
    </div>

  renderVotesSummary: ->
    { votedEtdData, votingStatus } = @props
    # no votes summary for test rounds as users cannot vote on options in test rounds
    return null if votingStatus is 'test'

    optionsMapper = @_getVotingOptionRenderer()
    if @isProposed()
      <div className='votes-summary'>
        {PROPOSED_VOTING_OPTIONS.map(optionsMapper).toList()}
      </div>
    else
      votedOptions = votedEtdData.get 'options'
      votedAdditionalOptions = votedEtdData.get 'additionalOptions'
      return null unless votedOptions or votedAdditionalOptions

      <div className='votes-summary'>
        {votedOptions.map(optionsMapper).toList()}
        {if votedAdditionalOptions
          <div>
            <hr />
            {votedAdditionalOptions.map(optionsMapper).toList()}
          </div>
        }
      </div>

  render: ->
    {
      votingStatus
      votedEtdData
      membersVotedCount: votedCount
      membersVotingCount: votingCount
      renderMode
      etdPart
    } = @props

    draftJudgement = votedEtdData.get 'selectedOption'
    isTestRound = votingStatus is 'test'
    votesCountLabel = if isTestRound then 'test_comments_stats' else 'votes_stats'

    <div className='judgement-details'>
      <div className='voted-data' onClick={@onVoteDataClick}>
        <div>
          {@i18n votesCountLabel, { votedCount, votingCount }}
        </div>
      </div>
      <div className="voted-data__options">
        <div className='draft-judgement'>
          {if @isProposed() and not isTestRound
            <span>
              {@i18n 'draft_judgement'}: {' '}
              <span className="bold">
                {getVotingOptionLabel votedEtdData, draftJudgement, etdPart}
              </span>
            </span>
          }
        </div>
        {if renderMode isnt 'printout'
          @renderResultsViewTypeControls()
        }
      </div>
      {@renderVotesSummary()}
    </div>

module.exports = JudgementPanelVoiceDetails
