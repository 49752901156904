var OUTCOME_SCALE_TYPES, QUALITY_MODIFIERS, QualityScore, array, boolean, calculated, docShape, extendedCheck, extendedParse, extraChecks, initial, mediator, number, numberChecks, object, oneOf, optional, optionalBoolean, optionalFewerMore, optionalNumber, optionalString, outcomeShape, setOrdinalScaleIfEmptyString, shape, string, updateImportanceLabel, updateOutcomeQuality, _ref, _ref1, _ref2;

shape = require('lib/db_docs/field_types/shape');

docShape = require('lib/db_docs/field_types/doc_shape');

oneOf = require('lib/db_docs/field_types/one_of');

_ref = require('lib/db_docs/field_types/built_in_types'), array = _ref.array, string = _ref.string, boolean = _ref.boolean, number = _ref.number, extraChecks = _ref.extraChecks, object = _ref.object;

_ref1 = require('lib/db_docs/field_types/type_decorators'), extendedCheck = _ref1.extendedCheck, initial = _ref1.initial, extendedParse = _ref1.extendedParse;

OUTCOME_SCALE_TYPES = require('lib/outcomes_helper').OUTCOME_SCALE_TYPES;

_ref2 = shape.typeDecorators, optional = _ref2.optional, calculated = _ref2.calculated;

numberChecks = extraChecks.number;

mediator = require('mediator');

setOrdinalScaleIfEmptyString = R.ifElse(R.isEmpty, R.always(OUTCOME_SCALE_TYPES[0]), R.identity);

QualityScore = shape({
  name: string,
  label: string,
  score: number
});

QUALITY_MODIFIERS = ['riskOfBias', 'inconsistency', 'indirectness', 'imprecision', 'publicationBias', 'largeEffect', 'plausibleConfounding', 'doseResponseGradient'];

updateOutcomeQuality = function(outcomeData) {
  var baseScore, score, _ref3, _ref4;
  baseScore = (_ref3 = outcomeData.designStudies) != null ? _ref3.baseScore : void 0;
  if (baseScore && !Boolean((_ref4 = mediator.project) != null ? _ref4.get('manualQuality') : void 0)) {
    score = QUALITY_MODIFIERS.reduce(function(acc, prop) {
      if (outcomeData[prop]) {
        return acc += outcomeData[prop].score;
      }
    }, baseScore);
    if (isNaN(score)) {
      return null;
    } else {
      return R.clamp(1, 4, score);
    }
  } else {
    return null;
  }
};

updateImportanceLabel = function(_arg) {
  var importance, importanceLabel;
  importance = _arg.importance;
  if (importance != null) {
    importanceLabel = importance > 6 ? $.t('es:outcome.critical') : importance > 3 ? $.t('es:outcome.important') : $.t('es:outcome.not_important');
    return importanceLabel.toUpperCase();
  } else {
    return '';
  }
};

optionalNumber = optional(number);

optionalString = optional(string);

optionalBoolean = optional(boolean);

optionalFewerMore = optional(oneOf(['', 'fewer', 'more']));

outcomeShape = docShape({
  name: string,
  shortName: optionalString,
  noOfStudies: number,
  noOfPatients: optional(extendedCheck(numberChecks.min(0))(number)),
  designStudies: optional(shape({
    name: string,
    label: string,
    baseScore: number,
    values: optional(array),
    value: optionalString,
    fullName: optionalString
  })),
  riskOfBias: optional(QualityScore),
  inconsistency: optional(QualityScore),
  indirectness: optional(QualityScore),
  imprecision: optional(QualityScore),
  publicationBias: optional(QualityScore),
  largeEffect: optional(QualityScore),
  plausibleConfounding: optional(QualityScore),
  doseResponseGradient: optional(QualityScore),
  eventType: optionalString,
  nonEvent: optionalString,
  effectMeasure: string,
  originalEffectMeasure: optionalString,
  effectRate: string,
  effectDenominator: string,
  effectOtherDenominator: string,
  effectDuration: string,
  effectOtherDuration: string,
  effectCount: string,
  effectTotal: string,
  interventionCount: optionalNumber,
  interventionTotal: optionalNumber,
  controlCount: optionalNumber,
  controlTotal: optionalNumber,
  calculatedControl: initial(true)(boolean),
  lowControl: boolean,
  moderateControl: boolean,
  highControl: boolean,
  lowControlRisk: number,
  moderateControlRisk: number,
  highControlRisk: number,
  lowControlLabel: initial($.t('es:outcome.low_control_label'))(string),
  moderateControlLabel: initial($.t('es:outcome.moderate_control_label'))(string),
  highControlLabel: initial($.t('es:outcome.high_control_label'))(string),
  effectSize: optionalNumber,
  originalEffectSize: optionalNumber,
  confidenceIntervalFrom: optionalNumber,
  originalConfidenceIntervalFrom: optionalNumber,
  confidenceIntervalTo: optionalNumber,
  originalConfidenceIntervalTo: optionalNumber,
  followUpLength: number,
  followUpLengthTo: number,
  absDenominator: optionalNumber,
  perPatients: initial(1000)(number),
  perPatientsOther: optionalNumber,
  perTimeFrame: initial('years')(string),
  perTimeFrameOther: optionalString,
  absEffectSize: optionalNumber,
  absEffectFrom: optionalNumber,
  absEffectTo: optionalNumber,
  absEffectAutoCalc: initial(true)(boolean),
  absEffectSizeLow: optionalNumber,
  absEffectFromLow: optionalNumber,
  absEffectToLow: optionalNumber,
  absEffectAutoCalcLow: initial(true)(boolean),
  absEffectSizeModerate: optionalNumber,
  absEffectFromModerate: optionalNumber,
  absEffectToModerate: optionalNumber,
  absEffectAutoCalcModerate: initial(true)(boolean),
  absEffectSizeHigh: optionalNumber,
  absEffectFromHigh: optionalNumber,
  absEffectToHigh: optionalNumber,
  absEffectAutoCalcHigh: initial(true)(boolean),
  comments: string,
  populationDirectnessDesc: string,
  interventionDirectnessDesc: string,
  comparisonDirectnessDesc: string,
  direct_comparisonDirectnessDesc: string,
  outcomeDirectnessDesc: string,
  populationDirectnessRadio: string,
  interventionDirectnessRadio: string,
  comparisonDirectnessRadio: string,
  direct_comparisonDirectnessRadio: string,
  outcomeDirectnessRadio: string,
  withOutcome: number,
  withoutOutcome: number,
  showFinalScoreInControls: initial(true)(boolean),
  importance: optional(extendedCheck(numberChecks.min(1), numberChecks.max(9))(number)),
  importanceLabel: calculated(updateImportanceLabel)(string),
  quality: calculated(updateOutcomeQuality)(optionalNumber),
  orderNumber: number,
  forceTypeSelection: optionalBoolean,
  measuredWith: optionalString,
  othersFollowUpTimeUnit: optionalString,
  othersFollowUpToTimeUnit: optionalString,
  scaleFrom: optionalString,
  scaleTo: optionalString,
  followUpType: optionalString,
  timingOfExposure: optionalString,
  followUpTimeUnit: optionalString,
  followUpToTimeUnit: optionalString,
  metaType: optionalString,
  calculatedControlValue: optionalString,
  fewerMore: optionalFewerMore,
  fewerMoreFrom: optionalFewerMore,
  fewerMoreTo: optionalFewerMore,
  unitsType: optionalString,
  type: oneOf(['dich', 'narrative', 'cont', 'time_to_event']),
  footnotes: object,
  references: object,
  studies: optional(object),
  robinsIEnabled: optionalBoolean,
  'automatic-narrative-effect-size': optionalString,
  'plain_language_summary': optionalString,
  changeScoreInControls: optionalString,
  changeScoreInControlsValue: optionalString,
  units: optionalString,
  narrativeDesc: optionalString,
  otherMeasure: optionalString,
  fewerMoreLow: optionalFewerMore,
  fewerMoreFromLow: optionalFewerMore,
  fewerMoreToLow: optionalFewerMore,
  fewerMoreModerate: optionalFewerMore,
  fewerMoreFromModerate: optionalFewerMore,
  fewerMoreToModerate: optionalFewerMore,
  fewerMoreHigh: optionalFewerMore,
  fewerMoreFromHigh: optionalFewerMore,
  fewerMoreToHigh: optionalFewerMore,
  source: optionalString,
  rob_acrossStudies: optional(oneOf(['low', 'unclear', 'high'])),
  rob_limitations: optional(oneOf(['small', 'large'])),
  scaleType: optional(extendedParse(setOrdinalScaleIfEmptyString)(oneOf(OUTCOME_SCALE_TYPES))),
  conventionalScaleLowerLimit: optionalNumber,
  conventionalScaleUpperLimit: optionalNumber,
  complicationsPrevalencesDesc: optionalString,
  inconclusivePrevalencesDesc: optionalString,
  utilityValue: optionalNumber,
  thresholdT1: optionalNumber,
  thresholdT2: optionalNumber,
  thresholdT3: optionalNumber,
  interpretationOfResults: optionalString
});

module.exports = outcomeShape;
