var implementationConsiderationsChecked, templatesDefinitions, _ref,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

implementationConsiderationsChecked = __indexOf.call((_ref = window.gdt.serverSwitches) != null ? _ref : [], 'minsalFeatures') >= 0;

templatesDefinitions = function() {
  return [
    {
      id: 'tx-crIp',
      name: 'Clinical recommendation - Individual perspective',
      question: [
        {
          id: 'population',
          required: true
        }, {
          id: 'intervention',
          required: true
        }, {
          id: 'comparison',
          checked: true
        }, {
          id: 'mainOutcomes',
          checked: true
        }, {
          id: 'setting',
          checked: true
        }, {
          id: 'perspective',
          checked: true
        }, {
          id: 'background',
          checked: true
        }, {
          id: 'coi',
          checked: true
        }
      ],
      assessment: [
        {
          id: 'problem',
          checked: true
        }, {
          id: 'desirableEffects',
          checked: true
        }, {
          id: 'undesirableEffects',
          checked: true
        }, {
          id: 'certaintyOfEvidence',
          checked: true
        }, {
          id: 'values',
          checked: true
        }, {
          id: 'balanceOfEffects',
          checked: true
        }, {
          id: 'acceptability',
          checked: true
        }, {
          id: 'feasibility',
          checked: true
        }, {
          id: 'additionalSection',
          checked: false
        }
      ],
      conclusions: [
        {
          id: 'recommendationTypeIntervention',
          required: true
        }, {
          id: 'recommendation',
          required: true
        }, {
          id: 'justification',
          required: true
        }, {
          id: 'subgroupConsiderations',
          checked: true
        }, {
          id: 'implementationConsiderations',
          checked: true
        }, {
          id: 'monitoringAndEvaluation',
          checked: true
        }, {
          id: 'researchPriorities',
          checked: true
        }
      ],
      presentations: [
        {
          id: 'clinicians',
          checked: true,
          sections: [
            {
              id: 'background',
              checked: true
            }, {
              id: 'subgroupConsiderations',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'detailedJustification',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }, {
              id: 'implementationConsiderations',
              checked: implementationConsiderationsChecked
            }
          ]
        }, {
          id: 'patients',
          checked: true,
          sections: [
            {
              id: 'whyThisRecommendation',
              checked: true
            }, {
              id: 'whatItMeansForYou',
              checked: true
            }, {
              id: 'whoIsThisFor',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }, {
          id: 'policymakers',
          checked: true,
          sections: [
            {
              id: 'assessment',
              checked: true
            }, {
              id: 'background',
              checked: true
            }, {
              id: 'decision',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'implementation',
              checked: true
            }, {
              id: 'monitoringAndEvaluation',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }
      ]
    }, {
      id: 'tx-crPp',
      name: 'Clinical recommendation - Population perspective',
      question: [
        {
          id: 'population',
          required: true
        }, {
          id: 'intervention',
          required: true
        }, {
          id: 'comparison',
          checked: true
        }, {
          id: 'mainOutcomes',
          checked: true
        }, {
          id: 'setting',
          checked: true
        }, {
          id: 'perspective',
          checked: true
        }, {
          id: 'background',
          checked: true
        }, {
          id: 'coi',
          checked: true
        }
      ],
      assessment: [
        {
          id: 'problem',
          checked: true
        }, {
          id: 'desirableEffects',
          checked: true
        }, {
          id: 'undesirableEffects',
          checked: true
        }, {
          id: 'certaintyOfEvidence',
          checked: true
        }, {
          id: 'values',
          checked: true
        }, {
          id: 'balanceOfEffects',
          checked: true
        }, {
          id: 'resourcesRequired',
          checked: true
        }, {
          id: 'certaintyOfEvidenceOfRequiredResources',
          checked: true
        }, {
          id: 'costEffectiveness',
          checked: true
        }, {
          id: 'equity',
          checked: true
        }, {
          id: 'acceptability',
          checked: true
        }, {
          id: 'feasibility',
          checked: true
        }, {
          id: 'additionalSection',
          checked: false
        }
      ],
      conclusions: [
        {
          id: 'recommendationTypeIntervention',
          required: true
        }, {
          id: 'recommendation',
          required: true
        }, {
          id: 'justification',
          required: true
        }, {
          id: 'subgroupConsiderations',
          checked: true
        }, {
          id: 'implementationConsiderations',
          checked: true
        }, {
          id: 'monitoringAndEvaluation',
          checked: true
        }, {
          id: 'researchPriorities',
          checked: true
        }
      ],
      presentations: [
        {
          id: 'clinicians',
          checked: true,
          sections: [
            {
              id: 'background',
              checked: true
            }, {
              id: 'subgroupConsiderations',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'detailedJustification',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }, {
              id: 'implementationConsiderations',
              checked: implementationConsiderationsChecked
            }
          ]
        }, {
          id: 'patients',
          checked: true,
          sections: [
            {
              id: 'whyThisRecommendation',
              checked: true
            }, {
              id: 'whatItMeansForYou',
              checked: true
            }, {
              id: 'whoIsThisFor',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }, {
          id: 'policymakers',
          checked: true,
          sections: [
            {
              id: 'assessment',
              checked: true
            }, {
              id: 'background',
              checked: true
            }, {
              id: 'decision',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'implementation',
              checked: true
            }, {
              id: 'monitoringAndEvaluation',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }
      ]
    }, {
      id: 'tx-cd',
      name: 'Coverage Decision',
      question: [
        {
          id: 'population',
          required: true
        }, {
          id: 'intervention',
          required: true
        }, {
          id: 'comparison',
          checked: true
        }, {
          id: 'mainOutcomes',
          checked: true
        }, {
          id: 'setting',
          checked: true
        }, {
          id: 'perspective',
          checked: true
        }, {
          id: 'background',
          checked: true
        }, {
          id: 'coi',
          checked: true
        }
      ],
      assessment: [
        {
          id: 'problem',
          checked: true
        }, {
          id: 'desirableEffects',
          checked: true
        }, {
          id: 'undesirableEffects',
          checked: true
        }, {
          id: 'certaintyOfEvidence',
          checked: true
        }, {
          id: 'values',
          checked: true
        }, {
          id: 'balanceOfEffects',
          checked: true
        }, {
          id: 'resourcesRequired',
          checked: true
        }, {
          id: 'certaintyOfEvidenceOfRequiredResources',
          checked: true
        }, {
          id: 'costEffectiveness',
          checked: true
        }, {
          id: 'equity',
          checked: true
        }, {
          id: 'acceptability',
          checked: true
        }, {
          id: 'feasibility',
          checked: true
        }, {
          id: 'additionalSection',
          checked: false
        }
      ],
      conclusions: [
        {
          id: 'decisionTypeCoverage',
          required: true
        }, {
          id: 'decision',
          required: true
        }, {
          id: 'justification',
          required: true
        }, {
          id: 'restrictions',
          checked: true
        }, {
          id: 'implementationConsiderations',
          checked: true
        }, {
          id: 'monitoringAndEvaluation',
          checked: true
        }, {
          id: 'researchPriorities',
          checked: true
        }
      ],
      presentations: [
        {
          id: 'clinicians',
          checked: true,
          sections: [
            {
              id: 'background',
              checked: true
            }, {
              id: 'detailedJustification',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }, {
              id: 'implementationConsiderations',
              checked: implementationConsiderationsChecked
            }
          ]
        }, {
          id: 'patients',
          checked: true,
          sections: [
            {
              id: 'whyThisRecommendation',
              checked: true
            }, {
              id: 'whatItMeansForYou',
              checked: true
            }, {
              id: 'whoIsThisFor',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }, {
          id: 'policymakers',
          checked: true,
          sections: [
            {
              id: 'assessment',
              checked: true
            }, {
              id: 'background',
              checked: true
            }, {
              id: 'decision',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'implementation',
              checked: true
            }, {
              id: 'monitoringAndEvaluation',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }
      ]
    }, {
      id: 'tx-hsPhr',
      name: 'Health system and public health recommendation',
      question: [
        {
          id: 'problem',
          required: true
        }, {
          id: 'option',
          required: true
        }, {
          id: 'comparison',
          checked: true
        }, {
          id: 'mainOutcomes',
          checked: true
        }, {
          id: 'setting',
          checked: true
        }, {
          id: 'perspective',
          checked: true
        }, {
          id: 'background',
          checked: true
        }, {
          id: 'coi',
          checked: true
        }
      ],
      assessment: [
        {
          id: 'problem',
          checked: true
        }, {
          id: 'desirableEffects',
          checked: true
        }, {
          id: 'undesirableEffects',
          checked: true
        }, {
          id: 'certaintyOfEvidence',
          checked: true
        }, {
          id: 'values',
          checked: true
        }, {
          id: 'balanceOfEffects',
          checked: true
        }, {
          id: 'resourcesRequired',
          checked: true
        }, {
          id: 'certaintyOfEvidenceOfRequiredResources',
          checked: true
        }, {
          id: 'costEffectiveness',
          checked: true
        }, {
          id: 'equity',
          checked: true
        }, {
          id: 'acceptability',
          checked: true
        }, {
          id: 'feasibility',
          checked: true
        }, {
          id: 'additionalSection',
          checked: false
        }
      ],
      conclusions: [
        {
          id: 'recommendationTypeOption',
          required: true
        }, {
          id: 'recommendation',
          required: true
        }, {
          id: 'justification',
          required: true
        }, {
          id: 'subgroupConsiderations',
          checked: true
        }, {
          id: 'implementationConsiderations',
          checked: true
        }, {
          id: 'monitoringAndEvaluation',
          checked: true
        }, {
          id: 'researchPriorities',
          checked: true
        }
      ],
      presentations: [
        {
          id: 'clinicians',
          checked: true,
          sections: [
            {
              id: 'background',
              checked: true
            }, {
              id: 'subgroupConsiderations',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'detailedJustification',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }, {
              id: 'implementationConsiderations',
              checked: implementationConsiderationsChecked
            }
          ]
        }, {
          id: 'patients',
          checked: true,
          sections: [
            {
              id: 'whyThisRecommendation',
              checked: true
            }, {
              id: 'whatItMeansForYou',
              checked: true
            }, {
              id: 'whoIsThisFor',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }, {
          id: 'policymakers',
          checked: true,
          sections: [
            {
              id: 'assessment',
              checked: true
            }, {
              id: 'background',
              checked: true
            }, {
              id: 'decision',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'implementation',
              checked: true
            }, {
              id: 'monitoringAndEvaluation',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }
      ]
    }, {
      id: 'tx-hsPhd',
      name: 'Health system and public health decision',
      question: [
        {
          id: 'problem',
          required: true
        }, {
          id: 'option',
          required: true
        }, {
          id: 'comparison',
          checked: true
        }, {
          id: 'mainOutcomes',
          checked: true
        }, {
          id: 'setting',
          checked: true
        }, {
          id: 'perspective',
          checked: true
        }, {
          id: 'background',
          checked: true
        }, {
          id: 'coi',
          checked: true
        }
      ],
      assessment: [
        {
          id: 'problem',
          checked: true
        }, {
          id: 'desirableEffects',
          checked: true
        }, {
          id: 'undesirableEffects',
          checked: true
        }, {
          id: 'certaintyOfEvidence',
          checked: true
        }, {
          id: 'values',
          checked: true
        }, {
          id: 'balanceOfEffects',
          checked: true
        }, {
          id: 'resourcesRequired',
          checked: true
        }, {
          id: 'certaintyOfEvidenceOfRequiredResources',
          checked: true
        }, {
          id: 'costEffectiveness',
          checked: true
        }, {
          id: 'equity',
          checked: true
        }, {
          id: 'acceptability',
          checked: true
        }, {
          id: 'feasibility',
          checked: true
        }, {
          id: 'additionalSection',
          checked: false
        }
      ],
      conclusions: [
        {
          id: 'decisionTypeImplementation',
          required: true
        }, {
          id: 'decision',
          required: true
        }, {
          id: 'justification',
          required: true
        }, {
          id: 'subgroupConsiderations',
          checked: true
        }, {
          id: 'implementationConsiderations',
          checked: true
        }, {
          id: 'monitoringAndEvaluation',
          checked: true
        }, {
          id: 'researchPriorities',
          checked: true
        }
      ],
      presentations: [
        {
          id: 'clinicians',
          checked: true,
          sections: [
            {
              id: 'background',
              checked: true
            }, {
              id: 'subgroupConsiderations',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'detailedJustification',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }, {
              id: 'implementationConsiderations',
              checked: implementationConsiderationsChecked
            }
          ]
        }, {
          id: 'patients',
          checked: true,
          sections: [
            {
              id: 'whyThisRecommendation',
              checked: true
            }, {
              id: 'whatItMeansForYou',
              checked: true
            }, {
              id: 'whoIsThisFor',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }, {
          id: 'policymakers',
          checked: true,
          sections: [
            {
              id: 'assessment',
              checked: true
            }, {
              id: 'background',
              checked: true
            }, {
              id: 'decision',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'implementation',
              checked: true
            }, {
              id: 'monitoringAndEvaluation',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }
      ]
    }, {
      id: 'tx-eohr',
      name: 'Environmental or occupational health recommendation',
      question: [
        {
          id: 'problem',
          required: true
        }, {
          id: 'option',
          required: true
        }, {
          id: 'comparison',
          checked: true
        }, {
          id: 'mainOutcomes',
          checked: true
        }, {
          id: 'setting',
          checked: true
        }, {
          id: 'perspective',
          checked: true
        }, {
          id: 'background',
          checked: true
        }, {
          id: 'coi',
          checked: true
        }
      ],
      assessment: [
        {
          id: 'problemEOHR',
          checked: true
        }, {
          id: 'desirableEffectsEOHR',
          checked: true
        }, {
          id: 'undesirableEffectsEOHR',
          checked: true
        }, {
          id: 'certaintyOfEvidenceEOHR',
          checked: true
        }, {
          id: 'valuesEOHR',
          checked: true
        }, {
          id: 'balanceOfEffectsEOHR',
          checked: true
        }, {
          id: 'resourcesRequiredEOHR',
          checked: true
        }, {
          id: 'certaintyOfEvidenceOfRequiredResourcesEOHR',
          checked: true
        }, {
          id: 'costEffectivenessEOHR',
          checked: true
        }, {
          id: 'equityEOHR',
          checked: true
        }, {
          id: 'acceptabilityEOHR',
          checked: true
        }, {
          id: 'feasibilityEOHR',
          checked: true
        }, {
          id: 'additionalSection',
          checked: false
        }
      ],
      conclusions: [
        {
          id: 'recommendationTypeOption',
          required: true
        }, {
          id: 'recommendation',
          required: true
        }, {
          id: 'justification',
          required: true
        }, {
          id: 'subgroupConsiderations',
          checked: true
        }, {
          id: 'implementationConsiderations',
          checked: true
        }, {
          id: 'monitoringAndEvaluation',
          checked: true
        }, {
          id: 'researchPriorities',
          checked: true
        }
      ],
      presentations: [
        {
          id: 'clinicians',
          checked: true,
          sections: [
            {
              id: 'background',
              checked: true
            }, {
              id: 'subgroupConsiderations',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'detailedJustification',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }, {
              id: 'implementationConsiderations',
              checked: implementationConsiderationsChecked
            }
          ]
        }, {
          id: 'patients',
          checked: true,
          sections: [
            {
              id: 'whyThisRecommendation',
              checked: true
            }, {
              id: 'whatItMeansForYou',
              checked: true
            }, {
              id: 'whoIsThisFor',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }, {
          id: 'policymakers',
          checked: true,
          sections: [
            {
              id: 'assessment',
              checked: true
            }, {
              id: 'background',
              checked: true
            }, {
              id: 'decision',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'implementation',
              checked: true
            }, {
              id: 'monitoringAndEvaluation',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }
      ]
    }, {
      id: 'tx-whoIntegrate',
      name: 'WHO-INTEGRATE (complex interventions)',
      question: [
        {
          id: 'population',
          required: true
        }, {
          id: 'intervention',
          required: true
        }, {
          id: 'comparison',
          checked: true
        }, {
          id: 'mainOutcomes',
          checked: true
        }, {
          id: 'setting',
          checked: true
        }, {
          id: 'perspective',
          checked: true
        }, {
          id: 'background',
          checked: true
        }, {
          id: 'coi',
          checked: true
        }
      ],
      assessment: [
        {
          id: 'balanceOfHealthBenefitsAndHarms',
          checked: true
        }, {
          id: 'humanRights',
          checked: true
        }, {
          id: 'socioCulturalAcceptability',
          checked: true
        }, {
          id: 'healthEquityEqualityAndNonDiscrimination',
          checked: true
        }, {
          id: 'societalImplications',
          checked: true
        }, {
          id: 'financialAndEconomicConsiderations',
          checked: true
        }, {
          id: 'feasibilityAndHealthSystemConsiderations',
          checked: true
        }, {
          id: 'qualityOfEvidence',
          checked: true
        }, {
          id: 'additionalSection',
          checked: false
        }
      ],
      conclusions: [
        {
          id: 'recommendationTypeIntervention',
          required: true
        }, {
          id: 'recommendation',
          required: true
        }, {
          id: 'justification',
          required: true
        }, {
          id: 'subgroupConsiderations',
          checked: true
        }, {
          id: 'contextAndSystemConsiderations',
          checked: true
        }, {
          id: 'implementationConsiderations',
          checked: true
        }, {
          id: 'monitoringAndEvaluation',
          checked: true
        }, {
          id: 'researchPriorities',
          checked: true
        }
      ],
      presentations: [
        {
          id: 'clinicians',
          checked: true,
          sections: [
            {
              id: 'background',
              checked: true
            }, {
              id: 'subgroupConsiderations',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'detailedJustification',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }, {
              id: 'implementationConsiderations',
              checked: implementationConsiderationsChecked
            }
          ]
        }, {
          id: 'patients',
          checked: true,
          sections: [
            {
              id: 'whyThisRecommendation',
              checked: true
            }, {
              id: 'whatItMeansForYou',
              checked: true
            }, {
              id: 'whoIsThisFor',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }, {
          id: 'policymakers',
          checked: true,
          sections: [
            {
              id: 'assessment',
              checked: true
            }, {
              id: 'background',
              checked: true
            }, {
              id: 'decision',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'implementation',
              checked: true
            }, {
              id: 'monitoringAndEvaluation',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }
      ]
    }, {
      id: 'dx-testCrIp',
      name: 'Tests - Clinical recommendation - Individual Perspective',
      question: [
        {
          id: 'population',
          required: true
        }, {
          id: 'intervention',
          required: true
        }, {
          id: 'comparison',
          checked: true
        }, {
          id: 'purpose',
          checked: true
        }, {
          id: 'role',
          checked: true
        }, {
          id: 'linkedTreatments',
          checked: true
        }, {
          id: 'anticipatedOutcomes',
          checked: true
        }, {
          id: 'setting',
          checked: true
        }, {
          id: 'perspective',
          checked: true
        }, {
          id: 'background',
          checked: true
        }, {
          id: 'subgroups',
          checked: true
        }, {
          id: 'coi',
          checked: true
        }
      ],
      assessment: [
        {
          id: 'problem',
          checked: true
        }, {
          id: 'testAccuracy',
          checked: true
        }, {
          id: 'desirableEffects',
          checked: true
        }, {
          id: 'undesirableEffects',
          checked: true
        }, {
          id: 'certaintyOfEvidenceOfTestAccuracy',
          checked: true
        }, {
          id: 'certaintyOfEvidenceOfTestEffects',
          checked: true
        }, {
          id: 'certaintyOfEvidenceOfManagementEffects',
          checked: true
        }, {
          id: 'certaintyOfEvidenceOfTestResult',
          checked: true
        }, {
          id: 'certaintyOfEffects',
          checked: true
        }, {
          id: 'values',
          checked: true
        }, {
          id: 'balanceOfEffects',
          checked: true
        }, {
          id: 'resourcesRequired',
          checked: true
        }, {
          id: 'certaintyOfEvidenceOfRequiredResources',
          checked: true
        }, {
          id: 'costEffectiveness',
          checked: true
        }, {
          id: 'equity',
          checked: true
        }, {
          id: 'acceptability',
          checked: true
        }, {
          id: 'feasibility',
          checked: true
        }, {
          id: 'additionalSection',
          checked: false
        }
      ],
      conclusions: [
        {
          id: 'recommendationTypeIntervention',
          required: true
        }, {
          id: 'recommendation',
          required: true
        }, {
          id: 'justification',
          required: true
        }, {
          id: 'subgroupConsiderations',
          checked: true
        }, {
          id: 'implementationConsiderations',
          checked: true
        }, {
          id: 'monitoringAndEvaluation',
          checked: true
        }, {
          id: 'researchPriorities',
          checked: true
        }
      ],
      presentations: [
        {
          id: 'clinicians',
          checked: true,
          sections: [
            {
              id: 'background',
              checked: true
            }, {
              id: 'subgroupConsiderations',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'detailedJustification',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }, {
              id: 'implementationConsiderations',
              checked: implementationConsiderationsChecked
            }
          ]
        }, {
          id: 'patients',
          checked: true,
          sections: [
            {
              id: 'whyThisRecommendation',
              checked: true
            }, {
              id: 'whatItMeansForYou',
              checked: true
            }, {
              id: 'whoIsThisFor',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }, {
          id: 'policymakers',
          checked: true,
          sections: [
            {
              id: 'assessment',
              checked: true
            }, {
              id: 'background',
              checked: true
            }, {
              id: 'decision',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'implementation',
              checked: true
            }, {
              id: 'monitoringAndEvaluation',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }
      ]
    }, {
      id: 'dx-testCrPp',
      name: 'Tests - Clinical recommendation - Population Perspective',
      question: [
        {
          id: 'population',
          required: true
        }, {
          id: 'intervention',
          required: true
        }, {
          id: 'comparison',
          checked: true
        }, {
          id: 'purpose',
          checked: true
        }, {
          id: 'role',
          checked: true
        }, {
          id: 'linkedTreatments',
          checked: true
        }, {
          id: 'anticipatedOutcomes',
          checked: true
        }, {
          id: 'setting',
          checked: true
        }, {
          id: 'perspective',
          checked: true
        }, {
          id: 'background',
          checked: true
        }, {
          id: 'subgroups',
          checked: true
        }, {
          id: 'coi',
          checked: true
        }
      ],
      assessment: [
        {
          id: 'problem',
          checked: true
        }, {
          id: 'testAccuracy',
          checked: true
        }, {
          id: 'desirableEffects',
          checked: true
        }, {
          id: 'undesirableEffects',
          checked: true
        }, {
          id: 'certaintyOfEvidenceOfTestAccuracy',
          checked: true
        }, {
          id: 'certaintyOfEvidenceOfTestEffects',
          checked: true
        }, {
          id: 'certaintyOfEvidenceOfManagementEffects',
          checked: true
        }, {
          id: 'certaintyOfEvidenceOfTestResult',
          checked: true
        }, {
          id: 'certaintyOfEffects',
          checked: true
        }, {
          id: 'values',
          checked: true
        }, {
          id: 'balanceOfEffects',
          checked: true
        }, {
          id: 'resourcesRequired',
          checked: true
        }, {
          id: 'certaintyOfEvidenceOfRequiredResources',
          checked: true
        }, {
          id: 'costEffectiveness',
          checked: true
        }, {
          id: 'equity',
          checked: true
        }, {
          id: 'acceptability',
          checked: true
        }, {
          id: 'feasibility',
          checked: true
        }, {
          id: 'additionalSection',
          checked: false
        }
      ],
      conclusions: [
        {
          id: 'recommendationTypeIntervention',
          required: true
        }, {
          id: 'recommendation',
          required: true
        }, {
          id: 'justification',
          required: true
        }, {
          id: 'subgroupConsiderations',
          checked: true
        }, {
          id: 'implementationConsiderations',
          checked: true
        }, {
          id: 'monitoringAndEvaluation',
          checked: true
        }, {
          id: 'researchPriorities',
          checked: true
        }
      ],
      presentations: [
        {
          id: 'clinicians',
          checked: true,
          sections: [
            {
              id: 'background',
              checked: true
            }, {
              id: 'subgroupConsiderations',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'detailedJustification',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }, {
              id: 'implementationConsiderations',
              checked: implementationConsiderationsChecked
            }
          ]
        }, {
          id: 'patients',
          checked: true,
          sections: [
            {
              id: 'whyThisRecommendation',
              checked: true
            }, {
              id: 'whatItMeansForYou',
              checked: true
            }, {
              id: 'whoIsThisFor',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }, {
          id: 'policymakers',
          checked: true,
          sections: [
            {
              id: 'assessment',
              checked: true
            }, {
              id: 'background',
              checked: true
            }, {
              id: 'decision',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'implementation',
              checked: true
            }, {
              id: 'monitoringAndEvaluation',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }
      ]
    }, {
      id: 'dx-testCd',
      name: 'Tests - Coverage decision',
      question: [
        {
          id: 'population',
          required: true
        }, {
          id: 'intervention',
          required: true
        }, {
          id: 'comparison',
          checked: true
        }, {
          id: 'purpose',
          checked: true
        }, {
          id: 'role',
          checked: true
        }, {
          id: 'linkedTreatments',
          checked: true
        }, {
          id: 'anticipatedOutcomes',
          checked: true
        }, {
          id: 'setting',
          checked: true
        }, {
          id: 'perspective',
          checked: true
        }, {
          id: 'background',
          checked: true
        }, {
          id: 'subgroups',
          checked: true
        }, {
          id: 'coi',
          checked: true
        }
      ],
      assessment: [
        {
          id: 'problem',
          checked: true
        }, {
          id: 'testAccuracy',
          checked: true
        }, {
          id: 'desirableEffects',
          checked: true
        }, {
          id: 'undesirableEffects',
          checked: true
        }, {
          id: 'certaintyOfEvidenceOfTestAccuracy',
          checked: true
        }, {
          id: 'certaintyOfEvidenceOfTestEffects',
          checked: true
        }, {
          id: 'certaintyOfEvidenceOfManagementEffects',
          checked: true
        }, {
          id: 'certaintyOfEvidenceOfTestResult',
          checked: true
        }, {
          id: 'certaintyOfEffects',
          checked: true
        }, {
          id: 'values',
          checked: true
        }, {
          id: 'balanceOfEffects',
          checked: true
        }, {
          id: 'resourcesRequired',
          checked: true
        }, {
          id: 'certaintyOfEvidenceOfRequiredResources',
          checked: true
        }, {
          id: 'costEffectiveness',
          checked: true
        }, {
          id: 'equity',
          checked: true
        }, {
          id: 'acceptability',
          checked: true
        }, {
          id: 'feasibility',
          checked: true
        }, {
          id: 'additionalSection',
          checked: false
        }
      ],
      conclusions: [
        {
          id: 'decisionTypeCoverage',
          required: true
        }, {
          id: 'decision',
          required: true
        }, {
          id: 'justification',
          required: true
        }, {
          id: 'subgroupConsiderations',
          checked: true
        }, {
          id: 'implementationConsiderations',
          checked: true
        }, {
          id: 'monitoringAndEvaluation',
          checked: true
        }, {
          id: 'researchPriorities',
          checked: true
        }, {
          id: 'implementationConsiderations',
          checked: true
        }
      ],
      presentations: [
        {
          id: 'clinicians',
          checked: true,
          sections: [
            {
              id: 'background',
              checked: true
            }, {
              id: 'subgroupConsiderations',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'detailedJustification',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }, {
          id: 'patients',
          checked: true,
          sections: [
            {
              id: 'whyThisRecommendation',
              checked: true
            }, {
              id: 'whatItMeansForYou',
              checked: true
            }, {
              id: 'whoIsThisFor',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }, {
          id: 'policymakers',
          checked: true,
          sections: [
            {
              id: 'assessment',
              checked: true
            }, {
              id: 'background',
              checked: true
            }, {
              id: 'decision',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'implementation',
              checked: true
            }, {
              id: 'monitoringAndEvaluation',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }
      ]
    }, {
      id: '_overarchingTx-crPp',
      name: 'Overarching - Clinical recommendation - Population perspective',
      question: [
        {
          id: 'population',
          required: true
        }, {
          id: 'intervention',
          checked: true
        }, {
          id: 'mainOutcomes',
          checked: true
        }, {
          id: 'setting',
          checked: true
        }, {
          id: 'perspective',
          checked: true
        }, {
          id: 'background',
          checked: true
        }, {
          id: 'coi',
          checked: true
        }
      ],
      assessment: [
        {
          id: 'problem',
          checked: true
        }, {
          id: 'desirableEffects',
          checked: true,
          rating: true
        }, {
          id: 'undesirableEffects',
          checked: true,
          rating: true
        }, {
          id: 'certaintyOfEvidence',
          checked: true
        }, {
          id: 'values',
          checked: true
        }, {
          id: 'balanceOfEffects',
          checked: true,
          rating: true
        }, {
          id: 'resourcesRequired',
          checked: true,
          rating: true
        }, {
          id: 'certaintyOfEvidenceOfRequiredResources',
          checked: true
        }, {
          id: 'costEffectiveness',
          checked: true,
          rating: true
        }, {
          id: 'equity',
          checked: true,
          rating: true
        }, {
          id: 'acceptability',
          checked: true,
          rating: true
        }, {
          id: 'feasibility',
          checked: true,
          rating: true
        }, {
          id: 'additionalSection',
          checked: false
        }
      ],
      conclusions: [
        {
          id: 'multipleRecommendations',
          required: true
        }, {
          id: 'justification',
          required: true
        }, {
          id: 'subgroupConsiderations',
          checked: true
        }, {
          id: 'implementationConsiderations',
          checked: true
        }, {
          id: 'monitoringAndEvaluation',
          checked: true
        }, {
          id: 'researchPriorities',
          checked: true
        }
      ],
      presentations: [
        {
          id: 'clinicians',
          checked: true,
          sections: [
            {
              id: 'background',
              checked: true
            }, {
              id: 'subgroupConsiderations',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'detailedJustification',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }, {
              id: 'implementationConsiderations',
              checked: implementationConsiderationsChecked
            }
          ]
        }, {
          id: 'patients',
          checked: true,
          sections: [
            {
              id: 'whyThisRecommendation',
              checked: true
            }, {
              id: 'whatItMeansForYou',
              checked: true
            }, {
              id: 'whoIsThisFor',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }, {
          id: 'policymakers',
          checked: true,
          sections: [
            {
              id: 'assessment',
              checked: true
            }, {
              id: 'background',
              checked: true
            }, {
              id: 'decision',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'implementation',
              checked: true
            }, {
              id: 'monitoringAndEvaluation',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }
      ]
    }, {
      id: '_overarchingDx-testCrPp',
      name: 'Overarching - Tests - Clinical recommendation - Population Perspective',
      question: [
        {
          id: 'population',
          required: true
        }, {
          id: 'intervention',
          required: true
        }, {
          id: 'purpose',
          checked: true
        }, {
          id: 'role',
          checked: true
        }, {
          id: 'mainOutcomes',
          checked: true
        }, {
          id: 'linkedTreatments',
          checked: true
        }, {
          id: 'setting',
          checked: true
        }, {
          id: 'perspective',
          checked: true
        }, {
          id: 'background',
          checked: true
        }, {
          id: 'subgroups',
          checked: true
        }, {
          id: 'coi',
          checked: true
        }
      ],
      assessment: [
        {
          id: 'problem',
          checked: true
        }, {
          id: 'testAccuracy',
          checked: true,
          rating: true
        }, {
          id: 'desirableEffects',
          checked: true,
          rating: true
        }, {
          id: 'undesirableEffects',
          checked: true,
          rating: true
        }, {
          id: 'certaintyOfEvidenceOfTestAccuracy',
          checked: true
        }, {
          id: 'certaintyOfEvidenceOfTestEffects',
          checked: true
        }, {
          id: 'certaintyOfEvidenceOfManagementEffects',
          checked: true
        }, {
          id: 'certaintyOfEvidenceOfTestResult',
          checked: true
        }, {
          id: 'certaintyOfEffects',
          checked: true
        }, {
          id: 'values',
          checked: true
        }, {
          id: 'balanceOfEffects',
          checked: true,
          rating: true
        }, {
          id: 'resourcesRequired',
          checked: true,
          rating: true
        }, {
          id: 'certaintyOfEvidenceOfRequiredResources',
          checked: true
        }, {
          id: 'costEffectiveness',
          checked: true,
          rating: true
        }, {
          id: 'equity',
          checked: true,
          rating: true
        }, {
          id: 'acceptability',
          checked: true,
          rating: true
        }, {
          id: 'feasibility',
          checked: true,
          rating: true
        }, {
          id: 'additionalSection',
          checked: false
        }
      ],
      conclusions: [
        {
          id: 'multipleRecommendations',
          required: true
        }, {
          id: 'justification',
          required: true
        }, {
          id: 'subgroupConsiderations',
          checked: true
        }, {
          id: 'implementationConsiderations',
          checked: true
        }, {
          id: 'monitoringAndEvaluation',
          checked: true
        }, {
          id: 'researchPriorities',
          checked: true
        }
      ],
      presentations: [
        {
          id: 'clinicians',
          checked: true,
          sections: [
            {
              id: 'background',
              checked: true
            }, {
              id: 'subgroupConsiderations',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'detailedJustification',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }, {
              id: 'implementationConsiderations',
              checked: implementationConsiderationsChecked
            }
          ]
        }, {
          id: 'patients',
          checked: true,
          sections: [
            {
              id: 'whyThisRecommendation',
              checked: true
            }, {
              id: 'whatItMeansForYou',
              checked: true
            }, {
              id: 'whoIsThisFor',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }, {
          id: 'policymakers',
          checked: true,
          sections: [
            {
              id: 'assessment',
              checked: true
            }, {
              id: 'background',
              checked: true
            }, {
              id: 'decision',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'implementation',
              checked: true
            }, {
              id: 'monitoringAndEvaluation',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }
      ]
    }, {
      id: 'overarchingTx-acp',
      name: 'Overarching',
      question: [
        {
          id: 'population',
          required: true
        }, {
          id: 'intervention',
          checked: true
        }, {
          id: 'mainOutcomes',
          checked: true
        }, {
          id: 'setting',
          checked: true
        }, {
          id: 'perspective',
          checked: true
        }, {
          id: 'background',
          checked: true
        }, {
          id: 'coi',
          checked: true
        }
      ],
      assessment: [
        {
          id: 'problem',
          checked: true
        }, {
          id: 'desirableEffects',
          checked: true,
          rating: true
        }, {
          id: 'undesirableEffects',
          checked: true,
          rating: true
        }, {
          id: 'certaintyOfEvidence',
          checked: true,
          rating: true
        }, {
          id: 'values',
          checked: true,
          rating: true
        }, {
          id: 'balanceOfEffects',
          checked: true,
          rating: true
        }, {
          id: 'resourcesRequired',
          checked: true,
          rating: true
        }, {
          id: 'certaintyOfEvidenceOfRequiredResources',
          checked: false,
          rating: true
        }, {
          id: 'costEffectiveness',
          checked: true,
          rating: true
        }, {
          id: 'equity',
          checked: false,
          rating: true
        }, {
          id: 'acceptability',
          checked: false,
          rating: true
        }, {
          id: 'feasibility',
          checked: false,
          rating: true
        }, {
          id: 'additionalSection',
          checked: false
        }
      ],
      conclusions: [
        {
          id: 'multipleRecommendations',
          required: true
        }, {
          id: 'justification',
          required: true
        }, {
          id: 'subgroupConsiderations',
          checked: true
        }, {
          id: 'multipleChronicConditionsAndPolypharmacy',
          checked: true
        }, {
          id: 'clinicalConsiderations',
          checked: true
        }, {
          id: 'researchPriorities',
          checked: true
        }
      ],
      presentations: [
        {
          id: 'clinicians',
          checked: true,
          sections: [
            {
              id: 'background',
              checked: true
            }, {
              id: 'subgroupConsiderations',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'detailedJustification',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }, {
              id: 'clinicalConsiderations',
              checked: true
            }
          ]
        }, {
          id: 'patients',
          checked: true,
          sections: [
            {
              id: 'whyThisRecommendation',
              checked: true
            }, {
              id: 'whatItMeansForYou',
              checked: true
            }, {
              id: 'whoIsThisFor',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }, {
          id: 'policymakers',
          checked: true,
          sections: [
            {
              id: 'assessment',
              checked: true
            }, {
              id: 'background',
              checked: true
            }, {
              id: 'decision',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'implementation',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }
      ]
    }, {
      id: 'overarchingDx-acp',
      name: 'Overarching diagnostic',
      question: [
        {
          id: 'population',
          required: true
        }, {
          id: 'intervention',
          required: true
        }, {
          id: 'purpose',
          checked: true
        }, {
          id: 'role',
          checked: true
        }, {
          id: 'mainOutcomes',
          checked: true
        }, {
          id: 'linkedTreatments',
          checked: true
        }, {
          id: 'setting',
          checked: true
        }, {
          id: 'perspective',
          checked: true
        }, {
          id: 'background',
          checked: true
        }, {
          id: 'subgroups',
          checked: true
        }, {
          id: 'coi',
          checked: true
        }
      ],
      assessment: [
        {
          id: 'problem',
          checked: true
        }, {
          id: 'testAccuracy',
          checked: true,
          rating: true
        }, {
          id: 'desirableEffects',
          checked: true,
          rating: true
        }, {
          id: 'undesirableEffects',
          checked: true,
          rating: true
        }, {
          id: 'certaintyOfEvidenceOfTestAccuracy',
          checked: true,
          rating: true
        }, {
          id: 'certaintyOfEvidenceOfTestEffects',
          checked: true,
          rating: true
        }, {
          id: 'certaintyOfEvidenceOfManagementEffects',
          checked: true,
          rating: true
        }, {
          id: 'certaintyOfEvidenceOfTestResult',
          checked: true,
          rating: true
        }, {
          id: 'certaintyOfEffects',
          checked: true,
          rating: true
        }, {
          id: 'values',
          checked: true,
          rating: true
        }, {
          id: 'balanceOfEffects',
          checked: true,
          rating: true
        }, {
          id: 'resourcesRequired',
          checked: true,
          rating: true
        }, {
          id: 'certaintyOfEvidenceOfRequiredResources',
          checked: true
        }, {
          id: 'costEffectiveness',
          checked: true,
          rating: true
        }, {
          id: 'equity',
          checked: true,
          rating: true
        }, {
          id: 'acceptability',
          checked: true,
          rating: true
        }, {
          id: 'feasibility',
          checked: true,
          rating: true
        }, {
          id: 'additionalSection',
          checked: false
        }
      ],
      conclusions: [
        {
          id: 'multipleRecommendations',
          required: true
        }, {
          id: 'justification',
          required: true
        }, {
          id: 'subgroupConsiderations',
          checked: true
        }, {
          id: 'clinicalConsiderations',
          checked: true
        }, {
          id: 'monitoringAndEvaluation',
          checked: true
        }, {
          id: 'researchPriorities',
          checked: true
        }
      ],
      presentations: [
        {
          id: 'clinicians',
          checked: true,
          sections: [
            {
              id: 'background',
              checked: true
            }, {
              id: 'subgroupConsiderations',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'detailedJustification',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }, {
              id: 'clinicalConsiderations',
              checked: true
            }
          ]
        }, {
          id: 'patients',
          checked: true,
          sections: [
            {
              id: 'whyThisRecommendation',
              checked: true
            }, {
              id: 'whatItMeansForYou',
              checked: true
            }, {
              id: 'whoIsThisFor',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }, {
          id: 'policymakers',
          checked: true,
          sections: [
            {
              id: 'assessment',
              checked: true
            }, {
              id: 'background',
              checked: true
            }, {
              id: 'decision',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'implementation',
              checked: true
            }, {
              id: 'monitoringAndEvaluation',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }
      ]
    }, {
      id: 'good-practice-statement',
      name: 'Good practice-statement',
      question: [
        {
          id: 'population',
          required: true
        }, {
          id: 'intervention',
          required: true
        }, {
          id: 'purpose',
          checked: true
        }, {
          id: 'role',
          checked: true
        }, {
          id: 'linkedTreatments',
          checked: true
        }, {
          id: 'setting',
          checked: true
        }, {
          id: 'perspective',
          checked: true
        }, {
          id: 'background',
          checked: true
        }, {
          id: 'subgroups',
          checked: true
        }, {
          id: 'coi',
          checked: true
        }
      ],
      assessment: [],
      conclusions: [
        {
          id: 'goodPracticeStatement',
          required: true
        }, {
          id: 'clearAndActionable',
          required: true
        }, {
          id: 'theMessageIsNecessary',
          checked: true
        }, {
          id: 'implementationConsiderationsOfAllRelevantOutcomes',
          checked: true
        }, {
          id: 'collectingAndSummarizing',
          checked: true
        }, {
          id: 'rationaleConnectingIndirectEvidence',
          checked: true
        }, {
          id: 'implementationConsiderationsOrRemarks',
          checked: true
        }
      ],
      presentations: []
    }, {
      id: 'keyMessage',
      name: 'Key message',
      question: [
        {
          id: 'population',
          required: true
        }, {
          id: 'intervention',
          required: true
        }, {
          id: 'comparison',
          checked: true
        }, {
          id: 'outcomes',
          checked: true
        }, {
          id: 'linkedTreatments',
          checked: true
        }, {
          id: 'setting',
          checked: true
        }, {
          id: 'perspective',
          checked: true
        }, {
          id: 'background',
          checked: true
        }, {
          id: 'subgroups',
          checked: true
        }, {
          id: 'coi',
          checked: true
        }
      ],
      assessment: [],
      conclusions: [
        {
          id: 'keyMessage',
          required: true
        }, {
          id: 'justification',
          required: true
        }, {
          id: 'limitations',
          checked: true
        }, {
          id: 'considerations',
          checked: true
        }
      ],
      presentations: []
    }, {
      id: 'mdg',
      name: 'MD Guidelines',
      question: [],
      assessment: [],
      conclusions: [
        {
          id: 'mdgRecommendation',
          required: true
        }, {
          id: 'indications',
          required: true
        }, {
          id: 'benefits',
          checked: true
        }, {
          id: 'harms',
          checked: true
        }, {
          id: 'frequencyDoseDuration',
          checked: true
        }, {
          id: 'indicationsForDiscontinuation',
          checked: true
        }, {
          id: 'rationale',
          checked: true
        }, {
          id: 'evidence',
          checked: true
        }
      ],
      presentations: []
    }, {
      id: 'qualityIndicators',
      name: "Quality indicators",
      question: [
        {
          id: 'population',
          required: true
        }, {
          id: 'intervention',
          required: true
        }, {
          id: 'comparison',
          checked: true
        }, {
          id: 'mainOutcomes',
          checked: true
        }, {
          id: 'setting',
          checked: true
        }, {
          id: 'requirements',
          checked: true
        }, {
          id: 'coi',
          checked: true
        }
      ],
      assessment: [
        {
          id: 'scientificallySound',
          checked: true,
          rating: true
        }, {
          id: 'relevantDirect',
          checked: false,
          rating: true
        }, {
          id: 'responsive',
          checked: true,
          rating: true
        }, {
          id: 'feasible',
          checked: true,
          rating: true
        }
      ],
      conclusions: [
        {
          id: 'monitoringAndEvaluationQI',
          required: true
        }
      ],
      presentations: []
    }, {
      id: 'overarchingTx-v2',
      name: 'Overarching - V2',
      question: [
        {
          id: 'population',
          required: true
        }, {
          id: 'intervention',
          checked: true
        }, {
          id: 'mainOutcomes',
          checked: true
        }, {
          id: 'setting',
          checked: true
        }, {
          id: 'perspective',
          checked: true
        }, {
          id: 'background',
          checked: true
        }, {
          id: 'coi',
          checked: true
        }
      ],
      assessment: [
        {
          id: 'problem',
          checked: true
        }, {
          id: 'desirableEffects',
          checked: true,
          perOutcomes: true
        }, {
          id: 'undesirableEffects',
          checked: true,
          perOutcomes: true
        }, {
          id: 'netBalance',
          checked: true,
          parentSections: ['desirableEffects', 'undesirableEffects']
        }, {
          id: 'certaintyOfEvidence',
          checked: true,
          perOutcomes: true
        }, {
          id: 'values',
          checked: true,
          parentSections: ['netBalance', 'certaintyOfEvidence']
        }, {
          id: 'balanceOfEffects',
          checked: true,
          parentSections: ['desirableEffects', 'undesirableEffects', 'netBalance', 'certaintyOfEvidence', 'values']
        }, {
          id: 'resourcesRequired',
          checked: true,
          perIntervention: true
        }, {
          id: 'certaintyOfEvidenceOfRequiredResources',
          checked: true,
          perIntervention: true
        }, {
          id: 'costEffectiveness',
          checked: true,
          perIntervention: true
        }, {
          id: 'equity',
          checked: true,
          perIntervention: true
        }, {
          id: 'acceptability',
          checked: true,
          perIntervention: true
        }, {
          id: 'feasibility',
          checked: true,
          perIntervention: true
        }, {
          id: 'additionalSection',
          checked: false
        }
      ],
      conclusions: [
        {
          id: 'recommendationTypeInterventionMultipleChoice',
          required: true
        }, {
          id: 'multipleRecommendations',
          required: true
        }, {
          id: 'justification',
          required: true
        }, {
          id: 'subgroupConsiderations',
          checked: true
        }, {
          id: 'multipleChronicConditionsAndPolypharmacy',
          checked: true
        }, {
          id: 'clinicalConsiderations',
          checked: true
        }, {
          id: 'researchPriorities',
          checked: true
        }
      ],
      presentations: [
        {
          id: 'clinicians',
          checked: true,
          sections: [
            {
              id: 'background',
              checked: true
            }, {
              id: 'subgroupConsiderations',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'detailedJustification',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }, {
              id: 'clinicalConsiderations',
              checked: true
            }
          ]
        }, {
          id: 'patients',
          checked: true,
          sections: [
            {
              id: 'whyThisRecommendation',
              checked: true
            }, {
              id: 'whatItMeansForYou',
              checked: true
            }, {
              id: 'whoIsThisFor',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }, {
          id: 'policymakers',
          checked: true,
          sections: [
            {
              id: 'assessment',
              checked: true
            }, {
              id: 'background',
              checked: true
            }, {
              id: 'decision',
              checked: true
            }, {
              id: 'justification',
              checked: true
            }, {
              id: 'implementation',
              checked: true
            }, {
              id: 'summaryOfFindings',
              checked: true
            }, {
              id: 'relatedRecommendations',
              checked: true
            }
          ]
        }
      ]
    }
  ];
};

module.exports = templatesDefinitions;
