var Controller, GdtRouter, ProjectController, Projects, ProjectsListView, canAccessProjectsList, mediator,
  __hasProp = {}.hasOwnProperty,
  __extends = function(child, parent) { for (var key in parent) { if (__hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; };

ProjectsListView = require('views/projects_list_view');

Projects = require('models/projects');

Controller = require('controllers/base/controller');

GdtRouter = require('router');

mediator = require('mediator');

canAccessProjectsList = function(workspace) {
  if (workspace === 'personal') {
    return mediator.user.get('hasPersonalSubscription');
  } else {
    return workspace in mediator.userOrganizations;
  }
};

module.exports = ProjectController = (function(_super) {
  __extends(ProjectController, _super);

  function ProjectController() {
    return ProjectController.__super__.constructor.apply(this, arguments);
  }

  ProjectController.prototype.historyURL = 'projects';

  ProjectController.prototype.start = function(params) {
    var moduleToRedirectTo;
    moduleToRedirectTo = mediator.services.switches.isOn('mda') ? 'mda-topics' : _.contains(mediator.user.getAccessRights(), 'mdg_panel_reviewer') ? 'document-sections' : mediator.services.switches.isServerSwitchOn('mdgFeatures') && mediator.user.isAdmin() ? 'dashboard' : 'evidence-syntheses';
    return this.redirectTo(GdtRouter.prototype.routeForModule(moduleToRedirectTo, params));
  };

  ProjectController.prototype.list = function(params) {
    var workspace, _ref, _ref1;
    workspace = (_ref = params.organizationId) != null ? _ref : 'personal';
    if (canAccessProjectsList(workspace)) {
      mediator.activeWorkspace = workspace;
      return this.view = new ProjectsListView({
        collection: mediator.projects,
        organizationId: params.organizationId,
        onboardingAutostart: (_ref1 = params.onboardingTourAutostart) != null ? _ref1 : false
      });
    } else {
      return this.redirectTo('organizations');
    }
  };

  ProjectController.prototype.mostRecentChange = function() {
    return alert('implement me');
  };

  ProjectController.prototype.myMostRecentChange = function() {
    return alert('implement me');
  };

  return ProjectController;

})(Controller);
