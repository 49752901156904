'use strict';
var parent = require('../../../actual/string/virtual');
// TODO: remove from `core-js@4`
require('../../../modules/esnext.string.at');
require('../../../modules/esnext.string.code-points');
// TODO: remove from `core-js@4`
require('../../../modules/esnext.string.match-all');
require('../../../modules/esnext.string.replace-all');

module.exports = parent;
