VotingAdminComment = require 'components/etd/voting_admin_comment'
PanelResponsesGroup =  require 'components/etd/panel_voice_etd/panel_responses_group'
Translation = require 'components/mixins/translation'
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
{ getVotingOptionLabel } = require 'lib/etd_helper'
{ string, func, instanceOf, bool } = PropTypes

PanelVoiceResponsesDetails = createReactClass
  displayName: 'PanelVoiceResponsesDetails'

  propTypes:
    activeOption: string
    adminVotingComment: instanceOf(Immutable.Map)
    etdPart: string.isRequired
    handleAdminVotingSummaryUpdate: func.isRequired
    memberNamesMap: instanceOf(Immutable.Map).isRequired
    readOnly: bool.isRequired
    renderMode: string.isRequired
    sectionId: string.isRequired
    votingResultsByOption: instanceOf(Immutable.Map)
    votedEtdData: instanceOf(Immutable.Map)

  mixins: [
    CustomRenderMixin
    Translation('es:recommendations.table')
  ]

  getMemberResponsesForOption: (option) ->
    { votingResultsByOption, memberNamesMap } = @props

    votingResultsByOption
      .get option
      .map (memberVote) =>
        memberId = memberVote.get 'memberId'
        # according to current design of PanelVoice process there cannot be a situation when
        # member leaves both a 'content' and a 'comment', so it is safe to consider that either
        memberComment = memberVote.get('comment')
        Immutable.Map
          memberName: memberNamesMap.get memberId
          responseText: if memberComment? then memberComment else memberVote.get('content', '')

  render: ->
    { votingResultsByOption, activeOption, etdPart, votedEtdData } = @props

    <div className='responses-details'>
      <div className='title'>{@i18n 'responses'}</div>
      {if activeOption
        <PanelResponsesGroup
          responses={@getMemberResponsesForOption activeOption}
          votedOptionLabel={getVotingOptionLabel votedEtdData, activeOption, etdPart}
        />
      else
        votingResultsByOption.map (_, votedOption) =>
          <PanelResponsesGroup
            key={votedOption}
            responses={@getMemberResponsesForOption votedOption}
            votedOptionLabel={getVotingOptionLabel votedEtdData, votedOption, etdPart}
          />
        .toList()
      }
      <VotingAdminComment
        adminComment={@props.adminVotingComment}
        renderMode={@props.renderMode}
        onApply={@props.handleAdminVotingSummaryUpdate}
        readOnly={@props.readOnly}
      />
    </div>

module.exports = PanelVoiceResponsesDetails
