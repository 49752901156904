EditButton = require 'components/common/edit_button'
EtdVotingMixin = require 'components/mixins/etd_voting_mixin'
OptionsTypeSection = require 'components/etd/conclusions_options_type_selection'
MultiInterventionTypeOfRecommendation =
  require 'components/overarching_questions/multiintervention_etd/multiintervention_type_of_recommendation'

TypeOfRecommendation = createReactClass
  displayName: "TypeOfRecommendation"

  propTypes:
    className: PropTypes.string
    editable: PropTypes.bool,
    highlightAsDiff: PropTypes.bool,
    highlightToPanelMembers: PropTypes.bool,
    noAutoSave: PropTypes.bool,
    overarchingQuestionData: PropTypes.instanceOf(Immutable.Map)
    renderMode: PropTypes.string.isRequired
    section: PropTypes.instanceOf(Immutable.Map).isRequired,
    sectionId: PropTypes.string.isRequired,

  mixins: [
    EtdVotingMixin
  ]

  getDefaultProps: ->
    className: 'type-of-recommendation-table-static'
    highlightAsDiff: false
    highlightToPanelMembers: false
    noAutoSave: false
    overarchingQuestionData: Immutable.Map()

  _getConsensusBasedField: (fieldName) ->
    { section } = @props
    draftValue = section.get fieldName
    # when in voting results tab, provide the consensus value (even empty string '') unless it
    # is null/undefined
    if @props.isConsensus
      consensusValue = section.get("#{fieldName}Consensus")
      if consensusValue? then consensusValue else draftValue
    else
      draftValue

  render: ->
    {
      editable
      highlightAsDiff
      highlightToPanelMembers
      isConsensus
      noAutoSave
      onEditClick
      overarchingQuestionData
      readOnly
      renderMode
      section
      sectionId
    } = @props

    contentCellClass = classNames highlighted: highlightToPanelMembers

    editable = if readOnly
      false
    else
      if editable? then editable else @_inEditMode()

    <div onClick={onEditClick unless readOnly or noAutoSave} className={contentCellClass}>
      {if sectionId is 'recommendationTypeInterventionMultipleChoice'
        <MultiInterventionTypeOfRecommendation
          noAutoSave={noAutoSave}
          overarchingQuestionData={overarchingQuestionData}
          options={section.get('options')}
          renderMode={renderMode}
          sectionId={sectionId}
          editable={editable}
          selectedOptions={section.get('selectedOptions')}
        />
      else
        <OptionsTypeSection
          cellId={"#{sectionId}#conclusions"}
          content={@_getConsensusBasedField('content')}
          editable={editable}
          highlightAsDiff={highlightAsDiff}
          isConsensus={isConsensus}
          options={section.get 'options'}
          noAutoSave={noAutoSave}
          renderMode={renderMode}
          sectionId={sectionId}
          selectedOption={@_getConsensusBasedField('selectedOption')}
        />}
    </div>

module.exports = TypeOfRecommendation
