module.exports = {
  udb: {
    create: 'lib/services/udb_service',
    properties: {
      retryTimeout: 500,
      adapter: {
        $ref: 'persistenceAdapter'
      },
      storeAdapter: {
        $ref: 'storePersistenceAdapter'
      }
    }
  },
  projectDb: {
    create: 'lib/services/project_db_service'
  },
  authentication: {
    create: 'lib/services/authentication_service',
    properties: {
      udbService: {
        $ref: 'udb'
      },
      tosService: {
        $ref: 'tosCheck'
      }
    }
  },
  replication: {
    create: 'lib/services/replication_service',
    properties: {
      udbService: {
        $ref: 'udb'
      },
      authentication: {
        $ref: 'authentication'
      },
      clock: {
        $ref: 'clock'
      },
      window: $(window),
      retryTimeout: 1000,
      quietTime: 30000,
      minBreakBetweenBgProjects: 500,
      bgSyncPeriod: 120000,
      connectionTimeout: 40000,
      heartbeat: 10000
    },
    destroy: 'destroy'
  },
  projectSharing: {
    create: 'lib/services/project_sharing_service'
  },
  publication: {
    create: 'lib/services/publication_service'
  },
  switches: {
    create: 'lib/services/switches_service'
  },
  projectMigration: {
    wire: 'lib/project_migrations/context'
  },
  walkthrough: {
    create: 'lib/services/walkthrough_service'
  },
  panelVoiceOnboarding: {
    create: 'lib/services/panel_voice_onboarding_service'
  },
  mobilePublication: {
    create: 'lib/services/mobile_publication_service'
  },
  pdfExport: {
    module: 'lib/services/pdf_export_service'
  },
  email: {
    module: 'lib/services/email_service'
  },
  persistenceAdapter: {
    module: 'lib/pouch_adapter'
  },
  storePersistenceAdapter: {
    module: 'actions/utils/pouch_adapter'
  },
  presentationService: {
    module: 'lib/services/presentation_service'
  },
  mdgService: {
    module: 'lib/services/mdg_service'
  },
  qualityIndicatorsService: {
    module: 'lib/services/quality_indicators_service'
  },
  tosCheck: {
    create: 'lib/services/tos_check_service'
  },
  modules: {
    module: 'lib/services/module_service'
  },
  startup: {
    create: 'lib/services/startup_service',
    properties: {
      tosService: {
        $ref: 'tosCheck'
      },
      walkthroughService: {
        $ref: 'walkthrough'
      },
      switchesService: {
        $ref: 'switches'
      }
    },
    destroy: 'destroy'
  },
  esView: {
    module: 'lib/services/es_view_service'
  },
  clock: {
    module: 'lib/services/clock_service',
    init: 'initialize'
  },
  projectChangelog: {
    create: 'lib/services/project_changelog_service',
    properties: {
      projectDbService: {
        $ref: 'projectDb'
      },
      replicationService: {
        $ref: 'replication'
      },
      clock: {
        $ref: 'clock'
      },
      timestampAccuracy: 5 * 60 * 1000
    },
    destroy: 'destroy'
  },
  jsonldDiagnosticExport: {
    create: 'lib/services/jsonld_exporter/jsonld_export_diagnostic_question_service',
    properties: {
      pouchAdapter: {
        $ref: 'storePersistenceAdapter'
      }
    }
  },
  jsonldManagementExport: {
    create: 'lib/services/jsonld_exporter/jsonld_export_management_question_service',
    properties: {
      pouchAdapter: {
        $ref: 'storePersistenceAdapter'
      }
    }
  },
  jsonldMultiComparisonExport: {
    create: 'lib/services/jsonld_exporter/jsonld_export_multicomparison_service',
    properties: {
      pouchAdapter: {
        $ref: 'storePersistenceAdapter'
      }
    }
  },
  externalAccounts: {
    module: 'lib/services/external_accounts_service'
  },
  dbepPublicationService: {
    module: 'lib/services/dbep_publication_service'
  },
  projectExportService: {
    module: 'lib/services/project_export_service'
  },
  projectImportService: {
    module: 'lib/services/project_import_service'
  },
  projectsInvitations: {
    create: 'lib/services/projects_invitations_service',
    properties: {
      adapter: {
        $ref: 'storePersistenceAdapter'
      },
      udbService: {
        $ref: 'udb'
      }
    }
  },
  subscription: {
    module: 'lib/services/subscription_service'
  },
  overReviewService: {
    create: 'lib/services/over_review_service'
  },
  projectPermissionsService: {
    create: 'lib/services/project_permissions_service'
  },
  licenseLimitsService: {
    create: 'lib/services/license_limits_service'
  },
  projectTaskRunner: {
    create: 'lib/services/project_task_runner_service',
    properties: {
      pouchAdapter: {
        $ref: 'storePersistenceAdapter'
      }
    }
  },
  projectsScreenOnboarding: {
    create: 'lib/services/projects_screen_onboarding_service'
  },
  globalExportService: {
    create: 'lib/services/global_export_service',
    properties: {
      pouchAdapter: {
        $ref: 'storePersistenceAdapter'
      },
      presentationService: {
        $ref: 'presentationService'
      },
      switchesService: {
        $ref: 'switches'
      }
    }
  },
  decisionAidsService: {
    create: 'lib/services/decision_aids_service'
  },
  adolopmentDataService: {
    create: 'lib/services/adolopment_data_service'
  },
  covidExporter: {
    module: 'lib/services/covid_exporter/covid_exporter'
  },
  cadthExporter: {
    module: 'lib/services/cadth_exporter/cadth_exporter'
  },
  whoTbExporter: {
    module: 'lib/services/who_tb_exporter/who_tb_exporter'
  },
  publicationPlatformExporter: {
    module: 'lib/services/publication_platform_exporter/publication_platform_exporter'
  },
  documentSectionsExportService: {
    module: 'lib/services/document_sections_exporter/document_sections_exporter'
  },
  revmanCSVImportService: {
    module: 'lib/services/revman_csv_import_service'
  }
};
