var ETD_PARTS, GeneralInformationDoc, NOT_VOTED_PARTS_MODES, TYPE_OF_RECOMMENDATION_SECTIONS, VOTING_RESULTS_VIEW_TYPES, VOTING_TABS, W, alt, constructViewOptionvalue, decodeEtdViewMode, etdHelper, etdTemplateHelper, etdViewSetter, getEtdPartsByViewMode, getEtdTemplate, getExportOptionsAllPartsVoted, getExportOptionsAssessmentVoted, getExportOptionsConclusionsNotVoted, getFirstKey, getProjectId, getStoreAdapter, isImmutable, mediator, preparePartsBasedOnTemplate, setEtdPartsVotingTab, storeSnapshots, updateRecommendationWithProgressPlusDataFromGeneralInformationDoc,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

alt = require('alt');

etdTemplateHelper = require('stores/utils/etd_template_helper');

GeneralInformationDoc = require('lib/db_docs/general_information_doc');

isImmutable = require('lib/immutable_utils').isImmutable;

getFirstKey = require('lib/immutable_utils').getFirstKey;

mediator = require('mediator');

updateRecommendationWithProgressPlusDataFromGeneralInformationDoc = require('lib/covid_extraction_form_helper').updateRecommendationWithProgressPlusDataFromGeneralInformationDoc;

W = require('when');

TYPE_OF_RECOMMENDATION_SECTIONS = ['recommendationTypeIntervention', 'recommendationTypeOption', 'decisionTypeCoverage', 'decisionTypeImplementation', 'recommendationTypeInterventionMultipleChoice'];

ETD_PARTS = ['judgements', 'recommendation', 'conclusions'];

VOTING_TABS = [
  {
    name: 'draftJudgement',
    labelKey: 'draft_judgement'
  }, {
    name: 'votingResults',
    labelKey: 'voting_results'
  }, {
    name: 'consensus',
    labelKey: 'consensus'
  }
];

VOTING_RESULTS_VIEW_TYPES = ['numeric', 'percent'];

NOT_VOTED_PARTS_MODES = ['proposed', 'empty'];

storeSnapshots = {};

getProjectId = function() {
  return mediator.project.id;
};

getStoreAdapter = function() {
  return mediator.services.storePersistenceAdapter;
};

constructViewOptionvalue = function(votedEtdParts, votedEtdPartsTab, remainingPartsMode) {
  var etdPart, remainingPartsString, votedPartsString;
  if (remainingPartsMode) {
    votedPartsString = votedEtdParts.map(function(part) {
      return "" + part + "-" + votedEtdPartsTab;
    }).join('#');
    remainingPartsString = ETD_PARTS.filter(function(part) {
      return __indexOf.call(votedEtdParts, part) < 0;
    }).map(function(part) {
      return "" + part + "-" + remainingPartsMode;
    }).join('#');
    return "" + votedPartsString + "#" + remainingPartsString;
  } else {
    etdPart = votedEtdParts[0];
    return "" + etdPart + "-" + votedEtdPartsTab;
  }
};

getExportOptionsAllPartsVoted = function(commonVotingTab) {
  return VOTING_TABS.map(function(tabData) {
    var labelKey, name;
    name = tabData.name, labelKey = tabData.labelKey;
    return {
      label: $.t("projects:export_dialog." + labelKey),
      value: constructViewOptionvalue(['ALL'], name),
      checked: commonVotingTab === name
    };
  });
};

getExportOptionsAssessmentVoted = function(currentVotingTab, hasProposedContent) {
  var currentMode;
  currentMode = hasProposedContent ? 'proposed' : 'empty';
  return NOT_VOTED_PARTS_MODES.map(function(mode) {
    var checked, disabled, label, value;
    label = $.t("projects:export_dialog.current_assessment_and_rest_parts_" + mode);
    value = constructViewOptionvalue(['judgements'], currentVotingTab, mode);
    checked = mode === currentMode;
    disabled = mode === 'proposed' && !hasProposedContent;
    return {
      label: label,
      value: value,
      checked: checked,
      disabled: disabled
    };
  });
};

getExportOptionsConclusionsNotVoted = function(currentVotingTab, hasProposedContent) {
  return Immutable.List(VOTING_TABS).flatMap(function(tabData) {
    var currentMode, isCurrentVotingTab, labelKey, name;
    name = tabData.name, labelKey = tabData.labelKey;
    isCurrentVotingTab = name === currentVotingTab;
    currentMode = hasProposedContent ? 'proposed' : 'empty';
    return NOT_VOTED_PARTS_MODES.map(function(mode) {
      var fullKey;
      fullKey = "projects:export_dialog." + labelKey + "_assessment_and_recommendation_" + mode + "_conclusions";
      return {
        label: $.t(fullKey),
        value: constructViewOptionvalue(['judgements', 'recommendation'], name, mode),
        checked: isCurrentVotingTab && mode === currentMode,
        disabled: mode === 'proposed' && !hasProposedContent
      };
    });
  }).toJS();
};

decodeEtdViewMode = function(etdViewMode) {
  if (etdViewMode === 'blank') {
    return etdViewMode;
  }
  return etdViewMode.split('#').map(function(etdPartSpec) {
    var etdPart, viewMode, _ref;
    _ref = etdPartSpec.split('-'), etdPart = _ref[0], viewMode = _ref[1];
    return {
      etdPart: etdPart,
      viewMode: viewMode
    };
  });
};

setEtdPartsVotingTab = function(etdParts, votingTab) {
  var currentlyActiveTabs, votingTabs;
  if (etdParts === 'all') {
    etdParts = ETD_PARTS;
  }
  currentlyActiveTabs = alt.stores.EtdVotingStore.getActiveVotingTabs();
  votingTabs = currentlyActiveTabs.reduce(function(tabs, activeTab, key) {
    if (_.contains(etdParts, key)) {
      tabs[key] = activeTab || votingTab;
    }
    return tabs;
  }, {});
  return alt.actions.EtdVotingActions.setActiveVotingTab(votingTabs);
};

getEtdTemplate = function(templateDocId) {
  var projectDbName, storeAdapter;
  projectDbName = getProjectId();
  storeAdapter = getStoreAdapter();
  return storeAdapter.fetch(projectDbName, templateDocId);
};

getEtdPartsByViewMode = function(etdParts) {
  return Immutable.fromJS(etdParts).groupBy(function(etdPart) {
    return etdPart.get('viewMode');
  }).map(function(etdParts) {
    return etdParts.map(function(etdPart) {
      return etdPart.get('etdPart');
    });
  });
};

etdViewSetter = function(etdTemplate) {
  return function(etdParts, viewMode) {
    var RecommendationTypeCurrentData, RecommendationTypeKey, updatedConclusions;
    if (viewMode === 'empty') {
      if (etdParts.includes('recommendation') && etdParts.includes('conclusions')) {
        return alt.actions.EtdActions.setEtdParts(_.pick(etdTemplate.templateData, 'conclusions'));
      } else {
        RecommendationTypeKey = etdTemplate.templateData.conclusions.sectionsOrder[0];
        RecommendationTypeCurrentData = alt.stores.EtdStore.getState().getIn(['template', 'templateData', 'conclusions', 'sections', RecommendationTypeKey]);
        updatedConclusions = _.pick(etdTemplate.templateData, 'conclusions');
        updatedConclusions.conclusions.sections[RecommendationTypeKey] = RecommendationTypeCurrentData.toJS();
        return alt.actions.EtdActions.setEtdParts(updatedConclusions);
      }
    } else {
      return setEtdPartsVotingTab(etdParts.toArray(), viewMode);
    }
  };
};

preparePartsBasedOnTemplate = function(etdParts, etdTemplate) {
  if (etdParts === 'blank') {
    setEtdPartsVotingTab('all', 'draft_judgement');
    return alt.actions.EtdActions.setEtdParts(_.pick(etdTemplate.templateData, ['assessment', 'conclusions']));
  } else {
    return getEtdPartsByViewMode(etdParts).forEach(etdViewSetter(etdTemplate));
  }
};

etdHelper = {
  VOTING_RESULTS_VIEW_TYPES: VOTING_RESULTS_VIEW_TYPES,
  OVERARCHING_V2_TEMPLATES: ['overarchingTx-v2'],
  getRecommendationTypeSections: function() {
    return TYPE_OF_RECOMMENDATION_SECTIONS;
  },
  getCurrentTORSectionId: function(conclusionsSections) {
    var firstSectionId;
    firstSectionId = getFirstKey(conclusionsSections);
    if (__indexOf.call(TYPE_OF_RECOMMENDATION_SECTIONS, firstSectionId) >= 0) {
      return firstSectionId;
    }
  },
  getEtdParts: function() {
    return ETD_PARTS;
  },
  getAppendicesList: function() {
    var EtdStore, contentSections;
    EtdStore = alt.stores.EtdStore;
    contentSections = EtdStore.getState().getIn(['template', 'templateData', 'assessment', 'researchEvidences']);
    return this.getAppendicesListFromContentSections(contentSections);
  },
  getAppendicesListFromContentSections: function(contentSections) {
    var imageAppendixCount, includedSofTables;
    imageAppendixCount = 0;
    includedSofTables = [];
    return contentSections.reduce(function(acc, editorState, sectionId) {
      var entityMap;
      entityMap = editorState.getIn(['content', 'entityMap'], Immutable.Map());
      if (entityMap.isEmpty()) {
        return acc;
      }
      entityMap.forEach(function(entry) {
        var appendixId, appendixSpec, entryType, idPath, name, section;
        entryType = entry.get('type');
        if (entryType !== 'GDT_TABLE' && entryType !== 'IMAGE') {
          return;
        }
        idPath = entryType === 'IMAGE' ? ['data', 'imageId'] : ['data', 'type'];
        appendixId = entry.getIn(idPath);
        if (entryType === 'GDT_TABLE') {
          if (__indexOf.call(includedSofTables, appendixId) >= 0) {
            return;
          }
          includedSofTables.push(appendixId);
          appendixSpec = {
            appendixId: appendixId
          };
        } else {
          section = _.str.humanize(sectionId).toUpperCase();
          name = "" + ($.t('es:recommendations.insert_modal.image')) + " " + (++imageAppendixCount);
          appendixSpec = {
            appendixId: appendixId,
            isImage: true,
            section: section,
            name: name
          };
        }
        return acc = acc.concat(appendixSpec);
      });
      return acc;
    }, []);
  },
  getReferencesFromEntityMap: function(entityMap, references) {
    if (references == null) {
      references = [];
    }
    if (entityMap.isEmpty()) {
      return references;
    }
    entityMap.forEach(function(entry) {
      var refIds;
      if (entry.get('type') !== 'REFERENCE') {
        return;
      }
      refIds = entry.getIn(['data', 'refIds']);
      return references = _.union(references, refIds.toJS());
    });
    return references;
  },
  getRefIdsFromContent: function(content) {
    var entityMap;
    if (!content || _.isString(content)) {
      return [];
    }
    entityMap = content.get('entityMap', Immutable.Map());
    return this.getReferencesFromEntityMap(entityMap);
  },
  getConsensusBasedField: function(section, fieldName, isConsensus) {
    var consensusValue, draftValue;
    draftValue = section.get(fieldName);
    if (isConsensus) {
      consensusValue = section.get("" + fieldName + "Consensus");
      if (consensusValue != null) {
        return consensusValue;
      } else {
        return draftValue;
      }
    } else {
      return draftValue;
    }
  },
  getReferences: function() {
    var EtdStore, etdState;
    EtdStore = alt.stores.EtdStore;
    etdState = EtdStore.getState();
    return this.getReferencesFromEtd(etdState);
  },
  getReferencesFromEtd: function(etdState) {
    var ReferencesStore, acSections, bgRefIds, conclusionsRefIds, conclusionsSections, conclusionsSectionsOrder, questionBgContent, reAndAcRefIds, reSections, refIds, sections, sectionsOrder;
    ReferencesStore = alt.stores.ReferencesStore;
    reSections = etdState.getIn(['template', 'templateData', 'assessment', 'researchEvidences']);
    acSections = etdState.getIn(['template', 'templateData', 'assessment', 'additionalConsiderations']);
    sections = etdState.getIn(['template', 'templateData', 'assessment', 'sections']);
    sectionsOrder = etdState.getIn(['template', 'templateData', 'assessment', 'sectionsOrder']);
    reAndAcRefIds = sectionsOrder.reduce((function(_this) {
      return function(refs, sectionId) {
        var acContent, acSectionId, reContent, reSectionId, _ref, _ref1;
        reSectionId = sections.getIn([sectionId, 'researchEvidenceId']);
        acSectionId = sections.getIn([sectionId, 'additionalConsiderationId']);
        reContent = (_ref = reSections.getIn([reSectionId, 'contentConsensus'])) != null ? _ref : reSections.getIn([reSectionId, 'content']);
        acContent = (_ref1 = acSections.getIn([acSectionId, 'contentConsensus'])) != null ? _ref1 : acSections.getIn([acSectionId, 'content']);
        refs = refs.concat(_this.getRefIdsFromContent(reContent));
        return refs = refs.concat(_this.getRefIdsFromContent(acContent));
      };
    })(this), []);
    questionBgContent = etdState.getIn(['template', 'templateData', 'question', 'sections', 'background', 'content']);
    if (!Immutable.Map.isMap(questionBgContent)) {
      questionBgContent = Immutable.Map();
    }
    bgRefIds = this.getRefIdsFromContent(questionBgContent);
    conclusionsSections = etdState.getIn(['template', 'templateData', 'conclusions', 'sections'], Immutable.Map());
    conclusionsSectionsOrder = etdState.getIn(['template', 'templateData', 'conclusions', 'sectionsOrder'], Immutable.List());
    conclusionsRefIds = conclusionsSectionsOrder.reduce((function(_this) {
      return function(refs, sectionId) {
        var content, refIds, _ref;
        content = (_ref = conclusionsSections.getIn([sectionId, 'contentConsensus'])) != null ? _ref : conclusionsSections.getIn([sectionId, 'content']);
        refIds = sectionId.indexOf('monitoringAndEvaluation') !== -1 ? _this.getRefIdsFromNestedContent(content).concat(_this.getRefIdsFromContent(content)) : _this.getRefIdsFromContent(content);
        return refs.concat(refIds);
      };
    })(this), []);
    refIds = _.uniq(bgRefIds.concat(reAndAcRefIds, conclusionsRefIds));
    if (_.isEmpty(refIds)) {
      return Immutable.List();
    }
    return ReferencesStore.getReferenceByIds(refIds);
  },
  getRefIdsFromNestedContent: function(content) {
    if (!isImmutable(content)) {
      return [];
    }
    return content.get('entityMap', Immutable.List()).reduce((function(_this) {
      return function(acc, entity) {
        var qiRefIds;
        if (entity.getIn(['data', 'type']) !== 'quality_indicators') {
          return acc;
        }
        if (entity.getIn(['data', 'data', 'qualityIndicators'], Immutable.List()).isEmpty()) {
          return acc;
        }
        qiRefIds = entity.getIn(['data', 'data', 'qualityIndicators'], Immutable.List()).reduce(function(acc2, qualityIndicator) {
          var refIds;
          refIds = qualityIndicator.filter(function(value) {
            return isImmutable(value);
          }).map(function(value) {
            return _this.getRefIdsFromContent(value);
          });
          return _.flatten(acc2.concat(refIds.valueSeq().toJS()));
        }, []);
        return acc.concat(qiRefIds);
      };
    })(this), []);
  },
  setRecommendationsView: function(etdViewMode) {
    var etdParts, etdTemplateId, templateDocId;
    if (!etdViewMode) {
      return W.resolve();
    }
    this.saveState();
    etdParts = decodeEtdViewMode(etdViewMode);
    return W.resolve(_.isArray(etdParts) && etdParts[0].etdPart === 'ALL' ? setEtdPartsVotingTab('all', etdParts[0].viewMode) : (etdTemplateId = alt.stores.EtdStore.getState().getIn(['template', 'templateDef', 'id']), templateDocId = "etd-" + (etdTemplateId.split('-')[0]) + "-template", getEtdTemplate(templateDocId).then(function(etdTemplate) {
      return preparePartsBasedOnTemplate(etdParts, etdTemplate);
    }).otherwise(function(response) {
      if (response.status === 404) {
        return preparePartsBasedOnTemplate(etdParts, etdTemplateHelper.prepareBaseTemplate(etdTemplateId).toJS());
      }
      throw response;
    })));
  },
  saveState: function() {
    var EtdStore, EtdVotingStore, _ref;
    _ref = alt.stores, EtdVotingStore = _ref.EtdVotingStore, EtdStore = _ref.EtdStore;
    return storeSnapshots = alt.takeSnapshot(EtdStore, EtdVotingStore);
  },
  flushSavedState: function() {
    return storeSnapshots = null;
  },
  restoreSavedState: function() {
    alt.bootstrap(storeSnapshots);
    return this.flushSavedState();
  },
  getExportViewOptions: function() {
    var EtdStore, EtdVotingStore, activeTabNames, activeVotingTabs, commonVotingTab, currentAssessmentTab, etd, hasProposedContent, judgementsTab, recommendationTab, votingData, _ref;
    _ref = alt.stores, EtdVotingStore = _ref.EtdVotingStore, EtdStore = _ref.EtdStore;
    votingData = EtdVotingStore.getVotingData();
    etd = EtdStore.getState().getIn(['template', 'templateData']);
    if (votingData.isEmpty()) {
      return null;
    }
    activeVotingTabs = EtdVotingStore.getActiveVotingTabs();
    switch (votingData.size) {
      case 1:
        currentAssessmentTab = activeVotingTabs.get('judgements');
        hasProposedContent = this.hasRecommendationFilled(etd, true) && this.hasConclusionsFilled(etd, true);
        return getExportOptionsAssessmentVoted(currentAssessmentTab, hasProposedContent);
      case 3:
        activeTabNames = activeVotingTabs.groupBy(function(tabName) {
          return tabName;
        });
        commonVotingTab = activeTabNames.size === 1 && activeTabNames.keySeq().first();
        return getExportOptionsAllPartsVoted(commonVotingTab);
      default:
        judgementsTab = activeVotingTabs.get('judgements');
        recommendationTab = activeVotingTabs.get('recommendation');
        commonVotingTab = judgementsTab === recommendationTab && recommendationTab;
        hasProposedContent = this.hasConclusionsFilled(etd, true);
        return getExportOptionsConclusionsNotVoted(commonVotingTab, hasProposedContent);
    }
  },
  hasJudgementsFilled: function(etd, isConsensus) {
    var criterias, sections;
    sections = etd.getIn(['assessment', 'sectionsOrder']);
    criterias = etd.getIn(['assessment', 'criteria']);
    return sections.every(function(sectionName) {
      var hasDraftCriteria;
      hasDraftCriteria = Boolean(criterias.getIn([sectionName, 'selectedOption']));
      if (isConsensus) {
        return Boolean(hasDraftCriteria || criterias.getIn([sectionName, 'selectedOptionConsensus']));
      } else {
        return hasDraftCriteria;
      }
    });
  },
  hasRecommendationFilled: function(etd, isConsensus) {
    var conclusionsSections, typeOfRecommendationSection;
    conclusionsSections = etd.getIn(['conclusions', 'sections']);
    typeOfRecommendationSection = conclusionsSections.find(function(data, sectionId) {
      return TYPE_OF_RECOMMENDATION_SECTIONS.indexOf(sectionId) >= 0;
    });
    if (isConsensus) {
      return Boolean(typeOfRecommendationSection.get('selectedOption') || typeOfRecommendationSection.get('selectedOptionConsensus'));
    } else {
      return Boolean(typeOfRecommendationSection.get('selectedOption'));
    }
  },
  hasConclusionsFilled: function(etd, isConsensus) {
    var conclusionsSections, hasDraftJustification;
    conclusionsSections = etd.getIn(['conclusions', 'sections']);
    hasDraftJustification = Boolean(conclusionsSections.getIn(['justification', 'content']) || conclusionsSections.getIn(['justification', 'details', 'criteria']) && (conclusionsSections.getIn(['recommendation', 'content']) || conclusionsSections.getIn(['decision', 'content'])));
    if (isConsensus) {
      return Boolean(hasDraftJustification || (conclusionsSections.getIn(['justification', 'contentConsensus']) || conclusionsSections.getIn(['justification', 'details', 'criteriaConsensus']) && (conclusionsSections.getIn(['recommendation', 'contentConsensus']) || conclusionsSections.getIn(['decision', 'contentConsensus']))));
    } else {
      return hasDraftJustification;
    }
  },
  getTemplateCode: function(type) {
    switch (type) {
      case 'diagnostic':
        return 'dx';
      case 'management':
        return 'tx';
      case 'overarching:diagnostic':
        return 'overarchingDx';
      case 'overarching:management':
        return 'overarchingTx';
      case 'good-practice-statement':
        return 'goodPracticeStatement';
      case 'keyMessage':
        return type;
      case 'mdgQuestion':
        return 'mdg';
      case 'qualityIndicators':
        return 'qualityIndicators';
      default:
        return 'tx';
    }
  },
  createRecommendationsTable: function(questionType, docId) {
    var projectDbName, questionTypeCode, storeAdapter, templateDocId;
    questionTypeCode = this.getTemplateCode(questionType);
    templateDocId = "etd-" + questionTypeCode + "-template";
    projectDbName = getProjectId();
    storeAdapter = getStoreAdapter();
    return getEtdTemplate(templateDocId).then(function(templ) {
      var withGeneralInformationData;
      templ._id = null;
      templ.$baseTemplRev = templ._rev;
      templ._rev = null;
      templ.templateData.question.docId = docId;
      withGeneralInformationData = mediator.services.switches.isServerSwitchOn('covidFeatures') ? GeneralInformationDoc.at(mediator.project.id).fetch()["catch"](function(err) {
        if (err.status !== 404) {
          throw err;
        }
        return {};
      }).then(function(generalInformationDoc) {
        if (_.isEmpty(generalInformationDoc)) {
          return templ;
        }
        return updateRecommendationWithProgressPlusDataFromGeneralInformationDoc(templ, generalInformationDoc);
      }) : W.resolve(templ);
      return withGeneralInformationData.then(function(doc) {
        return storeAdapter.save(projectDbName, doc);
      });
    });
  },
  ensuredInsertedOutcomesTablesQuestionId: function(etdTable) {
    var content, entityData, entityId, entityMap, questionId, researchEvidences, sectionName;
    questionId = etdTable.templateData.question.docId;
    researchEvidences = etdTable.templateData.assessment.researchEvidences;
    for (sectionName in researchEvidences) {
      content = researchEvidences[sectionName].content;
      if (_.isObject(content) && 'entityMap' in content) {
        entityMap = content['entityMap'];
        for (entityId in entityMap) {
          entityData = entityMap[entityId];
          if (entityData.type === 'GDT_TABLE') {
            entityData.data.qId = questionId;
          }
        }
      }
    }
    return etdTable;
  },
  duplicateRecommendationTable: function(originalRecommendationsId, questionId) {
    var originalEtDId, projectDbName, storeAdapter;
    originalEtDId = originalRecommendationsId[0];
    projectDbName = getProjectId();
    storeAdapter = getStoreAdapter();
    return storeAdapter.fetch(projectDbName, originalEtDId).then((function(_this) {
      return function(originalEtD) {
        originalEtD._id = null;
        originalEtD._rev = null;
        originalEtD.templateData.question.docId = questionId;
        originalEtD = _this.ensuredInsertedOutcomesTablesQuestionId(originalEtD);
        return storeAdapter.save(projectDbName, originalEtD);
      };
    })(this));
  },
  removeRecommendationTable: function(recommendationIds) {
    var projectDbName, storeAdapter;
    if (_.isEmpty(recommendationIds)) {
      return W.resolve([]);
    }
    projectDbName = getProjectId();
    storeAdapter = getStoreAdapter();
    return storeAdapter.fetch(projectDbName, recommendationIds, {
      attachments: true
    }).then(function(result) {
      var etds, etdsToDelete;
      etds = Immutable.fromJS(result.rows.map(function(row) {
        return row.doc;
      }));
      etdsToDelete = etds.map(function(doc) {
        if (doc) {
          return doc.set('_deleted', true);
        }
        return doc;
      });
      return {
        etdsToDelete: etdsToDelete,
        etds: etds
      };
    }).then(function(_arg) {
      var etds, etdsToDelete;
      etdsToDelete = _arg.etdsToDelete, etds = _arg.etds;
      return storeAdapter.bulkDocs(projectDbName, _(etdsToDelete.toJS()).compact()).then(function(deletedDocs) {
        return etds.filterNot(R.isNil).map(function(etd) {
          return etd.set('_rev', _(deletedDocs).findWhere({
            id: etd.get('_id')
          }).rev);
        }).toJS();
      });
    });
  },
  restoreRecommendationTables: function(recommendationTables) {
    return getStoreAdapter().bulkDocs(getProjectId(), recommendationTables);
  },
  getRecommendationTypeSectionName: function(etdDoc) {
    return etdDoc.getIn(['templateData', 'conclusions', 'sectionsOrder']).first();
  },
  getOrderedSections: function(blockData) {
    var sections;
    if (!blockData) {
      return Immutable.Map();
    }
    sections = Immutable.OrderedMap();
    return sections.withMutations(function(sections) {
      return blockData.get('sectionsOrder').map(function(sectionName) {
        return sections.set(sectionName, blockData.getIn(['sections', sectionName]));
      });
    });
  },
  getAssessmentSelectedOption: function(templateData, sectionName) {
    return templateData.getIn(['assessment', 'criteria', sectionName, 'selectedOption']);
  },
  getSelectedOptionFromSection: function(isConsensus, section) {
    var consensusJudgement, draftJudgement;
    draftJudgement = section.get('selectedOption');
    if (isConsensus) {
      consensusJudgement = section.get('selectedOptionConsensus');
      if (consensusJudgement != null) {
        return consensusJudgement;
      } else {
        return draftJudgement;
      }
    } else {
      return draftJudgement;
    }
  },
  prepareSectionsForPrintout: function(sections, sectionName, renderOptions) {
    if (_.isEmpty(renderOptions) || !renderOptions[sectionName].checked) {
      return sections;
    }
    if (renderOptions[sectionName].judgment.checked) {
      return sections;
    }
    switch (sectionName) {
      case 'assessment':
        return sections.map(function(section) {
          return section.set('selectedOption', '');
        });
      case 'recommendation':
        return sections.set('selectedOption', '');
      case 'conclusion':
        return sections.map(function(section, sectionId) {
          if (sectionId === 'justification') {
            section = section.setIn(['details', 'criteria'], Immutable.Map());
          }
          return section.set('content', '');
        });
      default:
        return sections;
    }
  },
  getVotingOptionLabel: function(votedEtdData, option, etdPart) {
    var possibleOptions, prefix, selectedOption;
    if (!option) {
      return $.t('es:recommendations.table.n_a');
    }
    if (option === '_all') {
      return '';
    }
    if ((option === 'agree' || option === 'disagree' || option === 'dont_know') || etdPart === 'recommendation') {
      prefix = option === 'agree' || option === 'disagree' || option === 'dont_know' ? '' : etdPart === 'recommendation' ? 'final_recommendations_judgements.' : void 0;
      return $.t("es:recommendations.table." + prefix + option);
    } else {
      possibleOptions = votedEtdData.get('options', Immutable.List()).concat(votedEtdData.get('additionalOptions', Immutable.List()));
      selectedOption = possibleOptions.find(function(possibleOption) {
        return possibleOption.get('value') === option;
      });
      if (selectedOption) {
        return selectedOption.get('text');
      } else {
        return "";
      }
    }
  },
  getQuestionText: function(question, renderMode) {
    var params, questionType;
    questionType = question.get('type') === 'diagnostic' ? 'dx' : 'tx';
    if (renderMode === 'printout') {
      if (questionType === 'dx') {
        params = {
          indexTest: question.get('indexTest'),
          comparatorTest: question.get('comparatorTest'),
          targetCondition: question.get('targetCondition'),
          healthProblemOrPopulation: question.get('healthProblemOrPopulation')
        };
        if (question.get('comparatorTestPresent')) {
          if (question.get('diagnosticVerb' === 'screen')) {
            return $.t("es:question.templates.diagnostic_vs_BOLD_screen", params);
          } else {
            return $.t("es:question.templates.diagnostic_vs_BOLD", params);
          }
        } else {
          if (question.get('diagnosticVerb' === 'screen')) {
            return $.t("es:question.templates.diagnostic_BOLD_screen", params);
          } else {
            return $.t("es:question.templates.diagnostic_BOLD", params);
          }
        }
      } else {
        params = {
          intervention: question.get('intervention'),
          comparison: question.get('comparison'),
          healthProblemOrPopulation: question.get('healthProblemOrPopulation')
        };
        return $.t("es:question.templates.FOR_HP_BOLD", params);
      }
    } else {
      return question.get('question');
    }
  },
  getOverarchingQuestionText: function(question) {
    return question.get('question');
  },
  getOutcomesString: function(question) {
    var outcomesNames, outcomesString;
    outcomesString = '';
    if (!question.get('outcomes')) {
      return outcomesString;
    }
    outcomesNames = question.get('outcomes').map(function(outcome) {
      return outcome.get('name');
    }).toArray();
    if (_(outcomesNames).isEmpty()) {
      return outcomesString;
    } else {
      return outcomesString + outcomesNames.map(function(outcomeName) {
        return "" + outcomeName + ";";
      }).join(' ');
    }
  },
  getAgeString: function(ages) {
    return ages.map(function(age) {
      return $.t("overarching_questions:labels.age_options." + age);
    }).join(', ');
  },
  getIntentString: function(intents) {
    return intents.last() && $.t("overarching_questions:labels.intent_options." + (intents.last()));
  },
  getTagsString: function(tagKey, tags) {
    return tags.get(tagKey, Immutable.List()).filter(function(tag) {
      return tag.get('included') === true;
    }).map(function(tag) {
      return tag.get('name');
    }).join(', ');
  },
  getTagsByKeys: function(tags, keys) {
    return (tags || Immutable.List()).filter(function(tag) {
      var _ref;
      return (_ref = tag.get('type'), __indexOf.call(keys, _ref) >= 0) && tag.get('included');
    });
  }
};

module.exports = etdHelper;
