module.exports = function () {
    return {
        "assessment": {
            "additionalConsiderations": {
                "acceptability": {
                    "content": ""
                },
                "balanceOfEffects": {
                    "content": ""
                },
                "certaintyOfEffects": {
                    "content": ""
                },
                "certaintyOfEvidence": {
                    "content": ""
                },
                "certaintyOfEvidenceOfManagementEffects": {
                    "content": ""
                },
                "certaintyOfEvidenceOfRequiredResources": {
                    "content": ""
                },
                "certaintyOfEvidenceOfTestAccuracy": {
                    "content": ""
                },
                "certaintyOfEvidenceOfTestEffects": {
                    "content": ""
                },
                "certaintyOfEvidenceOfTestResult": {
                    "content": ""
                },
                "costEffectiveness": {
                    "content": ""
                },
                "desirableEffects": {
                    "content": ""
                },
                "equity": {
                    "content": ""
                },
                "feasibility": {
                    "content": ""
                },
                "problem": {
                    "content": ""
                },
                "resourcesRequired": {
                    "content": ""
                },
                "testAccuracy": {
                    "content": ""
                },
                "undesirableEffects": {
                    "content": ""
                },
                "values": {
                    "content": ""
                }
            },
            "criteria": {
                "acceptability": {
                    "additionalOptions": [
                        {
                            "text": "Varieerub",
                            "value": "varies"
                        },
                        {
                            "text": "Ei oska öelda",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Kas sekkumine on huvitatud osapooltele vastuvõetav?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Ei oska öelda",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "jah",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "Pigem jah",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Pigem ei",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Ei",
                                        "value": "no"
                                    }
                                ],
                                "question": "Kas on huvitatud osapooli, kes ei oleks nõus hüvede, kahjude ja kulude jaotusega?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Ei oska öelda",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "jah",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "Pigem jah",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Pigem ei",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Ei",
                                        "value": "no"
                                    }
                                ],
                                "question": "Kas on huvitatud osapooli, kes ei lepiks lühemas perspektiivis kulude või soovimatute toimetega, ehkki tulevikus tooksid need soovitud toimeid (hüvesid)?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Ei oska öelda",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "jah",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "Pigem jah",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Pigem ei",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Ei",
                                        "value": "no"
                                    }
                                ],
                                "question": "Kas on huvitatud osapooli, kes ei nõustuks soovitud või soovimatutele toimetele omistatud väärtustega (põhjustel, mis puudutaksid neid isiklikult, või nende arusaama tõttu toimete suhtelisest olulisusest teistele)?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Ei oska öelda",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "jah",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "Pigem jah",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Pigem ei",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Ei",
                                        "value": "no"
                                    }
                                ],
                                "question": "Kas sekkumine (võimalus) mõjutaks negatiivselt inimeste autonoomiat?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Ei oska öelda",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "jah",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "Pigem jah",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Pigem ei",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Ei",
                                        "value": "no"
                                    }
                                ],
                                "question": "Kas on huvitatud osapooli, kes ei nõustuks sekkumisega (võimalusega) moraalsetel põhjustel, mis ei ole selle toime inimeste autonoomiale (teistel eetilistel kaalutlustel, nt mittepahasoovlikkus, heasoovlikkus või õiglus)?",
                                "selectedOption": ""
                            }
                        ],
                        "questionsType": "general"
                    },
                    "info": "Kas on tõenäoline, et kõik huvitatud osapooled ei nõustu sekkumise kasude, kahjude ning kulude vahekorraga või ei ole selleks, et pikemas perspektiivis kasu saada, nõus lühemas perspektiivis kahjusid ja kulusid kandma? Kas on tõenäoline, et nad ei nõustu soovitud või soovimatu mõju (erinevate toimete) olulisusele antud hinnangutega või et nad ei nõustu diagnostilise sekkumisega eetilistel kaalutlustel?",
                    "options": [
                        {
                            "text": "Ei",
                            "value": "no"
                        },
                        {
                            "text": "Pigem ei",
                            "value": "probably_no"
                        },
                        {
                            "text": "Pigem jah",
                            "value": "probably_yes"
                        },
                        {
                            "text": "jah",
                            "value": "yes"
                        }
                    ],
                    "ratingDescription": "Which intervention is more acceptable to key stakeholders?",
                    "ratingOptions": [
                        "best_acceptability",
                        "intermediate_acceptability",
                        "worst_acceptability"
                    ],
                    "selectedOption": ""
                },
                "additionalSection": {
                    "description": "Custom criterion description",
                    "info": "Provide custom tooltip",
                    "options": [
                      {
                        "text": "Judgement 1",
                        "value": "option_1"
                      },
                      {
                        "text": "Judgement 2",
                        "value": "option_2"
                      },
                      {
                        "text": "Judgement 3",
                        "value": "option_3"
                      },
                      {
                        "text": "Judgement 4",
                        "value": "option_4"
                      },
                      {
                        "text": "Judgement 5",
                        "value": "option_5"
                      }
                    ],
                    "additionalOptions": [
                      {
                        "text": "Additional judgement 1",
                        "value": "additionalOption_1"
                      },
                      {
                        "text": "Additional judgement 2",
                        "value": "additionalOption_2"
                      }
                    ],
                    "selectedOption": "",
                    "decision": {
                      "selectedOption": "",
                      "comments": ""
                    },
                    "details": {
                      "panelDiscussion": "",
                      // questionsType is one of ['general', 'resources', 'outcomes']
                      "questionsType": "general",
                      "questions": []
                    }
                },
                "balanceOfEffects": {
                    "additionalOptions": [
                        {
                            "text": "Varieerub",
                            "value": "varies"
                        },
                        {
                            "text": "Ei oska öelda",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Kas sekkumise soovitud ja soovimatu mõju vahekord viitab sekkumise või võrdlus(tegevuse) ülekaalule?",
                    "details": {
                        "additionalInfos": [
                            {
                                "criteriaAnswers": [
                                    {
                                        "criterionId": "values"
                                    },
                                    {
                                        "criterionId": "certaintyOfEvidence"
                                    },
                                    {
                                        "criterionId": "desirableEffects"
                                    },
                                    {
                                        "criterionId": "undesirableEffects"
                                    }
                                ],
                                "description": "Selle kriteeriumi üksikasjalikud hindamised hõlmavad kõigi nelja eelneva kriteeriumi hindamisi:"
                            },
                            {
                                "additions": [
                                    "Kuivõrd väärtustavad inimesed tuleviku tulemusnäitajaid vähem, võrreldes praeguste tulemusnäitajatega (nende allahindlusmääraga)?",
                                    "Inimeste suhtumine soovimatutesse toimetesse (kui riskivastased nad on).",
                                    "Inimeste suhtumine soovitud toimetesse (kui riskialtid nad on)."
                                ],
                                "description": "Lisaks võivad töörühmad tahta arvesse võtta (ja vajadusel dokumenteerida) ulatust, mille võrra võivad järgmised kaalutlused mõjutada soovitud ja soovimatu mõju tasakaalu:"
                            }
                        ],
                        "panelDiscussion": ""
                    },
                    "info": "Milline on soovitud ja soovimatu mõju tasakaal, võttes arvesse nii seda, kuivõrd inimesed erinevaid peamisi tulemusnäitajaid väärtustavad, kui suur on sekkumise arvatav/hinnanguline soovitud ja soovimatu mõju (kõigi tulemusnäitajate lõikes), kui suur on kindlus sekkumise mõju hinnangu(te)s, diskonteerimismääras, inimeste riskivalmiduses.",
                    "options": [
                        {
                            "text": "soosib võrdlust",
                            "value": "favors_comparison"
                        },
                        {
                            "text": "pigem soosib võrdlust",
                            "value": "probably_favors_comparison"
                        },
                        {
                            "text": "ei soosi sekkumist ega võrdlust",
                            "value": "doesnt_favor_any"
                        },
                        {
                            "text": "pigem soosib sekkumist",
                            "value": "probably_favors_intervention"
                        },
                        {
                            "text": "soosib sekkumist",
                            "value": "favors_intervention"
                        }
                    ],
                    "ratingDescription": "Which intervention does the balance between desirable and undesirable effects favor?",
                    "ratingOptions": [
                        "best_balance",
                        "intermediate",
                        "worst_balance"
                    ],
                    "selectedOption": ""
                },
                "certaintyOfEffects": {
                    "additionalOptions": [
                        {
                            "text": "kaasatud uuringud puuduvad",
                            "value": "no_included_studies"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Kui kindel võib kokkuvõttes olla testi mõju tõendatuses?",
                    "details": {
                        "panelDiscussion": ""
                    },
                    "info": "Kui hea näidustuse annab uurimistöö kõigi kriitiliste tulemuste tõenäoliste toimete kohta, st tõenäosuse kohta, et toimed erinevad piisavalt uuringutes tuvastatutest, nii et see võib mõjutada otsust diagnostilise sekkumise kohta?",
                    "options": [
                        {
                            "text": "Väga madal",
                            "value": "very_low"
                        },
                        {
                            "text": "madal",
                            "value": "low"
                        },
                        {
                            "text": "keskmine",
                            "value": "moderate"
                        },
                        {
                            "text": "väga",
                            "value": "high"
                        }
                    ],
                    "selectedOption": ""
                },
                "certaintyOfEvidence": {
                    "additionalOptions": [
                        {
                            "text": "kaasatud uuringud puuduvad",
                            "value": "no_included_studies"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Kui kindel võib kokkuvõttes olla sekkumise mõju tõendatuses? ",
                    "details": {
                        "panelDiscussion": ""
                    },
                    "info": "Millise kindluse annab tõendusmaterjal sekkumise mõju kohta kõigi kriitiliste tulemusnäitajate osas ehk kuivõrd tõenäoline on, et sekkumise tegelik mõju võib (mõne tulemusnäitaja osas) teadusuuringutes nähtust erineda sedavõrd, et see mõjutab otsuse langetamist?",
                    "options": [
                        {
                            "text": "Väga madal",
                            "value": "very_low"
                        },
                        {
                            "text": "madal",
                            "value": "low"
                        },
                        {
                            "text": "keskmine",
                            "value": "moderate"
                        },
                        {
                            "text": "väga",
                            "value": "high"
                        }
                    ],
                    "selectedOption": ""
                },
                "certaintyOfEvidenceOfManagementEffects": {
                    "additionalOptions": [
                        {
                            "text": "kaasatud uuringud puuduvad",
                            "value": "no_included_studies"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Kui kindel võib olla testitulemustest lähtuva ravi mõju tõendatuses?",
                    "details": {
                        "panelDiscussion": ""
                    },
                    "options": [
                        {
                            "text": "Väga madal",
                            "value": "very_low"
                        },
                        {
                            "text": "madal",
                            "value": "low"
                        },
                        {
                            "text": "keskmine",
                            "value": "moderate"
                        },
                        {
                            "text": "väga",
                            "value": "high"
                        }
                    ],
                    "selectedOption": ""
                },
                "certaintyOfEvidenceOfRequiredResources": {
                    "additionalOptions": [
                        {
                            "text": "kaasatud uuringud puuduvad",
                            "value": "no_included_studies"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Milline on ressursivajaduse (kulude) tõendatusse aste?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varieerub",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Ei oska öelda",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "ei",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Pigem ei",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Pigem jah",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "jah",
                                        "value": "yes"
                                    }
                                ],
                                "question": "Kas kõik olulised ressursid, mis sekkumisel ja võrdlusel võivad erineda, on välja selgitatud?",
                                "resources": []
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Ei oska öelda",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Oluline ebakindlus",
                                        "value": "important"
                                    },
                                    {
                                        "text": "võimalik oluline ebakindlus",
                                        "value": "probably_important"
                                    },
                                    {
                                        "text": "Oluline ebakindlus arvatavasti puudub",
                                        "value": "probably_no_important"
                                    },
                                    {
                                        "text": "Oluline ebakindlus puudub",
                                        "value": "no_important"
                                    }
                                ],
                                "question": "Kui kindel võib olla nende ressursside maksumuses, mis sekkumisel ja võrdlusel võivad erineda?",
                                "resources": []
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Ei oska öelda",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Oluline varieeruvus",
                                        "value": "important"
                                    },
                                    {
                                        "text": "Võimalik oluline varieeruvus",
                                        "value": "probably_important"
                                    },
                                    {
                                        "text": "Oluline varieeruvus eeldatavalt puudub",
                                        "value": "probably_no_important"
                                    },
                                    {
                                        "text": "Oluline varieeruvus puudub",
                                        "value": "no_important"
                                    }
                                ],
                                "question": "Kas nende ressursside maksumuses, mis sekkumisel ja võrdlusel erineda võivad, on suur varieeruvus?",
                                "resources": []
                            }
                        ],
                        "questionsType": "resources"
                    },
                    "info": "Kui hea on tõendusmaterjal erinevate ressursside vajaduse ja nende maksumuse kohta?",
                    "options": [
                        {
                            "text": "Väga madal",
                            "value": "very_low"
                        },
                        {
                            "text": "madal",
                            "value": "low"
                        },
                        {
                            "text": "keskmine",
                            "value": "moderate"
                        },
                        {
                            "text": "väga",
                            "value": "high"
                        }
                    ],
                    "selectedOption": ""
                },
                "certaintyOfEvidenceOfTestAccuracy": {
                    "additionalOptions": [
                        {
                            "text": "kaasatud uuringud puuduvad",
                            "value": "no_included_studies"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Kui kindel võib olla testi täpsuse tõendatuses?",
                    "details": {
                        "panelDiscussion": ""
                    },
                    "options": [
                        {
                            "text": "Väga madal",
                            "value": "very_low"
                        },
                        {
                            "text": "madal",
                            "value": "low"
                        },
                        {
                            "text": "keskmine",
                            "value": "moderate"
                        },
                        {
                            "text": "väga",
                            "value": "high"
                        }
                    ],
                    "selectedOption": ""
                },
                "certaintyOfEvidenceOfTestEffects": {
                    "additionalOptions": [
                        {
                            "text": "kaasatud uuringud puuduvad",
                            "value": "no_included_studies"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Kui kindel võib olla kriitiliste või oluliste otseste hüvede, kõrvaltoimete või testikoormuse tõendatuses?",
                    "details": {
                        "panelDiscussion": ""
                    },
                    "options": [
                        {
                            "text": "Väga madal",
                            "value": "very_low"
                        },
                        {
                            "text": "madal",
                            "value": "low"
                        },
                        {
                            "text": "keskmine",
                            "value": "moderate"
                        },
                        {
                            "text": "väga",
                            "value": "high"
                        }
                    ],
                    "selectedOption": ""
                },
                "certaintyOfEvidenceOfTestResult": {
                    "additionalOptions": [
                        {
                            "text": "kaasatud uuringud puuduvad",
                            "value": "no_included_studies"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Kui kindel on seos testi tulemuste ja raviotsuste vahel?",
                    "details": {
                        "panelDiscussion": ""
                    },
                    "options": [
                        {
                            "text": "Väga madal",
                            "value": "very_low"
                        },
                        {
                            "text": "madal",
                            "value": "low"
                        },
                        {
                            "text": "keskmine",
                            "value": "moderate"
                        },
                        {
                            "text": "väga",
                            "value": "high"
                        }
                    ],
                    "selectedOption": ""
                },
                "costEffectiveness": {
                    "additionalOptions": [
                        {
                            "text": "Varieerub",
                            "value": "varies"
                        },
                        {
                            "text": "kaasatud uuringud puuduvad",
                            "value": "no_included_studies"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Kas sekkumise kulutõhusus soosib sekkumist või võrdlust?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "kaasatud uuringud puuduvad",
                                        "value": "no_included_studies"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Ei",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Pigem ei",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Pigem jah",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "jah",
                                        "value": "yes"
                                    }
                                ],
                                "question": "Kas kulutõhususe määr varieerub ühe muutujaga tundlikkusanalüüsides oluliselt?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "kaasatud uuringud puuduvad",
                                        "value": "no_included_studies"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Ei",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Pigem ei",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Pigem jah",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "jah",
                                        "value": "yes"
                                    }
                                ],
                                "question": "Kas kulutõhususe määr varieerub mitme muutujaga tundlikkusanalüüsis oluliselt?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "kaasatud uuringud puuduvad",
                                        "value": "no_included_studies"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Ei",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Väga tõsised piirangud",
                                        "value": "very_serious"
                                    },
                                    {
                                        "text": "Tõsised piirangud",
                                        "value": "serious"
                                    },
                                    {
                                        "text": "Tõsised piirangud puuduvad",
                                        "value": "no_serious"
                                    }
                                ],
                                "question": "Kas kulutõhususe hinnangu aluseks olev majanduslik hindamine on usaldusväärne?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "kaasatud uuringud puuduvad",
                                        "value": "no_included_studies"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Ei",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Väga tõsised piirangud",
                                        "value": "very_serious"
                                    },
                                    {
                                        "text": "Tõsised piirangud",
                                        "value": "serious"
                                    },
                                    {
                                        "text": "Tõsised piirangud puuduvad",
                                        "value": "no_serious"
                                    }
                                ],
                                "question": "Kas kulutõhususe hinnangu aluseks olev majanduslik hindamine on asjakohane ka käsitletavas olukorras?",
                                "selectedOption": ""
                            }
                        ],
                        "questionsType": "general"
                    },
                    "info": "Kas sekkumine on kulutõhus, arvestades kulude määramatust või muutlikkust, netokasu määramatust või muutlikkust, tundlikkusanalüüse ning majandusliku hinnangu usaldusväärsust ja asjakohasust?",
                    "options": [
                        {
                            "text": "soosib võrdlust",
                            "value": "favors_comparison"
                        },
                        {
                            "text": "pigem soosib võrdlust",
                            "value": "probably_favors_comparison"
                        },
                        {
                            "text": "ei soosi sekkumist ega võrdlust",
                            "value": "doesnt_favor_any"
                        },
                        {
                            "text": "pigem soosib sekkumist",
                            "value": "probably_favors_intervention"
                        },
                        {
                            "text": "soosib sekkumist",
                            "value": "favors_intervention"
                        }
                    ],
                    "ratingDescription": "Which intervention does the cost effectiveness favor?",
                    "ratingOptions": [
                        "best_cost_effectiveness",
                        "intermediate_cost_effectiveness",
                        "worst_cost_effectiveness"
                    ],
                    "selectedOption": ""
                },
                "desirableEffects": {
                    "additionalOptions": [
                        {
                            "text": "Varieerub",
                            "value": "varies"
                        },
                        {
                            "text": "Ei oska öelda",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Kui suur on eeldatav soovitud mõju?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varieerub",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Ei oska öelda",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Tühine",
                                        "value": "trivial"
                                    },
                                    {
                                        "text": "Väike",
                                        "value": "small"
                                    },
                                    {
                                        "text": "keskmine",
                                        "value": "moderate"
                                    },
                                    {
                                        "text": "Suur",
                                        "value": "large"
                                    }
                                ],
                                "question": "Kui tuntav on eeldatav toime (erinevus) iga peamise tulemusnäitaja osas, mille puhul soovitud toime esineb?",
                                "selectedOptions": {}
                            }
                        ],
                        "questionsType": "outcomes"
                    },
                    "info": "Kui suur on kokkuvõttes sekkumise soovitud mõju, võttes arvesse nii tulemusnäitajate olulisust (seda, kuivõrd oluliseks neid peetakse) kui sekkumise mõju suurust (kui suure tõenäosusega saab inimene sekkumisest kasu või kui suur see kasu on)?",
                    "options": [
                        {
                            "text": "Tühine",
                            "value": "trivial"
                        },
                        {
                            "text": "Väike",
                            "value": "small"
                        },
                        {
                            "text": "keskmine",
                            "value": "moderate"
                        },
                        {
                            "text": "Suur",
                            "value": "large"
                        }
                    ],
                    "ratingDescription": "How do interventions compare against each other with regards to desirable effects?",
                    "ratingOptions": [
                        "most_effective",
                        "intermediate_effectiveness",
                        "least_effective"
                    ],
                    "selectedOption": ""
                },
                "equity": {
                    "additionalOptions": [
                        {
                            "text": "Varieerub",
                            "value": "varies"
                        },
                        {
                            "text": "Ei oska öelda",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Kuivõrd sekkumine mõjutab tervisevõimaluste võrdsust?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Ei oska öelda",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Jah",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "Pigem jah",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Pigem ei",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Ei",
                                        "value": "no"
                                    }
                                ],
                                "question": "Kas selle probleemiga seoses on mingeid rahvastikurühmi, kes võivad selle sekkumise korral sattuda ebasoodsamasse või ebavõrdsemasse olukorda?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Ei oska öelda",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "jah",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "Pigem jah",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Pigem ei",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Ei",
                                        "value": "no"
                                    }
                                ],
                                "question": "Kas on alust arvata, et selle sekkumise suhteline tõhusus võib vähemsoodsas ollukorras olevas rahvatikurühmas olla teistsugune?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Ei oska öelda",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "jah",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "Pigem jah",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Pigem ei",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Ei",
                                        "value": "no"
                                    }
                                ],
                                "question": "Kas erinevad algtingimused erinevates rahvastikurühmades võivad tingida selle, et sekkumise absoluutne tõhusus (sekkumise mõju) või probleemi olulisus on neis rahvastikurühmades erinev?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Ei oska öelda",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "jah",
                                        "value": "yes"
                                    },
                                    {
                                        "text": "Pigem jah",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "Pigem ei",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Ei",
                                        "value": "no"
                                    }
                                ],
                                "question": "Kas on mingeid olulisi asjaolusid, mida tuleks seda sekkumist ellu viies arvesse võtta, et võimalusel tagada ebavõrdsuse vähenemine või vähemalt ebavõrdsust mitte suurendada?",
                                "selectedOption": ""
                            }
                        ],
                        "questionsType": "general"
                    },
                    "info": "Kas on põhjust arvata, et ebasoodsamas olukorras inimestel on sekkumise suhteline toime teistsugune või mõjutab nende inimeste erinev lähteolukord sekkumise tulemuslikkust või probleemi olulisust?",
                    "options": [
                        {
                            "text": "vähendab võrdsust",
                            "value": "reduced"
                        },
                        {
                            "text": "tõenäoliselt vähendab võrdsust",
                            "value": "probably_reduced"
                        },
                        {
                            "text": "tõenäoliselt ei mõjuta võrdsust",
                            "value": "probably_no_impact"
                        },
                        {
                            "text": "tõenäoliselt suurendab võrdsust",
                            "value": "probably_increased"
                        },
                        {
                            "text": "suurendab võrdsust",
                            "value": "increased"
                        }
                    ],
                    "ratingDescription": "If recommended, which intervention would reduce health inequities the most?",
                    "ratingOptions": [
                        "most_reduction",
                        "intermediate_reduction",
                        "less_reduction"
                    ],
                    "selectedOption": ""
                },
                "feasibility": {
                    "additionalOptions": [
                        {
                            "text": "Varieerub",
                            "value": "varies"
                        },
                        {
                            "text": "Ei oska öelda",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Kas sekkumine on teostatav?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varieerub",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Ei oska öelda",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Ei",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Pigem ei",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Pigem jah",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "jah",
                                        "value": "yes"
                                    }
                                ],
                                "question": "Kas sekkumine (võimalus) on teostatav?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varieerub",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Ei oska öelda",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Ei",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Pigem ei",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Pigem jah",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "jah",
                                        "value": "yes"
                                    }
                                ],
                                "question": "Kas on olulisi takistusi, mis tõenäoliselt piiravad sekkumise (võimaluse) teostatavust või mida tuleb teostamisel arvesse võtta?",
                                "selectedOption": ""
                            }
                        ],
                        "questionsType": "general"
                    },
                    "info": "Kas sekkumine on elluviidav/teostatav ja kas on võimalik kõrvaldada seda takistada võivad tegurid?",
                    "options": [
                        {
                            "text": "Ei",
                            "value": "no"
                        },
                        {
                            "text": "Pigem ei",
                            "value": "probably_no"
                        },
                        {
                            "text": "Pigem jah",
                            "value": "probably_yes"
                        },
                        {
                            "text": "jah",
                            "value": "yes"
                        }
                    ],
                    "ratingDescription": "Which intervention is more feasible to implement?",
                    "ratingOptions": [
                        "most_feasible",
                        "intermediate_feasibility",
                        "least_feasible"
                    ],
                    "selectedOption": ""
                },
                "problem": {
                    "additionalOptions": [
                        {
                            "text": "Varieerub",
                            "value": "varies"
                        },
                        {
                            "text": "Ei oska öelda",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Kas probleem on prioriteetne?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varieerub",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Ei oska öelda",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Ei",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Pigem ei",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Pigem jah",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "jah",
                                        "value": "yes"
                                    }
                                ],
                                "question": "Kas probleemi tagajärjed on tõsised (st tuntavad või olulised võimalike hüvede või säästu osas)?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varieerub",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Ei oska öelda",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Ei",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Pigem ei",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Pigem jah",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "jah",
                                        "value": "yes"
                                    }
                                ],
                                "question": "Kas probleem on pakiline?",
                                "selectedOption": ""
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varieerub",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Ei oska öelda",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Ei",
                                        "value": "no"
                                    },
                                    {
                                        "text": "Pigem ei",
                                        "value": "probably_no"
                                    },
                                    {
                                        "text": "Pigem jah",
                                        "value": "probably_yes"
                                    },
                                    {
                                        "text": "jah",
                                        "value": "yes"
                                    }
                                ],
                                "question": "Kas see on tunnustatud prioriteet (nt põhineb poliitilisel või põhimõttelisel otsusel)?",
                                "selectedOption": ""
                            }
                        ],
                        "questionsType": "general"
                    },
                    "info": "Mida tõsisem või pakilisem on probleem, seda tähtsam on kõnealusele käsitluse (sh ravi) küsimusele lahendus leida.",
                    "options": [
                        {
                            "text": "Ei",
                            "value": "no"
                        },
                        {
                            "text": "Pigem ei",
                            "value": "probably_no"
                        },
                        {
                            "text": "Pigem jah",
                            "value": "probably_yes"
                        },
                        {
                            "text": "jah",
                            "value": "yes"
                        }
                    ],
                    "selectedOption": ""
                },
                "resourcesRequired": {
                    "additionalOptions": [
                        {
                            "text": "Varieerub",
                            "value": "varies"
                        },
                        {
                            "text": "Ei oska öelda",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Kui suur on ressursivajadus (kulud)?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varieerub",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Ei oska öelda",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Tühine",
                                        "value": "trivial"
                                    },
                                    {
                                        "text": "Väike",
                                        "value": "small"
                                    },
                                    {
                                        "text": "keskmine",
                                        "value": "moderate"
                                    },
                                    {
                                        "text": "Suur",
                                        "value": "large"
                                    }
                                ],
                                "question": "Kui suur on erinevus iga kasutatud ressursiühiku vahel, mille jaoks vajatakse vähem ressursse?",
                                "resources": []
                            },
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varieerub",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Ei oska öelda",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Suur",
                                        "value": "large"
                                    },
                                    {
                                        "text": "keskmine",
                                        "value": "moderate"
                                    },
                                    {
                                        "text": "Väike",
                                        "value": "small"
                                    },
                                    {
                                        "text": "Tühine",
                                        "value": "trivial"
                                    }
                                ],
                                "question": "Kui suur on erinevus iga kasutatud ressursiühiku vahel, mille jaoks vajatakse lisaressursse?",
                                "resources": []
                            }
                        ],
                        "questionsType": "resources"
                    },
                    "info": "Kui suur on sekkumiseks vajaminevate ressursside maksumus võrreldes võrdlus(tegevus)e ressursside maksumusega?",
                    "options": [
                        {
                            "text": "suur kulu",
                            "value": "large_costs"
                        },
                        {
                            "text": "keskmine kulu",
                            "value": "moderate_costs"
                        },
                        {
                            "text": "mittearvestatav kulu ja sääst",
                            "value": "negligible_costs_savings"
                        },
                        {
                            "text": "keskmine sääst",
                            "value": "moderate_savings"
                        },
                        {
                            "text": "suur sääst",
                            "value": "large_savings"
                        }
                    ],
                    "ratingOptions": [
                        "less_costs",
                        "intermediate_costs",
                        "most_costs"
                    ],
                    "selectedOption": ""
                },
                "testAccuracy": {
                    "additionalOptions": [
                        {
                            "text": "Varieerub",
                            "value": "varies"
                        },
                        {
                            "text": "Ei oska öelda",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Kui täpne on test?",
                    "details": {
                        "panelDiscussion": ""
                    },
                    "options": [
                        {
                            "text": "Väga ebatäpne",
                            "value": "very_inaccurate"
                        },
                        {
                            "text": "Ebatäpne",
                            "value": "inaccurate"
                        },
                        {
                            "text": "Täpne",
                            "value": "accurate"
                        },
                        {
                            "text": "Väga täpne",
                            "value": "very_accurate"
                        }
                    ],
                    "selectedOption": ""
                },
                "undesirableEffects": {
                    "additionalOptions": [
                        {
                            "text": "Varieerub",
                            "value": "varies"
                        },
                        {
                            "text": "Ei oska öelda",
                            "value": "dont_know"
                        }
                    ],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Kui suur on eeldatav soovimatu mõju?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "additionalOptions": [
                                    {
                                        "text": "Varieerub",
                                        "value": "varies"
                                    },
                                    {
                                        "text": "Ei oska öelda",
                                        "value": "dont_know"
                                    }
                                ],
                                "options": [
                                    {
                                        "text": "Suur",
                                        "value": "large"
                                    },
                                    {
                                        "text": "keskmine",
                                        "value": "moderate"
                                    },
                                    {
                                        "text": "Väike",
                                        "value": "small"
                                    },
                                    {
                                        "text": "Tühine",
                                        "value": "trivial"
                                    }
                                ],
                                "question": "Kui tuntav on eeldatav toime (erinevus) iga peamise tulemusnäitaja osas, mille puhul soovimatu toime esineb?",
                                "selectedOptions": {}
                            }
                        ],
                        "questionsType": "outcomes"
                    },
                    "info": "Kui suur on kokkuvõttes sekkumise soovimatu mõju, võttes arvesse nii tulemusnäitajate olulisust (seda, kuivõrd oluliseks neid peetakse) kui sekkumise mõju suurust (kui suure tõenäosusega saab inimene sekkumisest kasu või kui suur see kasu on)?",
                    "options": [
                        {
                            "text": "Suur",
                            "value": "large"
                        },
                        {
                            "text": "keskmine",
                            "value": "moderate"
                        },
                        {
                            "text": "Väike",
                            "value": "small"
                        },
                        {
                            "text": "Tühine",
                            "value": "trivial"
                        }
                    ],
                    "ratingDescription": "How do interventions compare against each other with regards to undesirable effects?",
                    "ratingOptions": [
                        "least_harmful",
                        "intermediate",
                        "more_harmful"
                    ],
                    "selectedOption": ""
                },
                "values": {
                    "additionalOptions": [],
                    "decision": {
                        "comments": "",
                        "selectedOption": ""
                    },
                    "description": "Kas see, kuivõrd inimesed (inimeste erinevad alarühmad) peamisi tulemusi väärtustavad, varieerub või kui ebakindlad me nende hinnangutes oleme?",
                    "details": {
                        "panelDiscussion": "",
                        "questions": [
                            {
                                "options": [
                                    {
                                        "text": "Oluline ebakindlus",
                                        "value": "important"
                                    },
                                    {
                                        "text": "võimalik oluline ebakindlus",
                                        "value": "probably_important"
                                    },
                                    {
                                        "text": "Oluline ebakindlus arvatavasti puudub",
                                        "value": "probably_no_important"
                                    },
                                    {
                                        "text": "Oluline ebakindlus puudub",
                                        "value": "no_important"
                                    }
                                ],
                                "question": "Kui ebakindel on see, kuivõrd inimesed peamisi tulemusnäitajaid väärtustavad?",
                                "selectedOptions": {}
                            }
                        ],
                        "questionsType": "outcomes"
                    },
                    "info": "Kuivõrd inimesed iga peamist tulemusnäitajat väärtustavad? Kas ebakindlus selles osas, kuivõrd iga erinevat tulemusnäitajat väärtustatakse või selles, kuivõrd varieerub erinevate inimeste hinnang erinevate tulemusnäitajate olulisusele, on piisavalt suur, et viia (mitme) erineva otsuseni?",
                    "options": [
                        {
                            "text": "oluline ebakindlus või varieeruvus",
                            "value": "important_uncertainty"
                        },
                        {
                            "text": "võimalik oluline ebakindlus või varieeruvus",
                            "value": "possible_important"
                        },
                        {
                            "text": "oluline ebakindlus või varieeruvus tõenäoliselt puudub",
                            "value": "probably_no_important"
                        },
                        {
                            "text": "oluline ebakindlus või varieeruvus puudub",
                            "value": "no_important"
                        }
                    ],
                    "selectedOption": ""
                }
            },
            "researchEvidences": {
                "acceptability": {
                    "content": ""
                },
                "balanceOfEffects": {
                    "content": ""
                },
                "certaintyOfEffects": {
                    "content": ""
                },
                "certaintyOfEvidence": {
                    "content": ""
                },
                "certaintyOfEvidenceOfManagementEffects": {
                    "content": ""
                },
                "certaintyOfEvidenceOfRequiredResources": {
                    "content": ""
                },
                "certaintyOfEvidenceOfTestAccuracy": {
                    "content": ""
                },
                "certaintyOfEvidenceOfTestEffects": {
                    "content": ""
                },
                "certaintyOfEvidenceOfTestResult": {
                    "content": ""
                },
                "costEffectiveness": {
                    "content": ""
                },
                "desirableEffects": {
                    "content": ""
                },
                "equity": {
                    "content": ""
                },
                "feasibility": {
                    "content": ""
                },
                "problem": {
                    "content": ""
                },
                "resourcesRequired": {
                    "content": ""
                },
                "testAccuracy": {
                    "content": ""
                },
                "undesirableEffects": {
                    "content": ""
                },
                "values": {
                    "content": ""
                }
            },
            "sections": {
                "acceptability": {
                    "additionalConsiderationId": "acceptability",
                    "criterionId": "acceptability",
                    "name": "Vastuvõetavus",
                    "researchEvidenceId": "acceptability"
                },
                "additionalSection": {
                    "name": "Custom criterion",
                    "criterionId": "additionalSection",
                    "researchEvidenceId": "additionalSection",
                    "additionalConsiderationId": "additionalSection"
                },
                "balanceOfEffects": {
                    "additionalConsiderationId": "balanceOfEffects",
                    "criterionId": "balanceOfEffects",
                    "name": "Mõjude tasakaal",
                    "researchEvidenceId": "balanceOfEffects"
                },
                "certaintyOfEffects": {
                    "additionalConsiderationId": "certaintyOfEffects",
                    "criterionId": "certaintyOfEffects",
                    "name": "Toimete kindlus ",
                    "researchEvidenceId": "certaintyOfEffects"
                },
                "certaintyOfEvidence": {
                    "additionalConsiderationId": "certaintyOfEvidence",
                    "criterionId": "certaintyOfEvidence",
                    "name": "Tõendatuse kindlus",
                    "researchEvidenceId": "certaintyOfEvidence"
                },
                "certaintyOfEvidenceOfManagementEffects": {
                    "additionalConsiderationId": "certaintyOfEvidenceOfManagementEffects",
                    "criterionId": "certaintyOfEvidenceOfManagementEffects",
                    "name": "Ravi mõju tõendatuse kindlus",
                    "researchEvidenceId": "certaintyOfEvidenceOfManagementEffects"
                },
                "certaintyOfEvidenceOfRequiredResources": {
                    "additionalConsiderationId": "certaintyOfEvidenceOfRequiredResources",
                    "criterionId": "certaintyOfEvidenceOfRequiredResources",
                    "name": "Vajaminevate ressursside tõendatuse kindlus",
                    "researchEvidenceId": "certaintyOfEvidenceOfRequiredResources"
                },
                "certaintyOfEvidenceOfTestAccuracy": {
                    "additionalConsiderationId": "certaintyOfEvidenceOfTestAccuracy",
                    "criterionId": "certaintyOfEvidenceOfTestAccuracy",
                    "name": "Testi täpsuse tõendatus ",
                    "researchEvidenceId": "certaintyOfEvidenceOfTestAccuracy"
                },
                "certaintyOfEvidenceOfTestEffects": {
                    "additionalConsiderationId": "certaintyOfEvidenceOfTestEffects",
                    "criterionId": "certaintyOfEvidenceOfTestEffects",
                    "name": "Testi mõju tõendatuse kindlus ",
                    "researchEvidenceId": "certaintyOfEvidenceOfTestEffects"
                },
                "certaintyOfEvidenceOfTestResult": {
                    "additionalConsiderationId": "certaintyOfEvidenceOfTestResult",
                    "criterionId": "certaintyOfEvidenceOfTestResult",
                    "name": "Kui kindel võib olla testi tulemuste / ravi mõju tõendatuses",
                    "researchEvidenceId": "certaintyOfEvidenceOfTestResult"
                },
                "costEffectiveness": {
                    "additionalConsiderationId": "costEffectiveness",
                    "criterionId": "costEffectiveness",
                    "name": "Kulutõhusus",
                    "researchEvidenceId": "costEffectiveness"
                },
                "desirableEffects": {
                    "additionalConsiderationId": "desirableEffects",
                    "criterionId": "desirableEffects",
                    "name": "Soovitud mõju",
                    "researchEvidenceId": "desirableEffects"
                },
                "equity": {
                    "additionalConsiderationId": "equity",
                    "criterionId": "equity",
                    "name": "Võrdsed võimalused",
                    "researchEvidenceId": "equity"
                },
                "feasibility": {
                    "additionalConsiderationId": "feasibility",
                    "criterionId": "feasibility",
                    "name": "Teostatavus",
                    "researchEvidenceId": "feasibility"
                },
                "problem": {
                    "additionalConsiderationId": "problem",
                    "criterionId": "problem",
                    "name": "Probleem",
                    "researchEvidenceId": "problem"
                },
                "resourcesRequired": {
                    "additionalConsiderationId": "resourcesRequired",
                    "criterionId": "resourcesRequired",
                    "name": "Vajaminevad ressursid",
                    "researchEvidenceId": "resourcesRequired"
                },
                "testAccuracy": {
                    "additionalConsiderationId": "testAccuracy",
                    "criterionId": "testAccuracy",
                    "name": "Testi täpsus",
                    "researchEvidenceId": "testAccuracy"
                },
                "undesirableEffects": {
                    "additionalConsiderationId": "undesirableEffects",
                    "criterionId": "undesirableEffects",
                    "name": "Soovimatu mõju",
                    "researchEvidenceId": "undesirableEffects"
                },
                "values": {
                    "additionalConsiderationId": "values",
                    "criterionId": "values",
                    "name": "Väärtushinnangud",
                    "researchEvidenceId": "values"
                }
            },
            "sectionsOrder": []
        },
        "conclusions": {
            "sections": {
                "clearAndActionable": {
                    "content": "",
                    "description": "Clear and actionable"
                },
                "collectingAndSummarizing": {
                    "content": "",
                    "description": "Collecting and summarizing the evidence is a poor use of the guideline panel's limited time and energy (opportunity cost in large)"
                },
                "decision": {
                    "content": "",
                    "description": "Otsus",
                    "options": [
                        {
                            "text": "Täielik rakendamine",
                            "value": "full_implementation"
                        },
                        {
                            "text": "Mõju hindamine",
                            "value": "impact_evaluation"
                        },
                        {
                            "text": "Pilootuuring",
                            "value": "pilot_study"
                        },
                        {
                            "text": "Edasi lükata",
                            "value": "postpone"
                        },
                        {
                            "text": "Mitte rakendada",
                            "value": "do_not_implement"
                        }
                    ]
                },
                "decisionTypeCoverage": {
                    "content": "",
                    "description": "Otsuse liik",
                    "options": [
                        {
                            "direction": "negative",
                            "text": "Ära rahasta",
                            "value": "do_not_cover"
                        },
                        {
                            "direction": "positive",
                            "text": "Rahasta tõendusmaterjali täienemisel",
                            "value": "cover_with_evidence"
                        },
                        {
                            "direction": "positive",
                            "text": "Rahasta pärast hinnaläbirääkimisi",
                            "value": "cover_with_price"
                        },
                        {
                            "direction": "positive",
                            "text": "Rahasta piiratud sihtgrupil",
                            "value": "restricted_coverage"
                        },
                        {
                            "direction": "positive",
                            "text": "Rahastada",
                            "value": "cover"
                        }
                    ],
                    "selectedOption": ""
                },
                "decisionTypeImplementation": {
                    "content": "",
                    "description": "Otsuse liik",
                    "options": [
                        {
                            "direction": "negative",
                            "text": "Ära võta sekkumist kasutusse",
                            "value": "do_not_implement"
                        },
                        {
                            "direction": "none",
                            "text": "Lükka otsustamine edasi",
                            "value": "postpone"
                        },
                        {
                            "direction": "none",
                            "text": "Vii läbi sekkumise pilootuuring",
                            "value": "conduct_pilot_study"
                        },
                        {
                            "direction": "positive",
                            "text": "Võta sekkumine kasutusse pärast (selle) mõju hindamist",
                            "value": "implement_with_evaluation"
                        },
                        {
                            "direction": "positive",
                            "text": "Võta sekkumine kasutusse",
                            "value": "implement"
                        }
                    ],
                    "selectedOption": ""
                },
                "goodPracticeStatement": {
                    "content": "",
                    "description": "Good practice statement"
                },
                "implementationConsiderations": {
                    "content": "",
                    "description": "Rakenduskaalutlused",
                    "details": {
                        "content": "",
                        "description": "Üksikasjalik rakendus"
                    }
                },
                "implementationConsiderationsOfAllRelevantOutcomes": {
                    "content": "",
                    "description": "After consideration of all relevant outcomes and potential downstream consequences, implementing the good practice statement results in a large net positive consequences"
                },
                "justification": {
                    "content": "",
                    "description": "Põhjendus",
                    "details": {
                        "description": "Üksikasjalik põhjendus"
                    },
                    "overallDescription": "Üldine põhjendus"
                },
                "monitoringAndEvaluation": {
                    "content": "",
                    "description": "Jälgimine ja hindamine",
                    "details": {
                        "content": "",
                        "description": "Üksikasjalik jälgimine ja hindamine"
                    }
                },
                "multipleRecommendations": {
                    "description": "",
                    "options": [
                        {
                            "direction": "negative",
                            "text": "Tugev soovitus mitte teha",
                            "value": "recommend_against"
                        },
                        {
                            "direction": "negative",
                            "text": "Nõrk soovitus sekkumise vastu",
                            "value": "suggest_against"
                        },
                        {
                            "direction": "none",
                            "text": "Nõrk soovitus kas sekkumise või alternatiivi poolt",
                            "value": "suggest_either"
                        },
                        {
                            "direction": "positive",
                            "text": "Nõrk soovitus sekkumise poolt",
                            "value": "suggest"
                        },
                        {
                            "direction": "positive",
                            "text": "Tugev soovitus sekkumise poolt",
                            "value": "recommend"
                        }
                    ],
                    "recommendations": [
                        {
                            "content": "",
                            "selectedOption": ""
                        }
                    ]
                },
                "rationaleConnectingIndirectEvidence": {
                    "content": "",
                    "description": "There is a well-documented clear and explicit rationale connecting the indirect evidence"
                },
                "recommendation": {
                    "content": "",
                    "description": "Soovitus"
                },
                "recommendationTypeIntervention": {
                    "content": "",
                    "description": "Soovituse liik",
                    "options": [
                        {
                            "direction": "negative",
                            "text": "Tugev soovitus mitte teha",
                            "value": "recommend_against"
                        },
                        {
                            "direction": "negative",
                            "text": "Nõrk soovitus sekkumise vastu",
                            "value": "suggest_against"
                        },
                        {
                            "direction": "none",
                            "text": "Nõrk soovitus kas sekkumise või alternatiivi poolt",
                            "value": "suggest_either"
                        },
                        {
                            "direction": "positive",
                            "text": "Nõrk soovitus sekkumise poolt",
                            "value": "suggest"
                        },
                        {
                            "direction": "positive",
                            "text": "Tugev soovitus teha",
                            "value": "recommend"
                        }
                    ],
                    "selectedOption": ""
                },
                "recommendationTypeOption": {
                    "content": "",
                    "description": "Soovituse liik",
                    "options": [
                        {
                            "direction": "negative",
                            "text": "Tugev soovitus mitte teha",
                            "value": "recommend_against"
                        },
                        {
                            "direction": "negative",
                            "text": "Tingimuslik vastuseis sekkumisele",
                            "value": "suggest_against"
                        },
                        {
                            "direction": "none",
                            "text": "Valige sekkumine või alternatiiv või pole kohaldatav",
                            "value": "suggest_either"
                        },
                        {
                            "direction": "positive",
                            "text": "Nõrk soovitus sekkumise poolt",
                            "value": "suggest"
                        },
                        {
                            "direction": "positive",
                            "text": "Tugev soovitus teha",
                            "value": "recommend"
                        }
                    ],
                    "selectedOption": ""
                },
                "researchPriorities": {
                    "content": "",
                    "description": "Edasiste/täpsustavate uuringute vajadus"
                },
                "restrictions": {
                    "content": "",
                    "description": "Piirangud"
                },
                "subgroupConsiderations": {
                    "content": "",
                    "description": "Kaalutlused alamrühmade osas"
                },
                "theMessageIsNecessary": {
                    "content": "",
                    "description": "The message is necessary in regard to actual health care practice"
                }
            }
        },
        "presentations": {
            "sections": {
                "clinicians": {
                    "name": "Tervishoiutöötajad",
                    "sections": {
                        "background": {
                            "name": "Taust"
                        },
                        "detailedJustification": {
                            "name": "Üksikasjalik põhjendus"
                        },
                        "justification": {
                            "name": "Põhjendus"
                        },
                        "relatedRecommendations": {
                            "name": "Seotud soovitused"
                        },
                        "subgroupConsiderations": {
                            "name": "Kaalutlused alamrühmade osas"
                        },
                        "summaryOfFindings": {
                            "name": "Tõendusmaterjali kokkuvõte"
                        },
                        "implementationConsiderations": {
                             "name": "Implementation considerations"
                        }
                    },
                    "sectionsOrder": []
                },
                "patients": {
                    "name": "Patsiendid",
                    "sections": {
                        "relatedRecommendations": {
                            "name": "Seotud soovitused"
                        },
                        "summaryOfFindings": {
                            "name": "SoF"
                        },
                        "whatItMeansForYou": {
                            "name": "Mida see Sulle tähendab?",
                            "parts": {
                                "speakWithHCProfessional": {
                                    "additionalFields": [
                                        {
                                            "content": ""
                                        },
                                        {
                                            "content": ""
                                        }
                                    ],
                                    "content": "",
                                    "name": "Räägi oma tervishoiutöötajaga"
                                },
                                "whatYouCanDo": {
                                    "content": "",
                                    "name": "Mida saad teha"
                                }
                            }
                        },
                        "whoIsThisFor": {
                            "name": "Kellele see on mõeldud?",
                            "parts": {
                                "whoIsThisFor": {
                                    "additionalFields": [
                                        {
                                            "content": ""
                                        }
                                    ],
                                    "content": "",
                                    "name": "Kellele see on mõeldud"
                                }
                            }
                        },
                        "whyThisRecommendation": {
                            "name": "Miks see soovitus?",
                            "parts": {
                                "additionalInformation": {
                                    "content": "",
                                    "name": "Lisateave"
                                },
                                "benefitsAndHarms": {
                                    "content": "",
                                    "name": "Hüved ja kahjud"
                                },
                                "whyThisRecommendation": {
                                    "content": "",
                                    "name": "Miks see soovitus"
                                }
                            }
                        }
                    },
                    "sectionsOrder": []
                },
                "policymakers": {
                    "name": "Tervishoiupoliitika kujundajad",
                    "sections": {
                        "assessment": {
                            "name": "Hindamine"
                        },
                        "background": {
                            "description": "Küsimuse üksikasjad soovituse jaoks",
                            "details": {
                                "name": "Teave otsuse kohta",
                                "sections": {
                                    "date": {
                                        "content": "",
                                        "description": "Kuupäev"
                                    },
                                    "decisionMakers": {
                                        "content": "",
                                        "description": "Otsustajad"
                                    },
                                    "perspective": {
                                        "content": "",
                                        "description": "Vaatenurk"
                                    },
                                    "setting": {
                                        "content": "",
                                        "description": "Kontekst"
                                    }
                                },
                                "sectionsOrder": [
                                    "setting",
                                    "perspective",
                                    "decisionMakers",
                                    "date"
                                ]
                            },
                            "name": "Taust"
                        },
                        "decision": {
                            "content": "",
                            "name": "Otsus",
                            "options": [
                                {
                                    "text": "Täielik rakendamine",
                                    "value": "full_implementation"
                                },
                                {
                                    "text": "Mõju hindamine",
                                    "value": "impact_evaluation"
                                },
                                {
                                    "text": "Pilootuuring",
                                    "value": "pilot_study"
                                },
                                {
                                    "text": "Edasi lükata",
                                    "value": "postpone"
                                },
                                {
                                    "text": "Mitte rakendada",
                                    "value": "do_not_implement"
                                }
                            ],
                            "selectedOption": ""
                        },
                        "implementation": {
                            "name": "Rakendus"
                        },
                        "justification": {
                            "name": "Põhjendus"
                        },
                        "monitoringAndEvaluation": {
                            "name": "Jälgimine ja hindamine"
                        },
                        "relatedRecommendations": {
                            "name": "Seotud soovitused"
                        },
                        "summaryOfFindings": {
                            "name": "Tõendusmaterjali kokkuvõte"
                        }
                    },
                    "sectionsOrder": []
                }
            },
            "sectionsOrder": []
        },
        "question": {
            "docId": "",
            "sections": {
                "age": {
                    "content": "",
                    "name": "Vanus"
                },
                "anticipatedOutcomes": {
                    "content": "",
                    "name": "Eeldatavad tulemusnäitajad"
                },
                "background": {
                    "content": "",
                    "name": "Taust"
                },
                "coi": {
                    "content": "",
                    "name": "Huvide konflikt"
                },
                "comparison": {
                    "content": "",
                    "name": "Võrdlus"
                },
                "intent": {
                    "content": "",
                    "name": "Kavatsus"
                },
                "intervention": {
                    "content": "",
                    "name": "Sekkumine"
                },
                "linkedTreatments": {
                    "content": "",
                    "name": "Seotud raviviisid"
                },
                "mainOutcomes": {
                    "content": "",
                    "name": "Peamised tulemusnäitajad"
                },
                "option": {
                    "content": "",
                    "name": "Võimalus"
                },
                "perspective": {
                    "content": "",
                    "name": "Vaatenurk"
                },
                "population": {
                    "content": "",
                    "name": "Sihtrühm"
                },
                "problem": {
                    "content": "",
                    "name": "Probleem"
                },
                "purpose": {
                    "content": "",
                    "name": "Testi eesmärk"
                },
                "role": {
                    "content": "",
                    "name": "Testi ülesanne"
                },
                "setting": {
                    "content": "",
                    "name": "Kontekst"
                },
                "subgroups": {
                    "content": "",
                    "name": "Alarühmad"
                }
            },
            "sectionsOrder": [],
            "type": ""
        }
    };
};
