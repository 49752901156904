var DecisionAidDoc, QuestionsActions, ReferencesActions, appUtils, currentProject, decisionAidDocPrefix, generateActions, mediator;

mediator = require('mediator');

generateActions = require('actions/utils/generate_actions');

DecisionAidDoc = require('lib/db_docs/decision_aid_doc');

QuestionsActions = require('actions/questions_actions');

ReferencesActions = require('actions/references_actions');

decisionAidDocPrefix = require('lib/doc_prefixes').decisionAid;

appUtils = require('lib/app_utils');

currentProject = function() {
  return mediator.project.id;
};

module.exports = generateActions({
  autoGenerate: ['editChaptersOrder', 'fetchSuccess', 'fetchError', 'setExpandedChapters', 'startDecisionAidEdit', 'stopDecisionAidEdit', 'stopEditingChaptersOrder', 'toggleEditModal'],
  name: 'DecisionAidsActions',
  actions: {
    dbChange: function(change, _pending, _lastSeq) {
      if (!_.str.startsWith(change.id, decisionAidDocPrefix)) {
        return;
      }
      return this.dispatch(change);
    },
    fetch: function(_arg) {
      var projectId;
      projectId = _arg.dbId;
      if (projectId == null) {
        projectId = currentProject();
      }
      DecisionAidDoc.at(projectId).fetchMany().then(this.actions.fetchSuccess)["catch"](this.actions.fetchError);
      QuestionsActions.fetch();
      return ReferencesActions.fetch({
        dbId: projectId,
        fetchUsedReferences: false
      });
    },
    create: function(data) {
      return mediator.services.decisionAidsService.create({
        projectId: currentProject(),
        title: data.title,
        authorOrOrganization: data.authorOrOrganization,
        questionId: data.questionId,
        outcomeIds: data.outcomeIds,
        appearance: {
          theme: data.selectedTheme,
          illustration: data.selectedIllustration
        }
      })["catch"](appUtils.errorHandler);
    },
    saveDecisionAid: function(data) {
      return mediator.services.decisionAidsService.save(data);
    },
    deleteDecisionAid: function(daId) {
      this.dispatch(daId);
      return mediator.services.decisionAidsService["delete"](daId);
    },
    deleteDecisionAidChapter: function(_arg) {
      var chapterId, daId;
      daId = _arg.daId, chapterId = _arg.chapterId;
      return mediator.services.decisionAidsService.deleteDecisionAidChapter(daId, chapterId);
    },
    updateDecisionAidChapter: function(_arg) {
      var chapterData, chapterId, daId;
      daId = _arg.daId, chapterId = _arg.chapterId, chapterData = _arg.chapterData;
      return mediator.services.decisionAidsService.updateDecisionAidChapter(daId, chapterId, chapterData);
    },
    createNewChapter: function(_arg) {
      var chapterData, daId;
      daId = _arg.daId, chapterData = _arg.chapterData;
      return mediator.services.decisionAidsService.createDecisionAidChapter(daId, chapterData);
    },
    saveDecisionAidChaptersOrder: function(_arg) {
      var chaptersOrder, daId;
      daId = _arg.daId, chaptersOrder = _arg.chaptersOrder;
      this.dispatch({
        daId: daId,
        chaptersOrder: chaptersOrder
      });
      return mediator.services.decisionAidsService.saveDecisionAidChaptersOrder(daId, chaptersOrder);
    },
    updateSubchapterIllustration: function(_arg) {
      var chapterId, daId, illustrationData, subchapterIdx;
      daId = _arg.daId, chapterId = _arg.chapterId, subchapterIdx = _arg.subchapterIdx, illustrationData = _arg.illustrationData;
      return mediator.services.decisionAidsService.updateSubchapterIllustration(daId, chapterId, subchapterIdx, illustrationData);
    },
    deleteSubchapterIllustration: function(_arg) {
      var chapterId, daId, subchapterIdx;
      daId = _arg.daId, chapterId = _arg.chapterId, subchapterIdx = _arg.subchapterIdx;
      return mediator.services.decisionAidsService.deleteSubchapterIllustration(daId, chapterId, subchapterIdx);
    },
    updateSubchapterContent: function(_arg) {
      var chapterId, content, daId, subchapterIdx;
      daId = _arg.daId, chapterId = _arg.chapterId, subchapterIdx = _arg.subchapterIdx, content = _arg.content;
      return mediator.services.decisionAidsService.updateSubchapterContent(daId, chapterId, subchapterIdx, content);
    },
    updateDecisionAidSubchapter: function(_arg) {
      var chapterId, daId, subchapterData, subchapterIdx;
      daId = _arg.daId, chapterId = _arg.chapterId, subchapterIdx = _arg.subchapterIdx, subchapterData = _arg.subchapterData;
      return mediator.services.decisionAidsService.updateDecisionAidSubchapter(daId, chapterId, subchapterIdx, subchapterData);
    },
    duplicateSubchapter: function(_arg) {
      var chapterId, daId, subchapterIdx;
      daId = _arg.daId, chapterId = _arg.chapterId, subchapterIdx = _arg.subchapterIdx;
      return mediator.services.decisionAidsService.duplicateSubchapter(daId, chapterId, subchapterIdx);
    },
    deleteSubchapter: function(_arg) {
      var chapterId, daId, subchapterIdx;
      daId = _arg.daId, chapterId = _arg.chapterId, subchapterIdx = _arg.subchapterIdx;
      return mediator.services.decisionAidsService.deleteSubchapter(daId, chapterId, subchapterIdx);
    },
    addNewSubchapter: function(_arg) {
      var chapterId, daId, position, subchapterIdx, title, titleHidden, type;
      daId = _arg.daId, chapterId = _arg.chapterId, subchapterIdx = _arg.subchapterIdx, type = _arg.type, position = _arg.position, title = _arg.title, titleHidden = _arg.titleHidden;
      return mediator.services.decisionAidsService.addNewSubchapter(daId, chapterId, subchapterIdx, position, {
        type: type,
        title: title,
        titleHidden: titleHidden
      });
    },
    saveSubchapterTableContent: function(_arg) {
      var chapterId, daId, subchapterIdx, tableContent;
      daId = _arg.daId, chapterId = _arg.chapterId, subchapterIdx = _arg.subchapterIdx, tableContent = _arg.tableContent;
      return mediator.services.decisionAidsService.saveSubchapterTableContent(daId, chapterId, subchapterIdx, tableContent);
    },
    updateOutcome: function(_arg) {
      var daId, outcomeData, outcomeId, propagateAbsDenominatorChange;
      daId = _arg.daId, outcomeId = _arg.outcomeId, outcomeData = _arg.outcomeData, propagateAbsDenominatorChange = _arg.propagateAbsDenominatorChange;
      return mediator.services.decisionAidsService.updateOutcome(daId, outcomeId, outcomeData, propagateAbsDenominatorChange);
    },
    updateOutcomeComment: function(_arg) {
      var comment, daId, outcomeId;
      daId = _arg.daId, outcomeId = _arg.outcomeId, comment = _arg.comment;
      return mediator.services.decisionAidsService.updateOutcomeComment(daId, outcomeId, comment);
    },
    updateQuestion: function(_arg) {
      var daId, key, value;
      daId = _arg.daId, key = _arg.key, value = _arg.value;
      return mediator.services.decisionAidsService.updateQuestion(daId, key, value);
    },
    markRiskValuePreferred: function(_arg) {
      var cellValue, daId, outcomeId, riskKey;
      daId = _arg.daId, outcomeId = _arg.outcomeId, riskKey = _arg.riskKey, cellValue = _arg.cellValue;
      return mediator.services.decisionAidsService.markRiskValuePreferred(daId, outcomeId, riskKey, cellValue);
    }
  }
});
