{ AutoSizer, List } = ReactVirtualized
Button = require 'components/common/button'
ConnectStore = require 'components/enhancers/connect_store'
{ convertListToOrderedMap } = require 'lib/immutable_utils'
DashboardActions = require 'actions/dashboard_actions'
DashboardStore = require 'stores/dashboard_store'
{ getAccessRightsList, getMembersLoginToNameMapOfCurrentProject } = require 'lib/members_helper'
IconButton = require 'components/common/icon_button'
Modal = require 'components/common/modal'
MultiSelectCustom = require 'components/common/multi_select_custom'
QuestionsStore = require 'stores/questions_store'
SelectCustom = require 'components/common/select_custom'
{ snakeCase } = require 'lodash'
Spinner = require 'components/common/spinner'
Tooltip = require 'components/common/tooltip'
{ useCoffeeCallback, useCoffeeMemo, useI18n } = require 'lib/react_utils'

CATEGORIES = [
  'all'
  'action'
  'user'
  'role'
]

HISTORY_ENTRY_TYPES = [
  'document_sections_section'
  'mdgQuestion'
  'recommendation'
]

getFilterItemsToShow = (i18n, filterCategory, usersIdToNameMap) ->
  switch filterCategory
    when 'action'
      _.map HISTORY_ENTRY_TYPES, (entryType) ->
        label: i18n "action_filter.#{snakeCase entryType}"
        value: entryType
    when 'role'
      _.chain getAccessRightsList()
        .filter ({ value }) -> value isnt 'no_access'
        .map ({ value, text }) -> { label: text, value }
        .value()
    when 'user'
      usersIdToNameMap.entrySeq()
        .sortBy ([_id, name]) -> name
        .map ([value, label]) -> { label, value }
        .toJS()
    else []

DashboardHistoryModal = ({
  docSecChapters
  docSecSections
  filterCategory
  filterItems
  history
  isFetching
  isOpen
  questions
}) ->
  i18n = useI18n "projects:dashboard.history_modal"

  [questionsIdToTitleMap, recommendationsToQuestionsTitleMap] = useCoffeeMemo [questions], ->
    questions.reduce ([questionsAcc, recommendationsAcc], question) ->
      newRecommendations = question.get('recommendationIds', Immutable.List()).reduce (acc, id) ->
        acc.set id, question.get('question')
      , recommendationsAcc
      newQuestions = questionsAcc.set question.get('_id'), question.get('question')
      [newQuestions, newRecommendations]
    , [Immutable.Map(), Immutable.Map()]

  usersIdToNameMap = useCoffeeMemo [], -> getMembersLoginToNameMapOfCurrentProject()

  sectionsIdToChapterTitleMap = useCoffeeMemo [docSecChapters, docSecSections], ->
    chaptersMap = convertListToOrderedMap docSecChapters
    docSecSections.reduce (acc, section) ->
      chapter = chaptersMap.get section.get 'parent'
      return acc unless chapter
      acc.set section.get('_id'), chapter.get 'title'
    , Immutable.Map()

  rowRenderer = useCoffeeCallback [
    history
    i18n
    questionsIdToTitleMap
    recommendationsToQuestionsTitleMap
    sectionsIdToChapterTitleMap
    usersIdToNameMap
  ], ({ index, key, style }) ->
    entry = history.get index
    styleToPass = _.extend {}, style, top: 2 + style.top
    changed = entry.get('revision', '').indexOf('1-') isnt 0
    entryType = entry.get('type')
    i18nKey = "#{snakeCase entryType}_#{if changed then 'changed' else 'created'}"
    i18nParams = switch entryType
      when 'recommendation'
        title: recommendationsToQuestionsTitleMap.get entry.get('docId'),
          i18n 'unknown_question'
      when 'mdgQuestion'
        title: questionsIdToTitleMap.get entry.get('docId'), i18n 'unknown_question'
      else
        title: sectionsIdToChapterTitleMap.get entry.get('docId'), i18n 'unknown_chapter'
    userName = usersIdToNameMap.get entry.get('revAuthor'), i18n 'unknown_user'
    timestampMoment = entry.get 'timestampMoment'
    date = if timestampMoment? then timestampMoment.format('DD/MM/YYYY') else ''
    action = i18n i18nKey, i18nParams

    <div className="history-table__row" key={key} style={styleToPass}>
      <div className="row__user">
        {userName}
      </div>
      <Tooltip>
        <div
          className="row__history-entry-type"
          dangerouslySetInnerHTML={__html: action}
          title={action}
        />
      </Tooltip>
      <div className="row__date">
        {date}
      </div>
    </div>

  onRefresh = useCoffeeCallback [], ->
    DashboardActions.fetchHistory()

  onChangeFilterCategory = useCoffeeCallback [i18n, usersIdToNameMap], (newCategory) ->
    DashboardActions.selectFilterCategory
      category: newCategory
      items: _.pluck getFilterItemsToShow(i18n, newCategory, usersIdToNameMap), 'value'

  filterItemsToShow = useCoffeeMemo [i18n, filterCategory, usersIdToNameMap], ->
    getFilterItemsToShow i18n, filterCategory, usersIdToNameMap

  <Modal
    className="dashboard-history-modal"
    closeButton
    isOpen={isOpen}
    modalSize="full-size"
    onClose={DashboardActions.closeHistoryDialog}
    title={i18n 'title'}
  >
    <div className="dashboard-history-modal__history-modal">
      {if isFetching
        <Spinner />
      else
        <React.Fragment>
          <div className="history-modal__filters">
            <div className="filters__filter">
              <div className="bold">
                {i18n 'filter_by'}
              </div>
              <div>
                <SelectCustom
                  className="filter__category"
                  controlled
                  onChange={onChangeFilterCategory}
                  options={_.map CATEGORIES, (category) ->
                    text: i18n "filters.#{snakeCase category}"
                    value: category
                  }
                  selected={filterCategory}
                />
              </div>
              {filterCategory isnt 'all' and <React.Fragment>
                <div>
                  <MultiSelectCustom
                    className="filter__items"
                    onChange={DashboardActions.selectFilterItems}
                    options={filterItemsToShow}
                    selectAllDisabled={false}
                    selectedOptions={filterItems.toJS()}
                  />
                </div>
                <div>
                  <IconButton iconName="clear" onClick={DashboardActions.clearFilters} />
                </div>
              </React.Fragment>}
            </div>
            <div className="filters__refresh-button">
              <Button
                className="icon refresh pl-20"
                label={i18n '/actions.refresh'}
                onClick={onRefresh}
              />
            </div>
          </div>
          <div className="history-modal__history-table">
            {if history.isEmpty()
              <div className="history-modal__empty-info">
                {i18n 'empty'}
              </div>
            else
              <div className="history-table__header">
                <div className="header__user">
                  {i18n 'user'}
                </div>
                <div className="header__action">
                  {i18n 'action'}
                </div>
                <div className="header__date">
                  {i18n 'date'}
                </div>
              </div>
            }
            <div className="history-table__body">
              <AutoSizer>
                {({ height, width }) ->
                  <List
                    height={height}
                    rowCount={history.size}
                    rowHeight={42}
                    rowRenderer={rowRenderer}
                    width={width}
                  />
                }
              </AutoSizer>
            </div>
          </div>
        </React.Fragment>
      }
    </div>
  </Modal>

DashboardHistoryModal.propTypes =
  docSecChapters: PropTypes.instanceOf(Immutable.List).isRequired
  docSecSections: PropTypes.instanceOf(Immutable.List).isRequired
  history: PropTypes.instanceOf(Immutable.List).isRequired
  isFetching: PropTypes.bool.isRequired
  isOpen: PropTypes.bool.isRequired
  questions: PropTypes.instanceOf(Immutable.List).isRequired

storeConnectors =
  DashboardStore: (Store) ->
    docSecChapters: Store.getDocSecChapters()
    docSecSections: Store.getDocSecSections()
    filterCategory: Store.getFilterCategory()
    filterItems: Store.getFilterSelectedItems()
    history: Store.getHistory()
    isFetching: Store.isFetchingHistory()
    isOpen: Store.isHistoryDialogOpen()
  QuestionsStore: (Store) ->
    questions: Store.getQuestions()

module.exports = ConnectStore DashboardHistoryModal,
  [DashboardStore, QuestionsStore],
  storeConnectors
