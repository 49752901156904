'use strict';
require('../../modules/es.regexp.constructor');
require('../../modules/es.regexp.to-string');
require('../../modules/es.regexp.dot-all');
require('../../modules/es.regexp.exec');
require('../../modules/es.regexp.flags');
require('../../modules/es.regexp.sticky');
require('../../modules/es.regexp.test');
require('../../modules/es.string.match');
require('../../modules/es.string.replace');
require('../../modules/es.string.search');
require('../../modules/es.string.split');
