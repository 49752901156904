{ AutoSizer, List } = ReactVirtualized
CustomRenderMixin = require 'components/mixins/custom_render_mixin'
Button = require 'components/common/button'
mediator = require 'mediator'
Modal = require 'components/common/modal'
ReferenceAttachmentLink = require 'components/references/attachment_link'
ReferencesStore = require 'stores/references_store'
Router = require 'router'
Tooltip = require 'components/common/tooltip'
Translation = require 'components/mixins/translation'
{ authorsToString } = require 'lib/references_utils'

filteredFields = ['authors', 'title', 'year']

ReferencesInsertModal = createReactClass
  displayName: 'ReferencesInsertModal'

  propTypes:
    onClose: PropTypes.func.isRequired
    onInsert: PropTypes.func.isRequired
    initialReferences: PropTypes.instanceOf(Immutable.Map)

  mixins: [
    CustomRenderMixin
    Translation()
  ]

  getDefaultProps: ->
    initialReferences: Immutable.Map()

  getInitialState: ->
    selectedReferences: Immutable.List()
    references: ReferencesStore.getDeduplicatedReferences()
    filterText: ''

  componentDidMount: ->
    @setState selectedReferences: @props.initialReferences.keySeq().toList()

  updateFilterText: ->
    @setState filterText: @filter.value.trim()
    @list?.scrollToPosition 0

  getFilteredReferences: ->
    { references } = @state
    { initialReferences } = @props

    return references if _.isEmpty(@state.filterText) and initialReferences.isEmpty()

    references.filter (ref) =>
      searchString = filteredFields
        .map (fieldName) -> ref.get fieldName, ''
        .join '-'
        .toLowerCase()

      searchString.match(@state.filterText.toLowerCase())? and
        not initialReferences.has ref.get('_id')

  getMissingReferences: ->
    { references } = @state
    existingReferencesIds = references.map((ref) -> ref.get('_id'))
    @props.initialReferences.filter (reference) ->
      not existingReferencesIds.contains reference.get('_id')

  isSubmitDisabled: ->
    # this will prevent from inserting empty set of references but at the same time it will
    # enable possibility to remove all references at once
    @state.selectedReferences.isEmpty() and @props.initialReferences.isEmpty()

  toggleReference: (ev) ->
    refId = ev.currentTarget.value
    refIdx = @state.selectedReferences.findIndex (ref) -> ref is refId
    updatedReferences = if refIdx is -1
      @state.selectedReferences.push refId
    else
      @state.selectedReferences.delete refIdx
    @setState selectedReferences: updatedReferences

  onInsert: ->
    { selectedReferences, references } = @state
    @props.onInsert
      refIds: selectedReferences.toJS()
      references: references.concat(@getMissingReferences().toList()).reduce (acc, ref) ->
        refId = ref.get('_id')
        return acc unless selectedReferences.contains refId
        R.assoc refId, ref.toJS(), acc
      , {}

  renderReference: (missingReferences, style) -> (ref) =>
    refId = ref.get '_id'
    isChecked = @state.selectedReferences.contains refId
    isMissing = missingReferences.has ref.get('_id')
    authors = authorsToString ref.get 'authors'
    year = ref.get 'year'
    title = ref.get 'title'
    attachmentFileName = ref.getIn ['attachment', 'fileName']

    <div key={refId} style={style}>
      <label className="flex flex-row items-start">
        <div className="flex">
          <input
            type='checkbox'
            value={refId}
            checked={isChecked}
            onChange={@toggleReference}
          />
          {isMissing and <Tooltip>
            <span title={@i18n 'references:missing_reference'} className="missing-reference" />
          </Tooltip>}
        </div>
        <div className='title'>
          <span title={"#{authors} (#{year})"}>
            {authors} ({year})
          </span>
          {attachmentFileName and <ReferenceAttachmentLink
            attachmentFileName={attachmentFileName}
            className="attachment-link"
            projectId={mediator.project.id}
            referenceId={refId}
          />}
          <span style={paddingLeft: '12px'} title={title} >
            <i>{title}</i>
          </span>
        </div>
      </label>
    </div>

  rowRenderer: (missingReferences, filteredReferences) -> ({ index, style }) =>
    @renderReference(missingReferences, style)(filteredReferences.get(index))

  _listRef: (el) ->
    @list = el

  render: ->
    missingReferences = @getMissingReferences()
    filteredReferences = @getFilteredReferences()
    { initialReferences } = @props

    <Modal
      className='references-insert-modal'
      closeButton
      isOpen
      onClose={@props.onClose}
      onRequestClose={@props.onClose}
      title={@i18n 'references:insert_title'}
    >
      {if @state.references.isEmpty()
        <div>
          <span>{@i18n '/translation:messages.no_references_message'}</span><br />
          <a href={Router::getProjectRelativeUrl '/references'}>{@i18n 'go_to_references'}</a>
        </div>
      else
        <div>
          <div>
            <div className='references-filter'>
              <input
                ref={(el) => @filter = el}
                defaultValue={@state.filterText}
                onChange={@updateFilterText}
                placeholder={@i18n 'references:filter_placeholder'}
                autoFocus
              />
            </div>
            <div className='references-list'>
              {not initialReferences.isEmpty() and <React.Fragment>
                <div className="initial-references-list">
                  {initialReferences.map(@renderReference(missingReferences)).toList()}
                </div>
                <div className="separator" />
              </React.Fragment>}
              {not filteredReferences.isEmpty() and <div className='references-list-virtualized'>
                <AutoSizer>
                  {({ width, height }) =>
                    <List
                      height={height}
                      overscanRowCount={10}
                      ref={@_listRef}
                      rowCount={filteredReferences.size}
                      rowHeight={45}
                      rowRenderer={@rowRenderer missingReferences, filteredReferences}
                      width={width}
                    />
                  }
                </AutoSizer>
              </div>}
            </div>
          </div>
          <div className="row mt-20">
            <div className="col-6">
              <Button
                className="btn btn-block btn-cancel"
                label={@i18n '/translation:actions.cancel'}
                onClick={@props.onClose}
              />
            </div>
            <div className="col-6">
              <Button
                className="btn btn-block btn-success"
                label={@i18n '/translation:actions.insert'}
                type="submit"
                disabled={@isSubmitDisabled()}
                onClick={@onInsert}
              />
            </div>
          </div>
        </div>
      }
    </Modal>

module.exports = ReferencesInsertModal
