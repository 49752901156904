var EtdActions, EtdAsync, EtdTemplateActions, EtdVotingActions, EtdsActions, Exceptions, MDGQuestionDoc, OrganizationsActions, OverarchingQuestionDoc, QualityIndicatorDoc, QuestionDoc, QuestionGroupsActions, QuestionsActions, ReferencesActions, SINGLE_TEMPLATES, alt, appUtils, callMethod, etdHelper, get, getEtdTemplateDocId, getEtdTemplateType, mediator, prepareContentForMonitoringAndEvaluation, qualityIndicatorDoc, setBaseTemplates, _ref,
  __indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

alt = require('alt');

appUtils = require('lib/app_utils');

EtdAsync = require('actions/async/etd');

etdHelper = require('lib/etd_helper');

EtdsActions = require('actions/etds_actions');

EtdTemplateActions = require('actions/etd_templates_actions');

EtdVotingActions = require('actions/etd_voting_actions');

Exceptions = require('lib/exceptions');

MDGQuestionDoc = require('lib/db_docs/mdg_question_doc');

qualityIndicatorDoc = require('lib/db_docs/quality_indicator_doc');

mediator = require('mediator');

OrganizationsActions = require('actions/organizations_actions');

OverarchingQuestionDoc = require('lib/db_docs/overarching_question_doc');

QualityIndicatorDoc = require('lib/db_docs/quality_indicator_doc');

QuestionDoc = require('lib/db_docs/question_doc');

QuestionGroupsActions = require('actions/question_groups_actions');

QuestionsActions = require('actions/questions_actions');

ReferencesActions = require('actions/references_actions');

callMethod = require('base/lib/utils').callMethod;

get = require('lodash').get;

_ref = require('stores/utils/etd_template_helper'), getEtdTemplateDocId = _ref.getEtdTemplateDocId, setBaseTemplates = _ref.setBaseTemplates, getEtdTemplateType = _ref.getEtdTemplateType;

prepareContentForMonitoringAndEvaluation = require('lib/quality_indicators_helper').prepareContentForMonitoringAndEvaluation;

SINGLE_TEMPLATES = ['mdg', 'overarchingTx', 'overarchingDx', 'keyMessage', 'qualityIndicators'];

EtdActions = (function() {
  function EtdActions() {
    this.generateActions('add', 'fetchError', 'fetchEtdDocSuccess', 'fetchEtdDocError', 'save', 'pouchSaveSuccess', 'pouchSaveError', 'templateUndefined', 'pouchPutAttachmentSuccess', 'pouchPutAttachmentError', 'disableContentEditable', 'saveContentEditable', 'insertContent', 'updateSelectedOutcomes', 'updateHeaderFieldContent', 'disableHeaderEdit', 'switchCellContentMode', 'toggleHeader', 'updateBackground', 'updatePresentationType', 'saveBackground', 'changeAndSaveDecision', 'toggleSofTableInPremiumView', 'setTemplateWithDataForPdf', 'toggleAdolopmentForSection', 'updateEditorContent', 'toggleRelatedQuestion', 'saveAttachment', 'updateConclusionsOption', 'updateAndSaveConclusionsOption', 'setCriterionJustificationValue', 'setOverallJustification', 'setOverallJustificationAndSave', 'deleteCriterionJustification', 'setEtdParts', 'toggleSectionHighlight', 'updatePresentationPatientsSectionContent', 'addPresentationSectionField', 'removePresentationSectionField', 'updatePresentationSectionFieldContent', 'updatePatientsPresentationName', 'toggleEditableFlag', 'setEditableFlag', 'showDefaultTemplateDialog', 'hideDefaultTemplateDialog', 'addRecommendation', 'removeRecommendation', 'updateSelectedRecommendationValue', 'setRating', 'setStarRating', 'setJudgementPerTableCell', 'changeSelectedValue', 'setSelectedOptions');
  }

  EtdActions.prototype.dbChange = function(data) {
    var doc, etdDocId;
    doc = data.doc;
    etdDocId = alt.stores.EtdStore.getTemplate().get('_id');
    if (doc['_id'] === etdDocId) {
      return this.dispatch(doc);
    }
  };

  EtdActions.prototype.fetch = function(_arg) {
    var dbId, docId;
    dbId = _arg.dbId, docId = _arg.docId;
    this.dispatch({
      dbId: dbId,
      docId: docId
    });
    return EtdAsync.fetchEtdDoc(dbId, docId).then(this.actions.fetchSuccess)["catch"](this.actions.fetchError);
  };

  EtdActions.prototype.fetchSuccess = function(response) {
    var etdDoc, _ref1;
    etdDoc = (_ref1 = response.rows[0]) != null ? _ref1.doc : void 0;
    if (etdDoc) {
      QuestionsActions.fetch();
      OrganizationsActions.checkIfCurrentProjectIsInOrganization();
      QuestionGroupsActions.fetch();
      EtdVotingActions.fetch({
        etdId: etdDoc._id
      });
      if (!_.isEmpty(etdDoc.templateData.relatedQuestionsIds)) {
        EtdsActions.fetchEtdsByQuestionIds(etdDoc.templateData.relatedQuestionsIds);
      }
      ReferencesActions.fetch({
        dbId: mediator.project.id,
        fetchUsedReferences: false
      });
      return this.dispatch(etdDoc);
    } else {
      return this.actions.templateUndefined();
    }
  };

  EtdActions.prototype.updateRelatedQuestionsIds = function(relatedQuestionsIds) {
    if (!relatedQuestionsIds.isEmpty()) {
      EtdsActions.fetchEtdsByQuestionIds(relatedQuestionsIds.toArray());
    }
    return this.dispatch(relatedQuestionsIds);
  };

  EtdActions.prototype.createRecommendationsTable = function(projectId, docId, etdType) {
    var templateCode, templateDocId;
    templateCode = etdHelper.getTemplateCode(etdType);
    templateDocId = getEtdTemplateDocId(templateCode);
    return mediator.services.storePersistenceAdapter.exists(projectId, templateDocId).then((function(_this) {
      return function(templateExists) {
        if (!templateExists) {
          return setBaseTemplates(projectId, templateCode).then(function() {
            if (__indexOf.call(SINGLE_TEMPLATES, templateCode) < 0) {
              return _this.actions.showDefaultTemplateDialog();
            }
          });
        }
      };
    })(this)).then((function(_this) {
      return function() {
        return _this.actions.createTable(projectId, docId, etdType);
      };
    })(this))["catch"](appUtils.errorHandler);
  };

  EtdActions.prototype.createTable = function(projectId, docId, etdType) {
    return etdHelper.createRecommendationsTable(etdType, docId).then(function(recommendationsTable) {
      var Doc;
      Doc = (function() {
        switch (etdType) {
          case 'qualityIndicators':
            return QualityIndicatorDoc;
          case 'diagnostic':
          case 'management':
          case 'good-practice-statement':
          case 'keyMessage':
          case '':
            return QuestionDoc;
          case 'mdgQuestion':
            return MDGQuestionDoc;
          default:
            return OverarchingQuestionDoc;
        }
      })();
      return Doc.at(mediator.project.id, docId).updateAsInstance(callMethod('setRecommendationsIds', [recommendationsTable._id]));
    }).then((function(_this) {
      return function(question) {
        return _this.actions.fetch({
          dbId: projectId,
          docId: question.recommendationIds
        });
      };
    })(this))["catch"](appUtils.errorHandler);
  };

  EtdActions.prototype.clearJudgement = function(sectionId, isConsensus) {
    return this.dispatch({
      sectionId: sectionId,
      isConsensus: isConsensus
    });
  };

  EtdActions.prototype.clearAllJudgements = function(isConsensus) {
    return this.dispatch({
      isConsensus: isConsensus
    });
  };

  EtdActions.prototype.clearConclusionsOption = function(sectionId, isConsensus) {
    this.dispatch({
      sectionId: sectionId,
      isConsensus: isConsensus
    });
    return this.actions.save();
  };

  EtdActions.prototype.keepSojStatus = function(status) {
    return this.dispatch({
      sojStatus: status
    });
  };

  EtdActions.prototype.changeImportance = function(sectionKey, newImportance, isConsensus) {
    if (isConsensus == null) {
      isConsensus = false;
    }
    this.dispatch({
      section: sectionKey,
      value: newImportance,
      isConsensus: isConsensus
    });
    return this.actions.save();
  };

  EtdActions.prototype.clearImportance = function(isConsensus) {
    if (isConsensus == null) {
      isConsensus = false;
    }
    this.dispatch({
      isConsensus: isConsensus
    });
    return this.actions.save();
  };

  EtdActions.prototype.changeJudgement = function(sectionId, newJudgement, isConsensus) {
    if (isConsensus == null) {
      isConsensus = false;
    }
    return this.dispatch({
      sectionId: sectionId,
      value: newJudgement,
      isConsensus: isConsensus
    });
  };

  EtdActions.prototype.changeAndSaveJudgement = function(sectionId, newJudgement, isConsensus) {
    if (isConsensus == null) {
      isConsensus = false;
    }
    return this.dispatch({
      sectionId: sectionId,
      value: newJudgement,
      isConsensus: isConsensus
    });
  };

  EtdActions.prototype.updatePresentationType = function(type) {
    return this.dispatch({
      type: type
    });
  };

  EtdActions.prototype.updateRecommendationContent = function(presentationType, content) {
    return this.dispatch({
      presentationType: presentationType,
      content: content
    });
  };

  EtdActions.prototype.updateContentEditableContent = function(contentEditableId, content, options) {
    if (options == null) {
      options = {};
    }
    return this.dispatch({
      contentEditableId: contentEditableId,
      content: content,
      options: options
    });
  };

  EtdActions.prototype.updatePanelDiscussion = function(sectionId, value, isConsensus) {
    if (isConsensus == null) {
      isConsensus = false;
    }
    return this.dispatch({
      sectionId: sectionId,
      value: value,
      isConsensus: isConsensus
    });
  };

  EtdActions.prototype.updateDetailedGeneralQuestion = function(sectionId, questionIdx, value, isConsensus) {
    if (isConsensus == null) {
      isConsensus = false;
    }
    return this.dispatch({
      sectionId: sectionId,
      questionIdx: questionIdx,
      value: value,
      isConsensus: isConsensus
    });
  };

  EtdActions.prototype.updateDetailedOutcomesQuestion = function(sectionId, questionIdx, outcomeId, value, isConsensus) {
    if (isConsensus == null) {
      isConsensus = false;
    }
    return this.dispatch({
      sectionId: sectionId,
      questionIdx: questionIdx,
      outcomeId: outcomeId,
      value: value,
      isConsensus: isConsensus
    });
  };

  EtdActions.prototype.changeDetailedResourceName = function(sectionId, questionIdx, resourceIdx, value, isConsensus) {
    if (isConsensus == null) {
      isConsensus = false;
    }
    return this.dispatch({
      sectionId: sectionId,
      questionIdx: questionIdx,
      resourceIdx: resourceIdx,
      value: value,
      isConsensus: isConsensus
    });
  };

  EtdActions.prototype.updateDetailedResourcesQuestion = function(sectionId, questionIdx, resourceIdx, value, isConsensus) {
    if (isConsensus == null) {
      isConsensus = false;
    }
    return this.dispatch({
      sectionId: sectionId,
      questionIdx: questionIdx,
      resourceIdx: resourceIdx,
      value: value,
      isConsensus: isConsensus
    });
  };

  EtdActions.prototype.addDetailedResource = function(sectionId, questionIdx, isConsensus) {
    if (isConsensus == null) {
      isConsensus = false;
    }
    return this.dispatch({
      sectionId: sectionId,
      questionIdx: questionIdx,
      isConsensus: isConsensus
    });
  };

  EtdActions.prototype.removeDetailedResource = function(sectionId, questionIdx, resourceIdx, isConsensus) {
    if (isConsensus == null) {
      isConsensus = false;
    }
    return this.dispatch({
      sectionId: sectionId,
      questionIdx: questionIdx,
      resourceIdx: resourceIdx,
      isConsensus: isConsensus
    });
  };

  EtdActions.prototype.changeAndSaveCriteriaDecision = function(sectionId, newJudgement) {
    return this.dispatch({
      sectionId: sectionId,
      value: newJudgement
    });
  };

  EtdActions.prototype.addCriterionJustification = function(data) {
    this.dispatch(data);
    return this.actions.save();
  };

  EtdActions.prototype.enableHeaderEdit = function() {
    this.actions.disableContentEditable();
    return this.dispatch();
  };

  EtdActions.prototype.saveHeaderData = function() {
    this.dispatch();
    return this.actions.disableHeaderEdit();
  };

  EtdActions.prototype.enableContentEditable = function(contentEditableId) {
    this.actions.saveHeaderData();
    return this.dispatch(contentEditableId);
  };

  EtdActions.prototype.updateManualContent = function(sectionId, value) {
    return this.dispatch({
      sectionId: sectionId,
      value: value
    });
  };

  EtdActions.prototype.updateAutoGenerationFlag = function(sectionId, value) {
    return this.dispatch({
      sectionId: sectionId,
      value: value
    });
  };

  EtdActions.prototype.hideVote = function(params) {
    this.dispatch(params);
    return this.actions.save();
  };

  EtdActions.prototype.showVote = function(params) {
    this.dispatch(params);
    return this.actions.save();
  };

  EtdActions.prototype.updateRecommendationMetadata = function(field, value) {
    this.dispatch({
      field: field,
      value: value
    });
    return this.actions.save();
  };

  EtdActions.prototype.importMonitoringAndEvaluationToEtd = function() {
    var dbId, err, qualityIndicatorsId;
    dbId = mediator.project.id;
    qualityIndicatorsId = alt.stores.QuestionsStore.getQuestion().get('qualityIndicatorsId');
    if (qualityIndicatorsId) {
      return qualityIndicatorDoc.at(dbId, qualityIndicatorsId).fetch().then(function(qiDoc) {
        return EtdAsync.fetchEtdDoc(dbId, _.first(qiDoc.recommendationIds));
      }).then((function(_this) {
        return function(etdDoc) {
          var cellId, content, requirements;
          cellId = "monitoringAndEvaluation#conclusions";
          content = get(etdDoc, 'templateData.conclusions.sections.monitoringAndEvaluationQI.content', '');
          requirements = get(etdDoc, 'templateData.question.sections.requirements.content', '');
          return _this.actions.updateEditorContent({
            cellId: cellId,
            content: Immutable.fromJS(prepareContentForMonitoringAndEvaluation(content, requirements)),
            options: {
              isConsensus: false
            }
          });
        };
      })(this));
    } else {
      err = new Exceptions.quality_indicators.document_missing;
      return mediator.dialogs.error(err);
    }
  };

  return EtdActions;

})();

module.exports = alt.createActions(EtdActions);
