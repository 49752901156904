var Code, DOCUMENT_SECTIONS_V2_SECTION_TYPE, EditingByInfo, SECTION_STATUSES, boolean, docShape, documentId, initial, mapOf, number, object, oneOf, optional, sectionDocShape, shape, string, _ref;

Code = require('lib/db_docs/field_types/code_type');

documentId = require('lib/db_docs/field_types/document_id');

docShape = require('lib/db_docs/field_types/doc_shape');

initial = require('lib/db_docs/field_types/type_decorators').initial;

oneOf = require('lib/db_docs/field_types/one_of');

mapOf = require('lib/db_docs/field_types/map_of');

shape = require('lib/db_docs/field_types/shape');

_ref = require('lib/db_docs/field_types/built_in_types'), boolean = _ref.boolean, number = _ref.number, object = _ref.object, string = _ref.string;

optional = shape.typeDecorators.optional;

DOCUMENT_SECTIONS_V2_SECTION_TYPE = require('lib/db_docs/doc_types').DOCUMENT_SECTIONS_V2_SECTION_TYPE;

SECTION_STATUSES = require('lib/document_sections_v2_helper').SECTION_STATUSES;

EditingByInfo = shape({
  timestamp: number,
  user: shape({
    id: string,
    name: string
  })
});

sectionDocShape = docShape({
  type: initial(DOCUMENT_SECTIONS_V2_SECTION_TYPE)(string),
  title: string,
  parent: optional(documentId),
  content: optional(object),
  status: optional(oneOf(SECTION_STATUSES)),
  editingBy: optional(EditingByInfo),
  questionId: optional(documentId),
  recommendationId: optional(documentId),
  mdaTableId: optional(documentId),
  selectedAppendices: optional(mapOf(boolean)),
  mdaTopicId: optional(documentId),
  codes: optional(mapOf(Code))
});

module.exports = sectionDocShape;
